import { get, includes, isEmpty, map } from 'lodash';
import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig, isShreeCement8000 } from 'utils/account_utils';
export function isNormalMovementTypeFieldEnabled() {
    return includes([FLEETX_ACCOUNTS.VRC_LOGISTICS], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null));
}
export function getNormalMovementTypeOptions() {
    return [
        {
            value: 'IMPORT',
            label: 'IMPORT',
        },
        {
            value: 'EXPORT',
            label: 'EXPORT',
        },
        {
            value: 'TRANSPORTER',
            label: 'TRANSPORTER',
        },
    ];
}
export function getMovementTypeOptionsForOrient(data) {
    const modifiedMovementTypeOptions = map(data, (item) => ({ label: item, value: item }));
    return modifiedMovementTypeOptions;
}
export function isJobReportsWithTripDetailsEnabled(type = '') {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', null);
    return (includes([FLEETX_ACCOUNTS.SHIVANI, FLEETX_ACCOUNTS.EXPRESS_ROADWAYS, 9263, 2169], accountId) ||
        (type === 'JOB_REPORT_WITH_TRIP_SUMMARY' && includes([10254], accountId)));
}
export function showManuallyClosedBy() {
    const isSwitchedUser = get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
    return isSwitchedUser || !includes([FLEETX_ACCOUNTS.SYNERGY], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null));
}
export function showSAPPayloadInJobDetails() {
    return (getFlagValueFromAccountConfig('isSAPPayloadInJobDetailsEnabled') ||
        includes([FLEETX_ACCOUNTS.BIRLA_DURGAPUR, FLEETX_ACCOUNTS.CHANDERIA, FLEETX_ACCOUNTS.SHREE_CEMENT_8000], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null)));
}
export function excludeCompletedJobBookingInJobCreate() {
    return getFlagValueFromAccountConfig('excludeCompletedJobBookingInJobCreate');
}
export function showVehicleStatusOnJobByLive() {
    return getFlagValueFromAccountConfig('showVehicleStatusOnJobByLive');
}
export function isDeviationTypeFilterEnabled() {
    return getFlagValueFromAccountConfig('isDeviationTypeFilterEnabled');
}
export function isUseLatestJobSummaryAPIEnabled() {
    return true;
    return getFlagValueFromAccountConfig('useLatestJobSummaryAPI');
}
export function isDeviationAlertEnabledInJobFormByDefault() {
    return getFlagValueFromAccountConfig('enableDeviationAlertInJobFormByDefault');
}
export function isShowTempDataAtJobLevelEnabled() {
    return getFlagValueFromAccountConfig('showTempDataAtJobLevel');
}
export function isJobExceptionReportEnabled() {
    return (includes([FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null)) ||
        isShreeCement8000());
}
export function isJindalCustomJobReportEnabledFlag() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', null) === 12205;
}
export function showSecondaryJobAnalyticsForAccount() {
    return (getFlagValueFromAccountConfig('isSecondaryAnalyticsEnabled') ||
        includes([FLEETX_ACCOUNTS.BISLERI, FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null)));
}
export function showAddressBookAnalyticsForAccount() {
    return getFlagValueFromAccountConfig('isAddressBookAnalyticsEnabled');
}
export function showInstantFuelInJobFlag() {
    return getFlagValueFromAccountConfig('showInstantFuelInJob');
}
export function enableSplitAPICallsForControlRoom() {
    return getFlagValueFromAccountConfig('enableSplitAPICallsInControlRoom');
}
export function isJobMileageEnabled() {
    return getFlagValueFromAccountConfig('jobMileage');
}
export function addDownloadedDocumentsInJob() {
    return getFlagValueFromAccountConfig('addDownloadedDocsInJob');
}
export function isUseAddressBookRadiusForGeofenceForCategoriesDefined() {
    return getFlagValueFromAccountConfig('useAddressBookRadiusForGeofenceForCategories');
}
export function isUseAddressBookRadiusForGeofenceEnabled(addressBookCategory = null) {
    const selectedAddressBookCategories = isUseAddressBookRadiusForGeofenceForCategoriesDefined();
    const useAddressBookRadiusForGeofenceFlag = getFlagValueFromAccountConfig('useAddressBookRadiusForGeofence');
    if (useAddressBookRadiusForGeofenceFlag && !isEmpty(selectedAddressBookCategories)) {
        return includes(selectedAddressBookCategories, addressBookCategory);
    }
    return useAddressBookRadiusForGeofenceFlag;
}
export function shouldSortJobLevelCustomFieldInAlphabeticalOrder() {
    return getFlagValueFromAccountConfig('sortJobLevelCustomFieldInAlphabeticalOrder');
}
export function isOldTripUpdateFalseByDefaultInEditJobFlag() {
    return getFlagValueFromAccountConfig('isOldTripUpdateFalseByDefaultInEditJob');
}
export function showJobDowntime() {
    return get(window.FLEETX_LOGGED_IN_USER, 'accountId', null) !== 12985;
}
export function hideEPODQuantityDetailsFlag() {
    return getFlagValueFromAccountConfig('hideEPODQuantityDetails');
}
export function isEPODDocMandatoryForTransporterFlag() {
    return getFlagValueFromAccountConfig('isEPODDocMandatoryForTransporter');
}
export function jobEndDateKeyForMergedTripsForAccount() {
    return getFlagValueFromAccountConfig('jobEndDateKeyForMergedTripsForAccount');
}
export function showDoorStatusForJob() {
    return getFlagValueFromAccountConfig('showDoorStatusForJob');
}
export function showOwnershipOnAssignVehicleFlag() {
    return getFlagValueFromAccountConfig('showOwnershipOnAssignVehicle');
}
export function isMandatoryJobBookingInJobForm() {
    return getFlagValueFromAccountConfig('mandatoryJobBookingInJobForm');
}
