import { get, toLower } from 'lodash';
export function getUserNameFromUserListMini(userListMiniAsMap, userId) {
    const user = get(userListMiniAsMap, userId);
    const fName = get(user, '[1]', '');
    const lName = get(user, '[2]', '');
    return `${fName ? fName : ''} ${lName ? lName : ''}`;
}
export function getFinalLoggedInUserEmailId() {
    const { switchedUserOriginalEmailId } = window.SWITCHED_USER_ORIGINAL_DATA || {};
    const { email } = window.FLEETX_LOGGED_IN_USER || {};
    return toLower(switchedUserOriginalEmailId ? switchedUserOriginalEmailId : email);
}
