import { handle } from 'redux-pack';
import {
    CREATE_CUSTOMER,
    FETCH_CUSTOMER_AUTOCOMPLETE,
    FETCH_CUSTOMER_SUMMARY,
    FETCH_CUSTOMERS,
} from '../../../actions/dashboard/finances/customer_action';
import { get, map, reduce } from 'lodash';
import { arrayToObject } from 'utils/list_utils';

const initialState = {
    isLoading: false,
    error: null,
    customerSummary: null,
    customers: [],
    customersMiniList: [],
    isFetchCustomersLoading: false,
    isFetchCustomerSummaryLoading: false,
    isFetchCustomerAutocompleteLoading: false,
    customerMiniAsMap: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_CUSTOMER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customers: [...state.customers, payload.data],
                }),
            });
        case FETCH_CUSTOMERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchCustomersLoading: true,
                    error: null,
                    customers: [],
                }),
                finish: (prevState) => ({ ...prevState, isFetchCustomersLoading: false, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customers: get(payload, 'data.content', []),
                    customersAsMap: new Map(
                        map(get(payload, 'data.content', []), (customer) => [customer.id, customer])
                    ),
                }),
            });
        case FETCH_CUSTOMER_AUTOCOMPLETE:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchCustomerAutocompleteLoading: true,
                    error: null,
                    customersMiniList: [],
                    customerMiniAsMap: {},
                }),
                finish: (prevState) => ({ ...prevState, isFetchCustomerAutocompleteLoading: false, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customersMiniList: get(payload, 'data', []),
                    customerMiniAsMap: arrayToObject(get(payload, 'data', []), 'id'),
                }),
            });
        case FETCH_CUSTOMER_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isFetchCustomerSummaryLoading: true,
                    error: null,
                    customerSummary: null,
                }),
                finish: (prevState) => ({ ...prevState, isFetchCustomerSummaryLoading: false, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    customerSummary: payload.data,
                }),
            });
        default:
            return state;
    }
}
