import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
import { getMomentTime } from 'utils/date_utils';
export const FETCH_GEAR_DISTRIBUTION = 'FETCH_GEAR_DISTRIBUTION';
export const FETCH_GEAR_STANDSTILL_DISTRIBUTION = 'FETCH_GEAR_STANDSTILL_DISTRIBUTION';
export function fetchGearStandstillDistribution(accesstoken, params) {
    const { from, to, vehicleIds } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleIds,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}analytics/gear-standstill-distribution`, config);
    return {
        type: FETCH_GEAR_STANDSTILL_DISTRIBUTION,
        promise: request,
    };
}
export function fetchGearDistribution(accesstoken, params) {
    const { from, to, vehicleIds } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleIds,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}analytics/gear-distribution`, config);
    return {
        type: FETCH_GEAR_DISTRIBUTION,
        promise: request,
    };
}
