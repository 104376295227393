import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useReducer, useState } from 'react';
import { get, isEmpty, startCase } from 'lodash';
import { bindActionCreators } from 'redux';
import { fetchObdPIDs, fetchObdHealth, fetchUsersByType } from 'actions/dashboard/users_action';
import { FxButton, FxModal, FxToolTip } from 'sharedV2';
import { FxInfoCircleTwoTone } from 'sharedV2/FxIcons';
import { getHumanizeTime, getMomentTime, last7DaysRange } from 'utils/date_utils';
import { filtersReducer } from 'utils/form_utils';
import { stringifyFiltersUrl } from 'constant';
import { camelCaseToWords, queryString } from 'utils/string_utils';
import SelectVehicleV2 from 'components/dashboard/vehicles/select_vehicle_v2';
import CalendarComponent from 'components/utils/calendar_component';
import PageLayout from 'components/utils/pageLayout';
import { FxRow } from 'sharedV2/index';
import { connect } from 'react-redux';
import GearDistributionComponent from 'components/dashboard/vehicles/gearDistribution/GearDistributionComponent';
import DTCsList from './dtcList';
import FuelEfficiency from 'components/dashboard/report_section/obd_health/fuelEfficiency';
import ECTCharts from 'components/dashboard/report_section/obd_health/EctCharts';
const initialFiltersState = {
    startDate: getMomentTime(last7DaysRange()[0]),
    endDate: getMomentTime(),
    vehicleId: null,
};
const OBDHealth = (props) => {
    const { accesstoken, vehicleListMiniAsMap } = props;
    const [pidsData, setPidsData] = useState({});
    const [obdHealthData, setOBDHealthData] = useState({});
    const [isOpenPidModal, setIsOpenPidModal] = useState(false);
    const [isOpenOBDHealthModal, setIsOpenOBDHealthModal] = useState(false);
    const [filters, dispatchFilters] = useReducer(filtersReducer, initialFiltersState, (state) => {
        const parsed = queryString.parse(location.hash);
        const parsedFilters = {
            ...parsed,
        };
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        return { ...state, ...parsedFilters };
    });
    const { startDate, endDate, vehicleId } = filters;
    const selectedVehicle = useMemo(() => {
        return vehicleListMiniAsMap[vehicleId || 0];
    }, [vehicleId, vehicleListMiniAsMap]);
    const handleFilterChange = (key, value) => {
        const newFiltersState = {};
        if (key === 'timeFilter') {
            newFiltersState.startDate = get(value, '[0]', null);
            newFiltersState.endDate = get(value, '[1]', null);
        }
        else {
            newFiltersState[key] = value;
        }
        dispatchFilters({ payload: newFiltersState });
    };
    const onVehicleSelected = (vehicleId) => {
        handleFilterChange('vehicleId', vehicleId);
    };
    const formatPidsData = (data) => {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const formattedKey = camelCaseToWords(key);
            const formattedValue = key.toLowerCase().includes('time') ? getHumanizeTime(Number(value)) : String(value);
            acc[formattedKey] = formattedValue;
            return acc;
        }, {});
    };
    const handlePIDs = () => {
        const deviceId = get(selectedVehicle, 'deviceId');
        props.fetchObdPIDs(deviceId).then((res) => {
            const formattedData = formatPidsData(res.payload.data);
            setPidsData(formattedData);
        });
        setIsOpenPidModal(true);
    };
    const handleOBDHealth = () => {
        const data = {
            vehicleId: get(selectedVehicle, 'id'),
            startTime: startDate.valueOf(),
            endTime: endDate.valueOf(),
        };
        props.fetchObdHealth(data).then((res) => {
            setOBDHealthData(res.payload.data);
        });
        setIsOpenOBDHealthModal(true);
    };
    const RenderOBDHealthData = ({ data }) => {
        if (isEmpty(data))
            return _jsx("div", { children: "No data found." });
        return (_jsx("ul", { children: Object.entries(data).map(([key, value], index) => {
                return (_jsxs("li", { children: [_jsxs("strong", { children: [startCase(key), ":"] }), " ", value] }, index));
            }) }));
    };
    const RenderDataList = ({ data }) => {
        if (isEmpty(data))
            return _jsx("div", { children: "No data found." });
        return (_jsx("ul", { children: Object.entries(data).map(([key, value], index) => {
                if (key === 'Last Fuel Valid Time' || key === 'Last Adblue Valid Time') {
                    return null;
                }
                return (_jsx("li", { children: key === 'Fuel Percent' ? (_jsxs(_Fragment, { children: [_jsxs("strong", { children: [key, ":"] }), " ", value, " at ", data['Last Fuel Valid Time']] })) : key === 'Adblue In Litres' ? (_jsxs(_Fragment, { children: [_jsxs("strong", { children: [key, ":"] }), " ", value, 'L', " at ", data['Last Adblue Valid Time']] })) : (_jsxs(_Fragment, { children: [_jsxs("strong", { children: [key, ":"] }), " ", value] })) }, index));
            }) }));
    };
    useEffect(() => {
        stringifyFiltersUrl(filters);
    }, [startDate, endDate, vehicleId]);
    useEffect(() => {
        props.fetchUsersByType(accesstoken, 'driver');
    }, []);
    const licensePlate = get(selectedVehicle, 'licensePlate', '');
    const vehicleMaker = get(selectedVehicle, 'vehicleModel.vehicleMaker.name');
    const vehicleModel = get(selectedVehicle, 'vehicleModel.name');
    return (_jsxs(PageLayout, { pageLabel: _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { style: { color: '#fff', background: '#333333', fontSize: 12, borderRadius: 12 }, className: "px-2 py-1 mr-1", children: "OBD" }), "Vehicle Health ", !!licensePlate ? `- ${licensePlate} ` : '', ' ', (!!vehicleMaker || !!vehicleModel) && (_jsx(FxToolTip, { title: `${vehicleMaker ? `${vehicleMaker} -` : ''} ${vehicleModel}`, children: _jsx(FxInfoCircleTwoTone, { className: "ml-2" }) }))] }), tabTitle: "OBD Health", children: [_jsxs(_Fragment, { children: [props.isSwitchedUser && _jsx(FxButton, { onClick: handlePIDs, children: "Show PIDs" }), props.isSwitchedUser && _jsx(FxButton, { onClick: handleOBDHealth, children: "Health Score" }), isOpenPidModal && (_jsxs(FxModal, { open: isOpenPidModal, onCancel: () => setIsOpenPidModal(false), footer: null, styles: {
                            body: {
                                maxHeight: 550,
                                overflow: 'scroll',
                            },
                        }, children: [_jsx("h4", { children: "Vehicle PIDs Data" }), _jsx(RenderDataList, { data: pidsData })] })), isOpenOBDHealthModal && (_jsxs(FxModal, { open: isOpenOBDHealthModal, onCancel: () => setIsOpenOBDHealthModal(false), footer: null, styles: {
                            body: {
                                maxHeight: 550,
                                overflow: 'scroll',
                            },
                        }, children: [_jsx("h4", { children: "Health Score" }), _jsx(RenderOBDHealthData, { data: obdHealthData })] })), _jsx(CalendarComponent, { withoutTime: true, startDate: startDate, endDate: endDate, onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, maxDate: getMomentTime(), onAntdPage: true, isDateRangeAllowed: (startDate, endDate) => {
                            if (getMomentTime(endDate).diff(startDate, 'days') > 60) {
                                return [false, 'Date range can not be greater than 2 months.'];
                            }
                        } }), _jsx(SelectVehicleV2, { style: {
                            minWidth: '200px',
                        }, value: vehicleId, allowClear: false, onVehicleSelected: onVehicleSelected, isOBDOnly: true })] }), _jsxs("div", { children: [_jsxs(FxRow, { gutter: [8, 8], children: [_jsx(FuelEfficiency, { startDate: startDate, endDate: endDate, vehicleId: vehicleId }), _jsx(ECTCharts, { startDate: startDate, endDate: endDate, vehicleId: vehicleId }), _jsx(DTCsList, { startDate: startDate, endDate: endDate, vehicleId: vehicleId })] }), vehicleId && (_jsx(GearDistributionComponent, { vehicleIds: vehicleId, startDate: startDate, endDate: endDate }))] })] }));
};
const mapStateToProps = (state) => {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        vehicleListMiniAsMap: get(state, 'vehicles.vehicleListMiniAsMap', []),
        isSwitchedUser: get(state, 'login.isSwitchedUser'),
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchUsersByType,
        fetchObdPIDs,
        fetchObdHealth,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(OBDHealth);
