import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditLedgerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditLedger" */ 'components/dashboard/consignments/ledgers/AddEditLedgerComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const LedgersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-ledgers" */ 'components/dashboard/consignments/ledgers/LedgersComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const LedgersPreviewComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-ledgers-preview" */ 'components/dashboard/consignments/ledgers/LedgersPreviewComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ledgersRoutes = [
    {
        path: 'add',
        lazy: AddEditLedgerComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditLedgerComponent,
    },
    {
        path: ':id',
        lazy: LedgersPreviewComponent,
    },
    {
        index: true,
        lazy: LedgersComponent,
    },
];
export default ledgersRoutes;
