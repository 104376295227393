import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { mappedBranches } from '../../utils/mapping_utils';
import { FxReactSelect as Select } from '../../../shared';
const space = 'mb-3';
const PaymentFiltersComponent = (props) => {
    const { handleFilterChange, branches, branchId, paymentNature, creditAccountId, allCustomers, loggedInUser, activeTab, activeSubTab, } = props;
    const setTextFilter = debounce((key, term) => {
        handleFilterChange(key, term);
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    return (_jsx("div", { children: _jsxs("div", { children: [activeTab !== 'CUSTOMER_OVERVIEW' && (_jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "Office", value: branchId, options: mappedBranches(branches), onChange: (value) => {
                        handleFilterChange('branchId', value);
                    } })), _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "CR Account", value: creditAccountId, options: allCustomers, onChange: (value) => {
                        handleFilterChange('creditAccountId', value);
                    } }), activeTab !== 'CUSTOMER_OVERVIEW' && (_jsx("div", { className: `${space}`, children: _jsx("input", { type: "text", name: "voucherNumber", placeholder: `Voucher Number`, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": 'voucherNumber' }) })), activeTab !== 'CUSTOMER_OVERVIEW' && (_jsx("div", { className: `${space}`, children: _jsx("input", { type: "text", name: "adviceNumber", placeholder: `Advice Number`, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": 'adviceNumber' }) }))] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentFiltersComponent);
