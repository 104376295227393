import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { memo } from 'react';
import { Label, Legend, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, } from 'recharts';
import { get } from 'lodash';
import { ChartTypes } from 'components/dashboard/report_section/obd_health/EctCharts/index';
let legendLabels = {
    mileage: 'Mileage',
    distance: 'Distance',
    avgMileage: 'Avg Mileage',
    range: 'Range',
    maxTemp: 'Max Temp',
    minTemp: 'Min Temp',
    timeStamp: 'Time',
    normalisedCoolantTemp: 'Coolant Temperature',
    rpm: 'RPM',
    obdEngFuelRate: 'Engine Fuel Rate',
};
const ScatterChartGraph = (props) => {
    const getColors = (temp) => {
        if ((temp >= 70 && temp < 100) || props.type === ChartTypes.EngineFuelRate) {
            return '#5AD8A6';
        }
        else if (temp < 70) {
            return '#5B8FF9';
        }
        else {
            return '#E86452';
        }
    };
    const getStrokeColor = (temp) => {
        if ((temp >= 70 && temp < 100) || ChartTypes.EngineFuelRate) {
            return '#3A8A6A';
        }
        else if (temp < 70) {
            return '#3A5B9F';
        }
        else {
            return '#954035';
        }
    };
    const [selectedNode, setSelectedNode] = React.useState(null);
    const renderLegend = () => {
        const data = [
            { label: 'In Ideal range', color: '#5AD8A6', border: '#3A8A6A' },
            { label: 'Above Ideal range', color: '#E86452', border: '#954035' },
            { label: 'Below Ideal range', color: '#5B8FF9', border: '#3A5B9F' },
        ];
        return (_jsx("div", { className: "d-flex justify-content-center", style: { position: 'relative', marginTop: '20px' }, children: (props.legendData || data).map((entry, index) => {
                return (_jsxs("div", { className: "d-flex align-items-center", style: { height: 40 }, children: [_jsx("div", { style: {
                                height: 14,
                                width: 14,
                                borderWidth: 2,
                                border: `solid 1px ${entry.border}`,
                                borderRadius: '50%',
                                backgroundColor: entry.color,
                            } }), _jsx("div", { className: "d-inline-block mr-2", style: { fontSize: 12, marginLeft: 8 }, children: entry.label })] }, `item-${index}`));
            }) }));
    };
    const formatter = (value, name, _props) => {
        return [value, legendLabels?.[name] || name, _props];
    };
    const CustomScatter = (_payload) => {
        const { cx, cy, payload } = _payload;
        return (_jsx("circle", { onMouseEnter: (v) => {
                setSelectedNode(payload.index);
            }, onMouseLeave: (v) => {
                setSelectedNode(null);
            }, cx: cx, cy: cy, r: selectedNode === payload.index ? 9 : 6, fill: getColors(_payload[props.dataKeyY]), stroke: getStrokeColor(_payload[props.dataKeyY]), strokeWidth: 0.5, filter: "url(#dropShadow)" }));
    };
    return (_jsx("div", { children: !get(props, 'data.length') ? (_jsx("div", { className: "d-flex justify-content-center align-items-center", style: { height: 300 }, children: "No data found" })) : (_jsx(ResponsiveContainer, { height: 300, width: '100%', children: _jsxs(ScatterChart, { data: props.data, margin: {
                    right: 60,
                }, children: [_jsx(XAxis, { type: props.xAxisType, dataKey: props.dataKeyX, name: props.dataKeyX, style: {
                            fontSize: props.tickFontSize || 10,
                        }, angle: props.xAxisAngle, dy: props.dyX, domain: props.domain && props.domain, children: _jsx(Label, { position: "insideBottom", style: { textAnchor: 'middle', fontSize: 12, fontWeight: '600' }, value: props.xAxisLabel, dy: 10 }) }), _jsx(YAxis, { ticks: props.ticks, type: props.yAxisType, dataKey: props.dataKeyY, name: props.dataKeyY, style: {
                            fontSize: 10,
                        }, children: _jsx(Label, { angle: 270, dx: -15, style: { textAnchor: 'middle', fontSize: 12, fontWeight: '600' }, value: props.yAxisLabel }) }), _jsx(Legend, { verticalAlign: "bottom", content: renderLegend }), _jsx(Tooltip, { formatter: formatter }), _jsx(Scatter, { name: "Engine Temperature", shape: (payload) => CustomScatter(payload), dataKey: props.dataKeyY, data: props.data }), !props.hideReferenceLine && (_jsx(ReferenceLine, { strokeDasharray: "3 3", y: 100, 
                        // label={{ position: 'right', value: 'Max Temp', dy: -8, dx: -100 }}
                        stroke: "#000000" })), !props.hideReferenceLine && (_jsx(ReferenceLine, { strokeDasharray: "3 3", y: 70, 
                        // label={{ position: 'right', value: 'Min Temp', dy: -8, dx: -100 }}
                        stroke: "#000000" }))] }) })) }));
};
export default memo(ScatterChartGraph);
