import axios from 'axios';
import { ROOT_API_URL, ROOT_API, parseQueryParams } from '../../constant';
import { assign, get, set } from 'lodash';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { getMomentTime } from '../../utils/date_utils';

export const FETCH_ALL_ACCOUNTS_FOR_USER = 'fetch_all_accounts_for_user';
export const FETCH_ACCOUNT_FOR_USER = 'fetch_account_for_user';
export const CREATE_ACCOUNT = 'create_account';
export const CREATE_ACCOUNT_V2 = 'create_account_v2';
export const UPDATE_ACCOUNT_V2 = 'update_account_v2';
export const UPDATE_ACCOUNT_PLAN_TYPE = 'update_account_plan_type';
export const UPLOADER_ACCOUNT_SUPERVISORS = 'uploader_account_supervisors';
export const FETCH_ACCOUNTS_ADDRESS = 'fetch_accounts_address';
export const FETCH_CHANNEL_PARTNERS = 'fetch_channel_partners';
export const UPDATE_CURRENCY_CODES = 'update_currency_codes';
export const FETCH_CURRENCY_CODES = 'fetch_currency_codes';
export const FETCH_ALL_REASONS = 'fetch_all_reasons';
export const FETCH_SUB_REASONS = 'fetch_sub_reasons';
export const FETCH_BILLING_OVERDUE_VEHICLES = 'FETCH_BILLING_OVERDUE_VEHICLES';
export function fetchAllAccountsForUser(accesstoken, start, end) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            start: start ? getMomentTime(start).valueOf() : null,
            end: end ? getMomentTime(end).valueOf() : null,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}accounts/all/details`, config);

    return {
        type: FETCH_ALL_ACCOUNTS_FOR_USER,
        promise: request,
    };
}

export function fetchAccountForUser(accesstoken, accountId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/${accountId}`, config);

    return {
        type: FETCH_ACCOUNT_FOR_USER,
        promise: request,
    };
}

export function createAccount(values) {
    let data = assign({}, values);
    set(data, 'phoneNumber', `${getPhonePrefixFromCountry()}-` + data.phoneNumber);
    const request = axios.post(`${ROOT_API_URL}accounts/new`, data);

    return {
        type: CREATE_ACCOUNT,
        promise: request,
    };
}

export function createAccountV2(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    let data = assign({}, values);
    set(data, 'phoneNumber', `${getPhonePrefixFromCountry()}-` + data.phoneNumber);
    const request = axios.post(`${ROOT_API_URL}accounts/new2`, data, config);

    return {
        type: CREATE_ACCOUNT_V2,
        promise: request,
    };
}

export function updateAccountV2(accesstoken, accountId, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    let data = assign({}, values);

    // set(data, 'phoneNumber', '+91-' + data.phoneNumber);
    const request = axios.patch(`${ROOT_API_URL}accounts/update/${accountId}`, data, config);

    return {
        type: UPDATE_ACCOUNT_V2,
        promise: request,
    };
}

export function updateAccountPlanType(accesstoken, accountId, planId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: {
            accountId,
            planId,
        },
    };
    const request = axios.put(`${ROOT_API}gg/account/plan`, null, config);
    return {
        type: UPDATE_ACCOUNT_PLAN_TYPE,
        promise: request,
    };
}

export function fetchAccountsAddress(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: {
            accountId,
        },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/address`, config);
    return {
        type: FETCH_ACCOUNTS_ADDRESS,
        promise: request,
    };
}

export function fetchChannelPartners(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };

    const request = axios.get(`${ROOT_API_URL}accounts/channel-partner`, config);

    return {
        type: FETCH_CHANNEL_PARTNERS,
        promise: request,
    };
}

export function updateCurrencyCodes(accesstoken, params = {}) {
    const currencyCodes = get(params, 'currencyCodes');
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            currencyCodes,
        }),
    };
    const request = axios.post(`${ROOT_API_URL}accounts/currency`, {}, config);
    return {
        type: UPDATE_CURRENCY_CODES,
        promise: request,
    };
}
export function fetchCurrencyCodes(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/currency`, config);
    return {
        type: FETCH_CURRENCY_CODES,
        promise: request,
    };
}
export function fetchAllReasons() {
    const request = axios.get(`${ROOT_API_URL}reason/all`);
    return {
        type: FETCH_ALL_REASONS,
        promise: request,
    };
}
export function fetchSubReasons(id) {
    const request = axios.get(`${ROOT_API_URL}reason/${id}/subreasons`);
    return {
        type: FETCH_SUB_REASONS,
        promise: request,
    };
}

export function fetchBillingOverDueVehicles(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle/renewal/billing/overdue-duesoon/vehicle`, config);

    return {
        type: FETCH_BILLING_OVERDUE_VEHICLES,
        promise: request,
    };
}
