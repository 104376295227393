import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
import { getMomentTime } from '../../../utils/date_utils';
export const CREATE_VOUCHER = 'CREATE_VOUCHER';
export const GET_VOUCHER_NEXT_SETTLEMENT = 'GET_VOUCHER_NEXT_SETTLEMENT';
export const FETCH_VOUCHER = 'FETCH_VOUCHER';
export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const APPROVE_AUTO_VOUCHER = 'APPROVE_AUTO_VOUCHER';
export const BULK_APPROVE_VOUCHERS = 'BULK_APPROVE_VOUCHERS';
export const FETCH_VOUCHER_REPORT = 'FETCH_VOUCHER_REPORT';
export const UNAPPROVE_VOUCHER = 'UNAPPROVE_VOUCHER';
export function createVoucher(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}voucher`, data, config);
    return {
        type: CREATE_VOUCHER,
        promise: request,
    };
}
export function updateVoucher(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}voucher`, data, config);
    return {
        type: UPDATE_VOUCHER,
        promise: request,
    };
}
export function deleteVoucher(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}voucher/${id}`, config);
    return {
        type: DELETE_VOUCHER,
        promise: request,
    };
}
export function fetchVouchers(accesstoken, params = {}) {
    const { startDate, endDate, voucherNumber, isSettled, voucherType, branchId, divisionId, isApproved, size = 1000, types, newRefLedgerId, ids, ledgerIds, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            voucherNumber,
            voucherType,
            isSettled,
            branchId,
            divisionId,
            isApproved,
            size,
            types,
            newRefLedgerId,
            ids,
            ledgerIds,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher`, config);
    return {
        type: FETCH_VOUCHERS,
        promise: request,
    };
}
export function fetchVoucher(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/${id}`, config);
    return {
        type: FETCH_VOUCHER,
        promise: request,
    };
}
export function fetchNextSettlement(accesstoken, id, otherParams = {}) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(otherParams),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/${id}/settlement/next`, config);
    return {
        type: GET_VOUCHER_NEXT_SETTLEMENT,
        promise: request,
    };
}
export function bulkApproveVouchers(accesstoken, voucherIds) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: {
            voucherIds,
        },
    };
    const request = axios.put(`${ROOT_API_ERP}voucher/approve`, null, config);
    return {
        type: BULK_APPROVE_VOUCHERS,
        promise: request,
    };
}
export function triggerVoucherReport(accesstoken, params = {}) {
    const { startDate, endDate } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}report/voucher`, config);
    return {
        type: FETCH_VOUCHER_REPORT,
        promise: request,
    };
}
export function unapproveVoucher(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            voucherIds: id,
        }),
    };
    const request = axios.patch(`${ROOT_API_ERP}voucher/un-approve`, null, config);
    return {
        type: UNAPPROVE_VOUCHER,
        promise: request,
    };
}
