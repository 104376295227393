import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Area, Bar, CartesianGrid, ComposedChart, Label, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { round } from 'lodash';
const MixChart = (props) => {
    const { xAxisKey, yAxisLabel, legendLabels, colors, hideLegend, rightYAxisConfig, leftYAxisKey, enableCharts, } = props;
    const formatter = (value, name, _props) => {
        if (props.unitObj) {
            value = round(value, 2) + ' ' + (props.unitObj[name] || '');
        }
        return [value, legendLabels?.[name] || name, _props];
    };
    return (_jsx(ResponsiveContainer, { width: "100%", height: props.height, children: _jsxs(ComposedChart, { width: 500, height: props.height || 400, data: props.data, margin: {
                top: 10,
                right: 10,
                left: 10,
                bottom: 10,
            }, children: [!props.hideGrid && _jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: xAxisKey, tick: { fontSize: props.xAxisFont || 10 }, interval: props.interval || 0, angle: props.xAxisTickAngle, dy: props.dyX, height: 60 }), _jsx(YAxis, { dataKey: leftYAxisKey, orientation: "left", tick: { fontSize: '12px' }, children: yAxisLabel && (_jsx(Label, { angle: -90, position: "insideLeft", style: { textAnchor: 'middle' }, value: yAxisLabel })) }), !!rightYAxisConfig && (_jsx(YAxis, { dataKey: rightYAxisConfig.dataKey, type: "number", yAxisId: "right", orientation: "right", style: {
                        fontSize: 10,
                    }, children: _jsx(Label, { value: rightYAxisConfig.label, angle: 270, dx: 15, style: {
                            textAnchor: 'middle',
                            fontSize: 12,
                            fontWeight: '600',
                        } }) })), !hideLegend && (_jsx(Legend, { formatter: (value) => legendLabels ? (_jsx("span", { style: { color: '#333333', fontSize: 12 }, children: legendLabels[value] })) : (value) })), _jsx(Tooltip, { itemStyle: { color: '#333333' }, cursor: false, formatter: formatter }), enableCharts?.bar && (_jsx(Bar, { yAxisId: props.yAxisId, dataKey: "bar", barSize: 20, fill: colors.bar || '#413ea0' })), enableCharts?.area && _jsx(Area, { yAxisId: props.yAxisId, dataKey: "area", fill: colors.area || '#FFC0A5' }), enableCharts?.line && (_jsx(Line, { type: "monotone", dot: false, strokeWidth: 2, dataKey: "line", stroke: colors.line || '#ff7300' }))] }) }));
};
export default memo(MixChart);
