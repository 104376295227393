import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import ErrorBoundary from 'components/utils/error_boundary_component';
import InwardOutwardBatteryComponent from 'components/dashboard/battery/inward_outward_batttery_component';
import IssuanceRemovalBatteryComponent from 'components/dashboard/battery/issuance_removal_battery_component';
const ViewBatteryDetails = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-battery-view" */ 'components/dashboard/battery/view_battery_details'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const BatteryManagement = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-battery-management" */ 'components/dashboard/battery/battery_management'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditBatteryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-battery-add-edit" */ 'components/dashboard/battery/add_edit_battery_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const batteryRoutes = [
    {
        index: true,
        lazy: BatteryManagement,
    },
    {
        path: 'add',
        lazy: AddEditBatteryComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditBatteryComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewBatteryDetails,
    },
    {
        path: 'inward',
        element: (_jsx(ErrorBoundary, { children: _jsx(InwardOutwardBatteryComponent, { batteryFlow: "INWARD" }) })),
    },
    {
        path: 'outward',
        element: (_jsx(ErrorBoundary, { children: _jsx(InwardOutwardBatteryComponent, { batteryFlow: "OUTWARD" }) })),
    },
    {
        path: 'issuance',
        element: (_jsx(ErrorBoundary, { children: _jsx(IssuanceRemovalBatteryComponent, { batteryFlow: "ISSUANCE" }) })),
    },
    {
        path: 'removal',
        element: (_jsx(ErrorBoundary, { children: _jsx(IssuanceRemovalBatteryComponent, { batteryFlow: "REMOVAL" }) })),
    },
];
export default batteryRoutes;
