import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { filter, get } from 'lodash';
import { FxButton, FxRow } from 'sharedV2';
import { mappedOptionsFromArray } from 'components/utils/mapping_utils';
import { memo, useCallback, useMemo } from 'react';
import DeductionRow from './DeductionRowComponent';
const BillSettlementDeductionComponent = memo((props) => {
    const { formikProps, consignmentId, freightBillId, ledgers } = props;
    const { values, setFieldValue } = formikProps;
    // Memoize ledger options
    const ledgerOptions = useMemo(() => {
        return mappedOptionsFromArray(filter(ledgers, (ledger) => [6, 7, 41].includes(get(ledger, 'group.baseGroupId'))), 'ledgerName');
    }, [ledgers]); // Only recompute when `ledgers` change
    // Memoize deduction mappings (avoid unnecessary recomputation)
    const deductionMappings = useMemo(() => {
        return get(values, `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings`, []);
    }, [values, consignmentId, freightBillId]); // Recompute when any of these change
    // Callback for adding deduction
    const handleAddDeduction = useCallback(() => {
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}`, {});
        const newDeductionMappings = [...(currentMapping.deductionMappings || [])];
        newDeductionMappings.push({
            ledgerId: null,
            deductionAmount: 0,
            remark: '',
        });
        setFieldValue(`paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings`, newDeductionMappings);
    }, [values, consignmentId, freightBillId, setFieldValue]);
    // Callback for removing deduction
    const handleRemoveDeduction = useCallback((index) => {
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}`, {});
        const newDeductionMappings = [...(currentMapping.deductionMappings || [])];
        newDeductionMappings.splice(index, 1);
        setFieldValue(`paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings`, newDeductionMappings);
    }, [values, consignmentId, freightBillId, setFieldValue]);
    return (_jsxs("div", { className: "p-4", children: [_jsxs(FxRow, { justify: "space-between", align: "middle", className: "mb-3", children: [_jsx("h6", { className: "m-0", children: "Deduction Details" }), _jsx(FxButton, { type: "primary", onClick: handleAddDeduction, children: "Add Deduction" })] }), deductionMappings.map((deduction, index) => (_jsx(DeductionRow, { index: index, consignmentId: consignmentId, freightBillId: freightBillId, ledgerOptions: ledgerOptions, deduction: deduction, onRemove: handleRemoveDeduction }, index)))] }));
}, (prevProps, nextProps) => {
    // Prevent re-render if the props haven't changed
    return (prevProps.formikProps.values === nextProps.formikProps.values &&
        prevProps.ledgers === nextProps.ledgers &&
        prevProps.consignmentId === nextProps.consignmentId &&
        prevProps.freightBillId === nextProps.freightBillId);
});
export default BillSettlementDeductionComponent;
