import { get } from 'lodash';
import { isWithinScope } from '../utils';
import { isSwitchVehicles, isSwitchVehiclesClient, isVehicleVisible, } from 'components/dashboard/roles/permission_utils';
import { isRoleEPODCustomer } from '../erpPermissions';
import { isTransporter } from '../tmsPermissions';
import { isVehiclesHeaderVisible } from 'utils/accountUtils/dashboardHeaderUtils';
import { getFlagValueFromAccountConfig } from 'utils/account_utils';
export const isRealtimeVisible = () => {
    return isWithinScope('VIEW_REAL_TIME');
};
export const isVehiclesDashboardAllowed = () => {
    const loggedInUser = window.FLEETX_LOGGED_IN_USER;
    const scope = get(loggedInUser, 'scopes', []);
    const isSwitchClient = isSwitchVehiclesClient(scope);
    const isSwitchUserAllowed = isSwitchVehicles(scope) || isSwitchClient;
    const showVehicles = 
    // !isSwitchUserAllowed &&
    isVehicleVisible(scope, loggedInUser) && !isTransporter() && !isRoleEPODCustomer() && isVehiclesHeaderVisible();
    return showVehicles;
};
export function useJobUtilisationForRunningVehicle() {
    return getFlagValueFromAccountConfig('useJobUtilisationForRunningVehicle');
}
