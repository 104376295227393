import { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'reactstrap';
import { image } from '../../constant';

import { getWhiteLabelLogoNumber } from '../../utils/account_utils';
const fallbackLogoImage = '/public/img/logos/fleetx/fleetx-logo-final-full.svg?v=3';
class LandingHeader extends Component {
    reloadPage = () => {
        window.location.reload();
    };
    render() {
        const logoVersion = 8;
        const containerClass = this.props.fluid ? '' : 'container';
        const hostName = window.location.hostname;
        let logoId = 113;
        if (this.props.accountId) {
            logoId = this.props.accountId;
        }
        if (getWhiteLabelLogoNumber(hostName)) {
            logoId = getWhiteLabelLogoNumber(hostName);
        }
        let src = `/public/img/logos/clients/${logoId}.png?v=${logoVersion}`;
        return (
            <div className="landing-header font-montserrat pos-rel">
                <Navbar fixed="top" light full toggleable={`true`} className={`pos-rel p-md-0`}>
                    <div className={containerClass}>
                        <a href={src}>
                            <span className="navbar-brand cursor-pointer">
                                <img
                                    className="fleetx-logo-full"
                                    alt="fleetx"
                                    src={image(src)}
                                    onError={this.setFallbackImage}
                                />
                            </span>
                        </a>
                    </div>
                </Navbar>
            </div>
        );
    }
    setFallbackImage(event) {
        event.target.src = fallbackLogoImage;
    }
}

LandingHeader.propTypes = {
    fluid: PropTypes.bool,
};

export default LandingHeader;
