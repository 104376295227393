import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FxCol, FxRow, FxTable } from 'sharedV2';
import { get, map, pickBy, filter, has, assign, includes } from 'lodash';
import CalendarComponent from 'components/utils/calendar_component';
import { getCommonColumns } from './utils';
const BillSettlementNonSupplementaryBillComponent = (props) => {
    const { formikProps, bills, loading, dateRange, onDateRangeChange } = props;
    const { values } = formikProps;
    const [nonSupplementaryBillData, setNonSupplementaryData] = useState([]);
    useEffect(() => {
        const { values } = formikProps;
        const currentMappings = { ...get(values, 'paymentMappingRequests', {}) };
        const debitNotes = pickBy(currentMappings, (mapping) => get(mapping, 'settlementType') === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE');
        const debitData = Object.values(debitNotes);
        const mergeUnique = (bills, debitNotes) => {
            const map = new Map();
            debitNotes.forEach((note) => {
                if (map.has(note.billId)) {
                    // If the bill exists, merge debitNote data
                    const mergedData = { id: note.billId, ...map.get(note.billId), ...note };
                    delete mergedData.billId; // Remove the billId after setting the id
                    map.set(note.billId, mergedData);
                }
                else {
                    // If the bill does not exist in bills, add it as a new entry
                    const newData = { ...note, id: note.billId };
                    delete newData.billId; // Remove the billId after setting the id
                    map.set(note.billId, newData);
                }
            });
            // Add all bills to the map with their id as the key
            bills.forEach((bill) => {
                map.set(bill.id, { ...bill });
            });
            return Array.from(map.values());
        };
        const finalData = mergeUnique(bills, debitData);
        finalData.forEach((item) => {
            if (item.hasOwnProperty('billAmount')) {
                item.totalFreight = item.billAmount;
                delete item.billAmount;
            }
        });
        setNonSupplementaryData(finalData);
    }, [bills]);
    const handleNonSupplementaryBillSelect = (selectedRowKeys, selectedRows) => {
        const { values, setFieldValue } = formikProps;
        const currentMappings = { ...get(values, 'paymentMappingRequests', {}) };
        // Separate debit notes from other bill types
        const nonSupplementaryBills = pickBy(currentMappings, (mapping) => get(mapping, 'settlementType') === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE');
        const otherBills = pickBy(currentMappings, (mapping) => get(mapping, 'settlementType') !== 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE');
        // Filter only debit notes based on selectedRowKeys
        const currentMappingsFiltered = pickBy(nonSupplementaryBills, (_, key) => includes(selectedRowKeys, key));
        // Find rows that are not already in currentMappingsFiltered
        const rowsToAdd = filter(selectedRows, (row) => !has(currentMappingsFiltered, row.id));
        const newMappings = rowsToAdd.reduce((acc, row) => {
            acc[row.id] = {
                billId: row.id,
                settlementType: 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE',
                totalAmount: row.totalAmount || get(currentMappings, [row.id, 'totalAmount'], null),
            };
            return acc;
        }, {});
        const updatedMappings = assign({}, otherBills, currentMappingsFiltered, newMappings);
        setFieldValue('paymentMappingRequests', updatedMappings);
    };
    const columns = getCommonColumns('SUPPLEMENTARY_NON_LOGISTICS_INVOICE', formikProps);
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { className: "mb-4", children: _jsx(FxCol, { span: 24, children: _jsx(CalendarComponent, { startDate: dateRange.startDate, endDate: dateRange.endDate, onDateSelected: onDateRangeChange }) }) }), _jsx(FxTable, { columns: columns, dataSource: nonSupplementaryBillData, loading: loading, rowKey: "id", pagination: false, scroll: { x: true }, rowSelection: {
                    type: 'checkbox',
                    selectedRowKeys: map(get(values, 'paymentMappingRequests', []), (r) => {
                        return r.billId;
                    }),
                    onChange: handleNonSupplementaryBillSelect,
                    getCheckboxProps: (record) => {
                        return {
                            disabled: record.totalFreight ===
                                (+record.settledAmount >= 0 ? record.settledAmount : record.voucher.settledAmount), // Disable if billAmount equals settledAmount
                        };
                    },
                } })] }));
};
export default BillSettlementNonSupplementaryBillComponent;
