import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../../../constant';
import { getMomentTime } from 'utils/date_utils';
import { get, toSafeInteger } from 'lodash';
export const CREATE_FREIGHT_BILL = 'CREATE_FREIGHT_BILL';
export const FETCH_FREIGHT_BILLS = 'FETCH_FREIGHT_BILLS';
export const FETCH_FREIGHT_BILL = 'FETCH_FREIGHT_BILL';
export const FETCH_LATEST_FREIGHT_BILL = 'FETCH_LATEST_FREIGHT_BILL';
export const UPDATE_FREIGHT_BILL = 'UPDATE_FREIGHT_BILL';
export const DELETE_FREIGHT_BILL = 'DELETE_FREIGHT_BILL';
export const FETCH_FREIGHT_BILL_REPORT = 'FETCH_FREIGHT_BILL_REPORT';
export const FETCH_FREIGHT_BILLS_SUMMARY = 'FETCH_FREIGHT_BILLS_SUMMARY';
export const DOWNLOAD_FRIEGHT_BILL_REPORT_BY_ID = 'DOWNLOAD_FRIEGHT_BILL_REPORT_ID';
export const CREATE_E_INVOICE = 'CREATE_E_INVOICE';
export const FETCH_FREIGHT_BILLS_SEARCH = 'FETCH_FREIGHT_BILLS_SEARCH';
export const FETCH_GST_INPUT_REPORT = 'FETCH_GST_INPUT_REPORT';
export function createFreightBill(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}consignment/freight-bill`, data, config);
    return {
        type: CREATE_FREIGHT_BILL,
        promise: request,
    };
}
export function updateFreightBill(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}consignment/freight-bill`, data, config);
    return {
        type: UPDATE_FREIGHT_BILL,
        promise: request,
    };
}
export function deleteFreightBill(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}consignment/freight-bill/${id}`, config);
    return {
        type: DELETE_FREIGHT_BILL,
        promise: request,
    };
}
export function fetchFreightBills(accesstoken, params = {}) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, size, page, billingPartyId, consignmentId, billNumber, billingNature, serviceType, stateOfSupply, paymentStatus, divisionId, advanceAmountPaid, unsettledVoucher, billMappingType, billTypeId, sortOrder, sortName, targetCurrency, ids, } = params;
    let sort = '';
    if (sortName) {
        sort = `${sortName}`;
    }
    if (sortName && sortOrder) {
        sort += `,${sortOrder}`;
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            billingOfficeId: branchId,
            billingPartyId,
            consignmentId,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            size,
            page: page - 1,
            billNumber,
            billingNature,
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisionId,
            advanceAmountPaid,
            unsettledVoucher,
            billMappingType,
            billTypeId,
            sort,
            targetCurrency,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill`, config);
    return {
        type: FETCH_FREIGHT_BILLS,
        promise: request,
    };
}
export function fetchFreightBillsSearch(accesstoken, params = {}) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, size, page, billingPartyId, consignmentId, billNumber, billingNature, serviceType, stateOfSupply, paymentStatus, divisionId, advanceAmountPaid, unsettledVoucher, billMappingType, billTypeId, sortOrder, sortName, targetCurrency, } = params;
    let sort = '';
    if (sortName) {
        sort = `${sortName}`;
    }
    if (sortName && sortOrder) {
        sort += `,${sortOrder}`;
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            billingOfficeId: branchId,
            billingPartyId,
            consignmentId,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            size,
            page: page - 1,
            billNumber,
            billingNature,
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisionId,
            advanceAmountPaid,
            unsettledVoucher,
            billMappingType,
            billTypeId,
            sort,
            targetCurrency,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill/search`, config);
    return {
        type: FETCH_FREIGHT_BILLS_SEARCH,
        promise: request,
    };
}
export function fetchFreightBill(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill/${id}`, config);
    return {
        type: FETCH_FREIGHT_BILL,
        promise: request,
    };
}
export function fetchLatestFreightBill(accesstoken, billingPartyId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            billingPartyId: toSafeInteger(billingPartyId),
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill/latest/`, config);
    return {
        type: FETCH_LATEST_FREIGHT_BILL,
        promise: request,
    };
}
export function triggerFrieghtBillReport(accesstoken, params = {}) {
    const { startDate, endDate, billNumber, billingPartyId, billingNature, serviceType, stateOfSupply, paymentStatus, divisions, routes, branchId, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            billNumber,
            billingPartyId,
            billingNature,
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisions,
            routes,
            branchId,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}report/freight-bill`, config);
    return {
        type: FETCH_FREIGHT_BILL_REPORT,
        promise: request,
    };
}
export function fetchGSTInputReport(params = {}) {
    const { startDate, endDate, branchId, routeId, size, billingPartyId, billNumber, billingNature, serviceType, stateOfSupply, paymentStatus, divisionId, billMappingType, } = params;
    const config = {
        params: parseQueryParams({
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            billingOfficeId: branchId,
            billingPartyId,
            routeId,
            size,
            billNumber,
            billingNature: 'FREIGHT_INVOICE',
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisionId,
            billMappingType: 'CN_MAPPING',
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}report/gst-input-report`, config);
    return {
        type: FETCH_GST_INPUT_REPORT,
        promise: request,
    };
}
export function fetchFreightBillsSummary(accesstoken, params = {}) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, billingPartyId, consignmentId, billNumber, serviceType, stateOfSupply, paymentStatus, divisionId, billMappingType, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            billingOfficeId: branchId,
            billingPartyId,
            consignmentId,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            billNumber,
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisionId,
            billMappingType,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill/summary`, config);
    return {
        type: FETCH_FREIGHT_BILLS_SUMMARY,
        promise: request,
    };
}
export function fetchNodeFreightBillReport(accesstoken, reportFormat, params) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            reportFormat,
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getFreightBillReport`, config);
    return {
        type: DOWNLOAD_FRIEGHT_BILL_REPORT_BY_ID,
        promise: request,
    };
}
export function createEInvoice(accesstoken, data, headers, params) {
    const gstin = get(params, 'gstin', '');
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}`, ...headers },
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}webhook/e-invoicing`, data, config);
    return {
        type: CREATE_E_INVOICE,
        promise: request,
    };
}
