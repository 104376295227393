import { get, filter, includes, map, toLower } from 'lodash';
import { getFinalLoggedInUserEmailId } from 'utils/userUtils';

export const SHIPMENT_PACKET_TYPES = [
    {
        value: 'DEVICE',
        label: 'DEVICE',
    },
];
export const SHIPMENT_DEVICE_TYPES = [
    {
        value: 'FMB',
        label: 'FMB',
    },
    {
        value: 'OBD',
        label: 'OBD',
    },
    {
        value: 'AIS',
        label: 'AIS',
    },
    {
        value: 'Temperature_Geosat',
        label: 'Temperature Geosat',
    },
];

export const SIM_STATUS = {
    ACTIVE: 'ACTIVE',
    ZERO_BILLING: 'ZERO_BILLING',
    INACTIVE: 'INACTIVE',
    ACTIVE_TEMP: 'ACTIVE_TEMP',
};

export const SIM_PROVIDER = {
    VODAFONE: 'VODAFONE',
    AIRTEL: 'AIRTEL',
    IDEA: 'IDEA',
    JIO: 'JIO',
    BSNL: 'BSNL',
    AERIS: 'AERIS',
};

export const HUL_INVENTORY_COUNTS = {
    inHand: 'In Hand Devices',
    devicesAllocated: 'Devices Allocated',
    toBeReversed: 'To Be Reversed',
    onJob: 'On Job',
    notOnJob: 'Not On Job',
    inReverseTransit: 'In Reverse Transit',
    totalInHand: 'Total In Hand',
    installedDevices: 'Installed Devices',
};

export function getHulInventoryLabel(key) {
    return get(HUL_INVENTORY_COUNTS, key, key);
}

export const ENTERPRISE_DASHBOARD = {
    availableAtPort: 'Available At Port',
    availableAtPlant: 'Available At Plant',
    installedInVehicle: 'Installed In Vehicle',
    inReverseTransit: 'In Reverse Transit',
};

export function getEnterpriseDashboardLabel(key) {
    return get(ENTERPRISE_DASHBOARD, key, key);
}

export const getFilteredIssuedToUserList = (data, usedAt = '', extraOptions = {}) => {
    const { switchedUserOriginalEmailId } = window.SWITCHED_USER_ORIGINAL_DATA || {};
    const { email } = window.FLEETX_LOGGED_IN_USER || {};
    const emailIdToUse = switchedUserOriginalEmailId ? switchedUserOriginalEmailId : email;

    const {} = extraOptions || {};
    return filter(data, (item) => {
        if (includes(['SEND_SHIPMENT', 'ASSIGN_DEVICE', 'DEVICE_COMMAND_MODAL'], usedAt)) {
            if (includes([1386787, 1386792, 1386796, 1386801, 1386804, 1386808, 1386811, 1386813, 1386815], item.id)) {
                return includes(['jatinder.kumar@fleetx.io'], emailIdToUse);
            }

            if (includes([1386818, 1386822, 1386823], item.id)) {
                return includes(
                    [
                        'kamal.chouhan@fleetx.io',
                        'suraj.jogi@fleetx.io',
                        'dinesh.pathak@fleetx.i',
                        'vikas.kumar@fleetx.io',
                    ],
                    emailIdToUse
                );
            }
        }

        return true;
    });
};

export const isDeviceAttachDetachAllowed = () => {
    const emailIdToUse = getFinalLoggedInUserEmailId();
    return includes(
        map(
            [
                'sudhanshu@fleetx.io',
                'mayank.tyagi@fleetx.io',
                'ashok.pandit@fleetx.io',
                'jatinder.kumar@fleetx.io',
                'vikas.kumar@fleetx.io',
                'suraj.jogi@fleetx.io',
                'anish.jaswal@fleetx.io',
                'Nimesh.sharma@fleetx.io',
                'Anuj.Maurya@fleetx.io',
                'prahlad.singh@fleetx.io',
                'sumeet.yadav@fleetx.io',
                'rupam.sharma@fleetx.io',
                'rishabh.ratnakar@fleetx.io',
                'dev@fleetx.io',
                'devender.nishad@fleetx.io',
                'mayank.joshi@fleetx.io',
                'purva.rai@fleetx.io',
                'wasir.alam@fleetx.io',
                'praveen.tiwari@fleetx.io',
                'gopal.gurjar@fleetx.io',
                'sagar.chawla@fleetx.io',
                'rajesh.bairagi@fleetx.io',
                'Shubham.sabbarwal@fleetx.io',
                'haldarva.aftab@fleetx.io',
                'pawan.yadav@fleetx.io',
                'gopal.gurjar@fleetx.io',
                'shiv.pathak@fleetx.io',
                'jigna.rathod@fleetx.io',
                'anuj.gour@fleetx.io',
                'deepak.mishra1@fleetx.io',
                'jayesh.vankar@fleetx.io',
                'somnath.panday@fleetx.io',
                'puran.gurjar@fleetx.io',
                'Shubhang.gupta@fleetx.io',
                'arun.sardana@fleetx.io',
                'Krishan.kumar@fleetx.io',
                'ravinder.rathee@fleetx.io',
                'devendra.nishad@fleetx.io',
                'devesh.sharma@fleetx.io',
                'Anuj.Maurya@fleetx.io',
                'devansh.arora@fleetx.io',
                'Rahul.mahida@fleetx.io',
                'lokesh.meena@fleetx.io',
                'diljeet.singh@fleetx.io',
                'tejveer@fleetx.io',
                'akhilendra.singh@fleetx.io',
                'anshu.pm@fleetx.io',
                'amitesh@fleetx.io',
                'abhishek.anand@fleetx.io',
                'Raj.das@fleetx.io',
                'Harsh.yadav@fleetx.io',
                'gaurav.bhadoriya@fleetx.io',
                'balram.sharma@fleetx.io',
                'amitesh@fleetx.io',
                'abhishek.anand@fleetx.io',
                'lokesh.kumar@fleetx.io',
                'lokesh@fleetx.io',
                'saket.verma@fleetx.io',
                'dinesh.sharma@fleetx.io',
                'jitendra.kumar@fleetx.io',
                'rohit.patel@fleetx.io',
                'akash.deep@fleetx.io',
                'brijesh.kumar@fleetx.io',
                'avnish.kumar@fleetx.io',
                'ravinder.rathee@fleetx.io',
                'satish@fleetx.io',
                'vinayak.sahai@fleetx.io',
                'poonam.gurnani@fleetx.io',
                'gaurav.bhadoriya@fleetx.io',
                'alkesh.raj@fleetx.io',
                'ijaz.ahmed@fleetx.io',
                'ovk9929@gmail.com',
                'pawan.tanwar@fleetx.io',
                'kumar.aniket@fleetx.io',
                'cttracking@maersk.com',
                'alok.tomar@fleetx.io',
                'akshay.yadav@fleetx.io',
                'hemant.jangir@fleetx.io',
            ],
            (email) => toLower(email)
        ),
        emailIdToUse
    );
};
