import { useFormikContext } from 'formik';
import { forEach, uniq, isArray, isEmpty, find, isObject, isNil, forOwn } from 'lodash';
import { useCallback, useEffect } from 'react';
let ERROR_KEY_TO_LABEL = {};
export const showErrors = (errors, errorKey) => {
    let validationErrors = [];
    ERROR_KEY_TO_LABEL = errorKey;
    traverseErrors(validationErrors, errors);
    return uniq(validationErrors);
};
const traverseErrors = (validationErrors, error, index, arrayKey) => {
    if (isArray(error)) {
        traverseArray(validationErrors, error, arrayKey);
    }
    else if (typeof error === 'object' && error !== null) {
        traverseObject(validationErrors, error, index, arrayKey);
    }
    else if (error) {
        const label = arrayKey && ERROR_KEY_TO_LABEL[arrayKey] ? ERROR_KEY_TO_LABEL[arrayKey] : 'Error';
        validationErrors.push(index != undefined ? `${label} #${index + 1}: ${error}` : error);
    }
};
const traverseArray = (validationErrors, arr, arrayKey) => {
    forEach(arr, (elem, index) => {
        traverseErrors(validationErrors, elem, index, arrayKey);
    });
};
const traverseObject = (validationErrors, obj, index, arrayKeyPrev) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const arrayKey = isArray(obj[key]) ? key : arrayKeyPrev;
            traverseErrors(validationErrors, obj[key], index, arrayKey);
        }
    }
};
// Helper function to get error field names in dot notation
const getFieldErrorNames = (formikErrors) => {
    const result = [];
    const transformObjectToDotNotation = (obj, prefix = '') => {
        forOwn(obj, (value, key) => {
            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (isNil(value))
                return; // Skip null or undefined
            if (isObject(value) && !isArray(value)) {
                transformObjectToDotNotation(value, nextKey);
            }
            else {
                result.push(nextKey);
            }
        });
    };
    transformObjectToDotNotation(formikErrors);
    return result;
};
// Escape special characters for querySelector compatibility
const escapeFieldName = (fieldName) => {
    return fieldName.replace(/([.[\]])/g, '\\$1');
};
export const ScrollToFieldError = () => {
    const { submitCount, errors, isValid } = useFormikContext();
    const scrollToFirstError = useCallback(() => {
        if (isValid || !submitCount)
            return; // Do nothing if form is valid or hasn't been submitted yet.
        const fieldErrorNames = getFieldErrorNames(errors);
        if (isEmpty(fieldErrorNames))
            return;
        // Ensure lodash only works with strings and returns a string or undefined
        const firstErrorFieldName = find(fieldErrorNames, (fieldName) => {
            const escapedFieldName = escapeFieldName(fieldName);
            return !!document.querySelector(`[name='${escapedFieldName}']`);
        });
        if (firstErrorFieldName !== undefined) {
            const escapedFieldName = escapeFieldName(firstErrorFieldName);
            const element = document.querySelector(`[name='${escapedFieldName}']`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                element.focus?.();
            }
        }
    }, [errors, isValid, submitCount]);
    useEffect(() => {
        scrollToFirstError();
    }, [scrollToFirstError]);
    return null;
};
