import { get } from 'lodash';
import InfoToolTip from '../components/utils/info_tool_tip';
export default (passedProps) => {
    const {
        field,
        form,
        isRequired,
        prependText,
        postpendText = false,
        postsup = false,
        infoTooltipText = false,
        helpTextClassName,
        labelClass: labelClassPassed,
        onChange,
        onBlur,
        errorStyle,
        postPendMetaData,
        ...props
    } = passedProps;
    const className = `${props.label ? 'form-group' : ''} ${
        form.touched[field.name] && form.errors[field.name] ? 'has-danger' : ''
    }`;
    const labelClass = `${
        get(form.touched, `${field.name}`) && get(form.errors, `${field.name}`)
            ? 'text-danger'
            : `${labelClassPassed ? labelClassPassed : ''}`
    }`;

    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');
    const postpendOnClick = get(postPendMetaData, 'onClick');
    const postpendStyle = get(postPendMetaData, 'style');

    const onChangeHandler = (event) => {
        field.onChange(event);
        if (onChange) {
            onChange(event.currentTarget.value);
        }
    };

    const onBlurHandler = (event) => {
        field.onBlur(event);
        if (onBlur) {
            onBlur(event.currentTarget.value);
        }
    };

    if (!prependText && !postpendText) {
        return (
            <div className={className}>
                {props.label && (
                    <div className={`${props.colClass}`}>
                        <label htmlFor={field.name} className={labelClass}>
                            {props.label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                        </label>
                    </div>
                )}
                <div className={`${props.colClass}`}>
                    <input
                        className="form-control rounded"
                        data-testid={field.name}
                        {...field}
                        {...props}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                    {isTouched && error ? (
                        <span className="help-block text-danger" style={errorStyle ? errorStyle : null}>
                            {error}
                        </span>
                    ) : (
                        props.helptext && <span className={`help-block ${helpTextClassName}`}>{props.helptext}</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            {props.label && (
                <div>
                    <label htmlFor={field.name} className={labelClass}>
                        {props.label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                        {infoTooltipText && <InfoToolTip text={infoTooltipText} />}
                    </label>
                </div>
            )}
            <div>
                <div className="input-group">
                    {prependText && <span className="input-group-addon">{prependText}</span>}
                    <input
                        className="form-control"
                        data-testid={field.name}
                        {...field}
                        {...props}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                    {postpendText && (
                        <span style={postpendStyle} onClick={postpendOnClick} className="input-group-addon">
                            {postpendText}
                            {postsup && <sup>{postsup}</sup>}
                        </span>
                    )}
                </div>
                {isTouched && error ? (
                    <span className={`help-block text-danger`} style={errorStyle ? errorStyle : null}>
                        {error}
                    </span>
                ) : (
                    props.helptext && <span className={`help-block ${helpTextClassName}`}>{props.helptext}</span>
                )}
            </div>
        </div>
    );
};
