import { handle } from 'redux-pack';
import { FETCH_ALL_TRANSPORTERS } from '../../actions/dashboard/finances/transporter_actions';
import { arrayToObject } from 'utils/list_utils';

const initialState = {
    isLoading: false,
    error: null,
    transporterList: [],
    data: {},
    transportersAsMap: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALL_TRANSPORTERS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    transporterList: payload.data,
                    transportersAsMap: arrayToObject(payload.data, 'id'),
                }),
            });
        default:
            return state;
    }
}
