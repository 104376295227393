import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const FxModal = (props) => {
    const { hideFooter, hideHeader, headerStyle, maxWidth, isOpen, ...rest } = props;
    return (
        <Modal
            {...rest}
            width={props.width ? props.width : '40%'}
            height={props.height ? props.height : '30%'}
            isOpen={props.isOpen}
            onClosed={props.onClosed}
            toggle={props.toggle}
            style={
                props.style ? props.style : props.maxWidth ? { maxWidth: `${props.maxWidth}` } : { maxWidth: '500px' }
            }
        >
            {!props.hideHeader && <ModalHeader style={props.headerStyle}>{props.title}</ModalHeader>}
            <ModalBody>{props.children}</ModalBody>
            {props.hideFooter ? '' : <ModalFooter className="d-block" />}
        </Modal>
    );
};

FxModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onClosed: PropTypes.func,
    children: PropTypes.any,
    title: PropTypes.string,
    headerStyle: PropTypes.object,
    hideFooter: PropTypes.bool,
    hideHeader: PropTypes.bool,
    maxWidth: PropTypes.string,
    style: PropTypes.object,
};

export default FxModal;
