import axios from 'axios';
import {
    delay,
    filter,
    find,
    get,
    indexOf,
    findIndex,
    intersection,
    isEmpty,
    keys,
    map,
    orderBy,
    round,
    toUpper,
    union,
    cloneDeep,
    last,
    forEach,
    includes,
    remove,
    concat,
} from 'lodash';
import { sendAppAlertToSlack } from '../analytics_utils';
import {
    combineNameAddress,
    getTripOdo,
    isPageVisible,
    parseAddress,
    ROOT_API_URL,
    strCmp,
    filterAssetMovementPoints,
    ROOT_NODE_API_URL,
    getNearestAddressFromQT,
} from '../../constant';
import {
    getBoundingBox,
    GOOGLE_MAPS_API_KEY_PREMIUM_OM,
    pythagorasEquirectangular,
    toDegrees,
    toRadians,
} from './map_utils';
import { isStopped, VEHICLE_STATUS, getMinutePointsFromRoutes } from '../vehicle_utils';
import { DATE_FORMAT_TIMESTAMP, getMomentTime } from '../date_utils';
import { logReverseGeocodeAPICalls, prepareNearestAddressMisMacthDataAndSaveInBigquery } from '../logging_utils';
import jsonp from 'jsonp';
import { getLoggingDetails } from '../../reducers';
import {
    getNearestAddressKmLimitForAccount,
    restrictAddressToMajorCitiesForAccount,
    addressNotToUsedForAccount,
    getAccountId,
} from '../account_utils';

import { QuadTree, Box, Point, Circle } from '../../vendors/quadtree';
import { getISOCountryCode, getLocationForPlacePrediction } from '../internationalization_utils';
import { getFilteredAddressBookForNearestAddress } from 'utils/address_book_utils';
import { isFindNearestAddressWithMinDistanceEnabled } from 'utils/accountUtils/realtime';
import { removeCountryRestrictionOnGooglePlaceAPISearchForAccountFlag } from 'utils/accountUtils/mapUtils';
import { avoidAddressCalculationOnRealtime } from 'utils/realtime_utils';

export const BING_MAPS_API_KEY = 'Aqtv8Oh7OahCiTfq3vHxJXsT4s8866xtlND0BdiI4_p4FV9hPljHSoJs0mSxRnu9';
export const OPEN_CAGE_API_KEY = 'f2b8115f26994b4e82cb75908487337b';
export const LOCATION_IQ_API_KEY = '96fad9ee7876c0';
export const GEOCODER = {
    DEFAULT: 'GOOGLE',
    GOOGLE: 'GOOGLE',
    GOOGLE_KEY_LESS: 'GOOGLE_KEY_LESS',
    LOCATION_IQ: 'LOCATION_IQ',
    NOMINATIM: 'NOMINATIM',
    BING: 'BING',
    OPEN_CAGE: 'OPEN_CAGE',
    MMI_NODE: 'MMI_NODE',
    BACKEND: 'BACKEND',
    BACKEND_OM: 'BACKEND_OM',
    BACKEND_WITH_TOKEN: 'BACKEND_WITH_TOKEN',
};

export const LOCATION_FETCHED_STATUS = {
    NOT_FETCHED: 'NOT_FETCHED',
    FETCHING: 'FETCHING',
    FETCH_ERROR: 'FETCH_ERROR',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    SUBMITTING: 'SUBMITTING',
    SUBMIT_ERROR: 'SUBMIT_ERROR',
    SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
};
const MIN_TIME_DIFF_BETWEEN_GEOLOCATION_UPDATES_SECONDS = 30;
const MIN_DISTANCE_BETWEEN_GEOLOCATION_UPDATES_KMS = 0.5;
const GEOLOCATION_FETCH_DELAY_INTERNAL_MS = 2100;
export const GEOLOCATION_FETCH_DELAY_INTERNAL_MS_FAST = 300;
const GEOLOCATION_FETCH_DELAY_INTERNAL_MS_SLOW = 500;
export const P2P_DISTANCE_NORMALIZATION_FACTOR = 1.25; //Calculated based on vehicles in tanwar logistics
let vehicleNearestAddressAnomalies = [];

export const AREA_TYPE = {
    CIRCLE: 'CIRCLE',
    POLYGON: 'POLYGON',
};

const CancelToken = axios.CancelToken;

export function getGeoCodedLocation(accesstoken, latitude, longitude, geocoder = GEOCODER.GOOGLE, callback) {
    if (!isPageVisible()) {
        // Disabling this after purchasing api
        // if (callback) {
        //     callback(null, true, 'Page hidden');
        // }
        // return;
    }
    // if (forceShowNominatim(accesstoken)) {
    //     geocoder = GEOCODER.NOMINATIM;
    // }

    // if (forceHitMMINode(accesstoken)) {
    //     geocoder = GEOCODER.MMI_NODE;
    // }

    if (includes([GEOCODER.BACKEND, GEOCODER.BACKEND_WITH_TOKEN], geocoder) && !accesstoken) {
        geocoder = GEOCODER.GOOGLE;
    }

    // logReverseGeocodeAPICalls({ geocoder: geocoder });

    switch (geocoder) {
        case GEOCODER.GOOGLE:
            if (typeof google === 'undefined') {
                return getGeoCodedLocationGoogleKeyless(latitude, longitude, callback);
            } else {
                if (callback) {
                    getGeoCodedLocationGoogle(latitude, longitude, callback);
                } else {
                    return getGeoCodedLocationGoogleAsPromise(
                        getGoogleGeocoder('getGeoCodedLocation'),
                        latitude,
                        longitude
                    );
                }
            }
            break;
        case GEOCODER.LOCATION_IQ:
        // return getGeoCodedLocationLocationIQ(latitude, longitude, callback);
        case GEOCODER.NOMINATIM:
            return getGeoCodedLocationNominatim(latitude, longitude, callback);
        case GEOCODER.BING:
            getGeoCodedLocationBing(latitude, longitude, callback);
            break;
        case GEOCODER.OPEN_CAGE:
            getGeoCodedLocationOpenCage(latitude, longitude, callback);
            break;
        case GEOCODER.MMI_NODE:
            const loggedInUser = window.FLEETX_LOGGED_IN_USER;
            const accountId = loggedInUser ? loggedInUser.accountId : '0';
            return getGeoCodedLocationMMI(accesstoken, latitude, longitude, accountId, callback);
            break;
        case GEOCODER.BACKEND_OM:
            return getGeoCodedLocationFromBackendOm(accesstoken, latitude, longitude, callback);
            break;
        case GEOCODER.BACKEND:
            return getGeoCodedLocationFromBackend(accesstoken, latitude, longitude, callback);
            break;
        case GEOCODER.BACKEND_WITH_TOKEN:
            return getGeoCodedLocationFromBackendWithToken(accesstoken, latitude, longitude, callback);
            break;
        case GEOCODER.GOOGLE_KEY_LESS:
        default:
            getGeoCodedLocationGoogleKeyless(latitude, longitude, callback);
            break;
    }
}

function getGeoCodedLocationNominatim(latitude, longitude, callback) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    return getGeoCodedLocationOpenStreetMap(url, latitude, longitude, callback);
}

const getGeoCodedLocationMMI = async (accesstoken, latitude, longitude, accountId, callback) => {
    try {
        if (accesstoken) {
            const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
            const url = `${ROOT_NODE_API_URL}lapp/mapmyindia?lat=${latitude}&lng=${longitude}&acnt=${accountId}`;
            const resp = await axios.get(url, config);
        }
    } catch (e) {
        //console.log(e);
    }
    return getGeoCodedLocationFromBackend(accesstoken, latitude, longitude, callback);
};

function getGeoCodedLocationLocationIQ(latitude, longitude, callback) {
    const url = `https://locationiq.org/v1/reverse.php?format=json&key=${LOCATION_IQ_API_KEY}&lat=${latitude}&lon=${longitude}`;
    getGeoCodedLocationOpenStreetMap(url, latitude, longitude, callback);
}

async function getGeoCodedLocationOpenStreetMap(url, latitude, longitude, callback) {
    const result = await axios.get(url);
    if (result.error) {
        callback(null, true, 'OSM Geocoder failed due to: ' + result.error);
        sendAppAlertToSlack('OSM Geocoder failed due to: ' + JSON.stringify(result.error));
    } else {
        const resultObj = getSplitAddresses(get(result, 'data.display_name', null));
        if (resultObj) {
            if (callback) {
                callback(resultObj);
            } else {
                return new Promise(function (resolve, reject) {
                    resolve(resultObj);
                });
            }
        } else {
            callback(null, true, 'OSM Geocoder No results found');
            sendAppAlertToSlack(`OSM Geocoder No results found\nLatitude: ${latitude}\nLongitude: ${longitude}`);
        }
    }
}

function getGeoCodedLocationGoogleKeyless(latitude, longitude, callback, key) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?sensor=false&language=en&latlng=${latitude},${longitude}${
        key ? `&key=${key}` : ''
    }`;
    const promise = axios.get(url);
    if (!callback) {
        return promise;
    }
    promise.then((result) => {
        let error = result.error;
        if (!error && get(result, 'data.error_message')) {
            error = get(result, 'data.error_message');
        }
        if (error) {
            callback(null, true, 'Google Geocoder failed due to: ' + error);
            if (!isEmpty(result)) {
                // sendAppAlertToSlack(
                //     'Google Geocoder failed due to \n ```' +
                //         get(result, 'data.error_message', 'no error_message') +
                //         '\n' +
                //         window.location.href +
                //         '\n' +
                //         latitude +
                //         '\n' +
                //         longitude +
                //         '```',
                //     '#test'
                // );
            }
        } else {
            googleMapsGeocoderResult(result.data.results, result.data.status, callback, result);
        }
    });
}

export function getGoogleGeocoder(functionName) {
    if (typeof google === 'undefined' || !google?.maps) {
        // sendAppAlertToSlack(functionName + ' failed due to: Google Maps not available', '#test');
        // sendAppAlertToSlack(
        //     'Google Geocoder failed due to: Google Maps not available\n```getGoogleGeocoder\n' +
        //         window.location.href +
        //         `\n` +
        //         `${get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)}` +
        //         '\n' +
        //         `${getLoggingDetails(action, state)}` +
        //         '\n' +
        //         '```',
        //     '#test'
        // );
        return false;
    }
    if (!window.googleMapsGeocoder) {
        window.googleMapsGeocoder = new google.maps.Geocoder();
    }

    return window.googleMapsGeocoder;
}

export function getGeoCodedLocationGoogle(latitude, longitude, callback, repeat = 8) {
    if (typeof google === 'undefined') {
        if (!repeat) {
            callback(null, true, 'Google Geocoder failed due to: Google Maps not available');
            sendAppAlertToSlack(
                'Google Geocoder failed due to: Google Maps not available\n```getGeoCodedLocationGoogle\n' +
                    window.location.href +
                    `\n` +
                    `${get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)}` +
                    '\n' +
                    '```',
                '#test'
            );
        } else {
            delay(getGeoCodedLocationGoogle, 3000, latitude, longitude, callback, repeat - 1);
        }
        return;
    }
    const googleMapsGeocoder = getGoogleGeocoder('getGeoCodedLocationGoogle');
    if (googleMapsGeocoder) {
        const latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
        googleMapsGeocoder.geocode({ location: latlng, region: 'en' }, function (results, status) {
            googleMapsGeocoderResult(results, status, callback, results);
        });
    }
}

function getGeoCodedLocationGoogleAsPromise(googleMapsGeocoder, latitude, longitude) {
    const latlng = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
    return new Promise(function (resolve, reject) {
        googleMapsGeocoder.geocode({ location: latlng, region: 'en' }, (response) => {
            if (response && response.length > 0) {
                const resultObject = googleMapsGeocoderResult(response, 'OK');
                resolve(resultObject);
            } else {
                resolve(null);
            }
        });
    });
}

async function getGeoCodedLocationFromBackend(accesstoken, latitude, longitude, callback) {
    if (accesstoken && latitude && longitude) {
        const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
        const response = await axios.get(`${ROOT_API_URL}geocode/reverse?lat=${latitude}&lon=${longitude}`, config);
        if (!callback) {
            return new Promise(function (resolve, reject) {
                resolve(response.data);
            });
        } else {
            callback(response.data);
        }
    } else {
        if (!callback) {
            return Promise.resolve([]);
        } else {
            callback(null);
        }
    }
}

async function getGeoCodedLocationFromBackendWithToken(token, latitude, longitude, callback) {
    if (token && latitude && longitude) {
        const config = {};
        const response = await axios.get(
            `${ROOT_API_URL}geocode/reverse/token?lat=${latitude}&lon=${longitude}&token=${token}`,
            config
        );
        if (!callback) {
            return new Promise(function (resolve, reject) {
                resolve(response.data);
            });
        } else {
            callback(response.data);
        }
    } else {
        if (!callback) {
            return Promise.resolve([]);
        } else {
            callback(null);
        }
    }
}

export async function getGeoCodedLocationsFromBackendInBulk(accesstoken, coordinates, callback) {
    if (accesstoken && coordinates && coordinates.length) {
        const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_getGeoCodedLocationsFromBackendInBulk';
        if (window[cancelCallbackKey]) {
            window[cancelCallbackKey]();
        }

        const config = {
            headers: { Authorization: `Bearer ${accesstoken}` },
            cancelToken: new CancelToken(function executor(c) {
                window[cancelCallbackKey] = c;
            }),
        };
        const data = {
            'lang': 'en',
            coordinates,
        };
        const response = await axios.post(`${ROOT_API_URL}geocode/reverse/bulk`, data, config);
        // logReverseGeocodeAPICalls({ url: `${ROOT_API_URL}geocode/reverse/bulk`, geocoder: 'BACKEND_BULK' });
        if (!callback) {
            return new Promise(function (resolve, reject) {
                resolve(response.data);
            });
        } else {
            callback(response.data);
        }
    } else {
        if (!callback) {
            return Promise.resolve([]);
        } else {
            callback([]);
        }
    }
}

async function getGeoCodedLocationFromBackendOm(token, latitude, longitude, callback) {
    if (token && latitude && longitude) {
        const response = await axios.get(
            `${ROOT_API_URL}geocode/reverse/om?lat=${latitude}&lon=${longitude}&token=${token}`
        );
        if (!callback) {
            return new Promise(function (resolve, reject) {
                resolve(response.data);
            });
        } else {
            callback(response.data);
        }
    } else {
        if (!callback) {
            return Promise.resolve([]);
        } else {
            callback(null);
        }
    }
}

export function getFormattedAddress(result) {
    if (!result) {
        return null;
    }
    if (get(result, 'address_components', []).length > 0) {
        let mergedAddressComponents = null;
        map(get(result, 'address_components'), (component) => {
            const longName = get(component, 'long_name');
            const types = get(component, 'types', []);
            if (longName && longName !== 'Unnamed Road') {
                mergedAddressComponents = `${mergedAddressComponents ? mergedAddressComponents + ', ' : ''}${longName}`;
                if (indexOf(types, 'administrative_area_level_2') >= 0) {
                    //append district for administrative_area_level_2
                    mergedAddressComponents += ' District';
                }
            }
        });
        return mergedAddressComponents;
    } else {
        return get(result, 'formatted_address', null);
    }
}

export function googleMapsGeocoderResult(results, status, callback, fullResult) {
    const restrictAddressToMajorCities = restrictAddressToMajorCitiesForAccount(window.FLEETX_LOGGED_IN_USER);
    const ALL_ALLOWED_RESULT_TYPES = [
        'street_address',
        'airport',
        'intersection',
        'locality',
        'establishment',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'sublocality_level_5',
    ];
    const MAJOR_ALLOWED_RESULT_TYPES = [
        'street_address',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'sublocality_level_5',
    ];
    //Replacing street_address with route
    const TYPES_P0_MAJOR = ['route'];
    const TYPES_P0_ALL = ['route', 'airport', 'intersection'];
    const TYPES_P1 = [
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'sublocality_level_5',
    ];
    const TYPES_P2 = ['sublocality'];
    const TYPES_P3 = ['locality'];
    const TYPES_P4_STRICT = ['establishment', 'point_of_interest'];
    const TYPES_P5 = [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
    ];
    if (status === 'OK') {
        if (results.length > 0) {
            let address = getFormattedAddress(results[0]);
            let indexP0 = null,
                indexP1 = null,
                indexP2 = null,
                indexP3 = null,
                indexP4 = null,
                indexP5 = null;
            for (let i = 0; i < results.length; i++) {
                if (
                    !indexP0 &&
                    intersection(results[i].types, restrictAddressToMajorCities ? TYPES_P0_MAJOR : TYPES_P0_ALL)
                        .length > 0
                ) {
                    indexP0 = i;
                } else if (!indexP1 && intersection(results[i].types, TYPES_P1).length > 0) {
                    indexP1 = i;
                } else if (!indexP2 && intersection(results[i].types, TYPES_P2).length > 0) {
                    indexP2 = i;
                } else if (!indexP3 && intersection(results[i].types, TYPES_P3).length > 0) {
                    indexP3 = i;
                } else if (!indexP4 && union(results[i].types, TYPES_P4_STRICT).length === TYPES_P4_STRICT.length) {
                    indexP4 = i;
                } else if (!indexP5 && intersection(results[i].types, TYPES_P5).length > 0) {
                    indexP5 = i;
                }
            }
            if (indexP0 !== null) {
                address = getFormattedAddress(results[indexP0]);
            } else if (indexP1 !== null) {
                address = getFormattedAddress(results[indexP1]);
            } else if (indexP2 !== null) {
                address = getFormattedAddress(results[indexP2]);
            } else if (indexP3 !== null) {
                address = getFormattedAddress(results[indexP3]);
            } else if (indexP4 !== null) {
                address = getFormattedAddress(results[indexP4]);
            } else if (indexP5 !== null) {
                address = getFormattedAddress(results[indexP5]);
            }

            const resultObj = {
                address: address,
            };

            if (callback) {
                callback(resultObj);
            } else {
                return resultObj;
            }
        } else {
            sendAppAlertToSlack('Google Geocoder No results found\n```' + JSON.stringify(fullResult) + '```');
            if (callback) {
                callback(null, true, 'Google Geocoder No results found');
            } else {
                return null;
            }
        }
    } else {
        if (status !== 'OVER_QUERY_LIMIT') {
            if (!isEmpty(fullResult)) {
                sendAppAlertToSlack('Google Geocoder failed due to\n```' + JSON.stringify(fullResult) + '```');
            }
            if (callback) {
                callback(null, true, 'Google Geocoder failed due to: ' + status);
            } else {
                return null;
            }
        }
    }
}

function getGeoCodedLocationBing(latitude, longitude, callback) {
    const url = `https://dev.virtualearth.net/REST/v1/Locations/${latitude},${longitude}?key=${BING_MAPS_API_KEY}&o=json&includeEntityTypes=Address`;
    jsonp(url, { param: 'jsonp' }, function (err, result) {
        if (err) {
            callback(null, true, 'Bing Geocoder failed due to: ' + err.message);
            sendAppAlertToSlack('Bing Geocoder failed due to: ' + err.message);
        } else {
            if (result.statusCode !== 200) {
                callback(null, true, 'Bing Geocoder failed due to: ' + result.statusDescription);
                sendAppAlertToSlack('Bing Geocoder failed due to```' + JSON.stringify(result) + '```');
            } else {
                const resultObj = getSplitAddresses(
                    get(result, 'resourceSets[0].resources[0].address.formattedAddress', null)
                );
                if (resultObj) {
                    if (callback) {
                        callback(resultObj);
                    }
                } else {
                    callback(null, true, 'BING Geocoder No results found');
                    sendAppAlertToSlack(
                        `BING Geocoder No results found\nLatitude: ${latitude}\nLongitude: ${longitude}\n` +
                            '```' +
                            JSON.stringify(result) +
                            '```'
                    );
                }
            }
        }
    });
}

function getGeoCodedLocationOpenCage(latitude, longitude, callback) {
    const url = `https://api.opencagedata.com/geocode/v1/json?key=${OPEN_CAGE_API_KEY}&q=${latitude},${longitude}&no_annotations=1`;
    axios.get(url).then((result) => {
        if (get(result, 'data.status.code') !== 200) {
            callback(null, true, 'OpenCage Geocoder failed due to: ' + get(result, 'data.status.message'));
            sendAppAlertToSlack('OpenCage Geocoder failed due to: ' + JSON.stringify(result.data));
        } else {
            const resultObj = getSplitAddresses(get(result, 'data.results[0].formatted', null));
            if (resultObj) {
                if (callback) {
                    callback(resultObj);
                }
            } else {
                callback(null, true, 'OpenCage Geocoder No results found');
                sendAppAlertToSlack(
                    `OpenCage Geocoder No results found\nLatitude: ${latitude}\nLongitude: ${longitude}`
                );
            }
        }
    });
}

function getSplitAddresses(formattedAddress) {
    if (!formattedAddress) {
        return null;
    }
    const i1 = formattedAddress.indexOf(',');
    const ADDRESS_LEVEL_1 = i1 > 0 ? formattedAddress.substring(i1 + 2) : formattedAddress;
    const ADDRESS_LEVEL_2 =
        ADDRESS_LEVEL_1 && ADDRESS_LEVEL_1.indexOf(',') > 0
            ? ADDRESS_LEVEL_1.substring(ADDRESS_LEVEL_1.indexOf(',') + 2)
            : formattedAddress;
    const resultObj = {
        address: formattedAddress,
    };
    return resultObj;
}

export function isTimeStampDiffForGeoLocationFetchSatified(lastUpdatedTimestamp) {
    const curTime = getMomentTime();
    const lastTime = getMomentTime(lastUpdatedTimestamp);
    const diff = curTime.diff(lastTime, 'seconds');
    return diff > MIN_TIME_DIFF_BETWEEN_GEOLOCATION_UPDATES_SECONDS;
}

export function isDistanceDiffForGeoLocationFetchSatified(lastFetchedLocation, currentLocation) {
    if (!get(lastFetchedLocation, 'latitude') || !get(lastFetchedLocation, 'longitude')) {
        return true;
    }
    const diff = pythagorasEquirectangular(
        lastFetchedLocation.latitude,
        lastFetchedLocation.longitude,
        currentLocation.latitude,
        currentLocation.longitude
    );
    return diff > MIN_DISTANCE_BETWEEN_GEOLOCATION_UPDATES_KMS;
}

export function getAddressFromAddressbookOrGeoCodedLocations(
    vehicleId,
    vehicleAddress,
    status,
    lat,
    lon,
    addressbook,
    geoCodedLocations,
    loggedInuser,
    addressBookAsMap,
    addressBookAsQT,
    skipNearestAddressCalculation = false
) {
    status = toUpper(status);
    let nearestAddress = null;
    if (!skipNearestAddressCalculation) {
        nearestAddress = getNearestAddressFromQT(
            lat,
            lon,
            addressBookAsQT,
            addressBookAsMap,
            addressbook,
            loggedInuser
        );
    }

    if (isInsideNearestAddress(nearestAddress)) {
        return nearestAddress.addressName;
    }
    if (
        vehicleAddress &&
        (status === VEHICLE_STATUS.PARKED ||
            status === VEHICLE_STATUS.PARKED_10_DAYS ||
            status === VEHICLE_STATUS.DISCONNECTED ||
            status === VEHICLE_STATUS.DISCONNECTED_3_DAYS ||
            status === VEHICLE_STATUS.DISCONNECTED_4_DAYS ||
            status === VEHICLE_STATUS.DISCONNECTED_5_DAYS ||
            status === VEHICLE_STATUS.DISCONNECTED_6_DAYS)
    ) {
        return vehicleAddress;
    } else if (
        status === VEHICLE_STATUS.RUNNING ||
        status === VEHICLE_STATUS.IDLE ||
        status === VEHICLE_STATUS.UNREACHABLE ||
        VEHICLE_STATUS.UNREACHABLE_3_DAYS ||
        VEHICLE_STATUS.UNREACHABLE_4_DAYS ||
        VEHICLE_STATUS.UNREACHABLE_5_DAYS ||
        VEHICLE_STATUS.UNREACHABLE_6_DAYS ||
        status === VEHICLE_STATUS.API_UNREACHABLE ||
        VEHICLE_STATUS.WIRING_DEFECT ||
        VEHICLE_STATUS.WIRING_DEFECT_PARKED ||
        VEHICLE_STATUS.GPS_DEFECT ||
        VEHICLE_STATUS.GPS_0 ||
        status === VEHICLE_STATUS.IMMOBILISED ||
        status === VEHICLE_STATUS.NO_POWER ||
        status === VEHICLE_STATUS.BATTERY_DISCHARGED ||
        status === VEHICLE_STATUS.BATTERY_DISCHARGED
    ) {
        const geoCodedAddress = getAddressFromGeoCodedLocations(vehicleId, geoCodedLocations, lat, lon);
        if (geoCodedAddress) {
            return geoCodedAddress;
        } else {
            return '';
        }
    } else if (
        status === VEHICLE_STATUS.REMOVED ||
        status === VEHICLE_STATUS.INSHOP ||
        status === VEHICLE_STATUS.STANDBY
    ) {
        const geoCodedAddress = getAddressFromGeoCodedLocations(vehicleId, geoCodedLocations, lat, lon);
        if (geoCodedAddress) {
            return geoCodedAddress;
        } else {
            return vehicleAddress;
        }
    } else {
        const geoCodedAddress = getAddressFromGeoCodedLocations(vehicleId, geoCodedLocations);
        if (geoCodedAddress) {
            return geoCodedAddress;
        } else {
            return vehicleAddress;
        }
    }
}

export function getNearestAddress(
    vehicleId,
    vehicleToNearestAddressMap,
    uiCalculatedNearestAddress = {},
    checkForAddressAnomalies = false,
    account_id
) {
    if (vehicleToNearestAddressMap && vehicleToNearestAddressMap[vehicleId]) {
        const nearestAddressDetails = vehicleToNearestAddressMap[vehicleId] && vehicleToNearestAddressMap[vehicleId][0];
        const isInside = get(nearestAddressDetails, 'pointInside');
        const address = get(nearestAddressDetails, 'nearestAddress.address');
        const addressDistanceMeters = !isInside ? get(nearestAddressDetails, 'distance') : 0;
        const addressDistance = !isInside ? getTripOdo(addressDistanceMeters, true, true) : '0 km';
        const addressName = get(nearestAddressDetails, 'nearestAddress.name');
        const addressText = !isInside
            ? `${getTripOdo(round(addressDistanceMeters, 2), true, true)} from ${get(
                  nearestAddressDetails,
                  'nearestAddress.name',
                  '-'
              )}`
            : get(nearestAddressDetails, 'nearestAddress.name');
        const fullAddress = get(nearestAddressDetails, 'nearestAddress.address');
        const addressId = get(nearestAddressDetails, 'nearestAddress.id');
        const latitude = get(nearestAddressDetails, 'nearestAddress.latitude');
        const longitude = get(nearestAddressDetails, 'nearestAddress.longitude');
        // if (
        //     checkForAddressAnomalies &&
        //     get(uiCalculatedNearestAddress, 'id') !=
        //         get(vehicleToNearestAddressMap[vehicleId][0], 'nearestAddress.id') &&
        //     !isInside &&
        //     !get(uiCalculatedNearestAddress, 'isInside')
        // ) {
        //     console.log('MisMatch Found');
        //     const mismatchInfo = {
        //         account_id,
        //         vehicle_id: vehicleId,
        //         original_nearest_address_id: get(uiCalculatedNearestAddress, 'id'),
        //         new_nearest_address_id: get(vehicleToNearestAddressMap[vehicleId][0], 'nearestAddress.id'),
        //         original_is_inside: get(uiCalculatedNearestAddress, 'isInside'),
        //         new_is_inside: isInside,
        //         vehicle_lat: get(nearestAddressDetails, 'point.latitude'),
        //         vehicle_lng: get(nearestAddressDetails, 'point.longitude'),
        //     };
        //     prepareNearestAddressMisMacthDataAndSaveInBigquery(mismatchInfo);
        // }

        const nearestAddress = {
            address,
            addressDistance,
            addressDistanceMeters,
            addressName,
            addressText,
            fullAddress,
            id: addressId,
            isInside,
            latitude,
            longitude,
        };
        return nearestAddress;
    }
}

export function getDistanceFromAddressBook(lat, lon, addressbook, radiusInMeters = 200000) {
    const {
        latitude,
        longitude,
        name,
        radius,
        areaType,
        coordinates,
        constant,
        multiple,
        needNormalize,
        id,
        address,
        addressBookCategory,
    } = addressbook;
    let addressAndDistanceObject = {};
    let isInside = false;
    const distanceFromAddressCenterKm = pythagorasEquirectangular(lat, lon, latitude, longitude);
    const normalizedDistanceFromAddressCenterKm = distanceFromAddressCenterKm * P2P_DISTANCE_NORMALIZATION_FACTOR;
    if (distanceFromAddressCenterKm < radiusInMeters / 1000) {
        if (areaType === AREA_TYPE.CIRCLE && distanceFromAddressCenterKm <= radius / 1000) {
            isInside = true;
        } else if (
            areaType === AREA_TYPE.POLYGON &&
            isPointInsidePolygon(lat, lon, coordinates, constant, multiple, needNormalize)
        ) {
            isInside = true;
        }
        if (isInside) {
            addressAndDistanceObject.addressName = name;
            addressAndDistanceObject.fullAddress = address;
            addressAndDistanceObject.addressText = name;
            addressAndDistanceObject.addressDistance = '0 km';
            addressAndDistanceObject.addressDistanceMeters = 0;
            addressAndDistanceObject.isInside = true;
            addressAndDistanceObject.latitude = latitude;
            addressAndDistanceObject.longitude = longitude;
            addressAndDistanceObject.address = address;
            addressAndDistanceObject.addressBookCategory = addressBookCategory;
        } else if (normalizedDistanceFromAddressCenterKm < radiusInMeters / 1000) {
            addressAndDistanceObject.addressName = name;
            addressAndDistanceObject.fullAddress = address;
            addressAndDistanceObject.addressDistance = getTripOdo(normalizedDistanceFromAddressCenterKm, true, true);
            addressAndDistanceObject.addressText = `${getTripOdo(
                normalizedDistanceFromAddressCenterKm,
                true,
                true
            )} from ${name}`;
            addressAndDistanceObject.addressDistanceMeters = normalizedDistanceFromAddressCenterKm * 1000;
            addressAndDistanceObject.isInside = false;
            addressAndDistanceObject.latitude = latitude;
            addressAndDistanceObject.longitude = longitude;
            addressAndDistanceObject.address = address;
            addressAndDistanceObject.addressBookCategory = addressBookCategory;
        }
        addressAndDistanceObject.id = id;
    }
    return addressAndDistanceObject;
}

export function getNearestAddressFromAddressbookAsText(
    lat,
    lon,
    addressbook,
    includeIfInsideAddress,
    loggedInUser,
    fallbackAddress = null
) {
    const addressObject = getNearestAddressFromAddressbook(lat, lon, addressbook, includeIfInsideAddress, loggedInUser);
    return getNearestAddressText(addressObject, fallbackAddress);
}

export function getNearestAddressText(addressObject, fallbackAddress) {
    if (addressObject && addressObject.addressName && isInsideNearestAddress(addressObject)) {
        return addressObject.addressName;
    } else {
        return fallbackAddress;
    }
}

export function getNearestAddressFromAddressbook(
    lat,
    lon,
    addressbook,
    includeIfInsideAddress,
    loggedInUser,
    isAddressBookMap = false,
    { matchAll = false } = {}
) {
    const withoutAddress = avoidAddressCalculationOnRealtime(getAccountId());
    if (withoutAddress) {
        return null;
    }

    let filteredAddressBook = getFilteredAddressBookForNearestAddress(addressbook);

    let addressAndDistanceObject = {};
    const addressAndDistanceObjectMatchAll = [];
    const MAX_NEAREST_ADDRESS_KM_LIMIT = getNearestAddressKmLimitForAccount(loggedInUser);
    let minKm = MAX_NEAREST_ADDRESS_KM_LIMIT;

    if (
        lat &&
        lon &&
        filteredAddressBook &&
        (filteredAddressBook.length > 0 || (isAddressBookMap && !isEmpty(filteredAddressBook)))
    ) {
        let addressBookLength = 0;
        let addressBookKeys = [];
        if (isAddressBookMap) {
            addressBookKeys = Object.keys(filteredAddressBook);
            addressBookLength = get(addressBookKeys, 'length', 0);
        } else {
            addressBookLength = get(filteredAddressBook, 'length', 0);
        }
        for (let i = 0; i < addressBookLength; i++) {
            const addressDistanceLocalObject = getDistanceFromAddressBook(
                lat,
                lon,
                isAddressBookMap ? filteredAddressBook[addressBookKeys[i]] : filteredAddressBook[i],
                MAX_NEAREST_ADDRESS_KM_LIMIT * 1000
            );

            if (addressDistanceLocalObject.isInside) {
                if (!includeIfInsideAddress) {
                    return matchAll ? [] : null;
                }
                if (matchAll) {
                    addressAndDistanceObjectMatchAll.push(addressDistanceLocalObject);
                } else {
                    return addressDistanceLocalObject;
                }
            } else if (
                addressDistanceLocalObject.addressDistanceMeters &&
                addressDistanceLocalObject.addressDistanceMeters / 1000 < minKm
            ) {
                minKm = addressDistanceLocalObject.addressDistanceMeters / 1000;
                addressAndDistanceObject = { ...addressDistanceLocalObject };
            }
        }
    }
    if (matchAll) {
        return addressAndDistanceObjectMatchAll.length > 0 ? addressAndDistanceObjectMatchAll : null;
    } else {
        if (!addressAndDistanceObject.addressName) {
            return null;
        } else {
            return addressAndDistanceObject;
        }
    }
}

function isPointInsidePolygonOld1(latitude, longitude, polygonCoordinates) {
    let i,
        j = polygonCoordinates.length - 1;
    let oddNodes = false;

    for (i = 0; i < polygonCoordinates.length; i++) {
        if (
            ((polygonCoordinates[i].lon < longitude && polygonCoordinates[j].lon >= longitude) ||
                (polygonCoordinates[j].lon < longitude && polygonCoordinates[i].lon >= latitude)) &&
            (polygonCoordinates[i].lat <= latitude || polygonCoordinates[j].lat <= latitude)
        ) {
            oddNodes ^=
                polygonCoordinates[i].lat +
                    ((longitude - polygonCoordinates[i].lon) /
                        (polygonCoordinates[j].lon - polygonCoordinates[i].lon)) *
                        (polygonCoordinates[j].lat - polygonCoordinates[i].lat) <
                latitude;
        }
        j = i;
    }

    return oddNodes;
}

function isPointInsidePolygonOld2(latitude, longitude, polygonCoordinates) {
    //https://stackoverflow.com/questions/13950062/checking-if-a-longitude-latitude-coordinate-resides-inside-a-complex-polygon-in

    // if (!Bounds.Contains(location))
    //     return false;

    let lastPoint = polygonCoordinates[polygonCoordinates.length - 1];
    let isInside = false;
    let x = longitude;
    for (let i = 0; i < polygonCoordinates.length; i++) {
        const point = polygonCoordinates[i];
        let x1 = lastPoint.lon;
        let x2 = point.lon;
        let dx = x2 - x1;

        if (Math.abs(dx) > 180.0) {
            // we have, most likely, just jumped the dateline (could do further validation to this effect if needed).
            // normalise the numbers.
            if (x > 0) {
                while (x1 < 0) {
                    x1 += 360;
                }
                while (x2 < 0) {
                    x2 += 360;
                }
            } else {
                while (x1 > 0) {
                    x1 -= 360;
                }
                while (x2 > 0) {
                    x2 -= 360;
                }
            }
            dx = x2 - x1;
        }

        if ((x1 <= x && x2 > x) || (x1 >= x && x2 < x)) {
            const grad = (point.lat - lastPoint.lat) / dx;
            const intersectAtLat = lastPoint.lat + (x - x1) * grad;

            if (intersectAtLat > location.lat) {
                isInside = !isInside;
            }
        }
        lastPoint = point;
    }

    return isInside;
}

function normalizeLon(needNormalize, lon) {
    if (needNormalize && lon < -90) {
        return lon + 360;
    }
    return lon;
}

export function precalc(coordinates) {
    if (coordinates == null) {
        return;
    }

    const polyCorners = coordinates.length;
    let i;
    let j = polyCorners - 1;

    let constant = [];
    let multiple = [];

    let hasNegative = false;
    let hasPositive = false;
    for (i = 0; i < polyCorners; i++) {
        if (coordinates.lon > 90) {
            hasPositive = true;
        } else if (coordinates.lon < -90) {
            hasNegative = true;
        }
    }
    const needNormalize = hasPositive && hasNegative;

    for (i = 0; i < polyCorners; j = i++) {
        if (normalizeLon(needNormalize, coordinates[j].lon) == normalizeLon(needNormalize, coordinates[i].lon)) {
            constant.push(coordinates[i].lat);
            multiple.push(0);
        } else {
            constant.push(
                coordinates[i].lat -
                    (normalizeLon(needNormalize, coordinates[i].lon) * coordinates[j].lat) /
                        (normalizeLon(needNormalize, coordinates[j].lon) -
                            normalizeLon(needNormalize, coordinates[i].lon)) +
                    (normalizeLon(needNormalize, coordinates[i].lon) * coordinates[i].lat) /
                        (normalizeLon(needNormalize, coordinates[j].lon) -
                            normalizeLon(needNormalize, coordinates[i].lon))
            );
            multiple.push(
                (coordinates[j].lat - coordinates[i].lat) /
                    (normalizeLon(needNormalize, coordinates[j].lon) - normalizeLon(needNormalize, coordinates[i].lon))
            );
        }
    }

    return {
        constant,
        multiple,
        needNormalize,
    };
}

export function preCalcAddressbook(addressBookList) {
    const newAddress = orderBy(
        map(addressBookList, (address) => {
            if (!address.areaType || address.areaType === AREA_TYPE.CIRCLE) {
                return { ...address };
            } else if (
                address.areaType === AREA_TYPE.POLYGON &&
                address.coordinates &&
                address.coordinates.length > 0
            ) {
                return {
                    ...address,
                    ...precalc(address.coordinates),
                };
            } else {
                return {
                    ...address,
                };
            }
        }),
        ['radius'],
        ['asc']
    );
    return newAddress;
}

export function isPointInsidePolygon(latitude, longitude, coordinates, constant, multiple, needNormalize) {
    if (coordinates == null) {
        return false;
    }
    if (!constant || !multiple) {
        const precalcData = precalc(coordinates);
        constant = precalcData.constant;
        multiple = precalcData.multiple;
        needNormalize = precalcData.needNormalize;
    }
    const polyCorners = coordinates.length;
    let i;
    let j = polyCorners - 1;
    const longitudeNorm = normalizeLon(needNormalize, longitude);
    let oddNodes = false;

    for (i = 0; i < polyCorners; j = i++) {
        if (
            (normalizeLon(needNormalize, coordinates[i].lon) < longitudeNorm &&
                normalizeLon(needNormalize, coordinates[j].lon) >= longitudeNorm) ||
            (normalizeLon(needNormalize, coordinates[j].lon) < longitudeNorm &&
                normalizeLon(needNormalize, coordinates[i].lon) >= longitudeNorm)
        ) {
            oddNodes ^= longitudeNorm * multiple[i] + constant[i] < latitude;
        }
    }
    return !!oddNodes;
}

export function isInsideNearestAddress(nearestAddress) {
    return nearestAddress && nearestAddress.isInside;
}

export function getAddressFromGeoCodedLocations(vehicleId, geoCodedLocations, lat, lon) {
    if (vehicleId && geoCodedLocations) {
        if (geoCodedLocations[vehicleId] && !isEmpty(geoCodedLocations[vehicleId].resultObj)) {
            if (lat && lon && geoCodedLocations[vehicleId].latitude && geoCodedLocations[vehicleId].longitude) {
                const lastDistance = pythagorasEquirectangular(
                    lat,
                    lon,
                    geoCodedLocations[vehicleId].latitude,
                    geoCodedLocations[vehicleId].longitude
                );
                if (lastDistance > 1) {
                    return null;
                }
                if (geoCodedLocations[vehicleId].resultObj.address) {
                    return geoCodedLocations[vehicleId].resultObj.address;
                } else if (get(geoCodedLocations[vehicleId], 'address')) {
                    return get(geoCodedLocations[vehicleId], 'address');
                }
            }
            // if (geoCodedLocations[vehicleId].resultObj.address) {
            //     return geoCodedLocations[vehicleId].resultObj.address;
            // } else if (get(geoCodedLocations[vehicleId], 'address')) {
            //     return get(geoCodedLocations[vehicleId], 'address');
            // }
        }
    }
    return null;
}

export async function getGeoCodedLocationsInBulk(
    accesstoken,
    vehicleList,
    geoCodedLocations,
    callback,
    loggedInUser,
    dontFilter = false
) {
    if (!vehicleList || vehicleList.length === 0) {
        callback(geoCodedLocations);
        return;
    }
    const length = vehicleList.length;
    const coordinates = [];
    for (let i = 0; i < length; i++) {
        const vehicle = vehicleList[i];
        if (vehicle && vehicle.vehicleId && !isStopped(vehicle.currentStatus)) {
            const vehicleLocation = geoCodedLocations[vehicle.vehicleId];
            if (!vehicleLocation || dontFilter || isDistanceDiffForGeoLocationFetchSatified(vehicleLocation, vehicle)) {
                coordinates.push({
                    latitude: vehicle.latitude,
                    longitude: vehicle.longitude,
                    id: vehicle.vehicleId,
                });
            }
        }
    }
    if (!coordinates || coordinates.length === 0) {
        callback(geoCodedLocations);
        return;
    }
    try {
        const result = await getGeoCodedLocationsFromBackendInBulk(accesstoken, coordinates);

        const newGeoCodedLocations = {};
        const timestamp = getMomentTime().format(DATE_FORMAT_TIMESTAMP);

        forEach(result, (item) => {
            const vehicle = find(coordinates, { id: +item.id }) || {};
            newGeoCodedLocations[item.id] = {
                latitude: vehicle.latitude,
                longitude: vehicle.longitude,
                resultObj: item,
                timestamp,
            };
        });
        callback(newGeoCodedLocations);
    } catch (err) {
        if (get(err, 'response.status', '0') == 406) {
            sendAppAlertToSlack(
                get(err, 'response.status', '0') +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails({
                        type: 'bulk reverse_geocoding_locations',
                        payload: {
                            ...err,
                        },
                    }) +
                    '\n' +
                    JSON.stringify(err) +
                    '```',
                '#alerts-406'
            );
        } else if (get(err, 'response.status', '0') == 429) {
            sendAppAlertToSlack(
                get(err, 'response.status', '0') +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails(
                        {
                            type: 'bulk reverse_geocoding_locations',
                            payload: {
                                ...err,
                            },
                        },
                        { settings: { loggedInUser } }
                    ) +
                    `accessToken               : ${accesstoken}` +
                    '```'
            );
        } else if (get(err, 'response.status', '0') != 0) {
            sendAppAlertToSlack(
                get(err, 'response.status', '0') +
                    ' HTTP STATUS CODE LOGGED\n```' +
                    getLoggingDetails({
                        type: 'bulk reverse_geocoding_locations',
                        payload: {
                            ...err,
                        },
                    }) +
                    '```'
            );
        }
    }
}

export async function getGeoCodedLocations(
    accesstoken,
    vehicleList,
    geoCodedLocations,
    callback,
    noRepeatCount = 0,
    loggedInUser,
    geocoder = GEOCODER.BACKEND
) {
    // if (window.geoCodedLocationsInProgress) {
    //     return;
    // }
    if (!vehicleList || vehicleList.length == 0) {
        callback(geoCodedLocations);
        return;
    }

    if (typeof google === 'undefined') {
        if (noRepeatCount >= 8) {
            callback(null, true, 'Google Geocoder failed due to: Google Maps not available');
        } else {
            delay(
                getGeoCodedLocations,
                2000,
                accesstoken,
                vehicleList,
                geoCodedLocations,
                callback,
                noRepeatCount + 1,
                null,
                geocoder
            );
        }
        return;
    }

    const googleMapsGeocoder = getGoogleGeocoder('getGeoCodedLocations');
    if (!accesstoken && !googleMapsGeocoder) {
        callback(geoCodedLocations);
        return;
    }

    window.geoCodedLocationsInProgress = true;

    const length = vehicleList.length;
    // map(vehicleList, (vehicle) => {
    for (let i = 0; i < length; i++) {
        // if (!window.geoCodedLocationsInProgress) {
        //     //Aborting
        //     break;
        // }
        const vehicle = vehicleList[i];
        if (vehicle && vehicle.vehicleId && !isStopped(vehicle.currentStatus)) {
            const vehicleLocation = geoCodedLocations[vehicle.vehicleId];
            if (!vehicleLocation || isDistanceDiffForGeoLocationFetchSatified(vehicleLocation, vehicle)) {
                let resultObj = null;

                if (accesstoken) {
                    try {
                        const result = await getGeoCodedLocation(
                            accesstoken,
                            vehicle.latitude,
                            vehicle.longitude,
                            geocoder
                        );
                        resultObj = result;
                    } catch (err) {
                        if (get(err, 'response.status', '0') == 406) {
                            sendAppAlertToSlack(
                                get(err, 'response.status', '0') +
                                    ' HTTP STATUS CODE LOGGED\n```' +
                                    getLoggingDetails({
                                        type: 'reverse_geocoding_locations',
                                        payload: {
                                            ...err,
                                        },
                                    }) +
                                    '\n' +
                                    JSON.stringify(err) +
                                    '```',
                                '#alerts-406'
                            );
                        } else if (get(err, 'response.status', '0') == 429) {
                            sendAppAlertToSlack(
                                get(err, 'response.status', '0') +
                                    ' HTTP STATUS CODE LOGGED\n```' +
                                    getLoggingDetails(
                                        {
                                            type: 'reverse_geocoding_locations',
                                            payload: {
                                                ...err,
                                            },
                                        },
                                        { settings: { loggedInUser } }
                                    ) +
                                    `accessToken               : ${accesstoken}` +
                                    '```'
                            );
                        } else if (get(err, 'response.status', '0') != 0) {
                            sendAppAlertToSlack(
                                get(err, 'response.status', '0') +
                                    ' HTTP STATUS CODE LOGGED\n```' +
                                    getLoggingDetails({
                                        type: 'reverse_geocoding_locations',
                                        payload: {
                                            ...err,
                                        },
                                    }) +
                                    '```'
                            );
                        }
                        break;
                    }
                } else {
                    resultObj = await getGeoCodedLocation(null, vehicle.latitude, vehicle.longitude, geocoder);
                }
                if (resultObj) {
                    const newGeoCodedLocations = {};
                    const timestamp = getMomentTime().format(DATE_FORMAT_TIMESTAMP);
                    newGeoCodedLocations.updatedTimestamp = timestamp;
                    newGeoCodedLocations[vehicle.vehicleId] = {
                        latitude: vehicle.latitude,
                        longitude: vehicle.longitude,
                        resultObj,
                        timestamp,
                    };
                    callback(newGeoCodedLocations);
                }
                await new Promise(function (resolve, reject) {
                    delay(resolve, GEOLOCATION_FETCH_DELAY_INTERNAL_MS_FAST);
                });
            } else {
                //callback(geoCodedLocations);
            }
        }
    }
    // );

    window.geoCodedLocationsInProgress = false;
}

export async function getBatchGeoCodedLocations(
    accesstoken,
    locationsForGeocoding,
    progressUpdateCallback,
    loggedInUser
) {
    const k = keys(locationsForGeocoding);
    const keyLength = k.length;
    const startTime = getMomentTime();
    for (let i = 0; i < keyLength; i++) {
        let result = null;
        try {
            result = await getGeoCodedLocation(
                accesstoken,
                locationsForGeocoding[k[i]].latitude,
                locationsForGeocoding[k[i]].longitude,
                GEOCODER.BACKEND
            );
        } catch (err) {
            result = null;
            if (get(err, 'response.status', '0') == 406) {
                sendAppAlertToSlack(
                    get(err, 'response.status', '0') +
                        ' HTTP STATUS CODE LOGGED\n```' +
                        getLoggingDetails({
                            type: 'reverse_geocoding_batch',
                            payload: {
                                ...err,
                            },
                        }) +
                        JSON.stringify(err) +
                        '```',
                    '#alerts-406'
                );
            } else if (get(err, 'response.status', '0') == 429) {
                sendAppAlertToSlack(
                    get(err, 'response.status', '0') +
                        ' HTTP STATUS CODE LOGGED\n```' +
                        getLoggingDetails(
                            {
                                type: 'reverse_geocoding_batch',
                                payload: {
                                    ...err,
                                },
                            },
                            { settings: { loggedInUser } }
                        ) +
                        `accessToken               : ${accesstoken}` +
                        '```'
                );
            } else if (get(err, 'response.status', '0') != 0) {
                sendAppAlertToSlack(
                    get(err, 'response.status', '0') +
                        ' HTTP STATUS CODE LOGGED\n```' +
                        getLoggingDetails({
                            type: 'reverse_geocoding_batch',
                            payload: {
                                ...err,
                            },
                        }) +
                        '```'
                );
            }
            progressUpdateCallback(100, 0, true, 'Something went wrong. Please try again later.');
            break;
        }

        if (progressUpdateCallback) {
            const diff = getMomentTime().diff(startTime) / 1000;
            const speed = (i + 1) / diff;
            progressUpdateCallback(((i + 1) * 100) / keyLength, round(((keyLength - i) * 1000) / speed));
        }
        if (i < keyLength - 1) {
            await new Promise(function (resolve, reject) {
                delay(
                    resolve,
                    keyLength > 500
                        ? GEOLOCATION_FETCH_DELAY_INTERNAL_MS_SLOW
                        : GEOLOCATION_FETCH_DELAY_INTERNAL_MS_FAST
                );
            });
        }

        if (result) {
            locationsForGeocoding[k[i]].address = result.address;
        }
    }

    return locationsForGeocoding;
}

export function isHTML5GeolocationSupported() {
    return !!window.navigator.geolocation;
}

export function getHTML5Geolocation(callback, enableHighAccuracy = false) {
    if (isHTML5GeolocationSupported()) {
        window.navigator.geolocation.getCurrentPosition(
            (position) => {
                callback({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                let errormessage = 'Sorry! Your current location cannot be established.';
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errormessage = 'Please enable location services & grant permission to access your location.';
                        break;
                    case error.POSITION_UNAVAILABLE:
                        break;
                    case error.TIMEOUT:
                        break;
                    case error.UNKNOWN_ERROR:
                        break;
                }
                callback(null, true, errormessage);
            },
            {
                enableHighAccuracy,
                timeout: 120000,
            }
        );
    } else {
        callback(null, true, 'Sorry! Your browser does not support location feature.');
    }
}

function getGooglePlacesService(functionName) {
    if (typeof google === 'undefined' || !google?.maps) {
        // sendAppAlertToSlack(functionName + ' failed due to: Google Maps not available', '#test');
        return false;
    }
    if (!window.googlePlacesService) {
        window.dummyGooglePlacesDiv = document.createElement('div');
        window.googlePlacesService = new google.maps.places.PlacesService(window.dummyGooglePlacesDiv);
    }

    return window.googlePlacesService;
}

// To be used only in case when the required page does not have a google map component
export const loadGoogleMap = (key, libraries, callback) => {
    if (typeof google === 'undefined') {
        const existingScript = document.getElementById('googleMaps');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=${libraries}`;
            script.id = 'googleMaps';
            document.body.appendChild(script);
            script.onload = () => {
                console.log('loaded');
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    } else if (callback) {
        callback();
    }
};

function getGoogleAutocompleteService(functionName) {
    if (typeof google === 'undefined' || !google?.maps) {
        // sendAppAlertToSlack(functionName + ' failed due to: Google Maps not available', '#test');
        loadGoogleMap(GOOGLE_MAPS_API_KEY_PREMIUM_OM, 'places');
    } else if (!window.googlePlacesAutocompleteService) {
        window.googlePlacesAutocompleteService = new google.maps.places.AutocompleteService();
    } else if (window.googlePlaceAutocompleteSessionToken) {
        window.googlePlaceAutocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
    }
    return window.googlePlacesAutocompleteService;
}

export function getGoogleAutocompleteSuggestions(text, lat, lng) {
    const autocompleteService = getGoogleAutocompleteService('getGoogleAutocompleteSuggestions');
    if (autocompleteService) {
        const request = {
            input: text,
            componentRestrictions: {
                country: removeCountryRestrictionOnGooglePlaceAPISearchForAccountFlag()
                    ? undefined
                    : getISOCountryCode(),
            },
        };
        if (window.googlePlaceAutocompleteSessionToken) {
            request.sessionToken = window.googlePlaceAutocompleteSessionToken;
        }
        if (lat && lng) {
            (request.location = new (google.maps?.LatLng)(lat, lng)), (request.radius = 50000);
        }

        return new Promise(function (resolve, reject) {
            //returning promise
            autocompleteService.getPlacePredictions(request, (predictions, status) => {
                if (status != google.maps.places.PlacesServiceStatus.OK) {
                    resolve([]);
                } else {
                    resolve(
                        map(predictions, (p) => ({
                            address: p.description,
                            place_id: p.place_id,
                        }))
                    );
                }
            });
        });
    } else {
        return Promise.resolve([]);
    }
}

export function getGooglePlaceSuggestions(text, lat, lng) {
    const placesService = getGooglePlacesService('getGooglePlaceSuggestions');
    if (placesService) {
        const request = {
            location: new google.maps.LatLng(lat, lng),
            radius: '50000',
            query: text,
        };

        return new Promise(function (resolve, reject) {
            //returning promise
            placesService.textSearch(request, (results, status) => {
                resolve(
                    map(results, (r) => ({
                        name: r.name,
                        address: getFormattedAddress(r),
                        latitude: r.geometry.location.lat(),
                        longitude: r.geometry.location.lng(),
                    }))
                );
            });
        });
    } else {
        return Promise.resolve([]);
    }
}

export function getGooglePlaceDetails(placeId) {
    const placesService = getGooglePlacesService('getGooglePlaceDetails');
    if (placesService) {
        var request = {
            placeId: placeId,
            fields: ['name', 'geometry'],
        };
        if (window.googlePlaceAutocompleteSessionToken) {
            request.sessionToken = window.googlePlaceAutocompleteSessionToken;
        }
        return new Promise(function (resolve, reject) {
            //returning promise
            placesService.getDetails(request, (place, status) => {
                window.googlePlaceAutocompleteSessionToken = null;
                if (status != google.maps?.places.PlacesServiceStatus.OK) {
                    resolve({});
                } else {
                    const newValue = {
                        name: place.name,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                    };
                    resolve(newValue);
                }
            });
        });
    } else {
        return Promise.resolve({});
    }
}

export function getNominatimPlaceAutocompleteSuggestions(text, lat, lng) {
    const boundingBox = getBoundingBox(lat, lng, 50000);
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${text}&countrycodes=${getISOCountryCode()}&limit=10&viewbox=${
        boundingBox[0]
    },${boundingBox[1]},${boundingBox[2]},${boundingBox[3]}`;

    return new Promise(function (resolve, reject) {
        //returning promise
        axios.get(url).then((result) => {
            resolve(
                map(result.data, (r) => ({
                    name: r.display_name.split(', ')[0],
                    address: r.display_name.split(', ').slice(1).join(', '),
                    latitude: r.lat,
                    longitude: r.lon,
                }))
            );
        });
    });
}

export function getSuggestions(input, latitude, longitude, addressBook, sliceSize = 100) {
    if (!latitude || !longitude) {
        //set default lat/lng
        const { lat, lng } = getLocationForPlacePrediction();
        latitude = lat;
        longitude = lng;
    }
    if (!input) {
        return Promise.resolve([]);
    } else {
        let addressBookResults = [];
        map(addressBook, (addr) => {
            if (strCmp(addr.name, input) || strCmp(addr.address, input)) {
                addressBookResults.push(addr);
            }
        });
        return getPlaceAutocompleteSuggestions(input, latitude, longitude, GEOCODER.GOOGLE).then((results) => {
            return concat(addressBookResults, results).slice(0, sliceSize);
        });
    }
}

export function renderAutocompleteOption(option, state) {
    const address = combineNameAddress(option.name, option.address);

    if (state.context === 'value') {
        return renderAutocompleteSelectedOption(option);
    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <div>
                <i className="fa fa-map-marker" /> &nbsp;
                {parseAddress(address)}
            </div>
            {!!option.id && <div className="text-muted font-italic text-right">(from Address Book)</div>}
        </div>
    );
}

export function renderAutocompleteSelectedOption(option) {
    const address = combineNameAddress(option.name, option.address);
    return parseAddress(address);
}

export function getPlaceAutocompleteSuggestions(text, latitude, longitude, geocoder = GEOCODER.GOOGLE) {
    switch (geocoder) {
        case GEOCODER.GOOGLE:
            // return getGooglePlaceSuggestions(text, latitude, longitude);
            return getGoogleAutocompleteSuggestions(text, latitude, longitude);
        case GEOCODER.NOMINATIM:
            return getNominatimPlaceAutocompleteSuggestions(text, latitude, longitude);
        default:
            return Promise.resolve([]);
    }
}

export function getGoogleDirections(
    sLat,
    sLon,
    eLat,
    eLon,
    provideRouteAlternatives = false,
    wayPoints,
    avoidTolls = false
) {
    if (typeof google === 'undefined' || !google?.maps) {
        // sendAppAlertToSlack('getGoogleDirections failed due to: Google Maps not available', '#test');
        return Promise.resolve({});
    } else {
        const request = {
            origin: new google.maps.LatLng(sLat, sLon),
            destination: new google.maps.LatLng(eLat, eLon),
            travelMode: 'DRIVING',
            unitSystem: google.maps.UnitSystem.METRIC,
            provideRouteAlternatives,
            avoidTolls,
        };
        if (wayPoints && wayPoints.length > 0) {
            request.waypoints = wayPoints;
        }
        const directionsService = new google.maps.DirectionsService();
        return new Promise(function (resolve, reject) {
            directionsService.route(request, function (result, status) {
                resolve(result, status);
            });
        });
    }
}

export function getCenterOfPolygon(path) {
    //based on this - https://stackoverflow.com/a/14231286
    if (!path || path.length == 0) {
        return null;
    }
    if (path.length == 1) {
        return path[0];
    }
    let x = 0.0,
        y = 0.0,
        z = 0.0;
    map(path, ({ lat, lon }) => {
        const latitude = toRadians(lat);
        const longitude = toRadians(lon);
        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    });
    const total = path.length;
    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        lat: toDegrees(centralLatitude),
        lng: toDegrees(centralLongitude),
    };
}

export function getRadiusOfPolygon(path, useMaxRadius = true) {
    const center = getCenterOfPolygon(path);
    let maxRadius = 0,
        minRadius = 0;
    if (center) {
        map(path, ({ lat, lon }) => {
            const curRadius = pythagorasEquirectangular(lat, lon, center.lat, center.lng);
            if (maxRadius === 0 || curRadius > maxRadius) {
                maxRadius = curRadius;
            }
            if (minRadius === 0 || curRadius < minRadius) {
                minRadius = curRadius;
            }
        });
    }

    if (useMaxRadius) {
        return round(maxRadius * 1000);
    } else {
        return round(minRadius * 1000);
    }
}

export function getselectedAddressesList(selectedAddresses, addressBook) {
    const selectedList = [];
    map(selectedAddresses, (id) => {
        const address = find(addressBook, { id: id });
        if (address) {
            selectedList.push(address);
        }
    });
    return selectedList;
}

export function totalRawPointsDistance(assetMovementDetails) {
    let distanceTillNow = 0;
    map(assetMovementDetails, (point, index) => {
        if (index > 0) {
            distanceTillNow += pythagorasEquirectangular(
                point.latitude,
                point.longitude,
                assetMovementDetails[index - 1].latitude,
                assetMovementDetails[index - 1].longitude
            );
        }
    });
    return distanceTillNow;
}

export const createQuadTreeFromAddressBook = (addressBook) => {
    const config = {
        capacity: 100, // Specify the maximum amount of point per node (default: 4)
        removeEmptyNodes: true,
        maximumDepth: 10, // Specify the maximum depth of the quadtree. -1 for no limit (default: -1).
    };
    const QT = new QuadTree(new Box(0, 0, 180, 360), config);
    let addressPointList = [];
    map(addressBook, (address) => {
        const addressPoint = new Point(
            normalizeLatitude(get(address, 'latitude')),
            normalizeLongitude(get(address, 'longitude')),
            get(address, 'id')
        );
        addressPointList.push(addressPoint);
    });
    QT.insert(addressPointList);
    return QT;
};
export const createQuadTree = (coordinates) => {
    const config = {
        capacity: 100, // Specify the maximum amount of point per node (default: 4)
        removeEmptyNodes: true,
        maximumDepth: 10, // Specify the maximum depth of the quadtree. -1 for no limit (default: -1).
    };
    const QT = new QuadTree(new Box(0, 0, 180, 360), config);
    let coordinatesList = [];
    map(coordinates, (coordinate) => {
        const coordinatePoint = new Point(
            normalizeLatitude(get(coordinate, 'lat')),
            normalizeLongitude(get(coordinate, 'lng')),
            get(coordinate, 'id')
        );
        coordinatesList.push(coordinatePoint);
    });
    QT.insert(coordinatesList);
    return QT;
};

export function getNearestAddressFromWithinQTNode(
    lat,
    lon,
    nearestQTNodePoints,
    addressbook,
    addressBookAsMap,
    includeIfInsideAddress,
    loggedInUser
) {
    let addressAndDistanceObject = {};
    const MAX_NEAREST_ADDRESS_KM_LIMIT = getNearestAddressKmLimitForAccount(loggedInUser);
    let minKm = MAX_NEAREST_ADDRESS_KM_LIMIT;
    if (lat && lon && addressbook && addressbook.length > 0 && nearestQTNodePoints && !isEmpty(nearestQTNodePoints)) {
        const ids = map(nearestQTNodePoints, (p) => {
            return p.data;
        });
        const idLength = get(ids, 'length', 0);
        for (let i = 0; i < idLength; i++) {
            const id = ids[i];
            if (addressBookAsMap[id]) {
                const addressDistanceLocalObject = getDistanceFromAddressBook(
                    lat,
                    lon,
                    addressBookAsMap[id],
                    MAX_NEAREST_ADDRESS_KM_LIMIT * 1000
                );
                if (
                    addressDistanceLocalObject.isInside &&
                    !includeIfInsideAddress &&
                    isFindNearestAddressWithMinDistanceEnabled()
                ) {
                    return null;
                } else if (addressDistanceLocalObject.isInside && !isFindNearestAddressWithMinDistanceEnabled()) {
                    if (!includeIfInsideAddress) {
                        return null;
                    }
                    return addressDistanceLocalObject;
                } else if (
                    addressDistanceLocalObject.addressDistanceMeters &&
                    addressDistanceLocalObject.addressDistanceMeters / 1000 < minKm
                ) {
                    minKm = addressDistanceLocalObject.addressDistanceMeters / 1000;
                    addressAndDistanceObject = { ...addressDistanceLocalObject };
                }
            }
        }
    }
    if (!addressAndDistanceObject.addressName) {
        return null;
    } else {
        return addressAndDistanceObject;
    }
}

export const NORMALIZE_X = 180;
export const NORMALIZE_Y = 90;

export const normalizeLatitude = (latitude) => {
    return latitude + NORMALIZE_Y;
};
export const deNormalizeLatitude = (latitude) => {
    return latitude - NORMALIZE_Y;
};

export const normalizeLongitude = (longitude) => {
    return longitude + NORMALIZE_X;
};
export const deNormalizeLongitude = (longitude) => {
    return longitude - NORMALIZE_X;
};

// function to compare nearest addresses fetched from quadtree and getNearestAddressFromAddressbook
// export const compareNearestAddresses = (addressBook, addressBookAsQT, addressBookAsMap, loggedInUser, vehicles) => {
//     let totalTrue = 0;
//     let totalFalse = 0;
//     map(vehicles, vehicle => {
//         const latitude = get(vehicle, 'latitude');
//         const longitude = get(vehicle, 'longitude');
//         let nearestAddressFromQT = addressBookAsQT.query(
//             new Circle(normalizeLatitude(get(vehicle, 'latitude')), normalizeLongitude(get(vehicle, 'longitude')), 2)
//         );
//         //console.log('nearestAddQT', nearestAddressFromQT, nearestAddressFromQT.length);
//         const nearestAddQT = getNearestAddressFromWithinQTNode(
//             latitude,
//             longitude,
//             nearestAddressFromQT,
//             addressBook,
//             addressBookAsMap,
//             true,
//             loggedInUser
//         );
//         //console.log('nearestAddQT selected', nearestAddQT);
//         const nearestAdd = getNearestAddressFromAddressbook(latitude, longitude, addressBook, true, loggedInUser);
//         //console.log('nearestAdd selected', nearestAdd);
//         if (get(nearestAddQT, 'id') === get(nearestAdd, 'id')) {
//             totalTrue++;
//         } else {
//             console.log(
//                 get(nearestAddQT, 'addressDistance'),
//                 get(nearestAdd, 'addressDistance'),
//                 (get(nearestAdd, 'addressDistanceMeters') - get(nearestAdd, 'addressDistanceMeters')) / 1000
//             );
//             totalFalse++;
//         }
//     });
//     console.log('totalTrue', totalTrue);
//     console.log('totalFalse', totalFalse);
// };
