import { jsx as _jsx } from "react/jsx-runtime";
import PaymentsComponent from 'components/dashboard/payments/PaymentsComponent';
import ErrorBoundary from 'components/utils/error_boundary_component';
import AddBulkPaymentFormComponent from 'components/dashboard/payments/forms/AddBulkPaymentFormComponent';
import AddBulkAdjustmentFormComponent from 'components/dashboard/payments/forms/AddBulkAdjustmentFormComponent';
import BillsSettlementFormComponent from 'components/dashboard/payments/forms/BillsSettlementFormComponent';
const paymentsRoutes = [
    {
        path: '',
        element: (_jsx(ErrorBoundary, { children: _jsx(PaymentsComponent, {}) })),
    },
    {
        path: 'bulk-payment/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddBulkPaymentFormComponent, {}) })),
    },
    {
        path: 'bulk-adjustment/add',
        element: (_jsx(ErrorBoundary, { children: _jsx(AddBulkAdjustmentFormComponent, {}) })),
    },
    {
        path: 'bills/settlement/:id',
        element: (_jsx(ErrorBoundary, { children: _jsx(BillsSettlementFormComponent, {}) })),
    },
];
export default paymentsRoutes;
