import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchPayments, fetchAdjustmentNotes, fetchCustomerOverview, } from '../../../actions/dashboard/paymentsNew/actions';
import PaymentListingComponent from './PaymentListingComponent';
const PaymentComponent = (props) => {
    const { accesstoken, 
    // customers,
    // allBranches,
    activeTab, activeSubTab, startDate, endDate, page, size, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, } = props;
    const [advances, setAdvances] = useState([]);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [totalElements, setTotalElements] = useState(0);
    const fetchData = () => {
        if ((activeTab === 'PAYMENTS' && activeSubTab === 'UNSETTLED') ||
            (activeTab === 'PAYMENTS' && activeSubTab === 'SETTLED') ||
            (activeTab === 'PAYMENTS' && activeSubTab === 'ALL_PAYMENTS')) {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
                page: page - 1,
                size,
            };
            setIsInitialLoad(true);
            if (activeSubTab === 'UNSETTLED') {
                params['allAmountApplied'] = false;
            }
            if (activeSubTab === 'SETTLED') {
                params['allAmountApplied'] = true;
            }
            props
                .fetchPayments(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                const totalElements = get(res, 'payload.data.totalElements', 0);
                setTotalElements(totalElements);
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
        if ((activeTab === 'ADJUSTMENT_NOTES' && activeSubTab === 'CREDIT_NOTE') ||
            (activeTab === 'ADJUSTMENT_NOTES' && activeSubTab === 'DEBIT_NOTE')) {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
                creditNote: activeSubTab === 'CREDIT_NOTE' ? true : false,
                page: page - 1,
                size,
            };
            setIsInitialLoad(true);
            props
                .fetchAdjustmentNotes(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                const totalElements = get(res, 'payload.data.totalElements', 0);
                setTotalElements(totalElements);
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
        if (activeTab === 'CUSTOMER_OVERVIEW') {
            const params = {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
                branchId,
                paymentNature,
                adviceNumber,
                creditAccountId,
                voucherNumber,
                page: page - 1,
                size,
            };
            setIsInitialLoad(true);
            props
                .fetchCustomerOverview(accesstoken, params)
                .then((res) => {
                const data = get(res, 'payload.data.content');
                const totalElements = get(res, 'payload.data.totalElements', 0);
                setTotalElements(totalElements);
                setAdvances(data);
                setIsInitialLoad(false);
            })
                .catch(() => {
                setIsInitialLoad(false);
            });
        }
    };
    useEffect(() => {
        fetchData();
    }, [
        startDate.valueOf(),
        endDate.valueOf(),
        branchId,
        paymentNature,
        adviceNumber,
        creditAccountId,
        voucherNumber,
        activeTab,
        activeSubTab,
        page,
        size,
    ]);
    return (_jsx(_Fragment, { children: _jsx("div", { children: _jsx(PaymentListingComponent, { onPageChange: props.onPageChange, page: page, size: size, data: advances, fetchData: fetchData, activeTab: activeTab, activeSubTab: activeSubTab, totalElements: totalElements, isInitialLoad: isInitialLoad }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPayments,
        fetchBranches,
        fetchAdjustmentNotes,
        fetchCustomerOverview,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
