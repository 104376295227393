import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { FxCol } from 'sharedV2/index';
import { connect } from 'react-redux';
import DtcListing from 'components/dashboard/dtcV2/DtcListing';
import { getFinalDtcsData, isSpecificDtcs } from 'utils/dtc_utils';
import { getEndDate } from 'utils/date_utils';
import { getMergedListWithVehicles, handleError } from 'constant';
import { useFxAlert } from 'components/dashboard/customHooks';
import { FLEETX_ACCOUNTS } from 'utils/account_utils';
import { fetchDTCs } from 'actions/dashboard/dtc_action';
const DTCsList = (props) => {
    const { vehicleId, accesstoken, startDate, endDate } = props;
    const [dtcList, setDtcList] = useState([]);
    const isVaruna = get(props.loggedInUser, 'accountId', 0) === FLEETX_ACCOUNTS.VARUNA;
    const showAlert = useFxAlert();
    useEffect(() => {
        if (vehicleId) {
            fetchDtcs();
        }
        else {
            setDtcList([]);
        }
    }, [vehicleId, startDate, endDate]);
    const fetchDtcs = () => {
        const params = {
            from: startDate.valueOf(),
            to: getEndDate(endDate).valueOf(),
            vehicleId,
        };
        try {
            props.fetchDTCs(accesstoken, params).then((result) => {
                if (!result.error) {
                    let updatedDtcList = getFinalDtcsData(result.payload.data.dtcs);
                    if (isVaruna) {
                        updatedDtcList = result.payload.data.dtcs.filter((dtc) => isSpecificDtcs(dtc));
                    }
                    setDtcList(updatedDtcList);
                }
                else {
                    throw new Error(handleError(result.payload.response));
                }
            });
        }
        catch (err) {
            setDtcList([]);
            showAlert(handleError(err));
        }
    };
    return (_jsx(FxCol, { md: 12, xs: 24, children: _jsx(DtcListing, { data: getMergedListWithVehicles(dtcList, props.vehicleListMiniAsMap), onlyColumns: ['code', 'name', 'timeStamp'], startDate: startDate, endDate: endDate, vehicleId: vehicleId, isUpdateDTCAllowed: false, isDtcsLoading: false, selectedCriticality: { keys: ['Critical'] }, emptyText: 'No Critical DTCs found' }) }));
};
const mapStateToProps = (state) => {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        vehicleListMiniAsMap: get(state, 'vehicles.vehicleListMiniAsMap', []),
        loggedInUser: state.settings.loggedInUser,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchDTCs,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DTCsList);
