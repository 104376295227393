import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { COLORS } from 'components/dashboard/overview/MyAnalysis/overview_dashboard_helper';
import { memo } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart as LineChartGraph, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { randomColorGenerator } from 'utils/map/map_utils';
import { map, round } from 'lodash';
import { abbreviateAmount } from 'utils/number_utils';
const LineChart = (props) => {
    const { xAxisKey, yAxisLabel, dataConfig, legendLabels, hideLegend, xAxisLabel, referenceLines } = props;
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (_jsx("g", { transform: `translate(${x},${y})`, children: _jsx("text", { x: 0, y: 0, dy: 10, textAnchor: "end", fill: "#666", children: payload.value }) }));
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0];
            return (_jsxs("div", { style: {
                    padding: 12,
                    background: 'white',
                    border: '1px solid #ccc',
                }, children: [_jsx("p", { style: {
                            padding: 0,
                            margin: 0,
                            textAlign: 'center',
                        }, children: `${legendLabels[data.dataKey] || data.dataKey}: ${data.value}` }), _jsxs("p", { style: {
                            padding: 0,
                            margin: 0,
                            textAlign: 'center',
                        }, children: [xAxisLabel ? `${xAxisLabel}:` : '', " ", `${data.payload?.[xAxisKey || ''] || ''}`] })] }));
        }
        return null;
    };
    const formatter = (value, name, _props) => {
        if (!props.noCurrencySymbol && props.loggedInUser) {
            value = abbreviateAmount(value || 0, props.loggedInUser, 2);
        }
        else if (props.unitObj) {
            value = round(value, 2) + ' ' + (props.unitObj[name] || '');
        }
        else {
            value = round(value, 2);
        }
        return [value, legendLabels?.[name] || name, _props];
    };
    const formatYAxis = (value) => {
        const v = value > 1000 ? round(value / 1000, 2) + 'K' : value;
        return props.yAxisPrefix ? props.yAxisPrefix + ' ' + v : v;
    };
    const formatRightYAxis = (value) => {
        const v = value > 1000 ? round(value / 1000, 2) + 'K' : value;
        return props.rightYAxisConfig?.yAxisPrefix ? v + props.rightYAxisConfig?.yAxisPrefix : v;
    };
    const chartMargin = props.chartMargin || {};
    return (_jsx(ResponsiveContainer, { width: "100%", height: props.height, children: !props.data.length ? (_jsx("div", { className: "d-flex justify-content-center align-items-center", style: { height: '100%' }, children: "No data found" })) : (_jsxs(LineChartGraph, { width: 500, height: props.height || 300, data: props.data || [], margin: {
                top: 10,
                right: 20,
                left: 5,
                bottom: 10,
                ...chartMargin,
            }, children: [!props.hideGrid && _jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: xAxisKey, tick: { fontSize: props.xAxisFont || 10 }, interval: props.interval || 0, angle: props.xAxisTickAngle, dy: props.dyX, children: xAxisLabel && (_jsx(Label, { position: "insideBottom", style: { textAnchor: 'middle', fontSize: 12, fontWeight: '600' }, value: xAxisLabel, dy: props.labelDy })) }), referenceLines?.length &&
                    map(referenceLines, (v) => {
                        return (_jsx(ReferenceLine, { strokeDasharray: "3 3", y: v, yAxisId: 'left', 
                            // label={{ position: 'right', value: 'Max Temp', dy: -8, dx: -100 }}
                            stroke: "#000000" }));
                    }), _jsx(YAxis, { ticks: props.ticks, orientation: "left", yAxisId: 'left', 
                    // angle={props.yAxisTickAngle}
                    tickFormatter: formatYAxis, tick: { fontSize: '12px' }, children: yAxisLabel ? (_jsx(Label, { angle: -90, position: "insideLeft", style: { textAnchor: 'middle', fontSize: 12 }, value: yAxisLabel, content: props.yAxisLabelContent })) : props.yAxisLabelContent ? (_jsx(Label, { angle: -90, position: "insideLeft", style: { textAnchor: 'middle', fontSize: 12 }, children: props.yAxisLabelContent })) : null }), props.rightYAxisConfig && (_jsx(YAxis, { orientation: "right", 
                    // angle={props.yAxisTickAngle}
                    tickFormatter: formatRightYAxis, yAxisId: 'right', tick: { fontSize: '12px' }, dataKey: props.rightYAxisConfig.datakey, children: _jsx(Label, { angle: -90, position: "insideRight", style: { textAnchor: 'middle', fontSize: 12 }, value: props.rightYAxisConfig.axisLabel }) })), !props.customToolTip && _jsx(Tooltip, { cursor: false, formatter: formatter }), props.customToolTip && _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), !hideLegend && _jsx(Legend, { formatter: (value) => (legendLabels ? legendLabels[value] : value) }), dataConfig.map((key, index) => {
                    return (_jsx(Line, { dot: props.dot, strokeWidth: props.strokeWidth, dataKey: key, yAxisId: index == 0 ? 'left' : props.rightYAxisConfig ? 'right' : 'left', stroke: props.color || COLORS[index] || randomColorGenerator() }, index));
                })] })) }));
};
export default memo(LineChart);
