import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { FxTypography } from 'sharedV2';
import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from 'styled-components';
const SectionHeader = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: ${(props) => (props.isOpen ? '16px' : '0')};
    transition: background-color 0.3s;
    cursor: ${(props) => (props.isOpen ? 'default' : 'pointer')};

    &:hover {
        background-color: ${(props) => (props.isOpen ? 'unset' : 'rgba(0, 0, 0, 0.06)')};
    }
`;
const SectionContent = styled.div `
    padding: 16px 0;
`;
const BillSectionCollapsible = (props) => {
    const { title, children, isEdit, isExpanded = false, onToggle, forceExpanded = false } = props;
    const [isOpen, setIsOpen] = useState(isExpanded || forceExpanded);
    useEffect(() => {
        if (forceExpanded) {
            setIsOpen(true);
        }
    }, [forceExpanded]);
    const handleToggle = () => {
        if (!forceExpanded) {
            setIsOpen(!isOpen);
            onToggle?.();
        }
    };
    return (_jsxs("div", { children: [_jsxs(SectionHeader, { isOpen: isOpen, onClick: handleToggle, children: [_jsx(FxTypography.Title, { level: 5, style: { margin: 0 }, children: title }), _jsx(Button, { type: "text", icon: isOpen ? _jsx(UpOutlined, {}) : _jsx(DownOutlined, {}), onClick: handleToggle, disabled: forceExpanded, children: isOpen ? 'Collapse' : 'Expand' })] }), isOpen && _jsx(SectionContent, { children: children })] }));
};
export default BillSectionCollapsible;
