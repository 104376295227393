import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mappedBranches, mappedVehicleListMiniOptions, mappedObjectToOptions } from '../../utils/mapping_utils';
import { mappedRoutes } from '../../../utils/form_utils';
import { ADDRESS_TYPES } from '../../../utils/consignmentsUtils';
import { Input, Label } from 'reactstrap';
import { debounce } from 'lodash';
import FxInput from 'sharedV2/FxInput';
import FxSelect from 'sharedV2/FxSelect';
const space = 'mb-3';
const ChallanFiltersComponent = (props) => {
    const { handleFilterChange, branches, branchId, type, routeId, vehicleId, routeList, vehicleList, loggedInUser, refNo, consignmentNumber, } = props;
    const setTextFilter = debounce((key, term) => {
        handleFilterChange(key, term);
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx(FxSelect, { className: `${space}`, placeholder: "Arrival Office", value: branchId, options: mappedBranches(branches), onChange: (value) => {
                        handleFilterChange('branchId', value);
                    } }), _jsx(FxSelect, { className: `${space}`, placeholder: "Arrival Type", value: type, options: mappedObjectToOptions(ADDRESS_TYPES), onChange: (value) => {
                        handleFilterChange('type', value);
                    } }), _jsx(FxSelect, { className: `${space}`, placeholder: "Route", value: routeId, options: mappedRoutes(routeList), onChange: (value) => {
                        handleFilterChange('routeId', value);
                    } }), _jsx(FxSelect, { className: `${space}`, placeholder: "Vehicle No.", value: vehicleId, options: mappedVehicleListMiniOptions(vehicleList, loggedInUser, true), onChange: (value) => {
                        handleFilterChange('vehicleId', value);
                    } }), _jsx(FxInput, { "data-gacategory": "Arrival Form", "data-gaaction": "Click", "data-galabel": "CN Number", type: "text", name: "consignmentNumber", defaultValue: consignmentNumber, placeholder: `CN Number`, onChange: handleOnTextFilterChange, "data-datafield": 'consignmentNumber', className: `${space} form-control rounded Select-control grey-placeholder` }), _jsx(FxInput, { "data-gacategory": "Arrival Form", "data-gaaction": "Click", "data-galabel": "Hire Slip Number", type: "text", name: "refNo", defaultValue: refNo, placeholder: `Hire Slip Number`, onChange: handleOnTextFilterChange, "data-datafield": 'refNo', className: `${space} form-control rounded Select-control grey-placeholder` }), _jsxs("div", { className: `mt-2`, children: [_jsxs(Label, { className: "switch switch-3d switch-success mr-2", children: [_jsx(Input, { type: "checkbox", className: "switch-input", onChange: (e) => {
                                        handleFilterChange('includeDataLoadingRange', e.target.checked);
                                    } }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }), _jsx("span", { className: "", children: "Include data in Loading range " })] }), _jsxs("div", { className: `mt-2`, children: [_jsxs(Label, { className: "switch switch-3d switch-success mr-2", children: [_jsx(Input, { type: "checkbox", className: "switch-input", onChange: (e) => {
                                        handleFilterChange('includeDataReachRange', e.target.checked);
                                    } }), _jsx("span", { className: "switch-label" }), _jsx("span", { className: "switch-handle" })] }), _jsx("span", { className: "", children: "Include data in Reach range " })] })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ChallanFiltersComponent);
