import { last, round, get, filter } from 'lodash';
import { FxInfoCircleTwoTone } from 'sharedV2/FxIcons';
import FxTooltip from 'sharedV2/FxTooltip';
import { getFuelCostByVolume, getFuelCostPerKm, getFuelUnitsForAccount } from '../../../utils/fuel_utils';
import { getTimeDiff, getTimeDiffHours, HOURS_24 } from '../../../utils/date_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { showGearPredictionDetailsForAccount, isShriBankeyBihariAccount, isAccountHUL } from 'utils/account_utils';
import { showConstructionMachineryData } from 'utils/accountUtils/vehicles';

const nightDriving = () => {
    // heading: '',
    const data = [
        {
            label: 'Start/End Location',
            key: 'includeNightDrivingAddresses',
        },
        {
            label: 'Running Time',
            key: 'includeNightDrivingRunningTime',
        },
        {
            label: 'Idling Time',
            key: 'includeNightDrivingIdlingTime',
        },
        {
            label: 'Stoppage Time',
            key: 'includeNightDrivingStoppageTime',
        },
    ];

    if (isAccountHUL()) {
        data.push({
            label: 'Include Hourly Running',
            key: 'includeNightDrivingDurationWise',
        });
    }
    return { data };
};

let initialVehicleReportParams = [
    {
        label: 'Vehicle Details',
        key: 'vehicleDetails',
        isSelected: true,
    },
    {
        label: 'Vehicle Device',
        key: 'vehicleDevice',
        isSelected: false,
    },
    {
        label: 'Vehicle Custom Details',
        key: 'vehicleCustomDetails',
        isSelected: false,
    },
    {
        label: 'Specifications',
        key: 'specifications',
        isSelected: false,
    },
    {
        label: 'Engine and Transmission',
        key: 'engineAndTransmission',
        isSelected: false,
    },
];
let initialVehiclePnLReportParams = [
    {
        label: 'Calc. Freight from Job',
        key: 'jobFreight',
        isSelected: true,
    },
];

const defaultIncludes = {
    includeVehicleDetails: false,
    includeDrivingDetails: false,
    includeDrivingBehaviourDetails: false,
    includeFuelDetails: false,
    includeCostDetails: false,
    includeSafetyDetails: false,
    includeSensorDetail: false,
    includeRunningDetails: false,
    includeDriverName: true,
    includeDriverNumber: true,
    includeDriverCode: false,
    includeVehicleType: false,
    includeScore: false,
    includeMileage: false,
    includeMileagePerHour: false,
    includeFuel: false,
    includeRunningFuel: false,
    includeWastedFuel: false,
    includeIdleDuration: false,
    includeHardAcc: false,
    includeHardDecc: false,
    includeDistance: false,
    includeRunningTime: false,
    includeStoppageTime: false,
    includeUtilization: false,
    includeJobUtilization: false,
    includeUptime: false,
    includeAlarm: false,
    includeEngineDuration: false,
    includeSensorDuration: false,
    includeDtc: false,
    includeLocation: false,
    includeMileageCost: false,
    includeFuelCost: false,
    includeAvgDistance: false,
    includeWastedFuelCost: false,
    includeOverSpeedDuration: false,
    includeAverageSpeed: false,
    includeInitialFuelLevel: false,
    includeFinalFuelLevel: false,
    includeFuelType: false,
    runnningKmLimit: null,
    includeStoppages: false,
    includeNightDrivingRunningTime: false,
    includeNightDrivingAddresses: false,
    includeNightDrivingIdlingTime: false,
    includeNightDrivingStoppageTime: false,
    includeNightDrivingDurationWise: false,
    nightDrivingStartTime: '20:00:00',
    nightDrivingEndTime: '05:00:00',
    includeTags: false,
    includeVehicleCreatedBy: false,
    includeAvgDailyOperatingTime: false,
    includeGearPickupFromStandstill: false,
    includeIdealPeakTorqueRPMRange: false,
    includeGearSpecificEngineSpeedDistribution: false,
    includeFatigueDriving: false,
    includeGPSDisconnect: false,
    includeOverSpeed: false,
    includeMileagePerHourIdle: false,
    includeSecondaryEngineFuel: false,
    includeSecondaryEngineRunningTime: false,
    includeMaxDriving: false,
    includeNightDriving: false,
};

const defaultIncludesMangalamCement = {
    ...defaultIncludes,
    includeDrivingBehaviourDetails: true,
    includeFuelDetails: true,
    includeCostDetails: true,
    includeStoppageTime: true,
    includeRunningDetails: true,
    includeMileage: true,
    includeMileagePerHour: true,
    includeWastedFuel: true,
    includeRunningFuel: true,
    includeIdleDuration: true,
    includeDistance: true,
    includeRunningTime: true,
    includeLocation: true,
    includeFuel: true,
};

const reportKeys = (loggedInUser) => {
    const accountId = get(loggedInUser, 'accountId');
    const fuelUnits = getFuelUnitsForAccount(accountId);
    return {
        includeDriverName: null,
        includeDriverNumber: null,
        includeDriverCode: null,
        includeVehicleType: null,
        includeDayWiseStatus: {
            key: 'dayWiseStatus',
            name: 'Day Wise Status',
            allowedWithOther: true,
        },
        includeScore: {
            key: 'score',
            unit: '',
            name: 'Driving Score',
            convertFunc: null,
            sum: false,
            average: true,
        },
        includeInitialFuelLevel: {
            key: 'initialFuel',
            name: 'Initial fuel',
            unit: 'L',
        },
        includeFinalFuelLevel: {
            key: 'finalFuel',
            name: 'Final fuel',
            unit: 'L',
        },
        includeMileage: {
            key: 'mileage',
            unit: get(fuelUnits, 'OTHERS.unit'),
            name: 'Mileage',
            convertFunc: null,
            sum: false,
            average: true,
        },
        includeFuel: {
            key: 'totalFuelConsumption',
            unit: 'L',
            name: 'Fuel',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeSecondaryEngineFuel: {
            key: 'totalFuelConsumptionConstructionMachinery',
            unit: 'L',
            name: 'Fuel',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeWastedFuel: {
            key: 'wastedFuel',
            unit: 'L',
            name: 'Wasted Fuel',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeHardAcc: {
            key: 'hardAcc',
            unit: '',
            name: 'Hard Acceleration Count',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeHardDecc: {
            key: 'hardDecc',
            unit: '',
            name: 'Hard Brak Count',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeDistance: {
            key: 'totalOdometer',
            unit: 'km',
            name: 'Distance',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeRunningTime: {
            key: 'durationEngineOn',
            unit: '',
            name: 'Running Time',
            convertFunc: (t) => getTimeDiffHours(t, false, true, true),
            sum: false,
            average: true,
        },
        includeSecondaryEngineRunningTime: {
            key: 'durationConstructionMachineryOn',
            unit: '',
            name: 'Secondary Engine Running Time',
            convertFunc: (t) => getTimeDiffHours(t, false, true, true),
            sum: false,
            average: true,
        },
        includeStoppageTime: {
            key: 'durationEngineOn',
            keyFunc: (t) => HOURS_24 - t,
            unit: '',
            name: 'Stoppage Time',
            convertFunc: (t) => getTimeDiffHours(t, false, true, true),
            sum: false,
            average: true,
        },
        includeUtilization: {
            key: 'utilization',
            unit: '%',
            name: 'Utilization',
            convertFunc: null,
            sum: false,
            average: true,
        },
        includeJobUtilization: {
            key: 'jobUtilization',
            unit: '%',
            name: 'Utilization By Jobs',
            convertFunc: null,
            sum: false,
            average: true,
        },
        includeUptime: {
            key: 'uptime',
            unit: '%',
            name: 'Uptime',
            convertFunc: null,
            sum: false,
            average: true,
        },
        includeAlarm: {
            key: 'alarms',
            unit: '',
            name: 'Alarms',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeDtc: {
            key: 'dtcs',
            unit: '',
            name: 'DTCs',
            convertFunc: null,
            sum: true,
            average: false,
        },
        includeMileageCost: {
            key: 'mileage',
            unit: `${getCurrencyFromCountry(loggedInUser)}/km`,
            name: 'Cost Per KM',
            convertFunc: (m, ft) => getFuelCostPerKm(m, ft, loggedInUser),
        },
        includeFuelCost: {
            key: 'totalFuelConsumption',
            unit: `${getCurrencyFromCountry(loggedInUser)}`,
            name: 'Fuel Cost',
            convertFunc: (tfc, ft) => getFuelCostByVolume(tfc, ft, null, loggedInUser),
        },
        includeWastedFuelCost: {
            key: 'wastedFuel',
            unit: `${getCurrencyFromCountry(loggedInUser)}`,
            name: 'Wasted Fuel Cost',
            convertFunc: (wf, ft) => getFuelCostByVolume(wf, ft, null, loggedInUser),
        },
        includeOverSpeedDuration: {
            key: 'overSpeedDuration',
            unit: '',
            name: 'Overspeed Duration',
            convertFunc: (t) => getTimeDiff(t, false, true),
            sum: false,
            average: true,
        },
        includeIdleDuration: {
            key: 'idleDuration',
            unit: '',
            name: 'Idle Duration',
            convertFunc: (t) => getTimeDiff(t, false, true),
            sum: false,
            average: true,
        },
        includeAverageSpeed: {
            key: 'speed',
            unit: 'km/h',
            name: 'Average Speed',
            convertFunc: (s) => round(s),
            sum: false,
            average: true,
        },
        includeStoppages: {
            key: 'trips',
            unit: '/h',
            name: 'Stoppages',
            convertFunc: (t) => (t > 0 ? t - 1 : 0),
            sum: true,
            average: false,
        },
        includeFuelType: {
            key: 'fuelType',
            unit: '',
            name: 'Fuel Type',
            convertFunc: null,
        },
        includeFatigueDriving: {
            key: 'fatigueDriving',
            name: 'Fatigue Driving',
            unit: '',
            convertFunc: null,
        },
        includeGPSDisconnect: {
            key: 'GPSDisconnect',
            name: 'GPS Disconnect',
            unit: '',
            convertFunc: null,
        },
        includeMaxSpeed: {
            key: 'includeMaxSpeed',
            name: 'Include Max Speed',
            allowedWithOther: false,
        },
    };
};

const createVehicleReportConfig = (
    dayWiseReport,
    isMultiVehicleDayWise,
    singleVehicleDayWise,
    isJob,
    showUptime = false,
    showFuelDetails = false
) => {
    const vehicleReportConfig = [
        {
            heading: 'Running Details',
            toggleKey: 'includeRunningDetails',
            data: [
                {
                    label: 'Distance',
                    key: 'includeDistance',
                },
                ...(isJob && !dayWiseReport
                    ? [
                          {
                              label: 'On Job Kms',
                              key: 'includeOnJobKms',
                          },
                          {
                              label: 'Not On Job Kms',
                              key: 'includeNotOnJobKms',
                          },
                      ]
                    : []),
                ...(dayWiseReport
                    ? [
                          {
                              label: 'Day Wise Status',
                              key: 'includeDayWiseStatus',
                          },
                          {
                              label: 'Custom Fields',
                              key: 'includeCustomFields',
                          },
                      ]
                    : []),
                {
                    label: 'Running Time',
                    key: 'includeRunningTime',
                },
                {
                    label: 'Stoppage Time',
                    key: 'includeStoppageTime',
                },
                {
                    label: 'Average Speed',
                    key: 'includeAverageSpeed',
                },
                {
                    label: 'Stoppages',
                    key: 'includeStoppages',
                },
                showFuelDetails
                    ? {
                          label: 'Mileage',
                          key: 'includeMileage',
                      }
                    : null,
                showFuelDetails
                    ? {
                          label: 'Fuel Consumption',
                          key: 'includeFuel',
                      }
                    : null,
                showFuelDetails
                    ? {
                          label: 'Initial Fuel',
                          key: 'includeInitialFuelLevel',
                      }
                    : null,
                showFuelDetails
                    ? {
                          label: 'Final Fuel',
                          key: 'includeFinalFuelLevel',
                      }
                    : null,
                {
                    label: 'Utilization',
                    key: isJob ? 'includeJobUtilization' : 'includeUtilization',
                },
                showUptime
                    ? {
                          label: 'Uptime',
                          key: 'includeUptime',
                      }
                    : null,
                {
                    label: 'Average KMs',
                    key: 'includeAvgDistance',
                },

                !isMultiVehicleDayWise
                    ? {
                          label: 'Start & End Location',
                          key: 'includeLocation',
                      }
                    : null,
                {
                    label: 'Avg. Daily Operating Time',
                    key: 'includeAvgDailyOperatingTime',
                },
                {
                    label: 'Max Speed',
                    key: 'includeMaxSpeed',
                },
                showConstructionMachineryData()
                    ? {
                          label: 'Secondary Engine Running Time',
                          key: 'includeSecondaryEngineRunningTime',
                      }
                    : null,
            ],
        },
        {
            heading: 'Fuel Details',
            toggleKey: 'includeFuelDetails',
            data: [
                {
                    label: 'Fuel',
                    key: 'includeFuel',
                },
                {
                    label: 'Wasted Fuel',
                    key: 'includeWastedFuel',
                },
                {
                    label: 'Fuel (running)',
                    key: 'includeRunningFuel',
                },
                showConstructionMachineryData()
                    ? {
                          label: 'Secondary Engine Fuel',
                          key: 'includeSecondaryEngineFuel',
                      }
                    : null,
            ],
        },
        {
            heading: 'Cost Details',
            toggleKey: 'includeCostDetails',
            hidesInDayWise: true,
            data: [
                {
                    label: 'Distance',
                    key: 'includeDistance',
                },
                {
                    label: 'Mileage',
                    key: 'includeMileage',
                },
                {
                    label: 'Mileage (hr)',
                    key: 'includeMileagePerHour',
                },
                {
                    label: 'Mileage Cost',
                    key: 'includeMileageCost',
                },
                {
                    label: 'Fuel Cost',
                    key: 'includeFuelCost',
                },
                {
                    label: 'Wasted Fuel Cost',
                    key: 'includeWastedFuelCost',
                },
                isShriBankeyBihariAccount()
                    ? {
                          label: 'Mileage (hr) Idle',
                          key: 'includeMileagePerHourIdle',
                      }
                    : null,

                showConstructionMachineryData()
                    ? {
                          label: 'Secondary Engine Mileage (hr)',
                          key: 'includeSecondaryEngineMileagePrHour',
                      }
                    : null,
            ],
        },
        {
            heading: 'Vehicle Details',
            toggleKey: 'includeVehicleDetails',
            data: [
                !isMultiVehicleDayWise
                    ? {
                          label: 'Fuel Type',
                          key: 'includeFuelType',
                      }
                    : null,
                !isMultiVehicleDayWise && !singleVehicleDayWise
                    ? {
                          label: 'Vehicle Type',
                          key: 'includeVehicleType',
                      }
                    : null,
                !isMultiVehicleDayWise && !singleVehicleDayWise
                    ? {
                          label: 'Tags',
                          key: 'includeTags',
                      }
                    : null,
                !isMultiVehicleDayWise && !singleVehicleDayWise
                    ? {
                          label: 'Vehicle Created By',
                          key: 'includeVehicleCreatedBy',
                      }
                    : null,
                {
                    label: 'Custom Fields',
                    key: 'includeCustomFields',
                },
            ],
        },
        {
            heading: 'Driver Details',
            toggleKey: 'includeDrivingDetails',
            hidesInDayWise: true,
            data: [
                {
                    label: 'Driver Name',
                    key: 'includeDriverName',
                },
                {
                    label: 'Driver Number',
                    key: 'includeDriverNumber',
                },
                {
                    label: 'Driver Code',
                    key: 'includeDriverCode',
                },
            ],
        },
        {
            heading: 'Driving Behaviour',
            toggleKey: 'includeDrivingBehaviourDetails',
            hidesInDayWise: true,
            data: [
                {
                    label: 'Driving Score',
                    key: 'includeScore',
                },
                {
                    label: 'Idle Duration',
                    key: 'includeIdleDuration',
                },
                {
                    label: 'Hard Acceleration Count',
                    key: 'includeHardAcc',
                },
                {
                    label: 'Hard Brake Count',
                    key: 'includeHardDecc',
                },
                {
                    label: 'Fatigue Driving',
                    key: 'includeFatigueDriving',
                },
                {
                    label: 'GPS Disconnect',
                    key: 'includeGPSDisconnect',
                },
                {
                    label: 'Overspeeding Count',
                    key: 'includeOverSpeed',
                },
                {
                    label: 'Night Driving Count',
                    key: 'includeNightDriving',
                },
                {
                    label: 'Max Driving Count',
                    key: 'includeMaxDriving',
                },
            ],
        },
        {
            heading: 'Safety Details',
            toggleKey: 'includeSafetyDetails',
            hidesInDayWise: true,
            data: [
                {
                    label: 'Overspeed Duration',
                    key: 'includeOverSpeedDuration',
                },
                {
                    label: 'Alarms',
                    key: 'includeAlarm',
                },
                {
                    label: 'DTCs',
                    key: 'includeDtc',
                },
            ],
        },
        {
            heading: 'Sensor Detail',
            toggleKey: 'includeSensorDetail',
            hidesInDayWise: true,
            data: [
                {
                    label: 'Ignition ON duration',
                    key: 'includeEngineDuration',
                },
                {
                    label: 'Engine ON Duration',
                    key: 'includeSensorDuration',
                },
            ],
        },
        showGearPredictionDetailsForAccount()
            ? {
                  heading: window.t('gearPredictionDetails'),
                  toggleKey: 'includeGearPredictionDetails',
                  data: [
                      {
                          label: window.t('gearPickupFromStandstill'),
                          key: 'includeGearPickupFromStandstill',
                      },
                      {
                          label: window.t('idealPeakTorqueRPMRange'),
                          key: 'includeIdealPeakTorqueRPMRange',
                      },
                      {
                          label: window.t('gearSpecificEngineSpeedDistribution'),
                          key: 'includeGearSpecificEngineSpeedDistribution',
                      },
                  ],
              }
            : null,
    ];
    return filter(vehicleReportConfig, (v) => !!v);
};

const mangalamColVehicleSummaryMapping = [
    { prompt: 'Vehicle Name', order: 1, name: 1 },
    { prompt: 'Vehicle No.', order: 2, name: 2 },
    { prompt: 'Start date & time', order: 3, name: 18 },
    { prompt: 'End date & time', order: 4, name: 22 },
    { prompt: 'Total distance travelled', order: 5, name: 9 },
    { prompt: 'Mileage (ltr/hour)', order: 6, name: 'includeMileagePerHour' },
    { prompt: 'Mileage (ltr/km)', order: 7, name: 34 },
    { prompt: 'Fuel Consumption During Vehicle idle', order: 8, name: 32 },
    { prompt: 'Fuel Consumption During Vehicle running', order: 9, name: 'includeRunningFuel' },
    { prompt: 'Vehicle running hours', order: 10, name: 10 },
    { prompt: 'Vehicle Idle hours', order: 11, name: 'idleDuration' },
    { prompt: 'Stoppage Time', order: 12, name: 11 },
    { prompt: 'Total Fuel consumption', order: 13, name: 31 },
    { prompt: 'Hard Acceleration Count', order: 14, name: 'hardAccelerator' },
    { prompt: 'Hard Deceleration Count', order: 15, name: 'hardDecelerator' },
    { prompt: 'Fuel Theft Count', order: 16, name: 'fuelTheftCount' },
    { prompt: 'Fuel Theft (ltr)', order: 17, name: 'fuelTheftLtrs' },
    { prompt: 'Fuel Fill Count', order: 17, name: 'fuelFillCount' },
    { prompt: 'Fuel Fill (ltr)', order: 17, name: 'fuelFillLtrs' },
    { prompt: 'Maintenance Time(dd/hh/mm)', order: 18, name: 'maintenanceTime' },
    { prompt: 'Manual Fill Entry(Quantity)', order: 19, name: 'manualFillEntry' },
    { prompt: 'Manual Fill Count', order: 20, name: 'manualFillCount' },
];

const smartGreenColVehicleSummaryMapping = [
    { prompt: 'Day', name: 1 },
    { prompt: 'Distance (km)', name: 9 },
    { prompt: 'WORKING Time', name: 'workingTimeDuration' },
    { prompt: 'Avg. Speed', name: 15 },
    { prompt: 'NO. OF Stoppages', name: 16 },
    { prompt: 'Start Location', name: 17 },
    { prompt: 'Start Time', name: 18 },
    { prompt: 'End Location', name: 21 },
    { prompt: 'End Time', name: 22 },
];

const VEHICLE_OWNERSHIP_OPTIONS = {
    BOTH: 'BOTH',
    OWNED: 'OWNED',
    NOT_OWNED: 'NOT_OWNED',
};

const mappedVehicleOwnershipOptions = [
    {
        label: 'Both',
        value: VEHICLE_OWNERSHIP_OPTIONS.BOTH,
    },
    {
        label: 'Owned',
        value: VEHICLE_OWNERSHIP_OPTIONS.OWNED,
    },
    {
        label: 'Not Owned',
        value: VEHICLE_OWNERSHIP_OPTIONS.NOT_OWNED,
    },
];

export {
    createVehicleReportConfig,
    defaultIncludes,
    defaultIncludesMangalamCement,
    reportKeys,
    nightDriving,
    mangalamColVehicleSummaryMapping,
    smartGreenColVehicleSummaryMapping,
    initialVehicleReportParams,
    initialVehiclePnLReportParams,
    VEHICLE_OWNERSHIP_OPTIONS,
    mappedVehicleOwnershipOptions,
};
