import {
    fetchAssetMovementDetails,
    fetchMergedTrips,
    fetchMovementPoints,
    fetchRoutePointsWithType,
} from '../../actions/dashboard/trips_action';
import {
    getAddressFromAddressbookOrGeoCodedLocations,
    getGeoCodedLocationsFromBackendInBulk,
    getNearestAddressFromAddressbook,
    isInsideNearestAddress,
} from '../map/geocode_utils';
import {
    getCurrentVehicleStatus,
    getMinutePointsFromRoutes,
    getMotionPointsFromRoutes,
    MOTION_TYPE,
    VEHICLE_STATUS,
} from '../vehicle_utils';
import {
    DATE_FORMAT_HUMANIZE_DAY,
    DATE_FORMAT_TIME_ONLY,
    DATE_FORMAT_TIMESTAMP,
    getEndDate,
    getHumanizeTime,
    getMomentTime,
    getMomentTimeFromObject,
    getReportTime,
    getTimeDiff,
    getTimeDiffHours,
    HOURS_24,
} from '../date_utils';
import {
    createStoppageData,
    filterAssetMovementPoints,
    getFrequencyTitle,
    getGraphFrequency,
    getGroupName,
    getTotalOdometerTillNow,
    getTripOdo,
    getVehicleTemperature,
    numberToLocaleString,
    parseAddress,
    parseCityFromAddress,
    parseQueryParams,
    ROOT_API_URL,
} from '../../constant';
import {
    GET_FUEL_TYPE,
    getFuelData,
    getFuelName,
    getFuelString,
    getFuelUnit,
    getFuelVolume,
    processFuelData,
} from '../fuel_utils';
import {
    addDataRow,
    addHeader,
    createReportFilename,
    generateReportCustomOrderingForHeader,
    getWOverFlow,
    ORIENTATION,
    REPORT_FORMAT,
    startReportCreation,
} from '../report_utils';
import {
    ceil,
    filter,
    find,
    first,
    floor,
    forEach,
    get,
    includes,
    isArray,
    isEmpty,
    last,
    map,
    max,
    orderBy,
    round,
    set,
    slice as lodashSlice,
    split,
    toArray,
    toSafeInteger,
    toUpper,
} from 'lodash';
import { fetchComments } from '../../actions/dashboard/easy/comment_action';
import { getGoogleMapLink, pythagorasEquirectangular } from '../map/map_utils';
import { COMMENT_TYPE, getCommentText } from '../comment_utils';
import {
    getHistoryReportColMappingForAccount,
    isDriverOpenInHistoryReportAccount,
    isHistoryReportColCustomOrderingForAccount,
    isQuadTreeSearchOpenForAccount,
    isSmartGreenAccount,
    preferredTimeStampFormat,
    showOdometerReadingInHistoryReport,
    toShowCityForAccount,
} from '../account_utils';
import { sortListNew } from '../list_utils';
import { fetchHistoricalVehicleLocation } from '../../actions/dashboard/realtime_action';
import { isViewVendorsAllowedForUser } from 'components/dashboard/roles/permission_utils';
import axios from 'axios';

export const STOPPAGE_INTERVAL_OPTIONS = [
    {
        value: 0,
        label: 'All Stops',
    },
    {
        value: 1,
        label: '1 Minute',
    },
    {
        value: 10,
        label: '10 Minutes',
    },
    {
        value: 20,
        label: '20 Minutes',
    },
    {
        value: 30,
        label: '30 Minutes',
    },
    {
        value: 45,
        label: '45 Minutes',
    },
    {
        value: 60,
        label: '1 Hour',
    },
    {
        value: 120,
        label: '2 Hours',
    },
    {
        value: 180,
        label: '3 Hours',
    },
    {
        value: 240,
        label: '4 Hours',
    },
    {
        value: 360,
        label: '6 Hours',
    },
    {
        value: 720,
        label: '12 Hours',
    },
    {
        value: 1440,
        label: '24 Hours',
    },
];

export const BULK_GEOCODE_COUNT = 50;

export const smartGreenColHistoryReportMapping = [
    { prompt: 'Start Time', name: 8 },
    { prompt: 'End Time', name: 9 },
    { prompt: 'Start Location', name: 10 },
    { prompt: 'WORKING Location', name: 11 },
    { prompt: 'WORKING TIME', name: 14 },
    { prompt: 'Distance (km)', name: 12 },
    { prompt: 'Cumulative Dist.', name: 13 },
];

export async function triggerTripReportDownload(
    tripList,
    aggregations,
    groupList,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    filterConfig,
    addressBook,
    vehicleId,
    loggedInUser,
    addressBookAsMap,
    addressBookAsQT,
    transportersMap = {},
    vehicleTransporterMapping,
    scope,
    vehicleSize
) {
    if (!tripList || tripList.length <= 0) {
        return;
    }
    tripList = orderBy(tripList, ['vehicleNumber', 'eDate'], ['asc']);
    const reportName = createReportFilename(reportFormat, 'History', licensePlate, groupName, fromDate, toDate);
    let headers = [];
    const footerRow = {};
    const isQuadTreeSearchOpen = isQuadTreeSearchOpenForAccount(loggedInUser);
    const includeGroups = groupList && groupList.length > 0;
    const includeLocation = get(filterConfig, 'includeLocation', true);
    const includeDistance = get(filterConfig, 'includeDistance', true);
    const includeDuration = get(filterConfig, 'includeDuration', true);
    const includeMileage = get(filterConfig, 'includeMileage', true);
    const includeFuel = get(filterConfig, 'includeFuel', true);
    const includeFuelUnit = includeFuel && reportFormat === REPORT_FORMAT.CSV;
    //const includeAlarm = get(filterConfig, 'includeAlarm', true);
    const includeTripId = false;
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);
    const includeACData = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeACData', false);
    const includeOdometerReading = reportFormat === REPORT_FORMAT.CSV && showOdometerReadingInHistoryReport();
    const isElectric = get(tripList[0], 'fuelType') === 'Electric';
    const includeVehicleSize = get(filterConfig, 'includeVehicleSize', false);

    // using vehicleId and not licensePlate,
    // License plate returns null on reload, even though the vehicle filter is set.
    // reason was in redux state -> vehicleList becomes empty on reload
    const showCumulativeDurationCol = vehicleId && includeDistance ? true : false;
    let cumulativeDistance = 0;
    let cDistance = 0;

    const wDistance = 80,
        wDuration = 80,
        wCumulativeDuration = 130,
        wMileage = 80,
        wFuel = 80,
        wAlarms = 70;
    const wLocation = 140;
    const wLatLon = 70,
        wMapLink = 140;
    let wLocationOverflow = 0;
    wLocationOverflow += getWOverFlow(includeDistance, wDistance);
    wLocationOverflow += getWOverFlow(includeDuration, wDuration);
    wLocationOverflow += getWOverFlow(includeMileage, wMileage);
    wLocationOverflow += getWOverFlow(includeFuel, wFuel);
    wLocationOverflow += getWOverFlow(showCumulativeDurationCol, wCumulativeDuration);
    // wLocationOverflow += getWOverFlow(includeAlarm, wAlarms);
    wLocationOverflow /= 2;

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', 130, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
        noOfCols += addHeader(includeGroups, headers, 3, 'Group', 150, footerRow);
        noOfCols += addHeader(true, headers, 4, 'Year', 150, footerRow);
        noOfCols += addHeader(true, headers, 5, 'Make', 150, footerRow);
        noOfCols += addHeader(true, headers, 6, 'Model', 150, footerRow);
        noOfCols += addHeader(isDriverOpenInHistoryReportAccount(), headers, 7, 'Driver', 150, footerRow);
    }
    noOfCols += addHeader(true, headers, 8, 'Start Time', 95, footerRow);
    noOfCols += addHeader(true, headers, 9, 'End Time', 95, footerRow);
    noOfCols += addHeader(includeLocation, headers, 10, 'Start Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeOdometerReading, headers, 'odoStart', 'Start Odometer', wDuration, footerRow);
    noOfCols += addHeader(isElectric, headers, 'startSoc', `Start Soc`, wMapLink, footerRow);
    noOfCols += addHeader(includeLocation, headers, 11, 'End Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeOdometerReading, headers, 'odoEnd', 'End Odometer', wDuration, footerRow);
    noOfCols += addHeader(isElectric, headers, 'endSoc', `End Soc`, wMapLink, footerRow);

    noOfCols += addHeader(
        includeDistance,
        headers,
        12,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );
    noOfCols += addHeader(
        showCumulativeDurationCol,
        headers,
        13,
        'Cumulative Dist.',
        wCumulativeDuration + wLocationOverflow,
        footerRow
    );
    noOfCols += addHeader(includeDuration, headers, 14, 'Duration', wDuration, footerRow);
    noOfCols += addHeader(includeMileage, headers, 15, 'Mileage (km/h)', wMileage, footerRow);
    noOfCols += addHeader(!isElectric && includeFuel, headers, 16, 'Fuel (L)', wFuel, footerRow);
    noOfCols += addHeader(isElectric, headers, 'socPercent', 'SOC (%)', wFuel, footerRow);
    noOfCols += addHeader(includeFuelUnit, headers, 17, 'Fuel Type', 80, footerRow);
    //noOfCols += addHeader(includeAlarm, headers, 17, 'Alarms', wAlarms, footerRow);
    noOfCols += addHeader(includeTripId, headers, 18, 'TripID', wAlarms, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 19, 'Start Lat,Lon', wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 20, 'Start Map Link', wMapLink, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 21, 'End Lat,Lon', wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 22, 'End Map Link', wMapLink, footerRow);
    noOfCols += addHeader(includeACData, headers, 23, 'AC ON Duration', wDuration, footerRow);
    noOfCols += addHeader(includeACData, headers, 24, 'AC OFF Duration', wDuration, footerRow);
    noOfCols += addHeader(includeVehicleSize, headers, 25, 'Vehicle Size', 80, footerRow);
    noOfCols += addHeader(
        isViewVendorsAllowedForUser(scope),
        headers,
        'transporter',
        'Transporter',
        wDuration,
        footerRow
    );

    if (isHistoryReportColCustomOrderingForAccount()) {
        headers = generateReportCustomOrderingForHeader(headers, getHistoryReportColMappingForAccount());
    }

    const data = [];
    let sumDuration = 0;
    let sumDurationCabinAcOn = 0;
    let curGroupName = '';
    const preferredDateFormat = preferredTimeStampFormat();
    map(
        tripList,
        ({
            id,
            groupId,
            eDate,
            sDate,
            tOdo,
            duration,
            durationCabinAcOn,
            alarm,
            fuelType,
            tFuel,
            mileage,
            vehicleName,
            vehicleNumber,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            driverName,
            sAddress,
            eAddress,
            sLat,
            sLon,
            eLat,
            eLon,
            sOdo,
            eOdo,
            vehicleId,
            sEVSOC,
            eEVSOC,
            status,
            tEVSOC,
        }) => {
            if (!eDate) {
                return;
            }
            sumDuration += duration;
            sumDurationCabinAcOn += durationCabinAcOn || 0;
            curGroupName = getGroupName(groupId, groupList);
            const sAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
                null,
                sAddress,
                VEHICLE_STATUS.PARKED,
                sLat,
                sLon,
                addressBook,
                null,
                loggedInUser,
                addressBookAsMap,
                addressBookAsQT
            );
            const eAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
                null,
                eAddress,
                VEHICLE_STATUS.PARKED,
                eLat,
                eLon,
                addressBook,
                null,
                loggedInUser,
                addressBookAsMap,
                addressBookAsQT
            );

            let distance = getTripOdo(tOdo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF);
            cDistance = cDistance + round(tOdo, tOdo > 100 ? 0 : 1);
            cumulativeDistance = getTripOdo(
                cDistance,
                reportFormat === REPORT_FORMAT.PDF,
                reportFormat === REPORT_FORMAT.PDF
            );

            const dataRow = {};
            if (reportFormat === REPORT_FORMAT.PDF) {
                addDataRow(true, dataRow, 1, `${vehicleNumber ? vehicleNumber : ''}`);
            } else {
                addDataRow(true, dataRow, 1, `${vehicleName}`);
                addDataRow(true, dataRow, 2, `${vehicleNumber}`);
                addDataRow(includeGroups, dataRow, 3, `${curGroupName ? curGroupName : '-'}`);
                addDataRow(true, dataRow, 4, `${vehicleYear}`);
                addDataRow(true, dataRow, 5, `${vehicleMake}`);
                addDataRow(true, dataRow, 6, `${vehicleModel}`);
                addDataRow(true, dataRow, 7, `${driverName ? driverName : '-'}`);
            }
            addDataRow(true, dataRow, 8, getHumanizeTime(sDate, true, true, preferredDateFormat));
            addDataRow(true, dataRow, 9, getHumanizeTime(eDate, true, true, preferredDateFormat));
            addDataRow(includeLocation, dataRow, 10, parseAddress(sAddressProcessed));
            addDataRow(includeLocation, dataRow, 11, parseAddress(eAddressProcessed));
            addDataRow(includeDistance, dataRow, 12, distance ? distance : '');
            addDataRow(showCumulativeDurationCol, dataRow, 13, cumulativeDistance ? cumulativeDistance : '');
            addDataRow(includeDuration, dataRow, 14, getTimeDiff(duration, false, true));
            addDataRow(includeMileage, dataRow, 15, `${round(mileage, 2)} (${getFuelName(fuelType, loggedInUser)})`);
            addDataRow(
                !isElectric && includeFuel,
                dataRow,
                16,
                `${numberToLocaleString(round(tFuel, 2))} (${getFuelName(fuelType, loggedInUser)})`
            );
            addDataRow(isElectric, dataRow, 'socPercent', tEVSOC + '' || '');
            addDataRow(
                includeFuelUnit,
                dataRow,
                17,
                `${getFuelName(fuelType, loggedInUser)} (${getFuelVolume(fuelType, loggedInUser)})`
            );
            // addDataRow(includeAlarm, dataRow, 17, alarm);
            addDataRow(includeTripId, dataRow, 18, id);
            addDataRow(includeMinuteLatLon, dataRow, 19, `${sLat}, ${sLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                20,
                getGoogleMapLink(sLat, sLon, reportFormat === REPORT_FORMAT.PDF)
            );
            addDataRow(includeMinuteLatLon, dataRow, 21, `${eLat}, ${eLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                22,
                getGoogleMapLink(eLat, eLon, reportFormat === REPORT_FORMAT.PDF)
            );
            addDataRow(
                includeACData,
                dataRow,
                23,
                durationCabinAcOn > 0 ? getTimeDiff(durationCabinAcOn || 0, false, true) : 0
            );
            addDataRow(includeACData, dataRow, 24, getTimeDiff(duration - (durationCabinAcOn || 0), false, true));
            addDataRow(includeVehicleSize, dataRow, 25, get(vehicleSize, 'vehicleSize', ''));
            let transporterObj = transportersMap[vehicleTransporterMapping[vehicleId]];
            let tName = transporterObj?.name || transporterObj?.contact?.name;
            addDataRow(isViewVendorsAllowedForUser(scope), dataRow, 'transporter', tName || '');
            addDataRow(includeOdometerReading, dataRow, 'odoStart', sOdo || '');
            addDataRow(includeOdometerReading, dataRow, 'odoEnd', (status ? eOdo : sOdo) || '');
            addDataRow(isElectric, dataRow, 'startSoc', sEVSOC + '' || '');
            addDataRow(isElectric, dataRow, 'endSoc', eEVSOC + '' || '');
            data.push(dataRow);
        }
    );

    const analyticsData = getFuelData(aggregations);

    const totalRow = {};

    // addDataRow(true, totalRow, 1, `TOTAL\n${find(analyticsData, ['type', 'totalTrips']).count}`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
        addDataRow(includeGroups, totalRow, 3, `-`);
        addDataRow(true, totalRow, 4, `-`);
        addDataRow(true, totalRow, 5, `-`);
        addDataRow(true, totalRow, 6, `-`);
        addDataRow(true, totalRow, 7, `-`);
    }
    addDataRow(true, totalRow, 8, '-');
    addDataRow(true, totalRow, 9, '-');
    addDataRow(includeLocation, totalRow, 10, '-');
    addDataRow(includeLocation, totalRow, 11, '-');
    addDataRow(
        includeDistance,
        totalRow,
        12,
        getTripOdo(
            get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0),
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF
        )
    );

    addDataRow(showCumulativeDurationCol, totalRow, 13, cumulativeDistance);
    addDataRow(includeDuration, totalRow, 14, getTimeDiff(sumDuration, false, true));
    addDataRow(includeACData, totalRow, 23, getTimeDiff(sumDurationCabinAcOn, false, true));
    addDataRow(includeACData, totalRow, 24, getTimeDiff(sumDuration - sumDurationCabinAcOn, false, true));

    if (includeMileage || includeFuel) {
        const dataPetrol = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).PETROL.value);
        const dataDiesel = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).DIESEL.value);
        const dataCNG = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).CNG.value);
        let avgMileageString = '',
            fuelString = '';
        if (dataPetrol) {
            avgMileageString += `${round(dataPetrol.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).PETROL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataPetrol.consumption.count,
                GET_FUEL_TYPE(loggedInUser).PETROL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
        }
        if (dataDiesel) {
            avgMileageString += `${round(dataDiesel.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataDiesel.consumption.count,
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
        }
        if (dataCNG) {
            avgMileageString += `${round(dataCNG.mileage.count, 2)} ${
                (getFuelUnit(GET_FUEL_TYPE(loggedInUser).CNG), loggedInUser)
            }\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
            fuelString += `${getFuelString(
                dataCNG.consumption.count,
                GET_FUEL_TYPE(loggedInUser).CNG,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
        }

        if (isEmpty(avgMileageString)) {
            avgMileageString = '-';
        }
        if (isEmpty(fuelString)) {
            fuelString = '-';
        }

        addDataRow(includeMileage, totalRow, 15, avgMileageString);
        addDataRow(includeFuel, totalRow, 16, fuelString);
        addDataRow(includeFuelUnit, totalRow, 17, '');
        addDataRow(includeFuelUnit, totalRow, 19, '-');
        addDataRow(includeFuelUnit, totalRow, 20, '-');
        addDataRow(includeFuelUnit, totalRow, 21, '-');
        addDataRow(includeFuelUnit, totalRow, 22, '-');
        addDataRow(isViewVendorsAllowedForUser(scope), totalRow, 'transporter', '-');
    }

    //addDataRow(includeAlarm, totalRow, 17, find(analyticsData, ['type', 'alarm']).count + '');
    addDataRow(includeTripId, totalRow, 18, '-');
    addDataRow(includeOdometerReading, totalRow, 'odoStart', '-');
    addDataRow(includeOdometerReading, totalRow, 'odoEnd', '-');
    addDataRow(isElectric, totalRow, 'startSoc', '-');
    addDataRow(isElectric, totalRow, 'endSoc', '-');
    addDataRow(isElectric, totalRow, 'socPercent', '-');
    data.push(totalRow);
    //data.push(footerRow);

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '99CCFF' },
        // },
    };

    let reportFromDate = isSmartGreenAccount(loggedInUser) ? '' : fromDate;
    let reportToDate = isSmartGreenAccount(loggedInUser) ? '' : toDate;
    let reportLicensePlate = isSmartGreenAccount(loggedInUser)
        ? `${get(tripList, '[0].vehicleName')}(${get(tripList, '[0].vehicleNumber', '-')}) (DRIVER NAME :- ${get(
              tripList,
              '[0].driverName',
              '-'
          )})`
        : licensePlate;

    const colorConfig = {
        endSoc: 'FF00FF00',
        startSoc: 'FF00FF00',
        odoStart: 'FFFFFF00',
        odoEnd: 'FFFFFF00',
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Trip History',
        reportFromDate,
        reportToDate,
        reportLicensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        true,
        { colorConfig, summaryData: { curGroupName } }
    );
}

export async function triggerTripsStoppageReportDownload(
    allTripList,
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    addressBook,
    loggedInUser,
    isFromTripsBWSites = false,
    groupList,
    stoppageIdleData
) {
    const stoppageTimeMinutes = get(filterConfig, 'stoppageTimeMinutes', 0);
    const showOnlyAddressbookStops = get(filterConfig, 'showOnlyAddressbookStops', false);
    const preferredDateFormat = preferredTimeStampFormat();
    const stopList = createStoppageData(
        allTripList,
        stoppageTimeMinutes,
        fromDate,
        toDate,
        addressBook,
        loggedInUser,
        showOnlyAddressbookStops,
        isFromTripsBWSites
    );
    if (!vehicleId) {
        alert('Please select vehicle to download report.');
        return;
    }
    const includeComment = get(filterConfig, 'includeTimelineComment', true);
    let commentList = [];
    if (includeComment) {
        const resultComments = await fetchComments(
            accesstoken,
            COMMENT_TYPE.TRIP,
            null,
            vehicleId,
            fromDate,
            getEndDate(toDate)
        ).promise;
        commentList = get(resultComments, 'data', []);
    }
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);

    const reportName = createReportFilename(reportFormat, 'Stoppage', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};
    let stopRows = 0;

    const includeStoppageLocation = get(filterConfig, 'includeStoppageLocation', true);
    const includeStoppageLastLocation = get(filterConfig, 'includeStoppageLastLocation', true);
    const includeNearestAddress =
        !isFromTripsBWSites && addressBook && addressBook.length > 0 && includeStoppageLocation;
    const includeDistance = get(filterConfig, 'includeStoppageDistance', true);
    const includeDuration = get(filterConfig, 'includeStoppageDuration', true);
    const includeIsOnAddress = !isFromTripsBWSites && includeNearestAddress && reportFormat === REPORT_FORMAT.CSV;
    const toShowCity =
        includeStoppageLocation && reportFormat === REPORT_FORMAT.CSV && toShowCityForAccount(loggedInUser);

    const wName = 120,
        wTime = 100;
    const wDistance = 80,
        wDuration = 80;
    const wLocation = 140;
    const wNearestAddress = 140;
    const wComment = includeStoppageLocation ? 100 : 150;
    const wLatLon = 70,
        wMapLink = 140;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeDistance, wDistance);
    wOverflow += getWOverFlow(includeStoppageLastLocation, wLocation);
    wOverflow += getWOverFlow(includeStoppageLocation, wLocation);
    wOverflow += getWOverFlow(includeDuration, wDuration);
    wOverflow = floor(
        wOverflow /
            (3 +
                includeDistance +
                includeDuration +
                includeNearestAddress +
                includeStoppageLastLocation +
                includeStoppageLocation)
    );

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle Name', wName + wOverflow, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
        noOfCols += addHeader(true, headers, 'group', 'Group', 150, footerRow);
    }
    noOfCols += addHeader(true, headers, 3, 'Start Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(true, headers, 4, 'End Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(includeDuration, headers, 5, 'Duration', wDuration + wOverflow, footerRow);
    noOfCols += addHeader(includeStoppageLastLocation, headers, 6, `Last Location`, wLocation + wOverflow, footerRow);
    noOfCols += addHeader(
        includeStoppageLocation,
        headers,
        7,
        `${isFromTripsBWSites ? 'Stop' : 'Current Location'}`,
        wLocation + wOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeIsOnAddress,
        headers,
        8,
        'At Addressbook Site',
        wNearestAddress + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeNearestAddress, headers, 9, 'Nearest Address', wNearestAddress + wOverflow, footerRow);
    noOfCols += addHeader(toShowCity, headers, 10, 'City', wNearestAddress + wOverflow, footerRow);
    noOfCols += addHeader(
        includeDistance,
        headers,
        11,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );
    noOfCols += addHeader(get(stoppageIdleData, 'length'), headers, 'status', `Status`, wComment, footerRow);
    noOfCols += addHeader(includeComment, headers, 12, `Comment`, wComment, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 13, 'Stop Lat,Lon', wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 14, 'Stop Map Link', wMapLink, footerRow);

    const data = [];
    let sumStopDuration = 0,
        sumStopOdo = 0;

    let lastEAddressProcessed = '-';

    let groupName = '-';
    if (stopList && stopList.length > 0) {
        groupName = get(find(groupList, { id: stopList[0].groupId }), 'name', '-');
    }

    const isStoppageIdleData = get(stoppageIdleData, 'length');

    filter(isStoppageIdleData ? stoppageIdleData : stopList, (stop) => {
        if (isStoppageIdleData && stop.duration < stoppageTimeMinutes * 60 * 1000) {
            return;
        }

        const eAddressProcessed = stop.address;

        const tripStopComment = find(commentList, {
            eventTime: getMomentTime(stop.sDate).format(DATE_FORMAT_TIMESTAMP),
        });
        const stoppedDataRow = {};
        const nearestAddress = stop.nearestAddress;
        sumStopDuration += stop.duration;
        sumStopOdo += stop.tOdo ?? 0;
        if (reportFormat === REPORT_FORMAT.PDF) {
            addDataRow(true, stoppedDataRow, 1, `Name: ${stop.vehicleName}\nNo: ${stop.vehicleNumber}`);
        } else {
            addDataRow(true, stoppedDataRow, 1, `${stop.vehicleName}`);
            addDataRow(true, stoppedDataRow, 2, `${stop.vehicleNumber}`);
        }

        addDataRow(
            true,
            stoppedDataRow,
            3,
            stop.sDate ? getHumanizeTime(stop.sDate, true, true, preferredDateFormat) : '-'
        );
        addDataRow(true, stoppedDataRow, 4, getHumanizeTime(stop.eDate, true, true, preferredDateFormat));
        addDataRow(
            includeDuration,
            stoppedDataRow,
            5,
            stop.duration
                ? reportFormat === REPORT_FORMAT.PDF
                    ? getTimeDiff(stop.duration, false, true)
                    : getTimeDiffHours(stop.duration, false, true)
                : '-'
        );
        addDataRow(includeStoppageLastLocation, stoppedDataRow, 6, parseAddress(lastEAddressProcessed));
        addDataRow(includeStoppageLocation, stoppedDataRow, 7, parseAddress(eAddressProcessed));
        addDataRow(includeIsOnAddress, stoppedDataRow, 8, !!isInsideNearestAddress(nearestAddress) ? 'YES' : 'NO');
        addDataRow(
            includeNearestAddress,
            stoppedDataRow,
            9,
            parseAddress(nearestAddress ? nearestAddress.addressText : '')
        );
        addDataRow(
            toShowCity,
            stoppedDataRow,
            10,
            parseCityFromAddress(
                isInsideNearestAddress(nearestAddress) ? nearestAddress.fullAddress : eAddressProcessed
            )
        );
        addDataRow(
            includeDistance,
            stoppedDataRow,
            11,
            getTripOdo(stop.tOdo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
        );
        addDataRow(!!get(stoppageIdleData, 'length'), stoppedDataRow, 'status', get(stop, 'status', '-'));
        addDataRow(includeComment, stoppedDataRow, 12, getCommentText(tripStopComment, '-'));
        addDataRow(includeMinuteLatLon, stoppedDataRow, 13, `${stop.sLat}, ${stop.sLon}`);
        addDataRow(
            includeMinuteMapLink,
            stoppedDataRow,
            14,
            getGoogleMapLink(stop.sLat, stop.sLon, reportFormat === REPORT_FORMAT.PDF)
        );
        addDataRow(true, stoppedDataRow, 'group', `${groupName}`);
        data.push(stoppedDataRow);
        stopRows++;
        lastEAddressProcessed = eAddressProcessed;
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL\n${`${stopRows} Stops`}`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, '-');
    }
    addDataRow(true, totalRow, 3, '-');
    addDataRow(true, totalRow, 4, '-');
    addDataRow(includeDuration, totalRow, 5, '');
    addDataRow(includeStoppageLastLocation, totalRow, 6, '-');
    addDataRow(includeStoppageLocation, totalRow, 7, '-');
    addDataRow(includeIsOnAddress, totalRow, 8, '-');
    addDataRow(includeNearestAddress, totalRow, 9, '-');
    addDataRow(toShowCity, totalRow, 10, '-');
    addDataRow(
        includeDistance,
        totalRow,
        11,
        getTripOdo(round(sumStopOdo, 2), reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
    );
    addDataRow(!!get(stoppageIdleData, 'length'), totalRow, 'status', `-`);
    addDataRow(includeComment, totalRow, 12, '-');
    addDataRow(includeMinuteLatLon, totalRow, 13, '-');
    addDataRow(includeMinuteMapLink, totalRow, 14, '-');
    addDataRow(includeMinuteMapLink, totalRow, 'group', '-');

    data.push(totalRow);
    //data.push(footerRow);
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Stoppage Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );
}

export async function triggerTripsTimelineReportDownload(
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    addressBook,
    loggedInUser
) {
    const includeComment = get(filterConfig, 'includeTimelineComment', true);
    let commentList = [];
    if (includeComment) {
        const resultComments = await fetchComments(
            accesstoken,
            COMMENT_TYPE.TRIP,
            null,
            vehicleId,
            fromDate,
            getEndDate(toDate)
        ).promise;
        commentList = get(resultComments, 'data', []);
    }
    const result = await fetchMergedTrips(accesstoken, fromDate.valueOf(), toDate.valueOf(), vehicleId, false, true)
        .promise;
    const mergedTrips = orderBy(result.data, ({ trip }) => trip.sDate, ['asc']);
    const reportName = createReportFilename(reportFormat, 'Timeline', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};
    let stopRows = 0;
    const includeOdometerReading = reportFormat === REPORT_FORMAT.CSV && showOdometerReadingInHistoryReport();

    const includeStartLocation = get(filterConfig, 'includeTimelineLocation', true);
    const includeEndLocation = includeStartLocation;
    const includeDistance = get(filterConfig, 'includeTimelineDistance', true);
    const includeDuration = get(filterConfig, 'includeTimelineDuration', true);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);
    const includeSOCHeader = get(mergedTrips[0], 'trip.fuelType') === 'Electric';
    const wStatus = 70,
        wTime = 150;
    const wDistance = 80,
        wDuration = 80;
    const wLocation = 170;
    const wComment = includeStartLocation ? 100 : 150;
    const wLatLon = 120;
    const wMapLink = 150;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeDistance, wDistance);
    wOverflow += getWOverFlow(includeStartLocation, wLocation);
    wOverflow += getWOverFlow(includeEndLocation, wLocation);
    wOverflow += getWOverFlow(includeDuration, wDuration);
    wOverflow = floor(wOverflow / (3 + includeDistance + includeDuration + includeStartLocation + includeEndLocation));

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Status', wStatus + wOverflow, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Start Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(true, headers, 3, 'End Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(includeDuration, headers, 4, 'Duration', wDuration + wOverflow, footerRow);
    noOfCols += addHeader(includeStartLocation, headers, 5, `Start Location`, wLocation + wOverflow, footerRow);
    noOfCols += addHeader(includeOdometerReading, headers, 'odoStart', 'Start Odometer', wDuration, footerRow);
    noOfCols += addHeader(includeSOCHeader, headers, 'startSoc', `Start Soc`, wMapLink, footerRow);
    noOfCols += addHeader(includeEndLocation, headers, 6, `End Location`, wLocation + wOverflow, footerRow);
    noOfCols += addHeader(includeOdometerReading, headers, 'odoEnd', 'End Odometer', wDuration, footerRow);
    noOfCols += addHeader(includeSOCHeader, headers, 'endSoc', `End Soc`, wMapLink, footerRow);
    noOfCols += addHeader(
        includeDistance,
        headers,
        8,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );

    noOfCols += addHeader(includeSOCHeader, headers, 14, `SOC (%)`, wComment, footerRow);
    noOfCols += addHeader(includeComment, headers, 9, `Comment`, wComment, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 10, `Start Lat, Lon`, wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 11, `Start Map Link`, wMapLink, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 12, `End Lat, Lon`, wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 13, `End Map Link`, wMapLink, footerRow);
    const data = [];
    let sumDuration = 0,
        sumStopDuration = 0;

    const length = mergedTrips.length;
    let sumOdoTillNow = 0,
        lastEAddressProcessed = '-';

    map(mergedTrips, ({ trip }, index) => {
        if (!trip.eDate) {
            return;
        }
        const sAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
            null,
            trip.sAddress,
            VEHICLE_STATUS.PARKED,
            trip.sLat,
            trip.sLon,
            addressBook
        );
        const eAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
            null,
            trip.eAddress,
            VEHICLE_STATUS.PARKED,
            trip.eLat,
            trip.eLon,
            addressBook
        );
        if (index === 0 && getMomentTime(trip.sDate).valueOf() > fromDate.valueOf()) {
            //insert first stopped before trip
            const firstStoppedDataRow = {};
            addDataRow(true, firstStoppedDataRow, 1, `${toUpper(MOTION_TYPE.STOP)}`);
            addDataRow(true, firstStoppedDataRow, 2, getHumanizeTime(fromDate, true, true, true));
            addDataRow(true, firstStoppedDataRow, 3, getHumanizeTime(trip.sDate, true, true, true));
            addDataRow(
                includeDuration,
                firstStoppedDataRow,
                4,
                getTimeDiff(getMomentTime(trip.sDate).diff(fromDate), false, true)
            );
            addDataRow(includeStartLocation, firstStoppedDataRow, 5, parseAddress(sAddressProcessed));
            addDataRow(includeEndLocation, firstStoppedDataRow, 6, parseAddress(sAddressProcessed));
            addDataRow(includeDistance, firstStoppedDataRow, 8, reportFormat === REPORT_FORMAT.CSV ? `` : `-`);
            addDataRow(includeComment, firstStoppedDataRow, 9, '-');
            addDataRow(includeSOCHeader, firstStoppedDataRow, 'startSoc', '');
            addDataRow(includeSOCHeader, firstStoppedDataRow, 'endSoc', '');
            addDataRow(includeSOCHeader, firstStoppedDataRow, 14, '');
            data.push(firstStoppedDataRow);
            sumStopDuration += getMomentTime(trip.sDate).diff(fromDate);
            stopRows++;
            lastEAddressProcessed = sAddressProcessed;
            sumOdoTillNow += trip.tOdo;
        } else if (index > 0) {
            sumOdoTillNow += trip.tOdo;
        }

        sumDuration += trip.duration;
        const dataRow = {};
        addDataRow(true, dataRow, 1, `${toUpper(MOTION_TYPE.TRIP)}`);
        addDataRow(true, dataRow, 2, getHumanizeTime(trip.sDate, true, true, true));
        addDataRow(true, dataRow, 3, getHumanizeTime(trip.eDate, true, true, true));
        addDataRow(includeDuration, dataRow, 4, getTimeDiff(trip.duration, false, true));
        addDataRow(includeStartLocation, dataRow, 5, parseAddress(sAddressProcessed));
        addDataRow(includeEndLocation, dataRow, 6, parseAddress(eAddressProcessed));
        addDataRow(
            includeDistance,
            dataRow,
            8,
            getTripOdo(trip.tOdo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
        );
        addDataRow(includeSOCHeader, dataRow, 'startSoc', trip.sEVSOC + '' || '');
        addDataRow(includeSOCHeader, dataRow, 'endSoc', trip.eEVSOC + '' || '');
        addDataRow(includeComment, dataRow, 9, '-');
        addDataRow(includeMinuteLatLon, dataRow, 10, trip.sLat ? `${trip.sLat}, ${trip.sLon}` : '-');
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            11,
            trip.sLat ? getGoogleMapLink(trip.sLat, trip.sLon, reportFormat === REPORT_FORMAT.PDF) : '-'
        );
        addDataRow(includeMinuteLatLon, dataRow, 12, trip.eLat ? `${trip.eLat}, ${trip.eLon}` : '-');
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            13,
            trip.eLat ? getGoogleMapLink(trip.eLat, trip.eLon, reportFormat === REPORT_FORMAT.PDF) : '-'
        );
        addDataRow(includeOdometerReading, dataRow, 'odoStart', trip.sOdo || '');
        addDataRow(includeOdometerReading, dataRow, 'odoEnd', (trip.status ? trip.eOdo : trip.sOdo) || '');
        addDataRow(includeSOCHeader, dataRow, 14, get(trip, 'tEVSOC', '-') + '');

        data.push(dataRow);

        const isLastStopRow = index === length - 1 || (index === length - 2 && !mergedTrips[index + 1].trip.eDate);
        if (getMomentTime(trip.eDate).valueOf() < toDate.valueOf()) {
            //insert stopped row after trip.
            const tripStopComment = find(commentList, {
                eventTime: getReportTime(trip.eDate),
            });
            const stoppedDataRow = {};
            const stopStartTime = trip.eDate ? getMomentTime(trip.eDate).valueOf() : null;
            const stopEndTime = index < length - 1 ? mergedTrips[index + 1].trip.sDate : toDate;
            let stopDuration = '';
            let stopDurationHumanized = '';
            if (stopStartTime && stopEndTime) {
                stopDuration = getMomentTime(stopEndTime).valueOf() - stopStartTime;
                stopDurationHumanized = getTimeDiff(Math.abs(stopDuration), false, true);
                sumStopDuration += stopDuration;
            }
            const nearestAddress = getNearestAddressFromAddressbook(trip.eLat, trip.eLon, addressBook, true);
            addDataRow(true, stoppedDataRow, 1, `${toUpper(MOTION_TYPE.STOP)}`);
            addDataRow(true, stoppedDataRow, 2, getHumanizeTime(trip.eDate, true, true, true));
            addDataRow(true, stoppedDataRow, 3, getHumanizeTime(stopEndTime, true, true, true));
            addDataRow(includeDuration, stoppedDataRow, 4, stopDurationHumanized);
            addDataRow(includeStartLocation, stoppedDataRow, 5, parseAddress(eAddressProcessed));
            addDataRow(includeEndLocation, stoppedDataRow, 6, parseAddress(eAddressProcessed));
            //getTimeDiff(sumDuration, false, true)
            addDataRow(includeDistance, stoppedDataRow, 8, reportFormat === REPORT_FORMAT.CSV ? '0' : '-');
            addDataRow(includeComment, stoppedDataRow, 9, getCommentText(tripStopComment, '-'));
            addDataRow(includeMinuteLatLon, stoppedDataRow, 10, trip.sLat ? `${trip.sLat}, ${trip.sLon}` : '-');
            addDataRow(
                includeMinuteMapLink,
                stoppedDataRow,
                11,
                trip.sLat ? getGoogleMapLink(trip.sLat, trip.sLon, reportFormat === REPORT_FORMAT.PDF) : '-'
            );
            addDataRow(includeMinuteLatLon, stoppedDataRow, 12, trip.eLat ? `${trip.eLat}, ${trip.eLon}` : '-');
            addDataRow(
                includeMinuteMapLink,
                stoppedDataRow,
                13,
                trip.eLat ? getGoogleMapLink(trip.eLat, trip.eLon, reportFormat === REPORT_FORMAT.PDF) : '-'
            );
            addDataRow(includeSOCHeader, stoppedDataRow, 'startSoc', '');
            addDataRow(includeSOCHeader, stoppedDataRow, 'endSoc', '');
            addDataRow(includeSOCHeader, stoppedDataRow, 14, '');

            data.push(stoppedDataRow);
            stopRows++;
            sumOdoTillNow = 0;
            lastEAddressProcessed = eAddressProcessed;
        }
    });

    const analyticsData = getFuelData(aggregations);

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL\n${`${get(find(analyticsData, ['type', 'totalTrips']), 'count', 0)}`}`);
    addDataRow(true, totalRow, 2, '-');
    addDataRow(true, totalRow, 3, '-');
    addDataRow(
        includeDuration,
        totalRow,
        4,
        `${`Duration\n${getTimeDiff(sumDuration, false, true)}\n\n`}Stop Duration\n${getTimeDiff(
            sumStopDuration,
            false,
            true
        )}`
    );
    addDataRow(includeStartLocation, totalRow, 5, '-');
    addDataRow(includeEndLocation, totalRow, 6, '-');
    addDataRow(
        includeDistance,
        totalRow,
        8,
        getTripOdo(
            get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0),
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF
        )
    );
    addDataRow(includeComment, totalRow, 9, '-');
    addDataRow(includeMinuteLatLon, totalRow, 10, '-');
    addDataRow(includeMinuteMapLink, totalRow, 11, '-');
    addDataRow(includeMinuteLatLon, totalRow, 12, '-');
    addDataRow(includeMinuteMapLink, totalRow, 13, '-');
    addDataRow(includeSOCHeader, totalRow, 14, '-');
    addDataRow(includeSOCHeader, totalRow, 'startSoc', '-');
    addDataRow(includeSOCHeader, totalRow, 'endSoc', '-');
    addDataRow(includeOdometerReading, totalRow, 'odoStart', '-');
    addDataRow(includeOdometerReading, totalRow, 'odoEnd', '-');

    data.push(totalRow);
    //data.push(footerRow);
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    const colorConfig = {
        endSoc: 'FF00FF00',
        startSoc: 'FF00FF00',
        odoStart: 'FFFFFF00',
        odoEnd: 'FFFFFF00',
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'Timeline Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        { colorConfig }
    );
}

const getDataFromGPSPoints = (routeGPSPoint, speed) => {
    const data = {};
    !speed && set(data, 'speed', round(routeGPSPoint.speed, 2), '-');
    set(data, 'latitude', routeGPSPoint.latitude, '-');
    set(data, 'longitude', routeGPSPoint.longitude, '-');
    return data;
};

const prepareRPMData = (routes, indexTrip, prevTripsDist, tripOdoActual) => {
    const route = routes[indexTrip];
    const routePossibleGPS1 = routes.length > indexTrip + 1 ? routes[indexTrip + 1] : {};
    const routePossibleGPS2 = routes.length > indexTrip + 2 ? routes[indexTrip + 1] : {};
    const routePossibleGPS3 = routes.length > indexTrip + 3 ? routes[indexTrip + 1] : {};
    const routeGPSPoints = [routePossibleGPS1, routePossibleGPS2, routePossibleGPS3];
    const tripOdoDevice = get(last(routes), 'otherAttributes.tripOdometer', 0);
    let distanceNormalizationFactor = !!tripOdoDevice && !!tripOdoActual ? tripOdoDevice / tripOdoActual : 1;
    const currentTripOdo = prevTripsDist + +get(route, 'otherAttributes.tripOdometer', 0) / distanceNormalizationFactor;
    const allData = {};
    set(allData, 'timeStamp', get(route, 'timeStamp'), '-');
    set(allData, 'rpm', toSafeInteger(split(get(route, 'otherAttributes.rpm'), ' ')[0]), 0);
    set(allData, 'coolant', toSafeInteger(split(get(route, 'otherAttributes.coolantTemperature'), '°')[0]), '-');
    set(allData, 'tripOdo', +currentTripOdo);
    set(allData, 'tripId', get(route, 'tripId', ''));
    let speed = toSafeInteger(split(get(route, 'otherAttributes.obdSpeed', ' '), ' ')[0]);
    if (speed > 0) {
        set(allData, 'speed', speed, '-');
    }
    let index = 0;
    let GPSFound = false;
    while (!GPSFound && index < 3) {
        if (get(routeGPSPoints[index], 'type') === 'GPS') {
            GPSFound = true;
            const data = getDataFromGPSPoints(routeGPSPoints[index], speed);
            set(allData, 'latitude', data.latitude, '-');
            set(allData, 'longitude', data.longitude, '-');
            if (!(speed > 0)) {
                set(allData, 'speed', data.speed, '0');
            }
            set(allData, 'map', getGoogleMapLink(data.latitude, data.longitude, false), '-');
        }
        index++;
    }

    if (!GPSFound) {
        set(allData, 'latitude', '-');
        set(allData, 'longitude', '-');
        set(allData, 'map', '-');
    }
    return allData;
};

export async function triggerTripsRPMReportDownload(
    vehicleId,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    accesstoken,
    loggedInUser
) {
    const result = await fetchMergedTrips(accesstoken, fromDate.valueOf(), toDate.valueOf(), vehicleId).promise;
    const mergedTrips = orderBy(result.data, ({ trip }) => trip.sDate, ['asc']);
    const reportName = createReportFilename(reportFormat, 'RPM report', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const wTime = 20;
    const wRPM = 20;
    const wCoolant = 30;
    const wDistance = 30;
    const wLatitude = 20;
    const wLongitude = 20;
    const wMaplink = 50;

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'timeStamp', 'Time', wTime, footerRow);
    noOfCols += addHeader(true, headers, 'rpm', 'RPM', wRPM, footerRow);
    noOfCols += addHeader(true, headers, 'speed', 'Speed (km/h)', wRPM, footerRow);
    noOfCols += addHeader(true, headers, 'coolant', 'Engine Temperature (°C)', wCoolant, footerRow);
    noOfCols += addHeader(true, headers, 'distance', 'Distance (KM)', wDistance, footerRow);
    noOfCols += addHeader(true, headers, 'latitude', 'Gps Latitude', wLatitude, footerRow);
    noOfCols += addHeader(true, headers, 'longitude', 'Gps Longitude', wLongitude, footerRow);
    noOfCols += addHeader(true, headers, 'map', 'Map Link', wMaplink, footerRow);

    const data = [];
    let totalKM = 0;
    let lastLatitude = 0;
    let lastLongitude = 0;
    let prevTripsDist = 0;
    let prevAllRPMData = {};
    let allOBDInvalid = true;
    map(mergedTrips, ({ routes, trip }, index) => {
        const obdData = filter(routes, ['type', 'OBD_DATA']);
        const obdDataLength = isArray(obdData) ? obdData.length : 0;
        const gpsData = filter(routes, ['type', 'GPS']);
        const gpsDataLength = isArray(gpsData) ? gpsData.length : 0;
        const totalLength = get(routes, 'length', 0);
        const isOBDValid = totalLength ? (obdDataLength * 100) / totalLength >= 40 : false;
        allOBDInvalid = allOBDInvalid && !isOBDValid;
        const isGPSValid = totalLength ? (gpsDataLength * 100) / totalLength >= 40 : false;
        const firstRoutePoint = first(get(mergedTrips, '0.routes', []));
        const startOdo = getTotalOdometerTillNow(firstRoutePoint);
        map(routes, (route, indexTrip) => {
            let dataRow = {};
            if (obdDataLength > 0 && isOBDValid && isGPSValid) {
                if (get(route, 'type') === 'OBD_DATA' && get(route, 'rpm')) {
                    routes = set(routes, `${indexTrip}.tripId`, get(trip, 'tripId', 0));
                    const allRPMData = prepareRPMData(routes, indexTrip, prevTripsDist, get(trip, 'tOdo'));
                    addDataRow(true, dataRow, 'timeStamp', getReportTime(route.timeStamp));
                    addDataRow(true, dataRow, 'rpm', allRPMData.rpm);
                    addDataRow(true, dataRow, 'speed', allRPMData.speed ? allRPMData.speed : 0);
                    addDataRow(true, dataRow, 'coolant', allRPMData.coolant);
                    addDataRow(true, dataRow, 'latitude', allRPMData.latitude);
                    addDataRow(true, dataRow, 'longitude', allRPMData.longitude);
                    addDataRow(true, dataRow, 'map', allRPMData.map);
                    totalKM = round(allRPMData.tripOdo, 2);
                    addDataRow(true, dataRow, 'distance', totalKM);
                    lastLatitude = allRPMData.latitude;
                    lastLongitude = allRPMData.longitude;
                    data.push(dataRow);
                    if (allRPMData.tripId != prevAllRPMData.tripId) {
                        prevTripsDist = prevAllRPMData.tripOdo ? round(prevAllRPMData.tripOdo, 2) : 0;
                    }
                    prevAllRPMData = { ...allRPMData };
                }
            } else {
                addDataRow(
                    true,
                    dataRow,
                    'timeStamp',
                    get(route, 'timeStamp') ? getReportTime(get(route, 'timeStamp')) : '-'
                );
                addDataRow(true, dataRow, 'rpm', '-');
                let speed = toSafeInteger(split(get(route, 'speed', ' '))[0]);
                addDataRow(true, dataRow, 'speed', speed ? speed : 0);
                addDataRow(true, dataRow, 'coolant', '-');
                const latitude = get(route, 'latitude', '');
                const longitude = get(route, 'longitude', '');

                if (latitude && longitude && lastLatitude && lastLongitude) {
                    let diff = pythagorasEquirectangular(lastLatitude, lastLongitude, latitude, longitude);
                    diff = startOdo && !getTotalOdometerTillNow(route) ? +diff + startOdo : diff;
                    if (diff) {
                        let odometer = getTotalOdometerTillNow(route, diff);
                        totalKM = +odometer - startOdo;
                    }
                }
                addDataRow(true, dataRow, 'latitude', get(route, 'latitude', ''));
                addDataRow(true, dataRow, 'longitude', get(route, 'longitude', ''));
                addDataRow(true, dataRow, 'distance', round(totalKM, 2));
                addDataRow(true, dataRow, 'map', getGoogleMapLink(latitude, longitude, false));
                data.push(dataRow);
                lastLatitude = latitude;
                lastLongitude = longitude;
                prevTripsDist = totalKM;
            }
        });
    });
    const totalRow = {};
    if (allOBDInvalid) {
        addDataRow(true, totalRow, 'rpm', 'Not enough OBD data Found');
        data.push(totalRow);
    }

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'RPM Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        2,
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        false,
        '',
        headerStyles,
        false
    );
}

export async function triggerTripsSplitByDistanceReportDownload(
    vehicleId,
    aggregations,
    groupList,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    addressBook,
    progressUpdateCallback,
    loggedInUser
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(30);
    }

    const result = await fetchMergedTrips(accesstoken, fromDate.valueOf(), toDate.valueOf(), vehicleId).promise;
    const mergedTrips = orderBy(result.data, ({ trip }) => trip.sDate, ['asc']);
    if (progressUpdateCallback) {
        progressUpdateCallback(80);
    }

    const reportName = createReportFilename(reportFormat, 'Split', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const includeGroups = groupList && groupList.length > 0;
    const includeLocationTripsSplitByDistanceReport = get(
        filterConfig,
        'includeLocationTripsSplitByDistanceReport',
        true
    );
    const includeDistanceTripsSplitByDistanceReport = get(
        filterConfig,
        'includeDistanceTripsSplitByDistanceReport',
        true
    );
    const includeDurationTripsSplitByDistanceReport = get(
        filterConfig,
        'includeDurationTripsSplitByDistanceReport',
        true
    );
    const includeMileageTripsSplitByDistanceReport = get(
        filterConfig,
        'includeMileageTripsSplitByDistanceReport',
        true
    );
    const includeFuelTripsSplitByDistanceReport = get(filterConfig, 'includeFuelTripsSplitByDistanceReport', true);
    const includeFuelUnit = includeFuelTripsSplitByDistanceReport && reportFormat === REPORT_FORMAT.CSV;
    const distanceIntervalTripsSplitByDistanceReportKms = get(
        filterConfig,
        'distanceIntervalTripsSplitByDistanceReportKms',
        1
    );
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);

    let splittedTrips = [];
    let currentSplittedTrip = null,
        currentDistance = 0,
        idleTime = 0,
        runningTime = 0,
        curActiveStatus = null,
        curStatusStartTime = null;
    map(mergedTrips, ({ trip, routes }, tripIndex) => {
        const gpsRoutes = filter(routes, ['type', 'GPS']);
        const totalTripDistance = get(trip, 'tOdo', 0);
        const totalDeviceTripDistance = parseFloat(get(last(gpsRoutes), 'otherAttributes.tripOdometer', 0));
        let distanceNormalizationFactor =
            totalDeviceTripDistance && totalTripDistance ? totalDeviceTripDistance / totalTripDistance : 1;
        if (get(loggedInUser, 'accountId', '') === 8237) {
            distanceNormalizationFactor = 1;
        }
        let lastTripOdometer = 0,
            curStatusStartTime = null,
            curActiveStatus = null;

        map(gpsRoutes, (point, routeIndex) => {
            if (!currentSplittedTrip) {
                currentDistance = 0;
                idleTime = 0;
                runningTime = 0;
                curActiveStatus = getCurrentVehicleStatus(point);
                curStatusStartTime = getMomentTime(point.timeStamp);

                currentSplittedTrip = {};
                currentSplittedTrip.vehicleId = trip.vehicleId;
                currentSplittedTrip.vehicleMake = trip.vehicleMake;
                currentSplittedTrip.vehicleModel = trip.vehicleModel;
                currentSplittedTrip.vehicleName = trip.vehicleName;
                currentSplittedTrip.vehicleNumber = trip.vehicleNumber;
                currentSplittedTrip.vehicleYear = trip.vehicleYear;
                currentSplittedTrip.groupId = trip.groupId;
                currentSplittedTrip.fuelType = trip.fuelType;
                currentSplittedTrip.driverName = trip.driverName;
                currentSplittedTrip.driverNumber = trip.driverNumber;

                currentSplittedTrip.sLat = point.latitude;
                currentSplittedTrip.sLon = point.longitude;
                currentSplittedTrip.sDate = point.timeStamp;
                currentSplittedTrip.sFuel =
                    point.totalFuelConsumption + parseFloat(get(point, 'otherAttributes.currentFuelConsumption', 0));
            } else {
                const currentTripOdometer = parseFloat(get(point, 'otherAttributes.tripOdometer', 0));
                currentDistance += (currentTripOdometer - lastTripOdometer) / distanceNormalizationFactor;
                lastTripOdometer = currentTripOdometer;
                const curStatus = getCurrentVehicleStatus(point);
                if (curStatus && curActiveStatus && curStatusStartTime && curStatus === curActiveStatus) {
                    const timeDiff = getMomentTime(point.timeStamp).diff(curStatusStartTime);
                    if (curActiveStatus === VEHICLE_STATUS.IDLE) {
                        idleTime += timeDiff;
                    } else if (curActiveStatus === VEHICLE_STATUS.RUNNING) {
                        runningTime += timeDiff;
                    }
                }
                curActiveStatus = curStatus;
                curStatusStartTime = getMomentTime(point.timeStamp);

                if (
                    currentDistance > distanceIntervalTripsSplitByDistanceReportKms ||
                    (tripIndex === mergedTrips.length - 1 && routeIndex === gpsRoutes.length - 1)
                ) {
                    currentSplittedTrip.eLat = point.latitude;
                    currentSplittedTrip.eLon = point.longitude;
                    currentSplittedTrip.eDate = point.timeStamp;
                    currentSplittedTrip.eFuel =
                        point.totalFuelConsumption +
                        parseFloat(get(point, 'otherAttributes.currentFuelConsumption', 0));

                    currentSplittedTrip.tOdo = currentDistance;
                    currentSplittedTrip.idleTime = idleTime;
                    currentSplittedTrip.runningTime = runningTime;
                    currentSplittedTrip.stopTime =
                        getMomentTime(currentSplittedTrip.eDate).diff(getMomentTime(currentSplittedTrip.sDate)) -
                        (idleTime + runningTime);
                    currentSplittedTrip.tFuel = currentSplittedTrip.eFuel - currentSplittedTrip.sFuel;
                    // //TODO: SHOW THIS IN REPORT AS WELL
                    // console.log(
                    //     getTimeDiff(currentSplittedTrip.idleTime),
                    //     getTimeDiff(currentSplittedTrip.runningTime),
                    //     getTimeDiff(currentSplittedTrip.stopTime)
                    // );

                    splittedTrips = [...splittedTrips, { ...currentSplittedTrip }];

                    currentDistance = 0;
                    idleTime = 0;
                    runningTime = 0;
                    currentSplittedTrip = null;
                }
            }
        });
    });

    let locationsForGeocoding = {};
    //create locations fo geocoding
    map(splittedTrips, ({ sLat, sLon, eLat, eLon }, index) => {
        if (sLat && sLon) {
            locationsForGeocoding[index] = {
                latitude: sLat,
                longitude: sLon,
                id: index,
            };
        }
        if (index === splittedTrips.length - 1 && eLat && eLon) {
            locationsForGeocoding[index + 1] = {
                latitude: eLat,
                longitude: eLon,
                id: index + 1,
            };
        }
    });

    progressUpdateCallback(25);
    let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
        accesstoken,
        toArray(locationsForGeocoding)
    );
    progressUpdateCallback(50);
    let locationsBulk = {};
    map(locationsForGeocodingBulk, (location) => {
        locationsBulk[location.id] = location;
    });
    locationsForGeocoding = locationsBulk;

    const wDistance = 90,
        wDuration = 90,
        wMileage = 100,
        wFuel = 90;
    const wLocation = 160;
    let wLocationOverflow = 0;
    wLocationOverflow += getWOverFlow(includeDistanceTripsSplitByDistanceReport, wDistance);
    wLocationOverflow += getWOverFlow(includeDurationTripsSplitByDistanceReport, wDuration);
    wLocationOverflow += getWOverFlow(includeMileageTripsSplitByDistanceReport, wMileage);
    wLocationOverflow += getWOverFlow(includeFuelTripsSplitByDistanceReport, wFuel);
    wLocationOverflow /= 2;

    let noOfCols = 0;
    if (reportFormat === REPORT_FORMAT.PDF) {
        noOfCols += addHeader(true, headers, 1, 'Vehicle', 130, footerRow);
    } else {
        noOfCols += addHeader(true, headers, 1, 'Name', 150, footerRow);
        noOfCols += addHeader(true, headers, 2, 'Number', 150, footerRow);
        noOfCols += addHeader(includeGroups, headers, 3, 'Group', 150, footerRow);
        noOfCols += addHeader(true, headers, 4, 'Year', 150, footerRow);
        noOfCols += addHeader(true, headers, 5, 'Make', 150, footerRow);
        noOfCols += addHeader(true, headers, 6, 'Model', 150, footerRow);
        noOfCols += addHeader(true, headers, 7, 'Driver', 150, footerRow);
    }
    noOfCols += addHeader(true, headers, 8, 'Start Time', 95, footerRow);
    noOfCols += addHeader(true, headers, 9, 'End Time', 95, footerRow);
    noOfCols += addHeader(
        includeLocationTripsSplitByDistanceReport,
        headers,
        10,
        'Start Location',
        wLocation + wLocationOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeLocationTripsSplitByDistanceReport,
        headers,
        11,
        'End Location',
        wLocation + wLocationOverflow,
        footerRow
    );
    noOfCols += addHeader(
        includeDistanceTripsSplitByDistanceReport,
        headers,
        12,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );
    noOfCols += addHeader(includeDurationTripsSplitByDistanceReport, headers, 13, 'Duration', wDuration, footerRow);
    noOfCols += addHeader(includeMileageTripsSplitByDistanceReport, headers, 14, 'Mileage', wMileage, footerRow);
    noOfCols += addHeader(includeFuelTripsSplitByDistanceReport, headers, 15, 'Fuel', wFuel, footerRow);
    noOfCols += addHeader(includeFuelUnit, headers, 16, 'Fuel Type', 80, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 17, 'Start Lat, Lon', 80, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 18, 'Start Map Link', 140, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 19, 'End Lat, Lon', 80, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 20, 'End Map Link', 140, footerRow);

    const data = [];
    let sumDuration = 0;
    map(
        splittedTrips,
        (
            {
                groupId,
                eDate,
                sDate,
                tOdo,
                fuelType,
                tFuel,
                vehicleName,
                vehicleNumber,
                vehicleMake,
                vehicleModel,
                vehicleYear,
                driverName,
                sLat,
                sLon,
                eLat,
                eLon,
            },
            index
        ) => {
            if (!eDate) {
                return;
            }
            const duration = getMomentTime(eDate).diff(getMomentTime(sDate));
            sumDuration += duration;
            const curGroupName = getGroupName(groupId, groupList);
            const sAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
                null,
                locationsForGeocoding[index].address,
                VEHICLE_STATUS.PARKED,
                sLat,
                sLon,
                addressBook
            );
            const eAddressProcessed = getAddressFromAddressbookOrGeoCodedLocations(
                null,
                locationsForGeocoding[index + 1].address,
                VEHICLE_STATUS.PARKED,
                eLat,
                eLon,
                addressBook
            );
            const mileage = tOdo && tFuel ? tOdo / tFuel : 0;

            const dataRow = {};
            if (reportFormat === REPORT_FORMAT.PDF) {
                addDataRow(
                    true,
                    dataRow,
                    1,
                    `Name: ${vehicleName}\nNo: ${vehicleNumber}${
                        includeGroups ? `\nGroup: ${curGroupName ? curGroupName : '-'}` : ''
                    }${driverName ? `\nDriver: ${driverName}` : ''}`
                );
            } else {
                addDataRow(true, dataRow, 1, `${vehicleName}`);
                addDataRow(true, dataRow, 2, `${vehicleNumber}`);
                addDataRow(includeGroups, dataRow, 3, `${curGroupName ? curGroupName : '-'}`);
                addDataRow(true, dataRow, 4, `${vehicleYear}`);
                addDataRow(true, dataRow, 5, `${vehicleMake}`);
                addDataRow(true, dataRow, 6, `${vehicleModel}`);
                addDataRow(true, dataRow, 7, `${driverName ? driverName : '-'}`);
            }
            addDataRow(true, dataRow, 8, getHumanizeTime(sDate, true, true, true));
            addDataRow(true, dataRow, 9, getHumanizeTime(eDate, true, true, true));
            addDataRow(includeLocationTripsSplitByDistanceReport, dataRow, 10, parseAddress(sAddressProcessed));
            addDataRow(includeLocationTripsSplitByDistanceReport, dataRow, 11, parseAddress(eAddressProcessed));
            addDataRow(
                includeDistanceTripsSplitByDistanceReport,
                dataRow,
                12,
                getTripOdo(tOdo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
            );
            addDataRow(includeDurationTripsSplitByDistanceReport, dataRow, 13, getTimeDiff(duration, false, true));
            addDataRow(
                includeMileageTripsSplitByDistanceReport,
                dataRow,
                14,
                `${round(mileage, 2)} ${getFuelUnit(fuelType, loggedInUser)}\n(${getFuelName(fuelType, loggedInUser)})`
            );
            addDataRow(
                includeFuelTripsSplitByDistanceReport,
                dataRow,
                15,
                `${getFuelString(tFuel, fuelType, reportFormat === REPORT_FORMAT.PDF, loggedInUser)}${
                    reportFormat === REPORT_FORMAT.PDF ? `\n(${getFuelName(fuelType, loggedInUser)})` : ''
                }`
            );
            addDataRow(
                includeFuelUnit,
                dataRow,
                16,
                `${getFuelName(fuelType, loggedInUser)} (${getFuelVolume(fuelType, loggedInUser)})`
            );
            addDataRow(includeMinuteLatLon, dataRow, 17, `${sLat}, ${sLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                18,
                getGoogleMapLink(sLat, sLon, reportFormat === REPORT_FORMAT.PDF)
            );
            addDataRow(includeMinuteLatLon, dataRow, 19, `${eLat}, ${eLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                20,
                getGoogleMapLink(eLat, eLon, reportFormat === REPORT_FORMAT.PDF)
            );
            data.push(dataRow);
        }
    );

    const analyticsData = getFuelData(aggregations);

    const totalRow = {};

    addDataRow(true, totalRow, 1, `TOTAL`);
    if (reportFormat === REPORT_FORMAT.CSV) {
        addDataRow(true, totalRow, 2, `-`);
        addDataRow(includeGroups, totalRow, 3, `-`);
        addDataRow(true, totalRow, 4, `-`);
        addDataRow(true, totalRow, 5, `-`);
        addDataRow(true, totalRow, 6, `-`);
        addDataRow(true, totalRow, 7, `-`);
    }
    addDataRow(true, totalRow, 8, '-');
    addDataRow(true, totalRow, 9, '-');
    addDataRow(includeLocationTripsSplitByDistanceReport, totalRow, 10, '-');
    addDataRow(includeLocationTripsSplitByDistanceReport, totalRow, 11, '-');
    addDataRow(
        includeDistanceTripsSplitByDistanceReport,
        totalRow,
        12,
        getTripOdo(
            get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0),
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF
        )
    );
    addDataRow(includeDurationTripsSplitByDistanceReport, totalRow, 13, getTimeDiff(sumDuration, false, true));
    if (includeMileageTripsSplitByDistanceReport || includeFuelTripsSplitByDistanceReport) {
        const dataPetrol = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).PETROL.value);
        const dataDiesel = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).DIESEL.value);
        const dataCNG = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).CNG.value);
        let avgMileageString = '',
            fuelString = '';
        if (dataPetrol) {
            avgMileageString += `${round(dataPetrol.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).PETROL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataPetrol.consumption.count,
                GET_FUEL_TYPE(loggedInUser).PETROL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
        }
        if (dataDiesel) {
            avgMileageString += `${round(dataDiesel.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataDiesel.consumption.count,
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
        }
        if (dataCNG) {
            avgMileageString += `${round(dataCNG.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).CNG,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
            fuelString += `${getFuelString(
                dataCNG.consumption.count,
                GET_FUEL_TYPE(loggedInUser).CNG,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
        }

        if (isEmpty(avgMileageString)) {
            avgMileageString = '-';
        }
        if (isEmpty(fuelString)) {
            fuelString = '-';
        }

        addDataRow(includeMileageTripsSplitByDistanceReport, totalRow, 14, avgMileageString);
        addDataRow(includeFuelTripsSplitByDistanceReport, totalRow, 15, fuelString);
        addDataRow(includeFuelUnit, totalRow, 16, '-');
        addDataRow(includeFuelUnit, totalRow, 17, '-');
        addDataRow(includeFuelUnit, totalRow, 18, '-');
        addDataRow(includeFuelUnit, totalRow, 19, '-');
        addDataRow(includeFuelUnit, totalRow, 20, '-');
    }
    data.push(totalRow);
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        'History Split Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}

export async function triggerTripsMovementReportDownloadUsingMergedTrips(
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    loggedInUser,
    addressBook
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }
    const result = await fetchMergedTrips(accesstoken, fromDate.valueOf(), toDate.valueOf(), vehicleId).promise;
    const mergedTrips = orderBy(result.data, ({ trip }) => trip.sDate, ['asc']);

    let movementsArray = [];
    let locationsForGeocoding = {};
    let motionPointIndex = 0;

    const includeLocation = get(filterConfig, 'includeMovementLocation', true);
    const includeDistance = get(filterConfig, 'includeMovementDistance', true);
    const includeDuration = get(filterConfig, 'includeMovementDuration', true);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);
    const includeAddressBooks = addressBook && addressBook.length > 0 && get(filterConfig, 'includeAddressBooks', true);

    const length = mergedTrips.length;
    map(mergedTrips, ({ trip, routes }, index) => {
        if (!trip.eDate) {
            return;
        }
        if (index === 0 && getMomentTime(trip.sDate).valueOf() > fromDate.valueOf()) {
            //insert first stopped before trip
            const firstStoppedMovement = {};
            firstStoppedMovement.status = VEHICLE_STATUS.PARKED;
            firstStoppedMovement.sDate = fromDate.format(DATE_FORMAT_TIMESTAMP);
            firstStoppedMovement.eDate = trip.sDate;
            firstStoppedMovement.sAddress = trip.sAddress;
            firstStoppedMovement.eAddress = trip.sAddress;
            movementsArray.push(firstStoppedMovement);
        }

        let motionPoints = getMotionPointsFromRoutes(routes);
        if (motionPoints) {
            const lengthMotionPoints = motionPoints.length;
            motionPoints = map(motionPoints, (motionPoint, index) => {
                motionPointIndex++;
                if (motionPoint.status === VEHICLE_STATUS.IDLE) {
                    sumIdleDuration += getMomentTime(motionPoint.eDate).diff(getMomentTime(motionPoint.sDate));
                }
                if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
                    sumRunningDuration += getMomentTime(motionPoint.eDate).diff(getMomentTime(motionPoint.sDate));
                }
                if (index === 0) {
                    motionPoint.sAddress = trip.sAddress;
                    if (motionPoint.status === VEHICLE_STATUS.IDLE) {
                        motionPoint.eAddress = trip.sAddress;
                    } else if (index === lengthMotionPoints - 1) {
                        motionPoint.eAddress = trip.eAddress;
                    } else if (
                        index === lengthMotionPoints - 2 &&
                        motionPoints[index + 1].status === VEHICLE_STATUS.IDLE
                    ) {
                        motionPoint.eAddress = trip.eAddress;
                    } else if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
                        locationsForGeocoding[motionPointIndex] = {
                            latitude: motionPoint.eLat,
                            longitude: motionPoint.eLon,
                            id: motionPointIndex,
                        };
                    }
                    return {
                        motionPointIndex,
                        ...motionPoint,
                    };
                } else if (index === lengthMotionPoints - 1) {
                    if (motionPoint.status === VEHICLE_STATUS.IDLE) {
                        motionPoint.sAddress = trip.eAddress;
                    }
                    motionPoint.eAddress = trip.eAddress;
                    return {
                        motionPointIndex,
                        ...motionPoint,
                    };
                } else {
                    motionPoint.sAddress = motionPoints[index - 1].eAddress;
                    if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
                        if (
                            index === lengthMotionPoints - 2 &&
                            motionPoints[index + 1].status === VEHICLE_STATUS.IDLE
                        ) {
                            motionPoint.eAddress = trip.eAddress;
                        } else {
                            locationsForGeocoding[motionPointIndex] = {
                                latitude: motionPoint.eLat,
                                longitude: motionPoint.eLon,
                                id: motionPointIndex,
                            };
                        }
                    }
                    return {
                        motionPointIndex,
                        ...motionPoint,
                    };
                }
            });
            movementsArray = [...movementsArray, ...motionPoints];
        }

        if (getMomentTime(trip.eDate).valueOf() < toDate.valueOf()) {
            //insert stopped row after trip.

            const endTimestamp =
                index < length - 1 ? mergedTrips[index + 1].trip.sDate : toDate.format(DATE_FORMAT_TIMESTAMP);
            const stoppedMovement = {};
            stoppedMovement.status = VEHICLE_STATUS.PARKED;
            stoppedMovement.sDate = trip.eDate;
            stoppedMovement.eDate = endTimestamp;
            stoppedMovement.sAddress = trip.eAddress;
            stoppedMovement.eAddress = trip.eAddress;
            movementsArray.push(stoppedMovement);
        }
    });

    // BATCH FETCH GEO CODEED LOCATIONS FOR ALL LOCATIONS
    if (includeLocation) {
        progressUpdateCallback(25);
        let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
            accesstoken,
            toArray(locationsForGeocoding)
        );
        progressUpdateCallback(50);
        let locationsBulk = {};
        map(locationsForGeocodingBulk, (location) => {
            locationsBulk[location.id] = location;
        });
        locationsForGeocoding = locationsBulk;

        map(movementsArray, (motionPoint, index) => {
            if (motionPoint.motionPointIndex && locationsForGeocoding[motionPoint.motionPointIndex]) {
                motionPoint.eAddress = locationsForGeocoding[motionPoint.motionPointIndex].address;
            }
            if (index > 0 && !motionPoint.sAddress && movementsArray[index - 1].eAddress) {
                motionPoint.sAddress = movementsArray[index - 1].eAddress;
            }
            if (!motionPoint.eAddress && motionPoint.sAddress && motionPoint.status == VEHICLE_STATUS.IDLE) {
                motionPoint.eAddress = motionPoint.sAddress;
            }
        });
    }

    const reportName = createReportFilename(reportFormat, 'Movement', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const wDistance = 80,
        wDuration = 115,
        wNearestAddress = 160;

    const wTime = includeLocation ? 100 : 200;
    const wLocation = 220;
    let wLocationOverflow = 0;
    wLocationOverflow += getWOverFlow(includeDistance, wDistance);
    wLocationOverflow += getWOverFlow(includeDuration, wDuration);
    wLocationOverflow /= 2;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeAddressBooks, wNearestAddress);

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Status', 120, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Start Time', wTime, footerRow);
    noOfCols += addHeader(true, headers, 3, 'End Time', wTime, footerRow);
    noOfCols += addHeader(includeDuration, headers, 4, 'Duration', wDistance, footerRow);
    noOfCols += addHeader(includeLocation, headers, 5, 'Start Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeLocation, headers, 6, 'End Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(
        includeDistance,
        headers,
        7,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );
    noOfCols += addHeader(includeMinuteLatLon, headers, 8, 'Start Lat, Lon', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 9, 'Start Map Link', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 10, 'End Lat, Lon', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 11, 'End Map Link', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeAddressBooks, headers, 12, 'Address Book', wNearestAddress + wOverflow, footerRow);

    const data = [];
    let sumStopDuration = 0,
        sumIdleDuration = 0,
        sumRunningDuration = 0;
    const movementsLength = movementsArray.length;
    map(movementsArray, (motionPoint, index) => {
        let nearestAddress;
        if (includeAddressBooks) {
            nearestAddress = getNearestAddressFromAddressbook(
                motionPoint.sLat,
                motionPoint.sLon,
                addressBook,
                true,
                loggedInUser
            );
        }

        const eDate = index < movementsLength - 1 ? movementsArray[index + 1].sDate : motionPoint.eDate;
        const tOdo = motionPoint.eOdo ? motionPoint.eOdo - motionPoint.sOdo : 0;

        if (motionPoint.status === VEHICLE_STATUS.PARKED) {
            sumStopDuration += getMomentTime(eDate).diff(motionPoint.sDate);
        }
        if (motionPoint.status === VEHICLE_STATUS.IDLE) {
            sumIdleDuration += getMomentTime(eDate).diff(motionPoint.sDate);
        }
        if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
            sumRunningDuration += getMomentTime(eDate).diff(motionPoint.sDate);
        }
        let dataRow = {};
        addDataRow(true, dataRow, 1, `${toUpper(motionPoint.status)}`);
        addDataRow(true, dataRow, 2, getHumanizeTime(motionPoint.sDate, true, true, true));
        addDataRow(true, dataRow, 3, getHumanizeTime(eDate, true, true, true));
        addDataRow(
            includeDuration,
            dataRow,
            4,
            getTimeDiff(getMomentTime(eDate).diff(getMomentTime(motionPoint.sDate)), false, true)
        );
        addDataRow(includeLocation, dataRow, 5, parseAddress(motionPoint.sAddress));
        // addDataRow(includeLocation, dataRow, 5, motionPoint.sAddress ? parseAddress(
        //     motionPoint.sAddress) :
        // `https://www.google.com/maps/search/?api=1&query=${motionPoint.sLat},${motionPoint.sLon}`);
        addDataRow(includeLocation, dataRow, 6, parseAddress(motionPoint.eAddress));
        // addDataRow(includeLocation, dataRow, 6, motionPoint.eAddress ? parseAddress(
        //     motionPoint.eAddress) :
        // `https://www.google.com/maps/search/?api=1&query=${motionPoint.eLat},${motionPoint.eLon}`);
        addDataRow(
            includeDistance,
            dataRow,
            7,
            getTripOdo(tOdo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
        );
        addDataRow(
            includeMinuteLatLon,
            dataRow,
            8,
            motionPoint.sLat ? `${motionPoint.sLat}, ${motionPoint.sLon}` : '-'
        );
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            9,
            motionPoint.sLat
                ? getGoogleMapLink(motionPoint.sLat, motionPoint.sLon, reportFormat === REPORT_FORMAT.PDF)
                : '-'
        );
        addDataRow(
            includeMinuteLatLon,
            dataRow,
            10,
            motionPoint.eLat ? `${motionPoint.eLat}, ${motionPoint.eLon}` : '-'
        );
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            11,
            motionPoint.eLat
                ? getGoogleMapLink(motionPoint.eLat, motionPoint.eLon, reportFormat === REPORT_FORMAT.PDF)
                : '-'
        );
        addDataRow(
            includeAddressBooks,
            dataRow,
            12,
            nearestAddress && nearestAddress.isInside ? nearestAddress.addressText : '-'
        );
        data.push(dataRow);
    });

    const analyticsData = getFuelData(aggregations);

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    addDataRow(true, totalRow, 2, '-');
    addDataRow(true, totalRow, 3, '-');
    addDataRow(
        includeDuration,
        totalRow,
        4,
        `Running Duration\n${getTimeDiff(sumRunningDuration, false, true)}\n\nIdle Duration\n${getTimeDiff(
            sumIdleDuration,
            false,
            true
        )}\n\nStop Duration\n${getTimeDiff(sumStopDuration, false, true)}`
    );
    addDataRow(includeLocation, totalRow, 5, '-');
    addDataRow(includeLocation, totalRow, 6, '-');
    addDataRow(
        includeDistance,
        totalRow,
        7,
        getTripOdo(
            get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0),
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF
        )
    );
    addDataRow(includeMinuteLatLon, totalRow, 8, '-');
    addDataRow(includeMinuteMapLink, totalRow, 9, '-');
    addDataRow(includeMinuteLatLon, totalRow, 10, '-');
    addDataRow(includeMinuteMapLink, totalRow, 11, '-');
    addDataRow(includeAddressBooks, totalRow, 12, '-');

    data.push(totalRow);
    //data.push(footerRow);
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        includeLocation ? ORIENTATION.LANDSCAPE : ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Movement Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}

export async function triggerTripsMovementReportDownloadBackend(
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    loggedInUser,
    addressBook
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }
    const analyticsData = getFuelData(aggregations);
    const totalActualOdometer = get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0);

    let mergedMovementPoints = [];
    const SPLIT_MILLES_TIME = HOURS_24;
    const requests = ceil(toDate.diff(fromDate) / SPLIT_MILLES_TIME);
    const includeAddressBooks = addressBook && addressBook.length > 0 && get(filterConfig, 'includeAddressBooks', true);
    for (let i = 0; i < requests; i++) {
        const from = fromDate.valueOf() + i * SPLIT_MILLES_TIME;
        const to = from + SPLIT_MILLES_TIME < toDate.valueOf() ? from + SPLIT_MILLES_TIME : toDate.valueOf();

        const result = await fetchMovementPoints(accesstoken, from, to, vehicleId).promise;
        if (result.data && result.data.length > 0) {
            const lastMergedPoint = last(mergedMovementPoints);
            const nextFirst = first(result.data);

            if (lastMergedPoint && nextFirst && lastMergedPoint.status === nextFirst.status) {
                lastMergedPoint.duration += nextFirst.duration;
                lastMergedPoint.distance += nextFirst.distance;
                lastMergedPoint.end = nextFirst.end;
                lastMergedPoint.endAddress = nextFirst.endAddress;
                mergedMovementPoints = [
                    ...lodashSlice(mergedMovementPoints, 0, mergedMovementPoints.length - 1),
                    ...[lastMergedPoint],
                    ...lodashSlice(result.data, 1),
                ];
            } else {
                mergedMovementPoints = [...mergedMovementPoints, ...result.data];
            }
        }
        if (progressUpdateCallback) {
            progressUpdateCallback(((i + 1) * 100) / requests);
        }
    }

    const firstPoint = get(mergedMovementPoints, '0');
    if (firstPoint && fromDate && get(firstPoint, 'status') === VEHICLE_STATUS.PARKED) {
        firstPoint.start = getMomentTime(fromDate).format(DATE_FORMAT_TIMESTAMP);
        firstPoint.duration = getMomentTime(firstPoint.end).valueOf() - getMomentTime(fromDate).valueOf();
        mergedMovementPoints[0] = firstPoint;
    } else if (!firstPoint) {
        const resp = await fetchHistoricalVehicleLocation(accesstoken, vehicleId, fromDate).promise;
        let createParkedRow = {};
        createParkedRow = set(createParkedRow, 'start', getMomentTime(fromDate).format(DATE_FORMAT_TIMESTAMP));
        createParkedRow = set(createParkedRow, 'end', getMomentTime(toDate).format(DATE_FORMAT_TIMESTAMP));
        createParkedRow = set(
            createParkedRow,
            'duration',
            getMomentTime(fromDate).valueOf() - getMomentTime(toDate).valueOf()
        );
        createParkedRow = set(createParkedRow, 'status', 'PARKED');
        createParkedRow = set(createParkedRow, 'distance', 0);
        createParkedRow = set(createParkedRow, 'vehicleId', vehicleId);
        createParkedRow = set(createParkedRow, 'startAddress', get(resp, 'data.address', '-'));
        createParkedRow = set(createParkedRow, 'endAddress', get(resp, 'data.address', '-'));
        createParkedRow = set(createParkedRow, 'startLat', get(resp, 'data.latitude', '-'));
        createParkedRow = set(createParkedRow, 'startLon', get(resp, 'data.longitude', '-'));
        createParkedRow = set(createParkedRow, 'endLat', get(resp, 'data.latitude', '-'));
        createParkedRow = set(createParkedRow, 'endLon', get(resp, 'data.longitude', '-'));
        mergedMovementPoints[0] = createParkedRow;
    }

    const includeLocation = get(filterConfig, 'includeMovementLocation', true);
    const includeDistance = get(filterConfig, 'includeMovementDistance', true);
    const includeDuration = get(filterConfig, 'includeMovementDuration', true);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);

    const reportName = createReportFilename(reportFormat, 'Movement', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const wDistance = 80,
        wDuration = 115;
    const wTime = includeLocation ? 100 : 200;
    const wLocation = 260;
    let wLocationOverflow = 0;
    wLocationOverflow += getWOverFlow(includeDistance, wDistance);
    wLocationOverflow += getWOverFlow(includeDuration, wDuration);
    wLocationOverflow /= 2;

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Status', 120, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Start Time', wTime, footerRow);
    noOfCols += addHeader(true, headers, 3, 'End Time', wTime, footerRow);
    noOfCols += addHeader(includeDuration, headers, 4, 'Duration', wDistance, footerRow);
    noOfCols += addHeader(includeLocation, headers, 5, 'Start Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeLocation, headers, 6, 'End Location', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(
        includeDistance,
        headers,
        7,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );
    noOfCols += addHeader(includeMinuteLatLon, headers, 8, 'Start Lat, Lon', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 9, 'Start Map Link', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 10, 'End Lat, Lon', wLocation + wLocationOverflow, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 11, 'End Map Link', wLocation + wLocationOverflow, footerRow);

    const data = [];
    let sumStopDuration = 0,
        sumIdleDuration = 0,
        sumRunningDuration = 0;
    const movementsLength = mergedMovementPoints.length;
    let totalRawOdometer = 0;
    map(mergedMovementPoints, ({ distance }) => {
        totalRawOdometer += distance ? distance : 0;
    });

    const odometerNormalizationFactor =
        !!totalActualOdometer && !!totalRawOdometer ? totalActualOdometer / totalRawOdometer : 1;

    map(mergedMovementPoints, (motionPoint, index) => {
        const end = index < movementsLength - 1 ? mergedMovementPoints[index + 1].start : motionPoint.end;
        const distance = motionPoint.distance ? motionPoint.distance : 0;
        let nearestAddressStart = '';
        let nearestAddressEnd = '';

        if (includeAddressBooks) {
            nearestAddressStart = getNearestAddressFromAddressbook(
                motionPoint.startLat,
                motionPoint.startLon,
                addressBook,
                true,
                loggedInUser
            );
            nearestAddressEnd = getNearestAddressFromAddressbook(
                motionPoint.endLat,
                motionPoint.endLon,
                addressBook,
                true,
                loggedInUser
            );
        }

        if (motionPoint.status === VEHICLE_STATUS.PARKED) {
            sumStopDuration += getMomentTime(end).diff(motionPoint.start);
        }
        if (motionPoint.status === VEHICLE_STATUS.IDLE) {
            sumIdleDuration += getMomentTime(end).diff(motionPoint.start);
        }
        if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
            sumRunningDuration += getMomentTime(end).diff(motionPoint.start);
        }
        const dataRow = {};
        addDataRow(true, dataRow, 1, `${toUpper(motionPoint.status)}`);
        addDataRow(true, dataRow, 2, getHumanizeTime(motionPoint.start, true, true));
        addDataRow(true, dataRow, 3, getHumanizeTime(end, true, true));
        addDataRow(
            includeDuration,
            dataRow,
            4,
            getTimeDiff(getMomentTime(end).diff(getMomentTime(motionPoint.start)), false, true)
        );
        addDataRow(
            includeLocation,
            dataRow,
            5,
            isInsideNearestAddress(nearestAddressStart)
                ? get(nearestAddressStart, 'addressName', '')
                : parseAddress(motionPoint.startAddress)
        );
        addDataRow(
            includeLocation,
            dataRow,
            6,
            isInsideNearestAddress(nearestAddressEnd)
                ? get(nearestAddressEnd, 'addressName', '')
                : parseAddress(motionPoint.endAddress)
        );
        addDataRow(
            includeDistance,
            dataRow,
            7,
            getTripOdo(
                distance * odometerNormalizationFactor,
                reportFormat === REPORT_FORMAT.PDF,
                reportFormat === REPORT_FORMAT.PDF
            )
        );
        addDataRow(
            includeMinuteLatLon,
            dataRow,
            8,
            motionPoint.startLat ? `${motionPoint.startLat}, ${motionPoint.startLon}` : '-'
        );
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            9,
            motionPoint.startLat
                ? getGoogleMapLink(motionPoint.startLat, motionPoint.startLon, reportFormat === REPORT_FORMAT.PDF)
                : '-'
        );
        addDataRow(
            includeMinuteLatLon,
            dataRow,
            10,
            motionPoint.endLat ? `${motionPoint.endLat}, ${motionPoint.endLon}` : '-'
        );
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            11,
            motionPoint.endLat
                ? getGoogleMapLink(motionPoint.endLat, motionPoint.endLon, reportFormat === REPORT_FORMAT.PDF)
                : '-'
        );
        data.push(dataRow);
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL`);
    addDataRow(true, totalRow, 2, '-');
    addDataRow(true, totalRow, 3, '-');
    addDataRow(
        includeDuration,
        totalRow,
        4,
        `Running Duration\n${getTimeDiff(sumRunningDuration, false, true)}\n\nIdle Duration\n${getTimeDiff(
            sumIdleDuration,
            false,
            true
        )}\n\nStop Duration\n${getTimeDiff(sumStopDuration, false, true)}`
    );
    addDataRow(includeLocation, totalRow, 5, '-');
    addDataRow(includeLocation, totalRow, 6, '-');
    addDataRow(
        includeDistance,
        totalRow,
        7,
        getTripOdo(totalActualOdometer, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
    );
    addDataRow(includeLocation, totalRow, 8, '-');
    addDataRow(includeLocation, totalRow, 9, '-');
    addDataRow(includeLocation, totalRow, 10, '-');
    addDataRow(includeLocation, totalRow, 11, '-');

    data.push(totalRow);
    //data.push(footerRow);
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        includeLocation ? ORIENTATION.LANDSCAPE : ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Movement Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}
export async function triggerBulkMovementReportDownload(
    vehicleListMiniAsMap,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    groupList
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }
    const vehicleIds = Object.keys(vehicleListMiniAsMap);
    const vehicleIdsLength = get(vehicleIds, 'length', 0);
    const allData = [];
    for (let i = 0; i < vehicleIdsLength; i++) {
        const from = fromDate.valueOf();
        const to = toDate.valueOf();

        const result = await fetchMovementPoints(accesstoken, from, to, vehicleIds[i]).promise;
        if (result.data) {
            let row = {};
            row.id = vehicleIds[i];
            row.result = result.data.length > 0 ? result.data : [];
            allData.push(row);
        }
        if (progressUpdateCallback) {
            progressUpdateCallback(((i + 1) * 100) / vehicleIdsLength);
        }
    }

    const reportName = createReportFilename(reportFormat, 'Bulk-Movement', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const width = 120;

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'vehicleNumber', 'Vehicle Number', 160, footerRow);
    noOfCols += addHeader(true, headers, 'runningTime', 'Running Time', width, footerRow);
    noOfCols += addHeader(true, headers, 'idleTime', 'Idle Time', width, footerRow);
    noOfCols += addHeader(true, headers, 'stopTime', 'Stop Time', width, footerRow);
    noOfCols += addHeader(true, headers, 'totalTime', 'Total Time', width, footerRow);
    noOfCols += addHeader(true, headers, 'groupName', 'Group Name', width, footerRow);
    noOfCols += addHeader(true, headers, 'licensePlate', 'License Plate', width, footerRow);

    const data = [];
    let sumStopDuration = 0,
        sumIdleDuration = 0,
        sumRunningDuration = 0;

    let dataRows = [];
    map(allData, (data, index) => {
        let dataRow = {};
        sumStopDuration = 0;
        sumIdleDuration = 0;
        sumRunningDuration = 0;
        map(data.result, (motionPoint, motionIndex) => {
            const end = motionPoint.end;
            if (motionPoint.status === VEHICLE_STATUS.PARKED) {
                sumStopDuration += getMomentTime(end).diff(motionPoint.start);
            }
            if (motionPoint.status === VEHICLE_STATUS.IDLE) {
                sumIdleDuration += getMomentTime(end).diff(motionPoint.start);
            }
            if (motionPoint.status === VEHICLE_STATUS.RUNNING) {
                sumRunningDuration += getMomentTime(end).diff(motionPoint.start);
            }
        });
        addDataRow(true, dataRow, 'vehicleNumber', get(vehicleListMiniAsMap[vehicleIds[index]], 'name', '-'));
        addDataRow(true, dataRow, 'runningTime', getTimeDiffHours(sumRunningDuration, true, true));
        addDataRow(true, dataRow, 'idleTime', getTimeDiffHours(sumIdleDuration, true, true));
        addDataRow(true, dataRow, 'stopTime', getTimeDiffHours(sumStopDuration, true, true));
        addDataRow(
            true,
            dataRow,
            'totalTime',
            getTimeDiffHours(sumStopDuration + sumRunningDuration + sumIdleDuration, true, true)
        );

        const groupId = get(vehicleListMiniAsMap[vehicleIds[index]], 'groupId', '');
        const groupName = groupId ? get(find(groupList, { id: groupId }), 'name', '') : '';
        addDataRow(true, dataRow, 'groupName', groupName);

        addDataRow(true, dataRow, 'licensePlate', get(vehicleListMiniAsMap[vehicleIds[index]], 'licensePlate', ''));

        dataRows.push(dataRow);
    });
    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '99CCFF' },
        },
    };

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        dataRows,
        reportFormat,
        'Bulk Movement Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}

export const checkMergedTripPayloadForAsset = (mergedTrips) => {
    if (mergedTrips.length > 1) {
        return false;
    } else if (get(mergedTrips, '0.trip')) {
        return false;
    } else if (get(mergedTrips, '0.routes')) {
        return true;
    }
    return false;
};

export async function triggerTripsMinuteLocationReportDownload(
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    addressBook,
    loggedInUser,
    isTemperatureDevice
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }
    const locationByMinuteTimeIntervalMinutes = get(filterConfig, 'locationByMinuteTimeIntervalMinutes', 1);

    const result = await fetchMergedTrips(
        accesstoken,
        fromDate.valueOf(),
        toDate.valueOf(),
        vehicleId,
        null,
        null,
        null,
        false
    ).promise;
    let assetData = [];
    const isAssetDataReport = checkMergedTripPayloadForAsset(result.data);
    if (isAssetDataReport) {
        assetData = get(result, 'data.0.routes');
    }
    const mergedTrips = orderBy(result.data, ({ trip }) => get(trip, 'sDate'), ['asc']);

    const includeTemp =
        (toDate.diff(fromDate, 'd') <= 15 ||
            (toDate.diff(fromDate, 'd') <= 31 && locationByMinuteTimeIntervalMinutes >= 15)) &&
        get(filterConfig, 'includeTemp', false);

    let routeRawTemperatureData = null,
        isTemperatureDataAvailable = false,
        currentTemperatureIndex = 0,
        tempDataLength;
    if (isTemperatureDevice && includeTemp) {
        const temperatureResult = await fetchRoutePointsWithType(
            accesstoken,
            fromDate.valueOf(),
            toDate.valueOf(),
            vehicleId,
            'GPS'
        ).promise;

        routeRawTemperatureData = sortListNew(get(temperatureResult, `data`, []), 'asc', 'timeStamp');
        isTemperatureDataAvailable = routeRawTemperatureData && routeRawTemperatureData.length > 0;
        tempDataLength = routeRawTemperatureData ? routeRawTemperatureData.length : 0;
    }

    let minuteArray = [];

    const mergedTripsLength = get(mergedTrips, 'length', 0);
    let startTime = fromDate.clone(),
        totalDistance = 0;

    const timeIntervalForTemp = 60; //minutes

    const getTemp = (time) => {
        if (includeTemp && isTemperatureDataAvailable) {
            while (
                currentTemperatureIndex < tempDataLength &&
                !getMomentTime(routeRawTemperatureData[currentTemperatureIndex].timeStamp).isSameOrAfter(time)
            ) {
                currentTemperatureIndex++;
            }
            if (
                currentTemperatureIndex < tempDataLength
                // &&getMomentTime(routeRawTemperatureData[currentTemperatureIndex].timeStamp).diff(time, 'm') <
                //     timeIntervalForTemp
            ) {
                return getVehicleTemperature(routeRawTemperatureData[currentTemperatureIndex]);
            } else {
                return 'N/A';
            }
        } else {
            return 'N/A';
        }
    };

    if (isAssetDataReport) {
        const includeMinuteStatus = get(filterConfig, 'includeMinuteStatus', true);
        const includeMinuteSpeed = get(filterConfig, 'includeMinuteSpeed', true);
        const locationByMinuteTimeIntervalMinutes = get(filterConfig, 'locationByMinuteTimeIntervalMinutes', 1);
        const includeMinuteDistance = get(filterConfig, 'includeMinuteDistance', true);
        const includeCumulativeDistance = get(filterConfig, 'includeCumulativeDistance', true);
        const includeLocation = get(filterConfig, 'includeMinuteLocation', false);
        const includeMinuteMapLink =
            reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
        const includeMinuteLatLon =
            reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);
        const includeNearestAddress =
            addressBook && addressBook.length > 0 && get(filterConfig, 'includeMinuteNearestAddress', true);
        const includeTemp = get(filterConfig, 'includeTemp', false);
        let locationsForGeocoding = {};

        if (includeLocation) {
            map(assetData, (route, index) => {
                if (route.latitude && route.longitude) {
                    locationsForGeocoding[index] = {
                        latitude: route.latitude,
                        longitude: route.longitude,
                        id: index,
                    };
                }
            });
            progressUpdateCallback(25);
            let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
                accesstoken,
                toArray(locationsForGeocoding)
            );
            progressUpdateCallback(50);
            let locationsBulk = {};
            map(locationsForGeocodingBulk, (location) => {
                locationsBulk[location.id] = location;
            });
            locationsForGeocoding = locationsBulk;
            const reportName = createReportFilename(
                reportFormat,
                'Location-By-Time',
                licensePlate,
                null,
                fromDate,
                toDate
            );
            const isCsv = reportFormat === REPORT_FORMAT.CSV;
            const headers = [];
            const footerRow = {};

            const wTime = 140,
                wLocation = 160,
                wNearestAddress = 160,
                wNearestAddressDistance = 60;
            const wStatus = 90,
                wSpeed = 60,
                wDistance = 110,
                wCumulativeDistanceTravelled = 130,
                wTemp = 60,
                wLink = 150;
            let wOverflow = 0;
            wOverflow += getWOverFlow(includeMinuteStatus, wStatus);
            wOverflow += getWOverFlow(includeMinuteSpeed, wSpeed);
            wOverflow += getWOverFlow(includeMinuteDistance, wDistance);
            wOverflow += getWOverFlow(includeCumulativeDistance, wCumulativeDistanceTravelled);
            wOverflow += getWOverFlow(includeLocation, wLocation);
            wOverflow += getWOverFlow(includeNearestAddress, wNearestAddress);
            wOverflow += getWOverFlow(includeNearestAddress, wNearestAddressDistance);
            wOverflow += getWOverFlow(includeMinuteMapLink, wLink);
            wOverflow += getWOverFlow(includeTemp, wTemp);
            wOverflow = floor(
                wOverflow /
                    (includeLocation +
                        2 * includeNearestAddress +
                        includeMinuteMapLink +
                        includeTemp +
                        includeCumulativeDistance +
                        includeMinuteDistance)
            );
            let noOfCols = 0;
            noOfCols += addHeader(true, headers, 1, 'Time', wTime, footerRow);
            noOfCols += addHeader(includeLocation, headers, 2, 'Location', wLocation + wOverflow, footerRow);
            noOfCols += addHeader(
                includeNearestAddress,
                headers,
                3,
                'Nearest Address',
                wNearestAddress + wOverflow,
                footerRow
            );
            noOfCols += addHeader(
                includeNearestAddress,
                headers,
                4,
                'Distance from\nAddress',
                wNearestAddressDistance + wOverflow,
                footerRow
            );
            noOfCols += addHeader(includeMinuteStatus, headers, 5, 'Status', wStatus, footerRow);
            noOfCols += addHeader(
                includeMinuteSpeed,
                headers,
                6,
                `Speed${reportFormat === REPORT_FORMAT.CSV ? ' (km/h)' : ''}`,
                wSpeed,
                footerRow
            );
            noOfCols += addHeader(
                includeMinuteDistance,
                headers,
                7,
                `Distance Travelled${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
                wDistance,
                footerRow
            );

            noOfCols += addHeader(
                includeCumulativeDistance,
                headers,
                'cumulativeDistanceTravelled',
                `Cumulative Distance ${reportFormat === REPORT_FORMAT.CSV ? 'Travelled (km)' : ''}`,
                wCumulativeDistanceTravelled,
                footerRow
            );

            noOfCols += addHeader(includeMinuteMapLink, headers, 8, 'Map Link', wLink + wOverflow, footerRow);
            noOfCols += addHeader(includeMinuteLatLon, headers, 9, 'Lat/Lon', wLink + wOverflow, footerRow);
            noOfCols += addHeader(includeTemp, headers, 'temp', 'Temp (°C)', wTemp, footerRow);

            const data = [];
            let cumulativeDistance = 0;
            map(assetData, (minutePoint, index) => {
                let address = '';
                if (locationsForGeocoding[index] && locationsForGeocoding[index].address) {
                    address = locationsForGeocoding[index].address;
                }
                const processedAddress = getAddressFromAddressbookOrGeoCodedLocations(
                    null,
                    address,
                    VEHICLE_STATUS.PARKED,
                    minutePoint.latitude,
                    minutePoint.longitude,
                    addressBook
                );
                const nearestAddress = getNearestAddressFromAddressbook(
                    minutePoint.latitude,
                    minutePoint.longitude,
                    addressBook,
                    true,
                    loggedInUser
                );
                address = processedAddress ? processedAddress : address;
                const dataRow = {};
                addDataRow(true, dataRow, 1, getReportTime(minutePoint.timeStamp));
                addDataRow(includeLocation, dataRow, 2, parseAddress(address));
                addDataRow(includeNearestAddress, dataRow, 3, nearestAddress ? nearestAddress.addressText : '-');
                addDataRow(includeNearestAddress, dataRow, 4, nearestAddress ? nearestAddress.addressDistance : '-');
                minutePoint.currentStatus = minutePoint.status;
                addDataRow(includeMinuteStatus, dataRow, 5, getCurrentVehicleStatus(minutePoint));
                addDataRow(
                    includeMinuteSpeed,
                    dataRow,
                    6,
                    `${round(minutePoint.speed)}${reportFormat === REPORT_FORMAT.PDF ? ' km/h' : ''}`
                );
                addDataRow(
                    includeMinuteStatus,
                    dataRow,
                    7,
                    round(get(minutePoint, 'otherAttributes.tripOdometer', 0), 2)
                );
                addDataRow(
                    includeMinuteStatus,
                    dataRow,
                    'cumulativeDistanceTravelled',
                    round(get(minutePoint, 'otherAttributes.tripOdometer', 0), 2)
                );
                addDataRow(
                    includeMinuteMapLink,
                    dataRow,
                    8,
                    getGoogleMapLink(minutePoint.latitude, minutePoint.longitude, reportFormat === REPORT_FORMAT.PDF)
                );
                addDataRow(
                    includeMinuteLatLon,
                    dataRow,
                    9,
                    `${round(minutePoint.latitude, 6)},${round(minutePoint.longitude, 6)}`
                );
                data.push(dataRow);
            });
            const orientation = ORIENTATION.LANDSCAPE;
            startReportCreation(
                orientation,
                headers,
                data,
                reportFormat,
                'Location by Time Report',
                fromDate,
                toDate,
                licensePlate,
                reportName,
                noOfCols,
                null,
                null,
                false,
                false,
                0,
                null, // border Styles for all cells
                false
            );

            if (progressUpdateCallback) {
                progressUpdateCallback(100);
            }
            return;
        }
    } else {
        map(mergedTrips, ({ trip, routes }, index) => {
            if (index === 0) {
                //create parked points before the start of first trip
                while (getMomentTime(trip.sDate).diff(startTime, 'minute') >= 0) {
                    const parkedMinutePoint = {};
                    parkedMinutePoint.currentStatus = VEHICLE_STATUS.PARKED;
                    parkedMinutePoint.speed = 0;
                    parkedMinutePoint.time = getHumanizeTime(startTime, true, true);
                    parkedMinutePoint.address = trip.sAddress;
                    parkedMinutePoint.latitude = trip.sLat;
                    parkedMinutePoint.longitude = trip.sLon;
                    parkedMinutePoint.distance = totalDistance;
                    parkedMinutePoint.temp = getTemp(startTime);
                    minuteArray.push(parkedMinutePoint);
                    startTime.add(locationByMinuteTimeIntervalMinutes, 'minutes');
                }
            }

            let minutePointsForTrip = getMinutePointsFromRoutes(
                trip,
                routes,
                locationByMinuteTimeIntervalMinutes,
                startTime,
                totalDistance
            );
            if (minutePointsForTrip && minutePointsForTrip.length > 0) {
                map(minutePointsForTrip, (minutePoint) => {
                    if (toDate.diff(getMomentTime(get(minutePoint, 'timeStamp'))) >= 0) {
                        minutePoint.temp = getTemp(minutePoint.timeStamp);
                        minuteArray.push(minutePoint);
                    }
                });

                // minuteArray = [...minuteArray, ...minutePointsForTrip];
                startTime = getMomentTime(get(last(minuteArray), 'timeStamp')).add(
                    locationByMinuteTimeIntervalMinutes,
                    'minutes'
                );
                totalDistance = last(minutePointsForTrip).distance;
            } else {
                totalDistance += get(trip, 'tOdo', 0);
            }

            if (trip.eDate && toDate.diff(getMomentTime(trip.eDate)) >= 0) {
                //create parked points after the end of each trip
                const nextMomentTime =
                    index === mergedTripsLength - 1 ? toDate : getMomentTime(mergedTrips[index + 1].trip.sDate);

                while (nextMomentTime.diff(startTime, 'minute') >= 0) {
                    const parkedMinutePoint = {};
                    parkedMinutePoint.currentStatus = VEHICLE_STATUS.PARKED;
                    parkedMinutePoint.speed = 0;
                    parkedMinutePoint.time = getHumanizeTime(startTime, true, true);
                    parkedMinutePoint.address = trip.eAddress;
                    parkedMinutePoint.latitude = trip.eLat;
                    parkedMinutePoint.longitude = trip.eLon;
                    parkedMinutePoint.distance = totalDistance;
                    parkedMinutePoint.temp = getTemp(startTime);
                    minuteArray.push(parkedMinutePoint);
                    startTime.add(locationByMinuteTimeIntervalMinutes, 'minutes');
                }
            }
        });
    }
    downloadTripsMinuteLocationReportDownload(
        minuteArray,
        vehicleId,
        aggregations,
        reportFormat,
        licensePlate,
        fromDate,
        toDate,
        filterConfig,
        accesstoken,
        progressUpdateCallback,
        addressBook,
        loggedInUser,
        totalDistance,
        true
    );
}

export async function triggerTripsMinuteLocationReportDownloadFromRawData(
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    addressBook,
    loggedInUser
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }

    const result = await fetchAssetMovementDetails(accesstoken, fromDate.valueOf(), toDate.valueOf(), vehicleId)
        .promise;

    const rawData = filterAssetMovementPoints(result.data, true);

    let startTime = fromDate.clone();

    const locationByMinuteTimeIntervalMinutes = get(filterConfig, 'locationByMinuteTimeIntervalMinutes', 1);

    const minuteArray = getMinutePointsFromRoutes(null, rawData, locationByMinuteTimeIntervalMinutes, startTime, 0);

    //fill time gap
    const filledNinuteArray = [];
    let prevItem = null;
    let curMomentTime = null;
    forEach(minuteArray, (item, index) => {
        curMomentTime = getMomentTime(item.timeStamp);

        if (index > 0) {
            if (curMomentTime.diff(prevItem.timeStamp, 'm') > locationByMinuteTimeIntervalMinutes) {
                while (curMomentTime.diff(prevItem.timeStamp, 'm') > locationByMinuteTimeIntervalMinutes) {
                    const newTime = getMomentTime(prevItem.timeStamp).add(locationByMinuteTimeIntervalMinutes, 'm');
                    const newItem = {
                        ...prevItem,
                        time: getHumanizeTime(newTime, true, true),
                        timeStamp: newTime.format(DATE_FORMAT_TIMESTAMP),
                        fillGapPoint: true,
                    };
                    filledNinuteArray.push(newItem);
                    prevItem = newItem;
                }
                filledNinuteArray.push(item);
                prevItem = item;
            } else {
                filledNinuteArray.push(item);
                prevItem = item;
            }
        } else {
            filledNinuteArray.push(item);
            prevItem = item;
        }
    });
    const totalDistance = get(last(filledNinuteArray), 'totalDistance', 0);

    downloadTripsMinuteLocationReportDownload(
        filledNinuteArray,
        vehicleId,
        aggregations,
        reportFormat,
        licensePlate,
        fromDate,
        toDate,
        filterConfig,
        accesstoken,
        progressUpdateCallback,
        addressBook,
        loggedInUser,
        totalDistance,
        false
    );
}

export async function downloadTripsMinuteLocationReportDownload(
    minuteArray,
    vehicleId,
    aggregations,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    addressBook,
    loggedInUser,
    totalDistance,
    showDiffDistance
) {
    const includeMinuteStatus = get(filterConfig, 'includeMinuteStatus', true);
    const includeMinuteSpeed = get(filterConfig, 'includeMinuteSpeed', true);
    const locationByMinuteTimeIntervalMinutes = get(filterConfig, 'locationByMinuteTimeIntervalMinutes', 1);
    const includeMinuteDistance = get(filterConfig, 'includeMinuteDistance', true);
    const includeCumulativeDistance = get(filterConfig, 'includeCumulativeDistance', true);
    const includeLocation = get(filterConfig, 'includeMinuteLocation', false);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);
    const includeNearestAddress =
        addressBook && addressBook.length > 0 && get(filterConfig, 'includeMinuteNearestAddress', true);
    const includeTemp = get(filterConfig, 'includeTemp', false);

    let locationsForGeocoding = {};
    //create locations fo geocoding
    if (includeLocation) {
        map(minuteArray, ({ address, latitude, longitude }, index) => {
            if (!address && latitude && longitude) {
                locationsForGeocoding[index] = {
                    latitude,
                    longitude,
                    id: index,
                };
            }
        });

        progressUpdateCallback(25);
        const locations = toArray(locationsForGeocoding);
        const totalPoints = get(locations, 'length', 0);
        const totalCallsNeeded = totalPoints / BULK_GEOCODE_COUNT;
        let locationsForGeocodingBulk = [];
        for (let i = 0; i < totalCallsNeeded; i++) {
            if (i < 50) {
                progressUpdateCallback(25 + i);
            }
            let pageWiseLocation = await getGeoCodedLocationsFromBackendInBulk(
                accesstoken,
                lodashSlice(locations, i * BULK_GEOCODE_COUNT, (+i + 1) * BULK_GEOCODE_COUNT)
            );
            if (!isEmpty(pageWiseLocation)) {
                locationsForGeocodingBulk.push(...pageWiseLocation);
            }
        }

        // let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
        //     accesstoken,
        //     toArray(locationsForGeocoding)
        // );
        progressUpdateCallback(50);
        let locationsBulk = {};
        map(locationsForGeocodingBulk, (location) => {
            locationsBulk[location.id] = location;
        });
        locationsForGeocoding = locationsBulk;
    }

    const reportName = createReportFilename(reportFormat, 'Location-By-Time', licensePlate, null, fromDate, toDate);
    const isCsv = reportFormat === REPORT_FORMAT.CSV;
    const headers = [];
    const footerRow = {};

    const wTime = 140,
        wLocation = 160,
        wNearestAddress = 160,
        wNearestAddressDistance = 60;
    const wStatus = 90,
        wSpeed = 60,
        wDistance = 110,
        wCumulativeDistanceTravelled = 130,
        wTemp = 60,
        wLink = 150;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeMinuteStatus, wStatus);
    wOverflow += getWOverFlow(includeMinuteSpeed, wSpeed);
    wOverflow += getWOverFlow(includeMinuteDistance, wDistance);
    wOverflow += getWOverFlow(includeCumulativeDistance, wCumulativeDistanceTravelled);
    wOverflow += getWOverFlow(includeLocation, wLocation);
    wOverflow += getWOverFlow(includeNearestAddress, wNearestAddress);
    wOverflow += getWOverFlow(includeNearestAddress, wNearestAddressDistance);
    wOverflow += getWOverFlow(includeMinuteMapLink, wLink);
    wOverflow += getWOverFlow(includeTemp, wTemp);
    wOverflow = floor(
        wOverflow /
            (includeLocation +
                2 * includeNearestAddress +
                includeMinuteMapLink +
                includeTemp +
                includeCumulativeDistance +
                includeMinuteDistance)
    );

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Time', wTime, footerRow);
    noOfCols += addHeader(includeLocation, headers, 2, 'Location', wLocation + wOverflow, footerRow);
    noOfCols += addHeader(includeNearestAddress, headers, 3, 'Nearest Address', wNearestAddress + wOverflow, footerRow);
    noOfCols += addHeader(
        includeNearestAddress,
        headers,
        4,
        'Distance from\nAddress',
        wNearestAddressDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeMinuteStatus, headers, 5, 'Status', wStatus, footerRow);
    noOfCols += addHeader(
        includeMinuteSpeed,
        headers,
        6,
        `Speed${reportFormat === REPORT_FORMAT.CSV ? ' (km/h)' : ''}`,
        wSpeed,
        footerRow
    );
    noOfCols += addHeader(
        includeMinuteDistance,
        headers,
        7,
        `Distance Travelled${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance,
        footerRow
    );

    noOfCols += addHeader(
        includeCumulativeDistance,
        headers,
        'cumulativeDistanceTravelled',
        `Cumulative Distance ${reportFormat === REPORT_FORMAT.CSV ? 'Travelled (km)' : ''}`,
        wCumulativeDistanceTravelled,
        footerRow
    );

    noOfCols += addHeader(includeMinuteMapLink, headers, 8, 'Map Link', wLink + wOverflow, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 9, 'Lat/Lon', wLink + wOverflow, footerRow);
    noOfCols += addHeader(includeTemp, headers, 'temp', 'Temp (°C)', wTemp, footerRow);

    const data = [];
    let cumulativeDistance = 0;
    map(minuteArray, (minutePoint, index) => {
        let distance =
            index == 0 || !showDiffDistance
                ? minutePoint.distance
                : minutePoint.distance - minuteArray[index - 1].distance;
        // fillGapPoint are points created to fill time gap. Adding there distance causes bug in cumulative distance
        if (minutePoint.fillGapPoint) {
            distance = 0;
        }
        let address = minutePoint.address;
        if (!address && locationsForGeocoding[index] && locationsForGeocoding[index].address) {
            address = locationsForGeocoding[index].address;
        }
        const processedAddress = getAddressFromAddressbookOrGeoCodedLocations(
            null,
            address,
            VEHICLE_STATUS.PARKED,
            minutePoint.latitude,
            minutePoint.longitude,
            addressBook
        );
        const nearestAddress = getNearestAddressFromAddressbook(
            minutePoint.latitude,
            minutePoint.longitude,
            addressBook,
            true,
            loggedInUser
        );
        address = processedAddress ? processedAddress : address;
        const dataRow = {};
        addDataRow(true, dataRow, 1, minutePoint.time);
        addDataRow(includeLocation, dataRow, 2, parseAddress(address));
        addDataRow(includeNearestAddress, dataRow, 3, nearestAddress ? nearestAddress.addressText : '-');
        addDataRow(includeNearestAddress, dataRow, 4, nearestAddress ? nearestAddress.addressDistance : '-');
        addDataRow(includeMinuteStatus, dataRow, 5, getCurrentVehicleStatus(minutePoint));
        addDataRow(
            includeMinuteSpeed,
            dataRow,
            6,
            `${round(minutePoint.speed)}${reportFormat === REPORT_FORMAT.PDF ? ' km/h' : ''}`
        );

        cumulativeDistance += distance;

        addDataRow(
            includeMinuteDistance,
            dataRow,
            7,
            `${getTripOdo(distance, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)}`
        );

        addDataRow(
            includeCumulativeDistance,
            dataRow,
            'cumulativeDistanceTravelled',
            `${getTripOdo(cumulativeDistance, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)}`
        );

        addDataRow(
            includeMinuteMapLink,
            dataRow,
            8,
            getGoogleMapLink(minutePoint.latitude, minutePoint.longitude, reportFormat === REPORT_FORMAT.PDF)
        );
        addDataRow(
            includeMinuteLatLon,
            dataRow,
            9,
            `${round(minutePoint.latitude, 6)},${round(minutePoint.longitude, 6)}`
        );
        addDataRow(includeTemp, dataRow, 'temp', `${get(minutePoint, 'temp', '-')}`);
        data.push(dataRow);
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `-`);
    addDataRow(includeLocation, totalRow, 2, '-');
    addDataRow(includeNearestAddress, totalRow, 3, '-');
    addDataRow(includeNearestAddress, totalRow, 4, '-');
    addDataRow(includeMinuteStatus, totalRow, 5, '-');
    addDataRow(includeMinuteSpeed, totalRow, 6, '-');
    addDataRow(
        includeMinuteDistance,
        totalRow,
        7,
        `${getTripOdo(totalDistance, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)}`
    );
    addDataRow(includeMinuteMapLink, totalRow, 8, '-');
    addDataRow(includeMinuteMapLink, totalRow, 9, '-');
    addDataRow(includeTemp, totalRow, 'temp', '-');
    // data.push(totalRow);
    //data.push(footerRow);

    const orientation = ORIENTATION.LANDSCAPE;
    startReportCreation(
        orientation,
        headers,
        data,
        reportFormat,
        'Location by Time Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        null,
        null,
        false,
        false,
        0,
        null, // border Styles for all cells
        false
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}

export async function triggerSummaryReportDownload(
    graphData,
    aggregations,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    filterConfig,
    loggedInUser
) {
    const frequency = getGraphFrequency(getEndDate(toDate).diff(fromDate, 'days'));

    const title = getFrequencyTitle(frequency);
    const reportName = createReportFilename(
        reportFormat,
        `${title}-Summary`,
        licensePlate,
        groupName,
        fromDate,
        toDate
    );
    const headers = [];
    const footerRow = {};

    const includeSummaryDistance = get(filterConfig, 'includeSummaryDistance', true);
    const includeSummaryDuration = get(filterConfig, 'includeSummaryDuration', true);
    const includeSummaryMileage = get(filterConfig, 'includeSummaryMileage', true);
    const includeSummaryFuel = get(filterConfig, 'includeSummaryFuel', true);
    const includeFuelUnit = includeSummaryFuel && reportFormat === REPORT_FORMAT.CSV;
    const includeSummaryAlarm = get(filterConfig, 'includeSummaryAlarm', true);
    const includeSummaryDtc = get(filterConfig, 'includeSummaryDtc', true);

    const wDistance = 90,
        wDuration = 120,
        wMileage = 110,
        wFuel = 130,
        wAlarms = 70,
        wLatLon = 70,
        wMapLink = 140,
        wDtc = 60;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeSummaryDistance, wDistance);
    wOverflow += getWOverFlow(includeSummaryDuration, wDuration);
    wOverflow += getWOverFlow(includeSummaryMileage, wMileage);
    wOverflow += getWOverFlow(includeSummaryFuel, wFuel);
    wOverflow += getWOverFlow(includeSummaryAlarm, wAlarms);
    wOverflow += getWOverFlow(includeSummaryDtc, wDtc);
    wOverflow = floor(
        wOverflow /
            (0 +
                includeSummaryDistance +
                includeSummaryDuration +
                includeSummaryMileage +
                includeSummaryFuel +
                includeSummaryAlarm +
                includeSummaryDtc)
    );

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, ' ', 100, footerRow);
    noOfCols += addHeader(
        includeSummaryDistance,
        headers,
        2,
        `Distance${reportFormat === REPORT_FORMAT.CSV ? ' (km)' : ''}`,
        wDistance + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeSummaryDuration, headers, 3, 'Duration', wDuration + wOverflow, footerRow);
    noOfCols += addHeader(includeSummaryMileage, headers, 4, 'Mileage', wMileage + wOverflow, footerRow);
    noOfCols += addHeader(includeSummaryFuel, headers, 5, 'Fuel', wFuel + wOverflow, footerRow);
    noOfCols += addHeader(includeFuelUnit, headers, 6, 'Fuel Type', 80, footerRow);
    noOfCols += addHeader(includeSummaryAlarm, headers, 7, 'Alarms', wAlarms + wOverflow, footerRow);
    noOfCols += addHeader(includeSummaryDtc, headers, 8, 'DTCs', wDtc + wOverflow, footerRow);

    const data = [];
    let sumDuration = 0;

    const mergedGraphData = [
        ...get(graphData, GET_FUEL_TYPE(loggedInUser).DIESEL.value, []),
        ...get(graphData, GET_FUEL_TYPE(loggedInUser).CNG.value, []),
        ...get(graphData, GET_FUEL_TYPE(loggedInUser).PETROL.value, []),
    ];
    const allGraphData = orderBy(mergedGraphData, ['year', 'month', 'day', 'hour']);
    map(allGraphData, ({ day, month, year, todo, duration, mileage, fuelType, tfuel, alarm, dtc }, index) => {
        sumDuration += duration;
        let showNonFuelData = true;
        if (index > 0) {
            const diff = getMomentTimeFromObject({ day, month, year }).diff(
                getMomentTimeFromObject(allGraphData[index - 1])
            );
            if (diff === 0) {
                showNonFuelData = false;
            }
        }
        const dataRow = {};
        const momentDate = getMomentTimeFromObject({ day, month, year });
        addDataRow(true, dataRow, 1, `${momentDate.format(DATE_FORMAT_HUMANIZE_DAY)}`);
        addDataRow(
            includeSummaryDistance,
            dataRow,
            2,
            showNonFuelData
                ? getTripOdo(todo, reportFormat === REPORT_FORMAT.PDF, reportFormat === REPORT_FORMAT.PDF)
                : ''
        );
        addDataRow(includeSummaryDuration, dataRow, 3, showNonFuelData ? getTimeDiff(duration, false, true) : '');
        addDataRow(
            includeSummaryMileage,
            dataRow,
            4,
            `${round(mileage, 2)} ${getFuelUnit(fuelType, loggedInUser)}\n(${getFuelName(fuelType, loggedInUser)})`
        );
        addDataRow(
            includeSummaryFuel,
            dataRow,
            5,
            `${getFuelString(tfuel, fuelType, reportFormat === REPORT_FORMAT.PDF, loggedInUser)}${
                reportFormat === REPORT_FORMAT.PDF ? `\n(${getFuelName(fuelType, loggedInUser)})` : ''
            }`
        );
        addDataRow(
            includeFuelUnit,
            dataRow,
            6,
            `${getFuelName(fuelType, loggedInUser)} (${getFuelVolume(fuelType, loggedInUser)})`
        );
        addDataRow(includeSummaryAlarm, dataRow, 7, showNonFuelData ? alarm : '');
        addDataRow(includeSummaryDtc, dataRow, 8, showNonFuelData ? dtc : '');
        data.push(dataRow);
    });

    const analyticsData = getFuelData(aggregations);
    const totalRow = {};

    addDataRow(true, totalRow, 1, `TOTAL`);
    addDataRow(
        includeSummaryDistance,
        totalRow,
        2,
        getTripOdo(
            get(find(analyticsData, ['type', 'totalOdometer']), 'count', 0),
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF,
            reportFormat === REPORT_FORMAT.PDF
        )
    );
    addDataRow(includeSummaryDuration, totalRow, 3, '-');

    if (includeSummaryMileage || includeSummaryFuel) {
        const dataPetrol = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).PETROL.value);
        const dataDiesel = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).DIESEL.value);
        const dataCNG = processFuelData(aggregations, GET_FUEL_TYPE(loggedInUser).CNG.value);
        let avgMileageString = '',
            fuelString = '';
        if (dataPetrol) {
            avgMileageString += `${round(dataPetrol.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).PETROL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataPetrol.consumption.count,
                GET_FUEL_TYPE(loggedInUser).PETROL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).PETROL, loggedInUser)})\n\n`;
        }
        if (dataDiesel) {
            avgMileageString += `${round(dataDiesel.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
            fuelString += `${getFuelString(
                dataDiesel.consumption.count,
                GET_FUEL_TYPE(loggedInUser).DIESEL,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).DIESEL, loggedInUser)})\n\n`;
        }
        if (dataCNG) {
            avgMileageString += `${round(dataCNG.mileage.count, 2)} ${getFuelUnit(
                GET_FUEL_TYPE(loggedInUser).CNG,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
            fuelString += `${getFuelString(
                dataCNG.consumption.count,
                GET_FUEL_TYPE(loggedInUser).CNG,
                true,
                loggedInUser
            )}\n(${getFuelName(GET_FUEL_TYPE(loggedInUser).CNG, loggedInUser)})`;
        }

        if (isEmpty(avgMileageString)) {
            avgMileageString = '-';
        }
        if (isEmpty(fuelString)) {
            fuelString = '-';
        }

        addDataRow(includeSummaryMileage, totalRow, 4, avgMileageString);
        addDataRow(includeSummaryFuel, totalRow, 5, fuelString);
        addDataRow(includeFuelUnit, totalRow, 6, '');
    }

    addDataRow(includeSummaryAlarm, totalRow, 7, get(find(analyticsData, ['type', 'alarm']), 'count', 0) + '');
    addDataRow(includeSummaryDtc, totalRow, 8, get(find(analyticsData, ['type', 'dtc']), 'count', 0) + '');

    data.push(totalRow);
    //data.push(footerRow);

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        `${title} Summary Report`,
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        isEmpty(licensePlate) ? 'All Vehicles' : ''
    );
}

export async function triggerTripsSpeedingReportDownload(
    vehicleId,
    speedingViolationsList,
    reportFormat,
    licensePlate,
    fromDate,
    toDate,
    filterConfig,
    accesstoken,
    progressUpdateCallback,
    loggedInUser
) {
    if (progressUpdateCallback) {
        progressUpdateCallback(1);
    }
    let locationsForGeocoding = {};

    const includeLocation = get(filterConfig, 'includeSpeedingLocation', true);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);

    const length = speedingViolationsList.length;
    const maxSpeed = round(max(map(speedingViolationsList, ({ speed }) => speed)));

    map(speedingViolationsList, ({ latitude, longitude }, index) => {
        locationsForGeocoding[index] = {
            latitude,
            longitude,
            id: index,
        };
    });

    // BATCH FETCH GEO CODEED LOCATIONS FOR ALL LOCATIONS
    if (includeLocation) {
        progressUpdateCallback(25);
        let locationsForGeocodingBulk = await getGeoCodedLocationsFromBackendInBulk(
            accesstoken,
            toArray(locationsForGeocoding)
        );
        progressUpdateCallback(50);
        let locationsBulk = {};
        map(locationsForGeocodingBulk, (location) => {
            locationsBulk[location.id] = location;
        });
        locationsForGeocoding = locationsBulk;
    }

    const reportName = createReportFilename(reportFormat, 'Speed-Violations', licensePlate, null, fromDate, toDate);

    const headers = [];
    const footerRow = {};

    const wTime = 160,
        wSpeed = 100;
    const wLocation = 430;
    const wLatLon = 70,
        wMapLink = 140;
    let wOverflow = 0;
    wOverflow += getWOverFlow(includeLocation, wLocation);
    wOverflow = floor(wOverflow / (2 + includeLocation));

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Time', wTime + wOverflow, footerRow);
    noOfCols += addHeader(
        true,
        headers,
        2,
        `Speed${reportFormat === REPORT_FORMAT.CSV ? ' (km/h)' : ''}`,
        wSpeed + wOverflow,
        footerRow
    );
    noOfCols += addHeader(includeLocation, headers, 3, 'Location', wLocation + wOverflow, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 4, 'Speeding Lat,Lon', wLatLon, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 5, 'Speeding Map Link', wMapLink, footerRow);

    const data = [];
    map(speedingViolationsList, ({ timeStamp, speed, latitude, longitude }, index) => {
        const dataRow = {};
        addDataRow(true, dataRow, 1, getHumanizeTime(timeStamp, false, false, true));
        addDataRow(true, dataRow, 2, `${round(speed)}${reportFormat === REPORT_FORMAT.PDF ? ' km/h' : ''}`);
        addDataRow(includeLocation, dataRow, 3, parseAddress(locationsForGeocoding[index].address));
        addDataRow(includeMinuteLatLon, dataRow, 4, `${latitude}, ${longitude}`);
        addDataRow(
            includeMinuteMapLink,
            dataRow,
            5,
            getGoogleMapLink(latitude, longitude, reportFormat === REPORT_FORMAT.PDF)
        );
        data.push(dataRow);
    });

    const totalRow = {};
    addDataRow(true, totalRow, 1, `TOTAL\n${length} Speeding Violations`);
    addDataRow(true, totalRow, 2, `Max. Speed\n${maxSpeed} km/h`);

    addDataRow(includeLocation, totalRow, 3, '-');
    addDataRow(includeMinuteLatLon, totalRow, 4, '-');
    addDataRow(includeMinuteMapLink, totalRow, 5, '-');

    data.push(totalRow);
    //data.push(footerRow);

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
        // fill: {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: '99CCFF' },
        // },
    };

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'Speed Violations Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );

    if (progressUpdateCallback) {
        progressUpdateCallback(100);
    }
}

export async function triggerFirstTripReportDownload(
    tripList,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    filterConfig
) {
    if (!tripList || tripList.length <= 0) {
        return;
    }
    const reportName = createReportFilename(reportFormat, 'First-Run', licensePlate, groupName, fromDate, toDate);
    const headers = [];
    const footerRow = {};

    const includeEndTime = get(filterConfig, 'includeFirstTripEndTime', true);
    const includeMinuteMapLink = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteMapLink', false);
    const includeMinuteLatLon = reportFormat === REPORT_FORMAT.CSV && get(filterConfig, 'includeMinuteLatLon', false);

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 1, 'Vehicle', 300, footerRow);
    noOfCols += addHeader(true, headers, 2, 'Start Time', 200, footerRow);
    noOfCols += addHeader(includeEndTime, headers, 3, 'End Time', 200, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 4, 'Start Lat,Lon', 70, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 5, 'Start Map Link', 140, footerRow);
    noOfCols += addHeader(includeMinuteLatLon, headers, 6, 'End Lat,Lon', 70, footerRow);
    noOfCols += addHeader(includeMinuteMapLink, headers, 7, 'End Map Link', 140, footerRow);

    const data = [];
    const addedVehicles = [];
    map(
        orderBy(tripList, ['sDate'], ['asc']),
        ({ eDate, sDate, vehicleName, vehicleNumber, driverName, sLat, sLon, eLat, eLon }) => {
            if (!eDate) {
                return;
            }
            if (includes(addedVehicles, vehicleNumber)) {
                return;
            }
            const dataRow = {};
            addDataRow(true, dataRow, 1, `${vehicleName}\n${vehicleNumber}\nDriver: ${driverName}`);
            addDataRow(true, dataRow, 2, getHumanizeTime(sDate, true, true));
            addDataRow(includeEndTime, dataRow, 3, getHumanizeTime(eDate, true, true));
            addDataRow(includeMinuteLatLon, dataRow, 4, `${sLat}, ${sLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                5,
                getGoogleMapLink(sLat, sLon, reportFormat === REPORT_FORMAT.PDF)
            );
            addDataRow(includeMinuteLatLon, dataRow, 6, `${eLat}, ${eLon}`);
            addDataRow(
                includeMinuteMapLink,
                dataRow,
                7,
                getGoogleMapLink(eLat, eLon, reportFormat === REPORT_FORMAT.PDF)
            );
            data.push(dataRow);
            addedVehicles.push(vehicleNumber);
        }
    );

    const totalRow = {};

    addDataRow(true, totalRow, 1, `TOTAL\n${addedVehicles.length} Vehicles`);
    addDataRow(true, totalRow, 2, '-');
    addDataRow(includeEndTime, totalRow, 3, '-');
    addDataRow(includeMinuteLatLon, totalRow, 4, '-');
    addDataRow(includeMinuteMapLink, totalRow, 5, '-');
    addDataRow(includeMinuteLatLon, totalRow, 6, '-');
    addDataRow(includeMinuteMapLink, totalRow, 7, '-');

    data.push(totalRow);
    //data.push(footerRow);

    const headerStyles = {
        font: { name: 'Arial', family: 2, size: 12, bold: true },
        alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' },
    };

    startReportCreation(
        ORIENTATION.POTRAIT,
        headers,
        data,
        reportFormat,
        'First Run Report',
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        '',
        '',
        '',
        '',
        '',
        {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
        true,
        '',
        headerStyles,
        true
    );
}

export async function triggerTripsBetweenAddressesReportDownload(
    trips,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    addressFrom,
    addressTo,
    filterConfig,
    addressBookMap
) {
    if (!trips || trips.length < 1) {
        return;
    }

    const source = get(addressBookMap[addressFrom], 'name', 'source');
    const dest = get(addressBookMap[addressTo], 'name', 'dest');

    const reportName = createReportFilename(
        reportFormat,
        `trips_between_${source}_and_${dest}`,
        licensePlate,
        groupName,
        fromDate,
        toDate
    );

    const headers = [];
    const data = [];
    let wVehicle = 150,
        wRunningTime = 120,
        wStopTime = 120,
        wTotalTime = 110,
        wDT = 180,
        wStartTime = 120,
        wEndTime = 120;

    const includeRunningTime = get(filterConfig, 'includeRunningTime', true);
    const includeStopTime = get(filterConfig, 'includeStopTime', true);
    const includeDistanceTravelled = get(filterConfig, 'includeDistanceTravelled', true);
    const includeStartTime = get(filterConfig, 'includeStartTime', true);
    const includeEndTime = get(filterConfig, 'includeEndTime', true);

    const includeTotalTime = includeRunningTime && includeStopTime;

    let wOverflow = 0;
    wOverflow += getWOverFlow(includeRunningTime, wRunningTime);
    wOverflow += getWOverFlow(includeStopTime, wStopTime);
    wOverflow += getWOverFlow(includeDistanceTravelled, wDT);
    wOverflow += getWOverFlow(includeStartTime, wDT);
    wOverflow += getWOverFlow(includeEndTime, wDT);

    wOverflow = floor(
        wOverflow /
            (1 + includeRunningTime + includeStopTime + includeDistanceTravelled + includeStartTime + includeEndTime)
    );

    let noOfCols = 0;
    noOfCols += addHeader(true, headers, 'vehicle', 'Vehicle', wVehicle + wOverflow);
    noOfCols += addHeader(includeStartTime, headers, 'startTime', 'Start Time', wStartTime + wOverflow);
    noOfCols += addHeader(includeEndTime, headers, 'endTime', 'End Time', wEndTime + wOverflow);
    noOfCols += addHeader(includeRunningTime, headers, 'runningTime', 'Running Time', wRunningTime + wOverflow);
    noOfCols += addHeader(includeStopTime, headers, 'stopTime', 'Stop Time', wStopTime + wOverflow);
    noOfCols += addHeader(includeTotalTime, headers, 'totalTime', 'Total Time', wTotalTime + wOverflow);
    noOfCols += addHeader(
        includeDistanceTravelled,
        headers,
        'distanceTravelled',
        'Distance Travelled(Kms)',
        wDT + wOverflow
    );

    if (reportFormat === REPORT_FORMAT.CSV) {
        noOfCols += addHeader(true, headers, 'source', 'Source', 0);
        noOfCols += addHeader(true, headers, 'dest', 'Destination', 0);
        noOfCols += addHeader(true, headers, 'totalFuelConsumed', 'Total Fuel Consumed', 0);
    }

    let rT, sT, tT;

    forEach(trips, (t) => {
        rT =
            reportFormat === REPORT_FORMAT.CSV ? getTimeDiff(t.trip.runningTime) : getTimeDiffHours(t.trip.runningTime);
        sT = reportFormat === REPORT_FORMAT.CSV ? getTimeDiff(t.trip.stopTime) : getTimeDiffHours(t.trip.stopTime);
        tT =
            reportFormat === REPORT_FORMAT.CSV
                ? getTimeDiff(toSafeInteger(t.trip.stopTime + t.trip.runningTime))
                : getTimeDiffHours(toSafeInteger(t.trip.stopTime + t.trip.runningTime));

        let totalFuelConsumed = round(get(t.trip, 'endFuel', 0) - get(t.trip, 'startFuel', 0), 2);
        totalFuelConsumed = totalFuelConsumed ? totalFuelConsumed : '';

        data.push({
            vehicle: t.licensePlate,
            runningTime: rT,
            stopTime: sT,
            totalTime: tT,
            startTime: getReportTime(t.trip.startDate),
            endTime: getReportTime(t.trip.endDate),
            distanceTravelled: round(t.trip.distanceTravelled),
            source,
            dest,
            totalFuelConsumed,
        });
    });
    data.push({
        vehicle: `Total  ${data.length}`,
        runningTime: '',
        stopTime: '',
        totalTime: '',
        distanceTravelled: '',
        startTime: '',
        endTime: '',
        source: '',
        dest: '',
    });

    startReportCreation(
        ORIENTATION.LANDSCAPE,
        headers,
        data,
        reportFormat,
        `Trips Between Sites`,
        fromDate,
        toDate,
        licensePlate,
        reportName,
        noOfCols,
        `${dest}`,
        `${source} To`
    );
}

export async function triggerMinesReportDownload(accesstoken, params) {
    const {
        startDate,
        endDate,
        email = get(SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId') ||
            get(window.FLEETX_LOGGED_IN_USER, 'email', ''),
    } = params;

    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate.valueOf(),
            to: endDate.valueOf(),
            email,
        }),
    };

    axios.get(`${ROOT_API_URL}reports/mines/running/report/`, config);
}
