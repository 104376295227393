import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, forEach, get, map, round, toInteger } from 'lodash';
import { FxTable } from 'sharedV2/index';
import { getHumanizeTime } from 'utils/date_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isAccountingManagementEnabled, isDeleteServiceEntryAllowedForUser, isUpdateServiceEntryAllowedForUser, } from 'components/dashboard/roles/permission_utils';
import GenerateReportForSelectedTemplateModal from 'components/dashboard/reportTemplates/GenerateReportForSelectedTemplateModal';
import { REPORT_TEMPLATE_TYPES } from 'utils/reportTemplatesUtils';
import { getApprovalStatusColor } from './service_entries_utils';
import { serviceEntryApprovalConfigEnabled } from 'utils/account_utils';
import ViewVoucherDrawerComponent from '../../vouchers/forms/ViewVoucherDrawerComponent';
const ServiceEntriesListing = (props) => {
    const currency = getCurrencyFromCountry(props.loggedInUser);
    const { modules } = props.login;
    const isUpdateServiceEntryAllowed = isUpdateServiceEntryAllowedForUser(props.login.scope);
    const isDeleteServiceEntryAllowed = isDeleteServiceEntryAllowedForUser(props.login.scope);
    const [isGenerateReportForTemplateModalOpen, setIsGenerateReportForTemplateModalOpen] = useState(false);
    const [selectedServiceEntry, setSelectedServiceEntry] = useState(null);
    const [isVoucherDrawerVisible, setIsVoucherDrawerVisible] = useState(false);
    const [selectedVoucherId, setSelectedVoucherId] = useState(null);
    const showVehicle = (vehicleId) => {
        const vehicle = get(props.vehicleListMiniAsMap, vehicleId);
        const name = get(vehicle, 'name', '');
        const licensePlate = get(vehicle, 'licensePlate', '');
        return (_jsxs("div", { className: "whitespace-normal", children: [_jsx("div", { className: "font-weight-bold", children: name }), _jsx("div", { className: "text-muted font-sm", children: licensePlate })] }));
    };
    const showDate = (date) => {
        return _jsx("div", { className: "whitespace-normal", children: getHumanizeTime(date) });
    };
    const showLineItems = (serviceLineItems) => {
        const count = serviceLineItems.length;
        return (_jsxs("div", { children: [count > 0 && (_jsxs("div", { className: "whitespace-normal", children: ["1.", ' ', props.serviceTasksListAsMap &&
                            props.serviceTasksListAsMap.get(get(serviceLineItems, '[0].itemId'))?.name] })), count > 1 && (_jsxs("div", { className: "whitespace-normal", children: ["2.", ' ', props.serviceTasksListAsMap &&
                            props.serviceTasksListAsMap.get(get(serviceLineItems, '[1].itemId'))?.name] })), count > 2 && (_jsxs(Button, { color: "link", size: "sm", className: "p-0", children: [count - 2, " more"] }))] }));
    };
    const showWorkOrder = (workOrderId, rowData) => {
        return (_jsx("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: _jsx(Link, { to: `/dashboard/workorders/edit/${workOrderId}`, children: get(rowData, 'workOrderNumber', '') }) }));
    };
    const showDomains = (serviceLineItems) => {
        const count = serviceLineItems.length;
        const domains = map(serviceLineItems, (item) => get(item, 'vehicleDomain')).filter((item) => !!item);
        return (_jsxs("div", { className: "service-domains", children: [count > 0 && (_jsx(Badge, { pill: true, color: "secondary", children: get(domains, '[0]') })), count > 1 && (_jsx(Badge, { pill: true, color: "secondary", children: get(domains, '[1]') })), count > 2 && (_jsxs(Badge, { pill: true, color: "secondary", children: ["+", count - 2] }))] }));
    };
    const showCost = (cell, row) => {
        return (_jsxs("div", { className: "whitespace-normal", children: [_jsxs("div", { children: ["Labor: ", currency, " ", get(row, 'laborSubtotal')] }), _jsxs("div", { children: ["Parts: ", currency, " ", get(row, 'partsSubtotal')] })] }));
    };
    const showOthers = (cell, row) => {
        const discount = getDiscount(row);
        const tax = getTax(row);
        const insurance = get(row, 'insuranceAmount', 0);
        return (_jsxs("div", { className: "whitespace-normal", children: [discount > 0 && (_jsxs("div", { className: "text-muted font-xs", children: ["Discount: - ", currency, " ", discount] })), tax > 0 && (_jsxs("div", { children: ["Tax: ", currency, " ", tax] })), !!insurance && (_jsxs("div", { children: ["Insurance: ", currency, " ", insurance] }))] }));
    };
    const showTotal = (cell, row) => {
        return (_jsx("div", { className: "d-flex align-items-center justify-content-center", children: _jsx("div", { className: "p-2 rounded-1 d-flex align-items-center justify-content-center", style: {
                    border: '1px solid #dadada',
                    minWidth: '100px',
                }, children: _jsxs("div", { className: "font-weight-bold", children: [currency, " ", round(get(row, 'totalAmount'))] }) }) }));
    };
    const showStatus = (cell, row) => {
        return (_jsx("div", { children: map(get(props.approvalMatrixData, `${row?.id}`), (level) => {
                return (_jsxs("div", { className: getApprovalStatusColor(get(level, 'approvalLevelStatus')), children: [get(level, 'approvalLevelName', 'Level'), ' : ', get(level, 'approvalLevelStatus')] }));
            }) }));
    };
    const showOdometer = (cell, row) => {
        return (_jsxs("div", { className: "whitespace-normal d-flex align-items-center justify-content-center", children: [get(row, 'meterEntry.value'), " Km"] }));
    };
    const showSettledStatus = (cell, row) => {
        return (_jsx("div", { className: "whitespace-normal d-flex align-items-center justify-content-center", children: get(row, 'voucher.settled') ? 'Settled' : 'UnSettled' }));
    };
    const showMore = (cell, row) => {
        const notes = get(row, 'notes');
        const vendor = get(row, 'vendor.name');
        const reference = get(row, 'reference');
        return (_jsxs("div", { className: "whitespace-normal", children: [notes && _jsxs("div", { children: ["Notes: ", notes] }), vendor && _jsxs("div", { children: ["Vendor: ", vendor] }), reference && _jsxs("div", { children: ["Reference: ", reference] })] }));
    };
    const showVoucher = (cell, row) => {
        const voucher = get(row, 'voucher', {});
        if (!voucher?.id)
            return '-';
        return (_jsx("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: _jsx("span", { className: "cursor-pointer text-primary", onClick: () => {
                    setSelectedVoucherId(voucher.id);
                    setIsVoucherDrawerVisible(true);
                }, children: get(voucher, 'voucherNumber', '-').split('#')[0] }) }));
    };
    const deleteServiceEntryById = (id) => {
        if (confirm(`Are you sure you want to delete this Service Entry?`)) {
            props.onDeleteRow(id);
        }
    };
    const onGenerateReportForTemplateModalToggle = (id = null) => {
        setIsGenerateReportForTemplateModalOpen(!isGenerateReportForTemplateModalOpen);
        setSelectedServiceEntry(isGenerateReportForTemplateModalOpen ? null : id);
    };
    const showActions = (id, row) => {
        return (_jsxs("div", { className: "d-flex", children: [_jsx("div", { className: "d-flex align-items-center p-1 hover mr-2", onClick: (e) => {
                        e.stopPropagation();
                        onGenerateReportForTemplateModalToggle(id);
                    }, children: _jsx("i", { className: "fa fa-print" }) }), isUpdateServiceEntryAllowed && (_jsx(Link, { to: `/dashboard/maintenance/serviceentries/edit/${row.vehicleId}/${id}`, className: "d-flex align-items-center p-1 hover mr-2 text-decoration-none", children: _jsx("i", { className: "fa fa-edit" }) })), isDeleteServiceEntryAllowed && (_jsx("div", { className: "d-flex align-items-center p-1 mr-2 hover", onClick: (e) => {
                        e.stopPropagation();
                        deleteServiceEntryById(id);
                    }, children: _jsx("i", { className: "fa fa-trash" }) }))] }));
    };
    const onRowClick = (row) => {
        props.onDetailToggle(row);
    };
    const onPageChange = (page, sizePerPage) => {
        props.onPageChange(page, sizePerPage);
    };
    const getColumns = () => {
        let columns = [
            {
                title: 'Vehicle',
                dataIndex: 'vehicleId',
                render: showVehicle,
            },
            {
                title: 'Domain',
                dataIndex: 'serviceLineItems',
                render: showDomains,
            },
            {
                title: 'Date',
                dataIndex: 'date',
                render: showDate,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: showStatus,
                hideColumn: !serviceEntryApprovalConfigEnabled(),
            },
            {
                title: 'Work Order',
                dataIndex: 'workOrderId',
                render: showWorkOrder,
            },
            {
                title: 'Voucher',
                dataIndex: 'voucher',
                render: showVoucher,
            },
            {
                title: 'Line Items',
                dataIndex: 'serviceLineItems',
                render: showLineItems,
            },
            {
                title: 'Costs',
                render: showCost,
            },
            {
                title: 'Other Details',
                render: showOthers,
            },
            {
                title: 'Total Amount',
                render: showTotal,
            },
            {
                title: 'More Info',
                render: showMore,
            },
            {
                title: 'Odometer',
                render: showOdometer,
            },
            {
                title: 'Settlement Status',
                render: showSettledStatus,
                hideColumn: !isAccountingManagementEnabled(modules),
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                render: showActions,
                fixed: 'right',
            },
        ];
        return filter(columns, (column) => !get(column, 'hideColumn', false));
    };
    return (_jsxs("div", { children: [_jsx(FxTable, { scroll: {
                    x: 'max-content',
                }, className: "service-table blue-header", dataSource: props.data, pagination: {
                    position: ['bottomRight'],
                    total: props.dataTotalSize,
                    current: props.page,
                    pageSize: props.sizePerPage,
                    onChange: onPageChange,
                }, onRow: (row) => ({
                    onClick: () => onRowClick(row),
                }), columns: getColumns() }), isVoucherDrawerVisible && (_jsx(ViewVoucherDrawerComponent, { visible: isVoucherDrawerVisible, onClose: () => setIsVoucherDrawerVisible(false), voucherId: selectedVoucherId })), isGenerateReportForTemplateModalOpen && (_jsx(GenerateReportForSelectedTemplateModal, { isOpen: isGenerateReportForTemplateModalOpen, onClose: onGenerateReportForTemplateModalToggle, formatType: REPORT_TEMPLATE_TYPES.SERVICE_HISTORY, itemId: selectedServiceEntry, onSuccess: onGenerateReportForTemplateModalToggle }))] }));
};
export const getDiscount = (serviceEntry) => {
    let totalDiscount = 0;
    let laborCost = 0;
    if (get(serviceEntry, 'serviceLineItems.length')) {
        forEach(get(serviceEntry, 'serviceLineItems'), (item) => {
            laborCost += get(item, 'laborCost') || 0;
            if (get(item, 'serviceParts.length')) {
                forEach(get(item, 'serviceParts'), (servicePart) => {
                    totalDiscount +=
                        (get(servicePart, 'cost') || 0) * 0.01 * (get(servicePart, 'discountPercent') || 0);
                });
            }
        });
    }
    if (get(serviceEntry, 'discountPercentage') > 0) {
        totalDiscount += toInteger(get(serviceEntry, 'discountPercentage') * 0.01 * laborCost);
    }
    else if (get(serviceEntry, 'discount') > 0) {
        totalDiscount += toInteger(get(serviceEntry, 'discount'));
    }
    if (totalDiscount > 0) {
        return round(totalDiscount, 2);
    }
    else {
        return -1;
    }
};
export const getTax = (serviceEntry) => {
    let combineTax = 0;
    let totalLaborCost = 0;
    if (get(serviceEntry, 'serviceLineItems.length')) {
        forEach(get(serviceEntry, 'serviceLineItems'), (item) => {
            if (get(item, 'serviceParts.length')) {
                forEach(get(item, 'serviceParts'), (servicePart) => {
                    const itemCost = get(servicePart, 'cost', 0);
                    const discountPercent = get(servicePart, 'discountPercent', 0);
                    const itemCostAfterDiscount = itemCost - discountPercent * 0.01 * itemCost;
                    combineTax +=
                        (servicePart.tax1Type ? get(servicePart, 'tax1Percent', 0) : 0) * 0.01 * itemCostAfterDiscount +
                            (servicePart.tax2Type ? get(servicePart, 'tax2Percent', 0) : 0) * 0.01 * itemCostAfterDiscount;
                });
            }
            if (item.laborCost) {
                totalLaborCost += get(item, 'laborCost', 0);
            }
        });
    }
    let laborCostAfterDiscount;
    if (get(serviceEntry, 'discountPercentage', 0)) {
        laborCostAfterDiscount = totalLaborCost - get(serviceEntry, 'discountPercentage', 0) * 0.01 * totalLaborCost;
    }
    else {
        laborCostAfterDiscount = totalLaborCost - get(serviceEntry, 'discount', 0);
    }
    if (get(serviceEntry, 'laborTax1')) {
        combineTax += get(serviceEntry, 'laborTax1');
    }
    else if (get(serviceEntry, 'laborTax1Percentage')) {
        combineTax += get(serviceEntry, 'laborTax1Percentage') * 0.01 * laborCostAfterDiscount;
    }
    if (get(serviceEntry, 'laborTax2')) {
        combineTax += get(serviceEntry, 'laborTax2');
    }
    else if (get(serviceEntry, 'laborTax2Percentage')) {
        combineTax += get(serviceEntry, 'laborTax2Percentage') * 0.01 * laborCostAfterDiscount;
    }
    return round(combineTax, 2);
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: get(state, 'login.data'),
        vehicleListMiniAsMap: get(state, 'vehicles.vehicleListMiniAsMap'),
        loggedInUser: get(state, 'settings.loggedInUser'),
        vehicleData: get(state, 'vehicle.data'),
        serviceTasksListAsMap: get(state, 'servicetasks.serviceTasksListAsMap'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceEntriesListing);
