import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FxReactPhoneNumberInput from './FxReactPhoneNumberInput';
import { get } from 'lodash';
const FxReactPhoneNumberInputFormik = (props) => {
    const { field, form, label, type, isRequired, helptext, outerDivClasses = '', labelDivClasses = '', inputDivClasses = '', isStacked = true, isFormatFinalValueDisabled = false, onChange, ...restOfProps } = props;
    const onChangeHandler = (v) => {
        form.setFieldValue(field.name, v);
        if (onChange) {
            onChange(v);
        }
    };
    const onBlurHandler = (event) => {
        if (isFormatFinalValueDisabled) {
            // on blur we get formatted value to dont pass event or call props.input.onChange( getNonFormattedPhoneNumber(input.value))
            form.setFieldTouched(field.name);
            // const formattedValue = event.target.value;
            // props.input.onChange(getNonFormattedPhoneNumber(formattedValue));
        }
        else {
            const formattedValue = event.target.value;
            form.setFieldValue(field.name, formattedValue);
            form.setFieldTouched(field.name);
            if (onChange) {
                onChange(formattedValue);
            }
        }
    };
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');
    const errorClass = error && isTouched ? 'text-danger' : '';
    return (_jsxs("div", { className: `${outerDivClasses} ${!isStacked ? 'row' : ''} form-group`, children: [label && (_jsxs("div", { className: `${labelDivClasses} ${!isStacked ? 'col-md-3' : ''} ${errorClass}`, children: [_jsx("label", { children: label }), isRequired && _jsx("abbr", { className: "text-danger", children: "*" })] })), _jsxs("div", { className: `${inputDivClasses} ${!isStacked ? 'col-md-9' : ''}`, children: [_jsx(FxReactPhoneNumberInput, { ...restOfProps, ...field, onChange: onChangeHandler, onBlur: onBlurHandler, type: 'text' }), error && isTouched && _jsx("div", { className: "text-help text-danger", children: error }), helptext && !error && _jsx("span", { className: "help-block", children: helptext })] })] }));
};
export default FxReactPhoneNumberInputFormik;
