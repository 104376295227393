import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
export const FxBreadcrumb = (props) => {
    const { themeModify, items, ...rest } = props;
    const itemsWithReactRouter = useMemo(() => {
        return items?.map((item) => ({
            ...item,
            title: item.href ? _jsx(Link, { to: item.href, children: item.title }) : item.title,
        }));
    }, [items]);
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Breadcrumb, { items: itemsWithReactRouter, ...rest }) }));
};
export default FxBreadcrumb;
