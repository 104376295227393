import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FxCard, FxPopconfirm, FxTable } from 'sharedV2/index';
import { getHumanizeTime } from '../../../utils/date_utils';
import GenerateReportForSelectedTemplateButton from '../reportTemplates/GenerateReportForSelectedTemplateButton';
import { isDeletePaymentAllowedForUser, isUpdateAdvanceAllowedForUser, isViewReportTemplatesAllowed, } from '../roles/permission_utils';
import { PAYMENT_NATURES } from './forms/account_form_utils';
import { deletePayment } from 'actions/dashboard/payment_forms/actions';
import { useFxMessage } from '../customHooks';
import { FxDeleteOutlined } from 'sharedV2/FxIcons';
import ViewVoucherDrawerComponent from 'components/dashboard/vouchers/forms/ViewVoucherDrawerComponent';
const AdvanceListingComponent = (props) => {
    const { data, login, currentPage, sizePerPage, groupedBranches, groupedCreditAccounts, groupedTransporters, fetchData, accesstoken, } = props;
    const scope = login.scope;
    const isUpdateAllowed = isUpdateAdvanceAllowedForUser(scope);
    const isDeletePaymentAllowed = isDeletePaymentAllowedForUser(scope);
    const showMessage = useFxMessage();
    const [isVoucherDrawerVisible, setIsVoucherDrawerVisible] = React.useState(false);
    const [selectedVoucherId, setSelectedVoucherId] = React.useState(null);
    const getEditURL = (paymentNature) => {
        switch (paymentNature) {
            case 'DRIVER_ADVANCE':
                return 'driver/';
            case 'CN_ADVANCE':
            case 'MONEY_RECEIPT':
                return 'cn-advance/';
            case 'PAYMENT_RECEIPT':
                return 'payment-receipt/';
            case 'HIRE_SLIP':
                return 'balance-payment/';
            case 'ON_ACCOUNT_PAYMENT':
            case 'ON_ACCOUNT_RECEIPT':
                return 'account/';
            case 'JOB_BILL_RECEIPT':
                return 'payment-receipt-job/';
            default:
                return '';
        }
    };
    const showButtons = (id, row) => {
        return (_jsxs("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: [isViewReportTemplatesAllowed(scope) && get(row, 'paymentNature') === 'PAYMENT_RECEIPT' && (_jsx(GenerateReportForSelectedTemplateButton, { onClick: () => {
                        props.onOpenReportForTemplate(row);
                    }, className: "d-inline-block", label: 'Print', buttonType: 'link' })), isUpdateAllowed && (_jsx(Link, { to: `/dashboard/advance/${getEditURL(row.paymentNature)}edit/${id}`, className: "text-dark p-1 mb-2 mr-2 d-inline-block hover", title: "Edit Advance", children: _jsx("i", { className: "fa fa-edit" }) })), isDeletePaymentAllowed && (_jsx(FxPopconfirm, { title: "Are you sure you want to delete this payment?", onConfirm: () => {
                        props.deletePayment(accesstoken, id).then((res) => {
                            if (!res.error) {
                                showMessage(`Payment deleted successfully.`);
                                fetchData();
                            }
                            else {
                                showMessage(`Error deleting payment.`);
                            }
                        });
                    }, okText: "Yes", cancelText: "No", children: _jsx(FxDeleteOutlined, {}) }))] }));
    };
    const showVoucherDate = (cell) => {
        return _jsx("div", { children: getHumanizeTime(cell) });
    };
    const showOffice = (cell) => {
        return _jsx("div", { children: get(groupedBranches[cell], `0.officeName`, '-') });
    };
    const showCreditAccount = (cell, row) => {
        const groupedData = row?.paymentNature === PAYMENT_NATURES.ON_ACCOUNT_PAYMENT || row?.paymentNature === 'HIRE_SLIP'
            ? groupedTransporters
            : groupedCreditAccounts;
        return _jsx("div", { children: get(groupedData[cell], `0.name`, '-') });
    };
    const showVoucherNumber = (cell, row) => {
        const voucher = get(row, 'voucher', {});
        if (!voucher?.id)
            return cell ? cell.split('#')[0] : '-';
        return (_jsx("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: _jsx("span", { className: "cursor-pointer text-primary", onClick: () => {
                    setSelectedVoucherId(voucher.id);
                    setIsVoucherDrawerVisible(true);
                }, children: cell ? cell.split('#')[0] : '-' }) }));
    };
    const options = {
        noDataText: 'No entry found.',
        paginationPosition: 'both',
        hideSizePerPage: false,
        sizePerPage,
        page: currentPage,
        alwaysShowAllBtns: true,
        onPageChange: props.onPageChange,
    };
    const columnsModified = [
        {
            title: 'Voucher No.',
            dataIndex: 'voucherNumber',
            key: 'voucherNumber',
            hidden: false,
            identifier: '',
            render: (field, row) => showVoucherNumber(field, row),
        },
        {
            title: 'Voucher Type',
            dataIndex: 'paymentNature',
            key: 'paymentNature',
            hidden: false,
            identifier: '',
        },
        {
            title: 'Voucher Date',
            dataIndex: 'voucherDate',
            key: 'voucherDate',
            hidden: false,
            identifier: '',
            render: (field, row) => showVoucherDate(field),
        },
        {
            title: 'Office',
            dataIndex: 'branchId',
            key: 'branchId',
            hidden: false,
            identifier: '',
            render: (field, row) => showOffice(field),
        },
        {
            title: 'CR Account',
            dataIndex: 'creditAccountId',
            key: 'creditAccountId',
            hidden: false,
            identifier: '',
            render: (field, row) => showCreditAccount(field, row),
        },
        {
            title: 'Advice Number',
            dataIndex: 'adviceNumber',
            key: 'adviceNumber',
            hidden: false,
            identifier: '',
        },
        {
            title: 'Advice Amount',
            dataIndex: 'adviceAmount',
            key: 'adviceAmount',
            hidden: false,
            identifier: '',
        },
        {
            title: 'Narration',
            dataIndex: 'narration',
            key: 'narration',
            hidden: false,
            identifier: '',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            hidden: false,
            identifier: '',
            render: (field, row) => showButtons(field, row),
        },
    ];
    const renderCustomButton = () => {
        return (_jsx("div", { className: "col-12 px-0 mb-2", children: _jsx("div", { className: "d-flex justify-content-between align-items-center pt-2", children: _jsxs("div", { className: "pl-3 pt-2", style: { fontWeight: '500' }, children: ["Payments (Total ", data?.length ? +data?.length : 0, "):"] }) }) }));
    };
    return (_jsxs(FxCard, { className: "mb-2", styles: {
            body: {
                padding: 0,
            },
        }, children: [renderCustomButton(), _jsx("div", { className: "fleetx-table-parent-container", children: _jsx(FxTable, { rowKey: 'id', columns: columnsModified, dataSource: data, className: "table-striped-rows", scroll: { x: 'max-content' }, pagination: {
                        position: ['bottomRight'],
                        current: currentPage,
                        pageSize: sizePerPage,
                        onChange: props.onPageChange,
                        showSizeChanger: false,
                    } }) }), isVoucherDrawerVisible && (_jsx(ViewVoucherDrawerComponent, { visible: isVoucherDrawerVisible, onClose: () => setIsVoucherDrawerVisible(false), voucherId: selectedVoucherId }))] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ deletePayment }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceListingComponent);
