import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../constant';

export const ADD_VEHICLE_IMAGE = 'add_vehicle_image';
export const ADD_VEHICLE_IMAGE_BULK = 'add_vehicle_image_bulk';
export const FETCH_VEHICLE_IMAGES = 'fetch_vehicle_images';
export const FETCH_VEHICLE_IMAGE = 'fetch_vehicle_image';
export const DELETE_VEHICLE_IMAGE = 'delete_vehicle_image';
export const UPDATE_VEHICLE_IMAGE = 'update_vehicle_image';
export const SEARCH_VEHICLE_IMAGES = 'search_vehicle_images';

export function addVehicleImage(values, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}vehicle_images/`, values, config);

    return {
        type: ADD_VEHICLE_IMAGE,
        promise: request,
    };
}
export function updateVehicleImage(accesstoken, id, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}vehicle_images/${id}`, values, config);

    return {
        type: UPDATE_VEHICLE_IMAGE,
        promise: request,
    };
}

export function deleteVehicleImage(id, vehicleId, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}vehicle_images/${id}?vehicleId=${vehicleId}`, config);
    return {
        type: DELETE_VEHICLE_IMAGE,
        promise: request,
    };
}

export function fetchVehicleImages(vehicleId, documentType, page, size, accesstoken, tag) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            documentType,
            page: page - 1,
            size,
            tag,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_images/`, config);

    return {
        type: FETCH_VEHICLE_IMAGES,
        promise: request,
    };
}

export function fetchVehicleImage(accesstoken, id, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_images/${id}`, config);

    return {
        type: FETCH_VEHICLE_IMAGE,
        promise: request,
    };
}

export function searchVehicleImages(accesstoken, { vehicleId, documentType, page, size, tag }) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            documentType,
            page: page - 1,
            size,
            tag,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_images/search`, config);

    return {
        type: SEARCH_VEHICLE_IMAGES,
        promise: request,
    };
}

export function addVehicleImageBulk(values) {
    const request = axios.post(`${ROOT_API_URL}vehicle_images/bulk`, values, {});

    return {
        type: ADD_VEHICLE_IMAGE_BULK,
        promise: request,
    };
}
