import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const VehicleGreen = (props) => {
    return (_jsx("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4.45221 14.9813C3.83824 14.9813 3.31563 14.7661 2.88438 14.3359C2.45312 13.9057 2.2375 13.3833 2.2375 12.7688H1V4.125C1 3.825 1.1125 3.5625 1.3375 3.3375C1.5625 3.1125 1.825 3 2.125 3H12.9813V6.13125H14.95L17.5 9.525V12.7688H16.1688C16.1688 13.3833 15.9539 13.9057 15.5241 14.3359C15.0943 14.7661 14.5724 14.9813 13.9585 14.9813C13.3445 14.9813 12.8219 14.7661 12.3906 14.3359C11.9594 13.9057 11.7438 13.3833 11.7438 12.7688H6.6625C6.6625 13.3813 6.44761 13.9031 6.01784 14.3344C5.58805 14.7656 5.06617 14.9813 4.45221 14.9813ZM4.45 13.8563C4.75 13.8563 5.00625 13.75 5.21875 13.5375C5.43125 13.325 5.5375 13.0688 5.5375 12.7688C5.5375 12.4688 5.43125 12.2125 5.21875 12C5.00625 11.7875 4.75 11.6813 4.45 11.6813C4.15 11.6813 3.89375 11.7875 3.68125 12C3.46875 12.2125 3.3625 12.4688 3.3625 12.7688C3.3625 13.0688 3.46875 13.325 3.68125 13.5375C3.89375 13.75 4.15 13.8563 4.45 13.8563ZM2.125 11.6438H2.5375C2.75 11.3063 3.01901 11.0375 3.34452 10.8375C3.67003 10.6375 4.03253 10.5375 4.43202 10.5375C4.83151 10.5375 5.19688 10.6406 5.52813 10.8469C5.85938 11.0531 6.13125 11.3188 6.34375 11.6438H11.8563V4.125H2.125V11.6438ZM13.9563 13.8563C14.2563 13.8563 14.5125 13.75 14.725 13.5375C14.9375 13.325 15.0438 13.0688 15.0438 12.7688C15.0438 12.4688 14.9375 12.2125 14.725 12C14.5125 11.7875 14.2563 11.6813 13.9563 11.6813C13.6563 11.6813 13.4 11.7875 13.1875 12C12.975 12.2125 12.8688 12.4688 12.8688 12.7688C12.8688 13.0688 12.975 13.325 13.1875 13.5375C13.4 13.75 13.6563 13.8563 13.9563 13.8563ZM12.9813 10.0313H16.4688L14.3875 7.25625H12.9813V10.0313Z", fill: "#52A395" }) }));
};
const VehicleGreenIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: VehicleGreen, ...props });
};
export default VehicleGreenIcon;
