import { getPhonePrefixFromCountry } from './internationalization_utils';
import { isNaN, map, startsWith, trim } from 'lodash';
export const GST_NUMBER_VALIDATION_REGEX = /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d][A-Z][A-Z\d]$/i;
export const MOBILE_NUMBER_VALIDATION_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/i;
export const AADHAR_CARD_VALIDATION_REGEX = /^\d{12}$/;
export const PAN_NUMBER_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const ALPHABETS_SPACE_REGEX = /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const MOBILE_NUMBER_LOOSE_VALIDATION_REGEX = /^(\+\d{1,3}[- ]?)?\d{3,13}$/;
export const STRICT_EMAIL_REGEX = /^(?!.*\.{2})(?!.*\s)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const transformNumber = (value) => (isNaN(value) ? undefined : value);
// prefix like +91 or +91-
export const getPhoneNumberWithoutPrefix = (phoneNumber) => {
    if (!phoneNumber) {
        return phoneNumber;
    }
    let prefix = '';
    if (startsWith(phoneNumber, `${getPhonePrefixFromCountry()}-`)) {
        prefix = `${getPhonePrefixFromCountry()}-`;
    }
    else if (startsWith(phoneNumber, getPhonePrefixFromCountry())) {
        prefix = getPhonePrefixFromCountry();
    }
    const prefixLen = prefix ? prefix.length : 0;
    return `${phoneNumber}`.substr(prefixLen);
};
export const getPhoneNumberWithPrefix = (phoneNumber, withHyphon = false) => {
    if (!phoneNumber) {
        return phoneNumber;
    }
    const prefix = getPhonePrefixFromCountry();
    if (startsWith(phoneNumber, prefix)) {
        return phoneNumber;
    }
    return `${prefix}${withHyphon ? '-' : ''}${phoneNumber}`;
};
export function validateEmails(emailsString) {
    const emails = map(emailsString.split(','), (email) => trim(email));
    const validationResults = [];
    for (const email of emails) {
        const isValid = EMAIL_REGEX.test(email);
        validationResults.push({ email, isValid });
    }
    return validationResults;
}
export const strictValidEmails = (email) => {
    return STRICT_EMAIL_REGEX.test(email);
};
