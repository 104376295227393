import { handle } from 'redux-pack';
import {
    CREATE_JOB,
    DELETE_JOB,
    FETCH_JOB,
    FETCH_JOB_DEVIATIONS,
    FETCH_JOBS,
    FETCH_JOBS_SUMMARY,
    FETCH_REAL_TIME_JOB_SHARES,
    FETCH_REALTIME_LINKED_SHARED_JOBS,
    FETCH_TIME_ON_SITE_ANALYTICS,
    FETCH_TIME_ON_SITE_ANALYTICS_TVS,
    FETCH_VEHICLE_ON_SITE_ANALYTICS,
    FETCH_VEHICLE_ON_SITE_ANALYTICS_SUMMARY,
    FETCH_VEHICLES_ON_JOB,
    UPDATE_JOB,
    FETCH_JOB_LIST_NEW,
    FETCH_JOB_RISK_DETAIL,
    FETCH_JOBS_SUMMARY_LATEST,
} from '../../../actions/dashboard/route_dispatch/jobs_action';
import { getMomentTime } from '../../../utils/date_utils';
import { get, map, set } from 'lodash';
import { appendJobDetails, appendJobDetailsInList, VEHICLE_JOB_STATE } from '../../../utils/job_route_utils';
import {
    BRAHAMAPUTRA_NORTH_REGION_COORDINATES,
    getBrahamaputraJobTag,
    isBrahamaputraAccount,
    getExpressRoadwaysBoundTags,
    isExpressRoadwaysAccount,
} from '../../../utils/account_utils';
import { precalc } from '../../../utils/map/geocode_utils';
import { JOB_TAGS } from '../../../constant';
import { reduce } from 'lodash';
import { arrayToObject } from '../../../utils/list_utils';
import { FETCH_VEHICLES_ON_JOB_NEW } from 'actions/dashboard/realtime_action';

const initialState = {
    isLoading: false,
    error: null,
    data: {},
    jobList: [],
    isTimeOnSiteLoading: false,
    isTimeOnSiteLoadingTvs: false,
    isVehicleOnSiteLoading: false,
    isJobListLoading: false,
    isVehiclesOnJobLoading: false,
    isJobSummaryLoading: false,
    isRealtimeJobSharesLoading: false,
    isRealtimeJobSharesFinished: false,
    realtimeJobSharesList: [],
    linkedToJobVehicleIds: [],
    isJobListNewLoading: false,
    jobListNew: [],
    vehicleOnSiteAnalyticsSummary: [],
    vehicleOnSiteAnalyticsSummaryAsMap: {},
    isVehicleOnSiteSummaryLoading: false,
    jobRiskDetail: {},
    jobRiskDetailStatus: '',
    totalDataSize: 0,
    isJobSummaryLatestLoading: false,
    jobSummaryLatest: null,
};

export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_REALTIME_LINKED_SHARED_JOBS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    linkedToJobVehicleIds: map(payload.data, (job) => {
                        return job.vehicle.id;
                    }),
                }),
            });

        case CREATE_JOB:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_JOB:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });

        case DELETE_JOB:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });

        case FETCH_JOBS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isJobListLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isJobListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    jobList: appendJobDetailsInList(payload.data.content),
                }),
            });
        case FETCH_JOB_LIST_NEW:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isJobListNewLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isJobListNewLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    jobListNew: appendJobDetailsInList(payload.data.content),
                    totalDataSize: payload.data.totalElements,
                }),
            });
        case FETCH_JOBS_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isJobSummaryLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isJobSummaryLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    jobSummary: payload.data,
                }),
            });

        case FETCH_JOBS_SUMMARY_LATEST:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                    isJobSummaryLatestLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isJobSummaryLatestLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    jobSummaryLatest: payload.data,
                }),
            });

        case FETCH_JOB:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    data: { ...prevState.data, [payload.data.id]: appendJobDetails(payload.data) },
                }),
            });
        case FETCH_TIME_ON_SITE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isTimeOnSiteLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isTimeOnSiteLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, timeOnSiteAnalytics: null }),
                success: (prevState) => ({ ...prevState, timeOnSiteAnalytics: payload.data }),
            });
        case FETCH_TIME_ON_SITE_ANALYTICS_TVS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isTimeOnSiteLoadingTvs: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isTimeOnSiteLoadingTvs: false }),
                failure: (prevState) => ({ ...prevState, error: payload, timeOnSiteAnalyticsTvs: null }),
                success: (prevState) => ({ ...prevState, timeOnSiteAnalyticsTvs: payload.data }),
            });
        case FETCH_VEHICLE_ON_SITE_ANALYTICS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isVehicleOnSiteLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isVehicleOnSiteLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload, vehicleOnSiteAnalytics: null }),
                success: (prevState) => ({ ...prevState, vehicleOnSiteAnalytics: payload.data.vehiclesOnSite }),
            });
        case FETCH_VEHICLE_ON_SITE_ANALYTICS_SUMMARY:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isVehicleOnSiteSummaryLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isVehicleOnSiteSummaryLoading: false }),
                failure: (prevState) => ({
                    ...prevState,
                    error: payload,
                    vehicleOnSiteAnalyticsSummary: [],
                    vehicleOnSiteAnalyticsSummaryAsMap: {},
                }),
                success: (prevState) => {
                    const vehicleOnSiteAnalyticsSummaryAsMap = arrayToObject(payload.data, 'siteId');
                    return {
                        ...prevState,
                        vehicleOnSiteAnalyticsSummary: payload.data,
                        vehicleOnSiteAnalyticsSummaryAsMap,
                    };
                },
            });

        case FETCH_REAL_TIME_JOB_SHARES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isRealtimeJobSharesLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    isRealtimeJobSharesLoading: false,
                    isRealtimeJobSharesFinished: true,
                }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    realtimeJobSharesList: payload.data,
                }),
            });
        case FETCH_VEHICLES_ON_JOB:
        case FETCH_VEHICLES_ON_JOB_NEW:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    error: null,
                    isVehiclesOnJobLoading: true,
                }),
                finish: (prevState) => ({ ...prevState, isVehiclesOnJobLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    const vehiclesOnJobList = get(payload.data, 'vehicles', []);
                    const jobTags = {};

                    if (
                        isBrahamaputraAccount(window.FLEETX_LOGGED_IN_USER) ||
                        isExpressRoadwaysAccount(window.FLEETX_LOGGED_IN_USER)
                    ) {
                        const preCalcData = precalc(BRAHAMAPUTRA_NORTH_REGION_COORDINATES);
                        const userTagIdsList = get(window.FLEETX_LOGGED_IN_USER, 'tagIdsSet', []);
                        const addressBookAsMap = get(root, 'addressBook.addressBookAsMap', {});
                        map(vehiclesOnJobList, (vehicleJob) => {
                            const vehicleId = vehicleJob.vehicleId;
                            if (isBrahamaputraAccount(window.FLEETX_LOGGED_IN_USER)) {
                                if (!!preCalcData && get(vehicleJob, 'status') !== VEHICLE_JOB_STATE.NOT_ON_JOB) {
                                    const upDownTag = getBrahamaputraJobTag(vehicleJob, preCalcData);
                                    if (upDownTag === JOB_TAGS.JOB_UP || upDownTag === JOB_TAGS.JOB_DOWN) {
                                        jobTags[vehicleId] = upDownTag;
                                    }
                                }
                            }
                            if (
                                isExpressRoadwaysAccount(window.FLEETX_LOGGED_IN_USER) &&
                                userTagIdsList &&
                                userTagIdsList.length > 0
                            ) {
                                const boundTag = getExpressRoadwaysBoundTags(
                                    vehicleJob,
                                    userTagIdsList,
                                    addressBookAsMap
                                );
                                if (boundTag) {
                                    jobTags[vehicleId] = boundTag;
                                }
                            }
                        });
                    }

                    const vehiclesOnJobAsMap = arrayToObject(vehiclesOnJobList, 'vehicleId');

                    return {
                        ...prevState,
                        vehiclesOnJob: {
                            ...payload.data,
                            vehicles: vehiclesOnJobList,
                            jobTags,
                        },
                        vehiclesOnJobAsMap: vehiclesOnJobAsMap,
                        lastFetchVehiclesOnJobRequestTime: getMomentTime().valueOf(),
                    };
                },
            });

        case FETCH_JOB_DEVIATIONS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    jobDeviations: payload.data,
                }),
            });
        case FETCH_JOB_RISK_DETAIL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    jobRiskDetailStatus: 'loading',
                }),
                finish: (prevState) => ({ ...prevState }),
                failure: (prevState) => ({ ...prevState, jobRiskDetailStatus: 'error' }),
                success: (prevState) => ({
                    ...prevState,
                    jobRiskDetail: payload.data,
                    jobRiskDetailStatus: 'success',
                }),
            });

        default:
            return state;
    }
}
