import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { map, get, round, isEmpty, reduce } from 'lodash';
import { FxSkeleton } from '../shared';
import { getTimeDiffHours } from 'utils/date_utils';
export const GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY = {
    belowPeakRange: 'belowPeakRange',
    withinPeakRange: 'withinPeakRange',
    abovePeakRange: 'abovePeakRange',
};
export const COLORS = {
    color1: '#95E5FF',
    color2: '#FEDA6E',
    color3: '#FFAAA5',
    color4: '#F58231',
    color5: '#AAFFC3',
    color6: '#FFD8B1',
    color7: '#808080',
    color8: '#9A6324',
};
export function getGearDistributionPeakRangeCategoryName(name) {
    return window.t(name);
}
const getPerc = (val, total) => {
    if (total) {
        return round((val / total) * 100, 2);
    }
    else {
        return 0;
    }
};
export function getPreparedGearDistributionData(apiData) {
    const gearDistributionPieChartData = [];
    let belowPeakRange = 0;
    let withinPeakRange = 0;
    let abovePeakRange = 0;
    const totalTimeSpent = reduce(apiData, (acc, d) => {
        return acc + get(d, 'timeSpent', 0);
    }, 0);
    const gearDistributionStackGraphData = map(apiData, (d) => {
        const currentBelowPeakRange = get(d, 'belowPeakRange', 0);
        const currentWithinPeakRange = get(d, 'withinPeakRange', 0);
        const currentAbovePeakRange = get(d, 'abovePeakRange', 0);
        belowPeakRange = belowPeakRange + currentBelowPeakRange;
        withinPeakRange = withinPeakRange + currentWithinPeakRange;
        abovePeakRange = abovePeakRange + currentAbovePeakRange;
        const total = currentBelowPeakRange + currentWithinPeakRange + currentAbovePeakRange;
        const timeSpent = get(d, 'timeSpent', 0);
        return {
            ...d,
            name: getGearName(d.gear),
            color: getColorForGear(d.gear),
            belowPeakRange: getPerc(currentBelowPeakRange, total),
            withinPeakRange: getPerc(currentWithinPeakRange, total),
            abovePeakRange: getPerc(currentAbovePeakRange, total),
            timeSpentPerc: getPerc(timeSpent, totalTimeSpent),
        };
    });
    const totalPeakRangeVal = belowPeakRange + withinPeakRange + abovePeakRange;
    if (!isEmpty(gearDistributionStackGraphData)) {
        gearDistributionPieChartData.push({
            key: 'belowPeakRange',
            value: getPerc(belowPeakRange, totalPeakRangeVal),
            name: getGearDistributionPeakRangeCategoryName(GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.belowPeakRange),
            color: COLORS.color2,
        });
        gearDistributionPieChartData.push({
            key: 'withinPeakRange',
            value: getPerc(withinPeakRange, totalPeakRangeVal),
            name: getGearDistributionPeakRangeCategoryName(GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.withinPeakRange),
            color: COLORS.color1,
        });
        gearDistributionPieChartData.push({
            key: 'abovePeakRange',
            value: getPerc(abovePeakRange, totalPeakRangeVal),
            name: getGearDistributionPeakRangeCategoryName(GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.abovePeakRange),
            color: COLORS.color3,
        });
    }
    return {
        gearDistributionPieChartData,
        gearDistributionStackGraphData,
    };
}
export function getGearName(gearNumber) {
    let friendlyName = window.t('gear');
    switch (gearNumber) {
        case 1:
            friendlyName = `1st ${friendlyName}`;
            break;
        case 2:
            friendlyName = `2nd ${friendlyName}`;
            break;
        case 3:
            friendlyName = `3rd ${friendlyName}`;
            break;
        case 4:
            friendlyName = `4th ${friendlyName}`;
            break;
        case 5:
            friendlyName = `5th ${friendlyName}`;
            break;
        case 6:
            friendlyName = `6th ${friendlyName}`;
            break;
        case 7:
            friendlyName = `7th ${friendlyName}`;
            break;
        default:
            friendlyName = `${gearNumber} ${friendlyName}`;
    }
    return friendlyName;
}
const getColorForGear = (gearNumber) => {
    return COLORS[`color${gearNumber}`] || COLORS.color1;
};
export function getPreparedStandstillData(apiData) {
    const total = reduce(apiData, (acc, d) => {
        return acc + d.fromStandStill;
    }, 0);
    return map(apiData, (d) => {
        return {
            ...d,
            color: getColorForGear(d.gear),
            value: getPerc(d.fromStandStill, total),
            name: getGearName(d.gear),
        };
    });
}
export const DotComponent = ({ color }) => {
    return (_jsx("div", { className: 'd-inline-block', style: { backgroundColor: color, width: '12px', height: '12px', borderRadius: '50%' } }));
};
export const renderDistributionTooltip = (props) => {
    const { active } = props;
    if (active) {
        const { payload, label } = props;
        const timeSpent = get(payload, '[0].payload.timeSpent');
        return (_jsxs("div", { className: "grph-tooltip-con p-2", children: [_jsx("div", { className: "text-white", children: label }), timeSpent && (_jsxs("div", { className: "text-white mb-2", children: [_jsx("span", { className: "text-muted", children: "Time Spent: " }), _jsx("span", { children: getTimeDiffHours(timeSpent) })] })), map(payload, (item) => {
                    return (_jsxs("div", { className: "text-white", children: [_jsxs("span", { className: "text-muted", children: [getGearDistributionPeakRangeCategoryName(item.name), ": "] }), _jsxs("span", { children: [item.value, "%"] })] }, item.name));
                })] }));
    }
    return null;
};
export function getLoadingUI() {
    return (_jsx("div", { className: 'card', children: _jsx("div", { className: 'card-body', children: _jsx(FxSkeleton, { width: "100%", height: "8rem", children: _jsx("div", {}) }) }) }));
}
