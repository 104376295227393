import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { forEach, get, isEmpty, round, toUpper } from 'lodash';
import { Bar, CartesianGrid, Cell, ComposedChart, Label, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { bindActionCreators } from 'redux';
import { getMomentTime, getTimeDiffHours } from 'utils/date_utils';
import { handleError } from 'constant';
import { FxCard, FxCol, FxRow, FxSpin, FxTypography } from 'sharedV2/index';
import FuelTypeSelector from 'components/utils/fuel_type_selector';
import { fetchTripsGraph } from 'actions/dashboard/trips_action';
import { connect } from 'react-redux';
import ChartSummary from 'components/dashboard/report_section/obd_health/chart_summary';
import { COLORS } from 'components/dashboard/overview/MyAnalysis/overview_dashboard_helper';
const FuelEfficiency = (props) => {
    const { vehicleId, accesstoken, startDate, endDate } = props;
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [availableFuelTypes, setAvailableFuelTypes] = useState([]);
    const [selectedFuelType, setSelectedFuelType] = useState('');
    const [summaryData, setSummaryData] = useState({
        distance: '',
        engineHours: '',
        fuelConsumed: '',
    });
    const style = { paddingBottom: 6, height: 450 };
    useEffect(() => {
        if (vehicleId) {
            setLoading(true);
            props.fetchTripsGraph(accesstoken, startDate.valueOf(), endDate.valueOf(), vehicleId).then((res) => {
                if (!res.error) {
                    setLoading(false);
                    const fuelType = Object.keys(get(res, 'payload.data', {}))[0];
                    setAvailableFuelTypes(Object.keys(get(res, 'payload.data', {})));
                    setSelectedFuelType(fuelType);
                    const _data = get(res, 'payload.data', []);
                    let _duration = 0;
                    let _mileage = 0;
                    let _todo = 0;
                    let _tfuel = 0;
                    forEach(_data[fuelType], (i) => {
                        _duration += i.duration;
                        _todo += i.todo;
                        _mileage += i.mileage;
                        _tfuel += i.tfuel;
                    });
                    setData(_data);
                    setSummaryData({
                        distance: round(_todo, 2),
                        engineHours: getTimeDiffHours(_duration),
                        fuelConsumed: round(_tfuel, 2),
                    });
                }
                else {
                    setLoading(false);
                    alert(handleError(res.error));
                }
            });
        }
    }, [startDate, endDate, vehicleId]);
    const SummaryHeading = [
        { label: 'Distance (km)', dataIndex: 'distance' },
        { label: 'Engine Hours (HH:MM)', dataIndex: 'engineHours' },
        { label: `Fuel Consumed (${toUpper(selectedFuelType) === 'CNG' ? 'Kg' : 'L'})`, dataIndex: 'fuelConsumed' },
        {
            label: `Fuel Efficiency (KM/${toUpper(selectedFuelType) === 'CNG' ? 'Kg' : 'L'})`,
            dataIndex: 'fuelEfficiency',
        },
    ];
    const getChartData = () => {
        let totalMileage = 0;
        const mappedDataForChart = [];
        let vehicleCountForMileage = 0;
        forEach(data[selectedFuelType] || [], (item) => {
            if (item.todo) {
                vehicleCountForMileage++;
                totalMileage += item.mileage || 0;
            }
            let _startDate = getMomentTime(item.startDate);
            mappedDataForChart.push({
                mileage: round(item.mileage, 2),
                distance: round(item.todo, 2),
                startDate: _startDate.format('D') + ' ' + _startDate.format('MMM'),
            });
        });
        const avgMileage = vehicleCountForMileage ? round(totalMileage / vehicleCountForMileage, 2) : 0;
        return mappedDataForChart.map((i) => ({
            ...i,
            avgMileage: avgMileage,
        }));
    };
    const chartData = getChartData();
    let legendLabels = {
        mileage: 'Mileage',
        distance: 'Distance',
        avgMileage: 'Avg Mileage',
    };
    const formatter = (value, name, _props) => {
        return [value, legendLabels?.[name] || name, _props];
    };
    const renderLegend = () => {
        const data = [
            { label: 'Above Mean', color: '#5AD8A6' },
            { label: 'Below Mean', color: '#E86452' },
            { label: 'Mean', color: '#000000' },
            { label: 'Distance', color: COLORS[0] },
        ];
        return (_jsx("div", { className: "d-flex align-items-center justify-content-center", children: data.map((entry, index) => {
                return (_jsxs("div", { className: "d-flex align-items-center justify-content-center", style: { height: 40 }, children: [index === 2 ? (_jsx("div", { style: {
                                fontSize: 16,
                                fontWeight: 800,
                                color: entry.color,
                            }, children: "---" })) : index === 3 ? (_jsx("div", { style: { height: 4, width: 24, background: entry.color } })) : (_jsx("div", { style: { height: 14, width: 14, background: entry.color } })), _jsx("div", { className: "d-inline-block mr-2", style: { fontSize: 12, marginLeft: 8 }, children: entry.label })] }, `item-${index}`));
            }) }));
    };
    return (_jsx(FxCol, { md: 12, xs: 24, children: _jsxs(FxCard, { className: "mb-2", bodyStyle: style, children: [vehicleId && (_jsxs("div", { children: [_jsxs("div", { className: "d-flex align-items-center justify-content-between", children: [_jsx("h6", { className: "mb-4", children: "Fuel Efficiency" }), _jsx("div", { style: { marginTop: -12 }, children: _jsx(FuelTypeSelector, { small: true, fuelTypes: availableFuelTypes, initialFuelType: selectedFuelType, onFuelTypeChange: (v) => setSelectedFuelType(v) }) })] }), isLoading ? (_jsx(FxRow, { justify: "center", align: "middle", style: { height: 300 }, children: _jsx(FxCol, { children: _jsx(FxSpin, { size: "large" }) }) })) : isEmpty(data) ? (_jsx(FxRow, { justify: "center", align: "middle", style: { height: 300 }, children: _jsx(FxCol, { children: _jsx(FxTypography, { children: "No Data Available" }) }) })) : (_jsx(ResponsiveContainer, { height: 300, width: '100%', children: _jsxs(ComposedChart, { data: chartData, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(Tooltip, { formatter: formatter }), _jsx(XAxis, { name: "startDate", dataKey: "startDate", style: {
                                            fontSize: 10,
                                        }, children: _jsx(Label, { value: '', dy: 10, style: {
                                                textAnchor: 'middle',
                                                fontSize: 12,
                                                fontWeight: '600',
                                            }, position: "insideBottom" }) }), _jsx(YAxis, { dataKey: "mileage", type: "number", children: _jsx(Label, { value: `Fuel (Mileage)`, angle: 270, dx: -15, style: {
                                                textAnchor: 'middle',
                                                fontSize: 12,
                                                fontWeight: '600',
                                            } }) }), _jsx(YAxis, { dataKey: "distance", type: "number", yAxisId: "right", orientation: "right", style: {
                                            fontSize: 10,
                                        }, children: _jsx(Label, { value: `Distance`, angle: 270, dx: 15, style: {
                                                textAnchor: 'middle',
                                                fontSize: 12,
                                                fontWeight: '600',
                                            } }) }), _jsx(Bar, { maxBarSize: 20, stackId: "a", dataKey: "mileage", children: (getChartData() || []).map((entry, index) => {
                                            return (_jsx(Cell, { fill: entry.mileage >= entry.avgMileage ? '#76c09d' : '#E86452' }));
                                        }) }), _jsx(Line, { yAxisId: "right", dataKey: "distance", dot: false, stroke: COLORS[0], name: "distance", strokeWidth: 2 }), _jsx(Line, { dataKey: "avgMileage", dot: false, stroke: "#000000", name: "avgMileage", strokeDasharray: "5 5", strokeWidth: 2 }), _jsx(Legend, { verticalAlign: "bottom", align: "center", content: renderLegend })] }) }))] })), vehicleId && chartData.length > 0 && (_jsx(ChartSummary, { headers: SummaryHeading, data: [
                        {
                            ...summaryData,
                            fuelEfficiency: chartData?.[0]?.avgMileage || '',
                        },
                    ] })), !vehicleId && (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mb-4", children: "Fuel Efficiency" }), _jsx(FxRow, { justify: "center", align: "middle", style: { height: 300 }, children: _jsx(FxCol, { children: _jsx(FxTypography, { children: "Please Select a Vehicle" }) }) })] }))] }) }));
};
const mapStateToProps = (state) => {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTripsGraph,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(FuelEfficiency);
