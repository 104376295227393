import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const FETCH_COLLECTION_SUMMARY = 'FETCH_COLLECTION_SUMMARY';
export function fetchCollectionSummary(accesstoken, params = {}) {
    const { startDate, endDate, branchId, voucherType, adviceNumber, creditAccountId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            voucherType,
            adviceNumber,
            creditAccountId,
            size: 7000,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}voucher/unsettled/summary`, config);
    return {
        type: FETCH_COLLECTION_SUMMARY,
        promise: request,
    };
}
export function fetchPaymentReceiptReport(params = {}) {
    const { from, to, customerId } = params;
    const config = {
        params: parseQueryParams({
            from,
            to,
            customerId,
        }),
    };
    return axios.get(`${ROOT_API_ERP}report/payment-receipt-report`, config);
}
export function fetchTDSReceivableReport(params = {}) {
    const { from, to, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber } = params;
    const config = {
        params: parseQueryParams({
            from,
            to,
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
        }),
    };
    return axios.get(`${ROOT_API_ERP}report/payment-receipt-tds-report`, config);
}
export function fetchTDSPayableReport(params = {}) {
    const { from, to, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber } = params;
    const config = {
        params: parseQueryParams({
            from,
            to,
            branchId,
            paymentNature,
            adviceNumber,
            creditAccountId,
            voucherNumber,
        }),
    };
    return axios.get(`${ROOT_API_ERP}report/balance-payment-tds-report`, config);
}
