import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { get, toSafeInteger } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { FxPlusIcon } from 'sharedV2/FxIcons';
import { FxButton, FxCustomSegment, FxDropdown, FxTab, FxTabsWrapper } from '../../../sharedV2';
import { getMomentTime } from '../../../utils/date_utils';
import { queryString } from '../../../utils/string_utils';
import { isCreateAdvanceAllowedForUser, isViewBranchAllowedForUser } from '../roles/permission_utils';
import { PAYMENTS_TABS, PAYMENTS_SUB_TABS, ADJUSTMENT_NOTES_SUB_TABS, tabOptions } from './paymentUtils';
import { filtersReducer, mappedCustomers } from '../../../utils/form_utils';
import CalendarComponent from '../../utils/calendar_component';
import PageLayoutComponent from 'components/utils/pageLayout';
import PaymentDashboardComponent from './PaymentDashboardComponent';
import { fetchCustomers } from '../../../actions/dashboard/finances/customer_action';
import { fetchBranches } from '../../../actions/dashboard/branch/actions';
import { fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { fetchRoutesMiniList } from '../../../actions/dashboard/route_dispatch/routes_action';
import { COLOR_WHITE } from '../../../theme/colors';
import { stringifyFiltersUrl } from '../../../constant';
import PaymentFiltersComponent from './PaymentFiltersComponent';
import MoreFiltersDrawerComponent from 'components/utils/more_filters/MoreFiltersDrawerComponent';
import MoreFiltersButtonComponent from 'components/utils/more_filters/MoreFiltersButtonComponent';
const initialFiltersState = {
    startDate: getMomentTime().subtract(1, 'months'),
    endDate: getMomentTime(),
    branchId: null,
    paymentNature: null,
    adviceNumber: '',
    voucherNumber: '',
    creditAccountId: null,
    page: 1,
    size: 10,
};
const PaymentsComponent = (props) => {
    const { accesstoken, login, customers, loggedInUser } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(PAYMENTS_TABS.PAYMENTS);
    const [activeSubTab, setActiveSubTab] = useState(PAYMENTS_SUB_TABS.UNSETTLED);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, dispatchFilters] = useReducer(filtersReducer, initialFiltersState, (state) => {
        const parsed = queryString.parse(location?.hash);
        const parsedFilters = {};
        if (parsed.startDate) {
            parsedFilters.startDate = getMomentTime(parsed.startDate);
        }
        if (parsed.endDate) {
            parsedFilters.endDate = getMomentTime(parsed.endDate);
        }
        if (parsed.currentPage) {
            parsedFilters.currentPage = toSafeInteger(parsed.currentPage);
        }
        return { ...state, ...parsedFilters };
    });
    const [allBranches, setAllBranches] = useState([]);
    const scope = login.scope;
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, page, size, } = filters;
    useEffect(() => {
        if (isViewBranchAllowedForUser(props.login.scope)) {
            props.fetchBranches(accesstoken).then((res) => {
                setAllBranches(get(res, 'payload.data'));
            });
        }
        props.fetchCustomers(accesstoken);
    }, []);
    const onPageChange = (page, size) => {
        handleFilterChange('page', [page, size]);
    };
    useEffect(() => {
        stringifyFiltersUrl({
            activeTab,
            activeSubTab,
            startDate,
            endDate,
            page,
            size,
        });
    }, [activeTab, activeSubTab, startDate.valueOf(), endDate.valueOf(), page, size]);
    const handleFilterChange = (key, value) => {
        const newFilersState = {};
        switch (key) {
            case 'timeFilter':
                newFilersState.startDate = get(value, '[0]', null);
                newFilersState.endDate = get(value, '[1]', null);
                break;
            case 'page':
                newFilersState.page = get(value, '[0]', null);
                newFilersState.size = get(value, '[1]', null);
                break;
            default:
                newFilersState[key] = value;
                break;
        }
        dispatchFilters({ payload: newFilersState });
    };
    const onTabChange = (key) => {
        if (key === PAYMENTS_TABS.PAYMENTS) {
            setActiveTab(key);
            setActiveSubTab(PAYMENTS_SUB_TABS.UNSETTLED); // setting default sub-tab for Payments
        }
        else if (key === PAYMENTS_TABS.ADJUSTMENT_NOTES) {
            setActiveTab(key);
            setActiveSubTab(ADJUSTMENT_NOTES_SUB_TABS.CREDIT_NOTE); // setting default sub-tab for Adjustment Notes
        }
        else if (Object.values(PAYMENTS_SUB_TABS).includes(key) ||
            Object.values(ADJUSTMENT_NOTES_SUB_TABS).includes(key)) {
            setActiveSubTab(key); // Handle sub-tab selection
        }
        else {
            setActiveTab(key);
            setActiveSubTab(null); // Clear sub-tab if another tab is selected
        }
        handleFilterChange('page', [1, size]);
    };
    const items = [
        isCreateAdvanceAllowedForUser(scope) && {
            key: '1',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/bulk-payment/add');
                }, className: "whitespace-normal", children: "Add Bulk Payment" })),
        },
        isCreateAdvanceAllowedForUser(scope) && {
            key: '2',
            label: (_jsx("a", { onClick: () => {
                    navigate('/dashboard/payments/bulk-adjustment/add');
                }, className: "whitespace-normal", children: "Add Bulk Adjustment" })),
        },
    ].filter(Boolean);
    return (_jsxs(PageLayoutComponent, { pageIcon: null, pageLabel: t('transactionsSettlements'), tabTitle: t('payments'), tabs: _jsx(_Fragment, { children: _jsx(FxTabsWrapper, { children: _jsx(FxTab, { activeKey: activeTab, onChange: onTabChange, items: tabOptions }) }) }), children: [_jsxs(_Fragment, { children: [_jsx(MoreFiltersButtonComponent, { onClick: () => setOpenFilters(!openFilters) }), _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, startDate: startDate, endDate: endDate, onAntdPage: true, withFinancialYear: true }), _jsx(FxDropdown, { menu: { items }, trigger: ['click'], children: _jsx(FxButton, { onClick: (e) => e.stopPropagation(), icon: _jsx(FxPlusIcon, {}) }) })] }), _jsxs(_Fragment, { children: [(activeTab === PAYMENTS_TABS.PAYMENTS || activeTab === PAYMENTS_TABS.ADJUSTMENT_NOTES) &&
                        activeSubTab && (_jsx(FxCustomSegment, { value: activeSubTab, options: tabOptions
                            .find((tab) => tab.key === activeTab)
                            ?.subTabs?.map((subTab) => ({
                            ...subTab,
                            value: subTab.key,
                            label: subTab.label,
                        })), size: 'middle', onSelect: (value) => {
                            onTabChange(value);
                        }, style: { backgroundColor: COLOR_WHITE }, className: "my-2" })), _jsx(PaymentDashboardComponent, { startDate: startDate, endDate: endDate, branchId: branchId, paymentNature: paymentNature, adviceNumber: adviceNumber, creditAccountId: creditAccountId, page: page, size: size, handleFilterChange: handleFilterChange, onPageChange: onPageChange, voucherNumber: voucherNumber, activeTab: activeTab, activeSubTab: activeSubTab }), _jsx(MoreFiltersDrawerComponent, { onClose: () => setOpenFilters(!openFilters), open: openFilters, children: _jsx(PaymentFiltersComponent, { handleFilterChange: handleFilterChange, branches: allBranches, loggedInUser: loggedInUser, branchId: branchId, paymentNature: paymentNature, creditAccountId: creditAccountId, allCustomers: mappedCustomers(customers), activeTab: activeTab, activeSubTab: activeSubTab }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBranches,
        fetchVehicleListMini,
        fetchRoutesMiniList,
        fetchCustomers,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        loggedInUser: state.settings.loggedInUser,
        routeList: state.routes.routeListMiniNew,
        customers: get(state, 'customers.customers', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent);
