import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader from 'components/utils/component_loader_utils';
import { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
const ListVehiclesComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles" */ 'components/dashboard/vehicles/list_vehicles_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditFuelEntryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-fuelentries-add-edit" */ 'components/dashboard/vehicles/fuelentries/add_edit_fuel_entry'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const VehicleAttendanceComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-attendance" */ 'components/dashboard/vehicles/vehicle_attendance_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddOdometerEntryComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-fuelentries-add-edit" */ 'components/dashboard/vehicles/add_odometer_entry_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CreateInspectionChecklistComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-inspection-checklist" */ 'components/dashboard/vehicles/inspectionChecklist/CreateInspectionChecklistComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditVehicleComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-add-edit" */ 'components/dashboard/vehicles/add_edit_vehicle_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditVehicleComponentV2 = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-add-edit-v2" */ 'components/dashboard/vehicles/add_edit_vehicle_component_v2'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewVehicleComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-view" */ 'components/dashboard/vehicles/view_vehicle_component1'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TroDetailsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-vehicles-tro-details" */ 'components/dashboard/vehicles/troDetails/TroDetailsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const vehicleRoutes = [
    {
        index: true,
        lazy: ListVehiclesComponent,
    },
    {
        path: 'add',
        lazy: AddEditVehicleComponent,
    },
    {
        path: 'add/basic',
        lazy: AddEditVehicleComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditVehicleComponent,
    },
    {
        path: 'edit/:id/basic',
        lazy: AddEditVehicleComponent,
    },
    //v2
    {
        path: 'v2/add',
        lazy: AddEditVehicleComponentV2,
    },
    {
        path: 'v2/add/basic',
        lazy: AddEditVehicleComponentV2,
    },
    {
        path: 'v2/edit/:id',
        lazy: AddEditVehicleComponentV2,
    },
    {
        path: 'v2/edit/:id/basic',
        lazy: AddEditVehicleComponentV2,
    },
    //v2 ends
    {
        path: 'view',
        lazy: ViewVehicleComponent,
    },
    {
        path: 'attendance',
        lazy: VehicleAttendanceComponent,
    },
    {
        path: 'add-odomter-entry',
        lazy: AddOdometerEntryComponent,
    },
    {
        path: 'createinspectionchecklist',
        lazy: CreateInspectionChecklistComponent,
    },
    {
        path: 'fuelentries/add',
        lazy: AddEditFuelEntryComponent,
    },
    {
        path: 'fuelentries/add/:vehicleId',
        lazy: AddEditFuelEntryComponent,
    },
    {
        path: 'fuelentries/edit/:vehicleId/:id',
        lazy: AddEditFuelEntryComponent,
    },
    {
        path: 'tro-details',
        lazy: TroDetailsComponent,
    },
];
export default vehicleRoutes;
