import axios from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';
export const CREATE_EPOD_FORM = 'CREATE_EPOD_FORM';
export const FETCH_EPODS_BY_JOB_ID = 'FETCH_EPODS_BY_JOB_ID';
export const FETCH_EPOD_FORM = 'FETCH_EPOD_FORM';
export const UPDATE_EPOD_FORM = 'UPDATE_EPOD_FORM';
export const SEND_EPOD_OTP = 'SEND_EPOD_OTP';
export const VERIFY_EPOD_OTP = 'VERIFY_EPOD_OTP';
export function createEPODForm(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/epod-form`, data, config);
    return {
        type: CREATE_EPOD_FORM,
        promise: request,
    };
}
export function updateEPODForm(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_URL}dispatch/epod-form`, data, config);
    return {
        type: UPDATE_EPOD_FORM,
        promise: request,
    };
}
export function fetchEPODByJobId(accesstoken, jobId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/epod-form/listing`, config);
    return {
        type: FETCH_EPODS_BY_JOB_ID,
        promise: request,
    };
}
export function fetchEPODForm(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/epod-form/`, config);
    return {
        type: FETCH_EPOD_FORM,
        promise: request,
    };
}
export function sendEPODOTP(params, payload) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/send-otp`, payload, config);
    return {
        type: SEND_EPOD_OTP,
        promise: request,
    };
}
export function verifyEPODOTP(payload) {
    const request = axios.post(`${ROOT_API_URL}dispatch/verify-otp`, payload);
    return {
        type: VERIFY_EPOD_OTP,
        promise: request,
    };
}
