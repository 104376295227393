import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const renderPieChartCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (_jsx("text", { x: x, y: y, fill: "black", textAnchor: x > cx ? 'start' : 'end', dominantBaseline: "central", children: `${value}%` }));
};
export const renderPieChartLegend = (props) => {
    return _jsx("div", { className: "font-weight-bold d-inline-block", children: props.legendLabel });
};
export const renderPieChartTooltip = (props, index) => {
    const { active } = props;
    if (active) {
        const { payload, label } = props;
        const { name, value } = payload[0];
        return (_jsx("div", { className: "grph-tooltip-con p-2", children: _jsxs("div", { className: "text-white", children: [_jsxs("span", { className: "text-muted text-bold", children: [name, ": "] }), _jsxs("span", { children: [value, "%"] })] }) }));
    }
    return null;
};
