import { ROLE_MAP } from 'constant';
import { getScopesArr, isWithinScope } from '../utils';
import { get } from 'lodash';
export function isViewERPConfigurationAllowedForUser(scopes) {
    return isWithinScope('VIEW_ERP_CONFIGURATION', scopes);
}
export function isViewTransporterAnalyticsAllowedForUser(scopes) {
    return isWithinScope('VIEW_TRANSPORTER_ANALYTICS', scopes);
}
export function isRoleEPODCustomer() {
    const loggedInUser = window.FLEETX_LOGGED_IN_DATA;
    const roleName = get(loggedInUser, 'role', {});
    return roleName === ROLE_MAP.ROLE_CUSTOMER_EPOD.name;
}
export function isCreateStagesAllowedForUser(scopes) {
    return isWithinScope('CREATE_STAGES', getScopesArr(scopes));
}
export function isDeleteStagesAllowedForUser(scopes) {
    return isWithinScope('DELETE_STAGES', getScopesArr(scopes));
}
export function isViewStagesAllowedForUser(scopes) {
    return isWithinScope('VIEW_STAGES', getScopesArr(scopes));
}
export function isUpdateStagesAllowedForUser(scopes) {
    return isWithinScope('UPDATE_STAGES', getScopesArr(scopes));
}
