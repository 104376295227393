import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import GearPickupFromStandstillGraph from './GearPickupFromStandstillGraph';
import GearSpecificEngineSpeedDistributionGraph from './GearSpecificEngineSpeedDistributionGraph';
import IdealPeakTorqueRPMRangeGraph from './IdealPeakTorqueRPMRangeGraph';
import { fetchGearDistribution, fetchGearStandstillDistribution, } from 'actions/dashboard/vehicles/gearDistributionActions';
import { getEndDate } from 'utils/date_utils';
import { handleError } from '../../../../constant';
import { getPreparedGearDistributionData, getPreparedStandstillData } from 'utils/gearDistributionUtils';
import { FxCol, FxRow } from 'sharedV2';
const GearDistributionComponent = (props) => {
    const { accesstoken, startDate, endDate, vehicleIds } = props;
    const { t } = useTranslation();
    const [gearDistributionData, setGearDistributionData] = useState({});
    const [isLoadingGearDistribution, setIsLoadingGearDistribution] = useState(true);
    const [isLoadingGearStandstillData, setIsLoadingGearStandstillData] = useState(true);
    const [gearStandstillDistributionData, setGearStandstillDistributionData] = useState([]);
    const [error, setError] = useState('');
    const from = startDate.valueOf();
    const to = getEndDate(endDate).valueOf();
    useEffect(() => {
        if (from && to && vehicleIds) {
            setIsLoadingGearDistribution(true);
            setIsLoadingGearStandstillData(true);
            props
                .fetchGearDistribution(accesstoken, {
                from,
                to,
                vehicleIds,
            })
                .then((result) => {
                setIsLoadingGearDistribution(false);
                if (result.error) {
                    throw new Error(handleError(result.payload.response));
                }
                else {
                    setGearDistributionData(getPreparedGearDistributionData(result.payload.data));
                }
            })
                .catch((err) => {
                setIsLoadingGearDistribution(false);
                setGearDistributionData({});
                setError(handleError(err));
            });
            props
                .fetchGearStandstillDistribution(accesstoken, {
                from,
                to,
                vehicleIds,
            })
                .then((result) => {
                setIsLoadingGearStandstillData(false);
                if (result.error) {
                    throw new Error(handleError(result.payload.response));
                }
                else {
                    setGearStandstillDistributionData(getPreparedStandstillData(result.payload.data));
                }
            })
                .catch((err) => {
                setIsLoadingGearStandstillData(false);
                setGearStandstillDistributionData([]);
                setError(handleError(err));
            });
        }
    }, [from, to, vehicleIds]);
    return (_jsxs(FxRow, { gutter: [8, 8], children: [_jsx(FxCol, { md: 12, xs: 24, children: _jsx(GearPickupFromStandstillGraph, { data: gearStandstillDistributionData, isLoading: isLoadingGearStandstillData }) }), _jsx(FxCol, { md: 12, xs: 24, children: _jsx(IdealPeakTorqueRPMRangeGraph, { data: gearDistributionData.gearDistributionPieChartData || [], isLoading: isLoadingGearDistribution }) }), _jsx(FxCol, { md: 24, xs: 24, children: _jsx(GearSpecificEngineSpeedDistributionGraph, { data: gearDistributionData.gearDistributionStackGraphData || [], isLoading: isLoadingGearDistribution }) })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchGearDistribution,
        fetchGearStandstillDistribution,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GearDistributionComponent);
