import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash';
import { FxCol, FxRow, FxSelect, FxTypography, FxButton, FxTable, FxToolTip, } from '../../../../sharedV2';
import { FxDeleteOutlined, FxInfoCircleTwoTone } from '../../../../sharedV2/FxIcons';
import CalendarComponent from '../../../utils/calendar_component';
import { mappedCustomers } from '../../../../utils/form_utils';
import { getPaymentTypeOptions, mappedBillNumbers } from '../paymentUtils';
import { getEndDate, getMomentTime } from 'utils/date_utils';
import { DatePicker, Input, InputNumber, Select } from 'sharedV2/FxFormItems';
const BulkPaymentSectionComponent = (props) => {
    const { index, section, customers, onChange, onBillNumberChange, removeSection, formikProps, getFilteredCustomers, creditStationaryId, debitStationaryId, } = props;
    const { allBillNumbers, entries } = section;
    const { setFieldValue, values } = formikProps;
    const columns = [
        {
            title: 'Bill Number',
            dataIndex: 'billNumber',
            key: 'billNumber',
            render: (text, record) => (_jsx(Input, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.billNumber`, placeholder: "Bill Number", disabled: true })),
            width: 175,
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            render: (text, record) => (_jsx(_Fragment, { children: _jsx(Select, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.paymentType`, options: getPaymentTypeOptions(creditStationaryId, debitStationaryId), placeholder: "Payment Type", formItem: {
                        help: !creditStationaryId && !debitStationaryId && (_jsx(FxTypography.Text, { type: "danger", style: { fontSize: '12px' }, children: "Please select either Credit Book Name or Debit Book Name" })),
                    } }) })),
            width: 250,
        },
        {
            title: 'Amount',
            dataIndex: 'freightAmount',
            key: 'freightAmount',
            render: (text, record) => (_jsx(_Fragment, { children: _jsx(InputNumber, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, placeholder: "Amount", onChange: (e) => {
                        const value = parseFloat(e);
                        const maxFreightAmount = record.freightAmount;
                        if (value <= maxFreightAmount || isNaN(value)) {
                            setFieldValue(`sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, e);
                        }
                        else {
                            alert(`Amount cannot exceed Freight Amount: ${maxFreightAmount}`);
                            setFieldValue(`sections.${record.sectionIndex}.entries.${record.entryIndex}.amount`, '');
                        }
                    }, addonAfter: _jsx(FxToolTip, { title: `Note: Amount cannot exceed the freight amount of ${record.freightAmount}.`, children: _jsx(FxInfoCircleTwoTone, {}) }) }) })),
            width: 200,
        },
        {
            title: 'Raise Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (_jsx(DatePicker, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.date`, type: "text" })),
            width: 200,
        },
        {
            title: 'Advice No.',
            dataIndex: 'adviceNo',
            key: 'adviceNo',
            render: (text, record) => (_jsx(Input, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.adviceNo`, type: "text", placeholder: "Advice No." })),
            width: 175,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            render: (text, record) => (_jsx(Input, { name: `sections.${record.sectionIndex}.entries.${record.entryIndex}.remarks`, type: "text", placeholder: "Remarks" })),
            width: 200,
        },
    ];
    const entriesWithBillNumber = entries
        .map((entry, entryIndex) => ({
        ...entry,
        key: `${index}-${entryIndex}`,
        sectionIndex: index,
        entryIndex,
        freightAmount: get(entry, 'freightAmount'),
    }))
        .filter((entry) => entry.billNumber);
    const handleCustomerChange = (customerId, index) => {
        // Update the selected customer in the current section
        setFieldValue(`sections[${index}].customerId`, customerId);
        // If customerId is cleared, reset related fields
        if (!customerId) {
            setFieldValue(`sections[${index}].entries`, []);
            setFieldValue(`sections[${index}].allBillNumbers`, []);
            setFieldValue(`sections[${index}].startDate`, getMomentTime().subtract(7, 'days'));
            setFieldValue(`sections[${index}].endDate`, getEndDate());
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { gutter: 16, style: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    borderBottom: '1px solid #f0f0f0',
                }, children: _jsx(FxTypography.Title, { level: 5, children: `Customer ${index + 1}` }) }), _jsxs(FxRow, { gutter: 24, children: [_jsx(FxCol, { xs: 24, md: 7, children: _jsx(Select, { name: `sections[${index}].customerId`, placeholder: "Customer Name", options: mappedCustomers(getFilteredCustomers(customers, index), true), onChange: (value) => handleCustomerChange(value, index), required: true }) }), _jsx(FxCol, { xs: 24, md: 7, children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                                onChange(index, 'startDate', startDate, setFieldValue);
                                onChange(index, 'endDate', endDate, setFieldValue);
                            }, startDate: section.startDate, endDate: section.endDate, containerStyles: { width: '100%' }, withoutTime: true, hideHourlyRange: true }) }), _jsx(FxCol, { xs: 24, md: 8, children: _jsx(FxSelect, { options: mappedBillNumbers(allBillNumbers), placeholder: "Bill Number", onChange: (selected) => {
                                const selectedBills = selected.map((selectedId) => {
                                    const bill = allBillNumbers.find((bill) => bill.id === selectedId);
                                    if (bill) {
                                        return {
                                            billId: bill.id,
                                            billNumber: get(bill, 'basicDetails.billNumber'),
                                            freightAmount: get(bill, 'basicDetails.freightAmount'),
                                        };
                                    }
                                    return null;
                                });
                                onBillNumberChange(selectedBills, index);
                            }, mode: "multiple" }) }), _jsx(FxCol, { xs: 24, md: 2, children: index > 0 && (_jsx(FxToolTip, { title: "Remove Section", children: _jsx(FxButton, { type: "default", onClick: removeSection, icon: _jsx(FxDeleteOutlined, {}) }) })) })] }), _jsx(FxRow, { children: _jsx(FxCol, { xs: 24, md: 24, children: entriesWithBillNumber.length > 0 && (_jsx(FxTable, { columns: columns, dataSource: entriesWithBillNumber, pagination: false, style: { marginTop: '1rem' } })) }) })] }));
};
export default BulkPaymentSectionComponent;
