export const DTCsFiveDigitCode = [
    'P0336',
    'P0335',
    'P0341',
    'P0340',
    'P0016',
    'P0238',
    'P0237',
    'P0235',
    'P0236',
    'P0113',
    'P0112',
    'P0118',
    'P0117',
    'P0116',
    'P2622',
    'P2621',
    'P2108',
    'P2100',
    'P2103',
    'P2102',
    'P2228',
    'P2229',
    'P042E',
    'P213A',
    'P0487',
    'P0406',
    'P0405',
    'P040D',
    'P040C',
    'P040B',
    'P2457',
    'P0401',
    'P0193',
    'P0192',
    'P228C',
    'P228D',
    'P228A',
    'P0087',
    'P0191',
    'P1111',
    'P1110',
    'P0090',
    'P0254',
    'P0002',
    'P000F',
    'P0201',
    'P0202',
    'P0203',
    'P0204',
    'P0262',
    'P0265',
    'P0268',
    'P0271',
    'P2148',
    'P2151',
    'P2147',
    'P2150',
    'P2696',
    'P026A',
    'P1262',
    'P1265',
    'P1268',
    'P1271',
    'P1261',
    'P1264',
    'P1267',
    'P1270',
    'P02CC',
    'P02D0',
    'P02D2',
    'P02CE',
    'P268A',
    'P0123',
    'P0122',
    'P0223',
    'P0222',
    'P2135',
    'P2299',
    'P0503',
    'P0501',
    'P0504',
    'P0563',
    'P0562',
    'P2267',
    'P2266',
    'P2269',
    'P1B01',
    'P1B02',
    'P1B03',
    'P0217',
    'P0127',
    'P0615',
    'P081B',
    'P081A',
    'P0617',
    'P0616',
    'U0113',
    'P0219',
    'P0641',
    'P0651',
    'P0697',
    'P06A3',
    'P060C',
    'P0685',
    'P068A',
    'P062D',
    'P062E',
    'P062F',
    'P060A',
    'P0341',
    'P0340',
    'P2227',
    'P228C',
    'P0088',
    'P0089',
    'P0112',
    'P0117',
    'P0524',
    'P0522',
    'P0523',
    'P0198',
    'P0197',
    'P0254',
    'P0253',
    'P0205',
    'P0206',
    'P0274',
    'P0277',
    'P0261',
    'P0264',
    'P0267',
    'P0270',
    'P0273',
    'P0276',
    'P0223',
    'P0503',
    'P0704',
    'P2671',
    'P2685',
    'P0658',
    'P0659',
    'P0194',
    'P018F',
    'P009E',
    'P2413',
    'P042F',
    'P0406',
    'P2535',
    'P0617',
    'P1B06',
    'P2112',
    'P2111',
    'P0692',
    'P0693',
    'P0495',
    'P0494',
    'P0691',
    'P0263',
    'P0266',
    'P0269',
    'P0272',
    'P0275',
    'P0278',
    'P0507',
    'P0506',
    'P062B',
    'U0121',
    'U1004',
    'P1015',
    'P1016',
    'P01FF',
    'P06AF',
    'P081D',
    'P0183',
    'P0928',
    'P0931',
    'P0930',
    'P0706',
    'P060D',
    'P061C',
    'P0611',
    'P061A',
    'P1218',
    'P0098',
    'P0298',
    'P0198',
    'P0402',
    'P1458',
    'P1459',
    'P2413',
    'P0103',
    'P0102',
    'P0101',
    'U0011',
    'P1272',
    'U0073',
    'U010E',
    'U010A',
    'U029D',
    'U029E',
    'U02A2',
    'P200E',
    'P244D',
    'P24A2',
    'P1451',
    'P2463',
    'P204F',
    'P0071',
    'P205F',
    'P0421',
    'P242F',
    'P244A',
    'P26B6',
    'P20CE',
    'P20CB',
    'P1273',
    'P20CF',
    'P1A26',
    'P06D4',
    'P06D3',
    'P2204',
    'P160A',
    'P1608',
    'P160C',
    'P1601',
    'P1602',
    'P2209',
    'P22A2',
    'P22A1',
    'P22A7',
    'P1603',
    'P1604',
    'P2667',
    'P2666',
    'P20E0',
    'P20DF',
    'P1A21',
    'P2455',
    'P2454',
    'P2453',
    'P2459',
    'P20EE',
    'P0546',
    'P0545',
    'P0544',
    'P1614',
    'P2032',
    'P242B',
    'P2031',
    'P242D',
    'P242C',
    'P2482',
    'P2481',
    'P2483',
    'P007D',
    'P007C',
    'P007B',
    'P0111',
    'P0097',
    'P0096',
    'P0182',
    'P203F',
    'P207F',
    'P2045',
    'P2044',
    'P203B',
    'P203D',
    'P205D',
    'P205C',
    'P2080',
    'P206D',
    'P206B',
    'P2BAE',
    'P1469',
    'P20A6',
    'U1002',
    'P1700',
    'P1701',
    'P1702',
    'P1703',
    'P2BA7',
    'P1350',
    'P263D',
    'P0070',
    'P1A2A',
    'P2042',
    'P205A',
    'P20F5',
    'P218F',
    'P1304',
    'P1484',
    'P214A',
    'P214B',
    'P214C',
    'P130A',
    'U0037',
    'U0001',
    'P245E',
    'P1900',
    'P2002',
    'P224D',
    'P1902',
    'P20CA',
    'P1A25',
    'P1A2D',
    'P20DD',
    'P1A22',
    'P1A23',
    'P2665',
    'P1508',
    'P1502',
    'P1A29',
    'P1514',
    'P20D1',
    'P20D5',
    'P1418',
    'P1419',
    'P1420',
    'P1421',
    'P1422',
    'P1423',
    'P1424',
    'P1425',
    'P21CE',
    'U05A3',
    'U1005',
    'U1001',
    'P22FB',
    'P2200',
    'U059E',
    'P22FE',
    'P229E',
    'U059F',
    'P2037',
    'P204A',
    'P1426',
    'P1427',
    'P1428',
    'P1429',
    'U100B',
    'P242A',
    'P2480',
    'P0560',
    'U1003',
    'U1015',
    'U1006',
    'U100D',
    'U1007',
    'U1008',
    'U1009',
    'U100A',
    'U100E',
    'U1018',
    'U1012',
    'P1600',
    'P225F',
    'P1607',
    'P2BAF',
    'P220B',
    'P0607',
    'U1020',
    'U1021',
    'P20E6',
    'P20E7',
    'P1443',
    'P208A',
    'P1450',
    'P246E',
    'P2047',
    'P1447',
    'P143F',
    'P20E9',
    'P20E8',
    'P244A',
    'P2033',
    'P200C',
    'U1611',
    'P1615',
    'P1616',
    'P1623',
    'P1613',
    'P2471',
    'P2470',
    'P20EC',
    'P2472',
    'P214D',
    'U1612',
    'P1625',
    'P1626',
    'P1627',
    'P1628',
    'P1624',
    'P1A28',
    'P1A15',
    'P1A31',
    'P2509',
    'U0002',
    'U0038',
    'U012A',
    'U042B',
    'U0100',
    'P0500',
    'P0687',
    'P0686',
    'P0073',
    'P0072',
    'P2456',
    'P1A20',
    'P1A27',
    'P1A24',
    'P0652',
    'P0653',
    'P20DE',
    'P2202',
    'P2201',
    'P225C',
    'P225D',
    'P225E',
    'P220A',
    'P1C54',
    'P203C',
    'U0619',
    'P203A',
    'P203E',
    'P205E',
    'P2043',
    'P1A17',
    'P206C',
    'P2049',
    'P2048',
    'P20A9',
    'P20A8',
    'P204D',
    'P204C',
    'P202F',
    'P204E',
    'P2038',
    'P2040',
    'P2039',
    'P21CB',
    'P21CC',
    'P05F4',
    'P05F5',
    'P1A2C',
    'P1A2B',
    'P1A12',
    'P1629',
    'P249F',
    'P25BA',
    'P1644',
    'U0401',
    'P20A3',
    'P20A2',
    'P5864',
    'P5865',
    'P208D',
    'P208C',
    'P202E',
    'P20F4',
    'P20CC',
    'P26B5',
    'P1A1B',
    'P1A1C',
    'P1A1F',
    'P20E1',
    'P246B',
];

export const DTCsFourDigitCode = [
    '0084',
    '0108',
    '0110',
    '0171',
    '0190',
    '0513',
    '0639',
    '1231',
    '1761',
    '3031',
    '3216',
    '3217',
    '3224',
    '3226',
    '3234',
    '3236',
    '3242',
    '3250',
    '3251',
    '3361',
    '3364',
    '3472',
    '3480',
    '3481',
    '3482',
    '3509',
    '3510',
    '3511',
    '3512',
    '3516',
    '3519',
    '3556',
    '3936',
    '4077',
    '4334',
    '4335',
    '4337',
    '4360',
    '4363',
    '4364',
    '4765',
    '4795',
    '5018',
    '5319',
    '5397',
    '5456',
    '5722',
    '5723',
    '5862',
    '5966',
    '6480',
    '0002',
    '0016',
    '0087',
    '0118',
    '0191',
    '0236',
    '0254',
    '0265',
    '0268',
    '0271',
    '0335',
    '0340',
    '0402',
    '0406',
    '0615',
    '0616',
    '0641',
    '0651',
    '0685',
    '0697',
    '1261',
    '1262',
    '1264',
    '1265',
    '1267',
    '1268',
    '1270',
    '1271',
    '1310',
    '1316',
    '1322',
    '2100',
    '2102',
    '2103',
    '2108',
    '2147',
    '2150',
    '2266',
    '2267',
    '2299',
    '2696',
    '0112',
    '0113',
    '0118',
    '0123',
    '0127',
    '0217',
    '0223',
    '0235',
    '0237',
    '0238',
    '040B',
    '0501',
    '0503',
    '0504',
    '0562',
    '0563',
    '060A',
    '0617',
    '062D',
    '062E',
    '062F',
    '1000',
    '0101',
    '0102',
    '0103',
    '10EB',
    '10EE',
    '10EF',
    '10F0',
    '10F1',
    '1100',
    '1104',
    '1106',
    '1108',
    '110B',
    '110C',
    '0111',
    '1110',
    '1111',
    '1118',
    '0112',
    '0113',
    '0118',
    '1218',
    '0123',
    '0127',
    '1271',
    '1272',
    '1273',
    '129D',
    '12BA',
    '12BB',
    '12BC',
    '1304',
    '1310',
    '1316',
    '1322',
    '1328',
    '1334',
    '139A',
    '13A0',
    '13A7',
    '1451',
    '1458',
    '1459',
    '14B2',
    '14C7',
    '1512',
    '1515',
    '1543',
    '1550',
    '157E',
    '0016',
    '1601',
    '1602',
    '1603',
    '1604',
    '160A',
    '1614',
    '165A',
    '165B',
    '166E',
    '166F',
    '16E6',
    '174E',
    '0182',
    '0183',
    '0193',
    '1950',
    '0197',
    '0198',
    '1A21',
    '1A26',
    '1A92',
    '1B01',
    '1B02',
    '1B03',
    '01BC',
    '200E',
    '0201',
    '2031',
    '2032',
    '203B',
    '203D',
    '203F',
    '2044',
    '2045',
    '204B',
    '204F',
    '205C',
    '205D',
    '206D',
    '207F',
    '2080',
    '020B',
    '20CB',
    '20CE',
    '20CF',
    '20DF',
    '20E0',
    '20EE',
    '2100',
    '2103',
    '2108',
    '2135',
    '213A',
    '2147',
    '2148',
    '2150',
    '2151',
    '0217',
    '0219',
    '2204',
    '2209',
    '2227',
    '2228',
    '2229',
    '0223',
    '2269',
    '228A',
    '229F',
    '22A1',
    '22A2',
    '22A7',
    '0235',
    '0238',
    '2413',
    '242B',
    '242C',
    '242D',
    '242F',
    '244A',
    '244D',
    '2453',
    '2454',
    '2455',
    '2457',
    '2459',
    '2463',
    '2481',
    '2482',
    '2483',
    '024A',
    '24A2',
    '251E',
    '2535',
    '0254',
    '0262',
    '2621',
    '2622',
    '0263',
    '0265',
    '0266',
    '2666',
    '2667',
    '0268',
    '268A',
    '0269',
    '2696',
    '026A',
    '26B6',
    '0271',
    '0272',
    '0274',
    '0275',
    '0277',
    '0278',
    '027F',
    '0298',
    '2BAE',
    '02CC',
    '02CE',
    '02D0',
    '02D2',
    '2E01',
    '2E03',
    '0335',
    '0340',
    '0400',
    '0405',
    '040B',
    '040C',
    '040D',
    '0421',
    '0432',
    '0487',
    '0494',
    '04CF',
    '0506',
    '0507',
    '0522',
    '0523',
    '0524',
    '0054',
    '0544',
    '0545',
    '0546',
    '0562',
    '0563',
    '060C',
    '0615',
    '0616',
    '0617',
    '0641',
    '0651',
    '0658',
    '0659',
    '068A',
    '0691',
    '0692',
    '06A3',
    '006C',
    '06D3',
    '06D4',
    '006E',
    '06E1',
    '0706',
    '0071',
    '007B',
    '007C',
    '007D',
    '7E000',
    '7E001',
    '7E002',
    '7E003',
    '7E004',
    '7E005',
    '7E006',
    '7E008',
    '7E00B',
    '7E00C',
    '7E00E',
    '7E010',
    '7E011',
    '7E012',
    '7E013',
    '7E015',
    '7E018',
    '7E019',
    '7E01A',
    '7E01B',
    '7E01C',
    '7E01D',
    '7E01E',
    '7E027',
    '7F3F3',
    '7F3F4',
    '7F3F5',
    '7F3F6',
    '7F3F9',
    '7F3FA',
    '7F45D',
    '081A',
    '081B',
    '0088',
    '0090',
    '0930',
    '0931',
    '0096',
    '0097',
    '0098',
    '0A4A',
    '00A8',
    '00AB',
    '0BD7',
    '00BE',
    'C073',
    'C10A',
    'C10E',
    'C113',
    'C29D',
    'C29E',
    'C2A2',
    '00C90',
    '00C91',
    '00C92',
    '00C98',
    '00C9A',
    '00C9B',
    '00C9C',
    '00CA2',
    '00CA4',
    '00CAA',
    '00CAE',
    '00CB2',
    '00CB3',
    '00CC2',
    '00D00',
    'D001',
    '00D21',
    '00D22',
    '00D24',
    '00D90',
    '00D98',
    '00D99',
    '00D9A',
    '0DB5',
    '0DB6',
    '0DB7',
    '0DB8',
    '0DBB',
    '0DBC',
    '0DBF',
    '00DD7',
    '00DD9',
    '00DE4',
    '00E0D',
    '00E1A',
    '00E6F',
    '00E77',
    '00E81',
    '00EF2',
    '000F5',
    '00F60',
    '00FED',
];
