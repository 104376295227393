import { find, get, isEmpty, isNull, round, split, toSafeInteger } from 'lodash';
import { getMomentTime } from 'utils/date_utils';
export function isDataPointDuringChargingState(dataPoint, chargingPerformanceData) {
    if (!dataPoint || isEmpty(chargingPerformanceData)) {
        return false;
    }
    return !!find(chargingPerformanceData, (d) => {
        return getMomentTime(dataPoint.timeStamp).isBetween(d.chargingStartTime, d.chargingStopTime);
    });
}
export function isElectricVehicle(liveVehicle, moreConfig = {}) {
    let isElectric = false;
    const fuelType = get(liveVehicle, 'fuelType');
    if (fuelType === 'Electric') {
        isElectric = true;
    }
    return isElectric;
}
export function getEVVehicleBatteryLevelPercentage(liveVehicle, moreConfig = {}) {
    const {} = moreConfig;
    let batteryPercent = get(liveVehicle, 'otherAttributes.evBatterySOC', null);
    if (isNull(batteryPercent)) {
        batteryPercent = get(liveVehicle, 'otherAttributes.evBatteryLevelBand', null);
    }
    return isNull(batteryPercent) ? null : round(batteryPercent, 2);
}
export function getEvVehicleVoltage(liveVehicle, moreConfig = {}) {
    const evVoltage = get(liveVehicle, 'otherAttributes.evVoltage', null);
    return isNull(evVoltage) ? null : round(evVoltage, 2);
}
export function getEvVehicleBatteryKmRange(liveVehicle, moreConfig = {}) {
    const { otherAttributes } = liveVehicle || {};
    const { evRangeForBand = null, range = null } = otherAttributes || {};
    let batteryKmRange = null;
    if (range) {
        batteryKmRange = range;
    }
    else if (evRangeForBand) {
        batteryKmRange = evRangeForBand;
    }
    return batteryKmRange;
}
export function getEvVehicleGearState(liveVehicle, moreConfig = {}) {
    return get(liveVehicle, 'otherAttributes.evGearState', null);
}
export function evVehicleBatterLevelFilterOptions() {
    return [
        {
            value: '0-20',
            label: '0-20%',
        },
        {
            value: '21-40',
            label: '21-40%',
        },
        {
            value: '41-60',
            label: '41-60%',
        },
        {
            value: '61-80',
            label: '61-80%',
        },
        {
            value: '81-100',
            label: '80-100%',
        },
    ];
}
export function isVehicleInSelectedBatteryLevelRange(evBatteryLevelFilter, liveVehicle, moreConfig = {}) {
    const {} = moreConfig;
    const filterRangeArr = split(evBatteryLevelFilter, '-');
    const minValuePerc = toSafeInteger(filterRangeArr[0]);
    const maxValuePerc = toSafeInteger(filterRangeArr[1]);
    const vehicleBatteryLevelPerc = getEVVehicleBatteryLevelPercentage(liveVehicle);
    if (vehicleBatteryLevelPerc === null) {
        return false;
    }
    return vehicleBatteryLevelPerc >= minValuePerc && vehicleBatteryLevelPerc <= maxValuePerc;
}
export const getIntervalOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '30', value: 30 },
    { label: '45', value: 45 },
    { label: '60', value: 60 },
];
export function isFastCharging(liveVehicle) {
    const { otherAttributes } = liveVehicle || {};
    const typeOfCharging = get(otherAttributes, 'typeOfCharging', null);
    return typeOfCharging === 'fast charging';
}
