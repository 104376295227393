import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const TripBridge = (props) => {
    return (_jsxs("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_735_6646", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "19", height: "18", children: _jsx("rect", { x: "0.875", width: "18", height: "18", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_735_6646)", children: _jsx("path", { d: "M2.75 8.22262V7.05581C3.92025 6.76056 5.09838 6.55406 6.28438 6.43631C7.4705 6.31856 8.66738 6.25969 9.875 6.25969C11.0826 6.25969 12.2795 6.31856 13.4656 6.43631C14.6516 6.55406 15.8298 6.76056 17 7.05581V8.22262C16.75 8.16012 16.5 8.10387 16.25 8.05387L15.5 7.90387V10.2909H14.375V7.70625C13.625 7.59662 12.875 7.51562 12.125 7.46325C11.375 7.41087 10.625 7.38469 9.875 7.38469C9.125 7.38469 8.375 7.40775 7.625 7.45387C6.875 7.5 6.125 7.57788 5.375 7.6875V10.2909H4.25V7.88512C4 7.93512 3.75 7.98825 3.5 8.0445C3.25 8.10075 3 8.16012 2.75 8.22262ZM5.79331 15.375L6.81594 9.14719C7.02169 9.12219 7.213 9.10513 7.38987 9.096C7.56687 9.08675 7.75825 9.07588 7.964 9.06338L6.91831 15.375H5.79331ZM7.89181 2.625H9.01681L8.69225 4.59656C8.48175 4.60906 8.29113 4.62113 8.12038 4.63275C7.94975 4.64425 7.75913 4.6625 7.5485 4.6875L7.89181 2.625ZM9.3125 15.375H10.4375V12.8004H9.3125V15.375ZM9.3125 11.0121H10.4375V9.04031H9.3125V11.0121ZM10.7332 2.625H11.8582L12.2015 4.6875C11.9956 4.675 11.8062 4.65988 11.6332 4.64213C11.4601 4.62425 11.2706 4.60906 11.0649 4.59656L10.7332 2.625ZM12.8317 15.375L11.786 9.06338C11.9917 9.08075 12.1843 9.09475 12.3637 9.10538C12.5431 9.11588 12.7356 9.13363 12.9414 9.15863L13.9567 15.375H12.8317Z", fill: "#FAAD14" }) })] }));
};
const TripBridgeIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: TripBridge, ...props });
};
export default TripBridgeIcon;
