import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from 'constant';
export const FETCH_BILLS = 'FETCH_BILLS';
export const FETCH_GST_OUTPUT_REPORT = 'FETCH_GST_OUTPUT_REPORT';
export function fetchBills(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}bill/filter`, config);
    return {
        type: FETCH_BILLS,
        promise: request,
    };
}
export function fetchGSTOutputReport(params = {}) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ERP}report/gst-output-report`, config);
    return {
        type: FETCH_GST_OUTPUT_REPORT,
        promise: request,
    };
}
