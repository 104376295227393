import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FxCol, FxRow } from 'sharedV2/index';
import { map } from 'lodash';
import './index.scss';
const ChartSummary = (props) => {
    const colSpan = Math.floor(24 / props.headers.length);
    return (_jsxs("div", { className: "chart-summary", children: [_jsx(FxRow, { gutter: [0, 0], style: { background: '#F6F8FA' }, children: map(props.headers, (i) => {
                    return (_jsx(FxCol, { md: i.colSpan || colSpan, xs: i.colSpan || colSpan, style: { whiteSpace: 'nowrap', fontSize: 12 }, children: i.label }));
                }) }), map(props.data, (i) => {
                return (_jsx(FxRow, { gutter: [0, 0], style: { borderTop: 'none' }, children: map(props.headers, (j) => {
                        return (_jsx(FxCol, { md: j.colSpan || colSpan, xs: j.colSpan || colSpan, children: _jsx("b", { children: i[j.dataIndex] }) }));
                    }) }));
            })] }));
};
export default ChartSummary;
