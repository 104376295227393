import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getHumanizeTime } from '../../../utils/date_utils';
import { isUpdateArrivalAllowedForUser, isDeleteArrivalAllowedForUser } from '../roles/permission_utils';
const ArrivalListingComponent = (props) => {
    const { data, currentPage, sizePerPage, groupedBranches, groupedVehicles, login, onDeleteArrival, isLorryArrival, onOpenReportForTemplate, totalDataSize, } = props;
    const scope = login.scope;
    const isUpdateAllowed = isUpdateArrivalAllowedForUser(scope);
    const isDeleteArrival = isDeleteArrivalAllowedForUser(scope);
    const showButtons = (id, row) => {
        return (_jsx("div", { onClick: (e) => {
                e.stopPropagation();
            }, children: _jsxs(UncontrolledButtonDropdown, { className: "pos-static", direction: "down", children: [_jsx(DropdownToggle, { className: "px-3", children: _jsx("i", { className: "fa fa-ellipsis-v", style: { fontSize: '1.1rem' } }) }), _jsxs(DropdownMenu, { style: { width: '12rem' }, right: true, children: [isUpdateAllowed && (_jsx(DropdownItem, { onClick: () => {
                                    if (isLorryArrival) {
                                        window.open(`/dashboard/arrival/lorry/edit/${id}`);
                                    }
                                    else {
                                        window.open(`/dashboard/arrival/edit/${id}`);
                                    }
                                }, children: _jsx("span", { children: "Edit" }) })), isDeleteArrival && _jsx(DropdownItem, { onClick: () => onDeleteArrival(id), children: "Delete" }), _jsx(DropdownItem, { onClick: (e) => {
                                    props.onOpenReportForTemplate(row);
                                }, children: "Print" })] })] }) }));
    };
    const showVehicleNumber = (vehicleId) => {
        return _jsx("div", { children: get(groupedVehicles[vehicleId], `0.licensePlate`, '-') });
    };
    const showDate = (date) => {
        return _jsx("div", { children: getHumanizeTime(date) });
    };
    const showArrivalOffice = (branchId) => {
        return _jsx("div", { children: get(groupedBranches[branchId], `0.officeName`, '-') });
    };
    const showCNHireSlipNo = (col, row, formatExtraData) => {
        const { dataField } = formatExtraData;
        const materials = get(row, 'materials', []);
        const consignmentId = get(row, 'materials.0.consignmentId', '');
        const consignmentNumber = get(row, 'materials.0.consignmentNumber', '');
        const refNo = get(row, 'materials.0.refNo', '');
        const hireSlipId = get(row, 'materials.0.hireSlipId', '');
        const consignmentStatus = get(row, 'materials.0.consignmentStatus', '');
        return (_jsx(_Fragment, { children: materials.length === 1 ? (_jsx("div", { children: dataField === 'consignmentNumber' ? (_jsx(Link, { target: "_blank", to: `/dashboard/consignments/edit/${consignmentId}/${consignmentStatus}`, children: consignmentNumber })) : (_jsx(Link, { target: "_blank", to: `/dashboard/hire-slip/edit/${hireSlipId}`, children: refNo })) })) : (_jsx(_Fragment, {})) }));
    };
    const options = {
        noDataText: 'No entry found.',
        paginationPosition: 'both',
        hideSizePerPage: true,
        sizePerPage: 10,
        page: currentPage,
        alwaysShowAllBtns: true,
        onPageChange: props.onPageChange,
    };
    return (_jsx("div", { className: "fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background pos-static", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", hover: true, data: data, options: options, remote: true, pagination: true, version: "4", fetchInfo: { dataTotalSize: totalDataSize }, children: [_jsx(TableHeaderColumn, { width: "130", dataField: "arrivalNumber", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Arrival No." }), _jsx(TableHeaderColumn, { width: "130", dataField: "arrivalType", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Arrival Type" }), _jsx(TableHeaderColumn, { width: "130", dataField: "arrivalDate", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showDate, children: "Arrival Date" }), _jsx(TableHeaderColumn, { width: "130", dataField: "arrivalBranchId", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showArrivalOffice, children: "Arrival Office" }), _jsx(TableHeaderColumn, { width: "120", dataField: "vehicleId", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showVehicleNumber, children: "Vehicle Number" }), _jsx(TableHeaderColumn, { width: "120", dataField: "consignmentNumber", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showCNHireSlipNo, formatExtraData: { dataField: 'consignmentNumber' }, children: "Consignment" }), _jsx(TableHeaderColumn, { width: "120", dataField: "refNo", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, dataFormat: showCNHireSlipNo, formatExtraData: { dataField: 'refNo' }, children: "Hire Slip" }), _jsx(TableHeaderColumn, { hidden: !isUpdateAllowed, columnClassName: "td-column-center", width: "100", dataFormat: showButtons, isKey: true, dataField: "id", children: "Action" })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ArrivalListingComponent);
