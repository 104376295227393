import {
    DEFAULT_LOCATION_SEARCH_RADIUS,
    DEFAULT_RUNNING_MAX_KM,
    SET_LAST_REFERRAL_SHOWN_TIME,
    SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_RADIUS,
    SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_SELECTED_ADDRESSES,
    SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_MAXKM,
    SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_ONLYJOBS,
    SET_LOCAL_DATE_PREFERENCE,
    SET_LOCAL_DEFAULT_GROUP_ID,
    SET_LOCAL_GEOCODED_LOCATIONS,
    SET_LOCAL_HISTORICAL_SEARCH_TIMESTAMP,
    SET_LOCAL_JS_VERSION,
    SET_LOCAL_MINIMIZE_OVERSPEED_GRAPH,
    SET_LOCAL_RESET_LOCAL_SETTINGS,
    SET_LOCAL_ROUTE_ONLY_POINTS,
    SET_LOCAL_SEARCH_LOCATION_RADIUS,
    SET_LOCAL_SOS_CHECK_FROM_USING_LIVE_DATA_TIMESTAMP,
    SET_LOCAL_STOPPAGE_TIME_MINUTES,
    SET_LOCAL_TRIP_VIEW_TYPE_PREFERENCE,
    SET_LOCAL_TRANSPORTER_ACCOUNT,
    SET_LOCAL_VEHICLE_ID_PREFERENCE,
    SET_LOCAL_SHOW_OPEN_APP_DRAWER,
    SET_LOCAL_TYRE_ID_PREFERENCE,
    SET_SHOW_NEW_UI_FLAG,
    SET_DEFAULT_NEW_JOB_FORM_FLAG,
    SET_TEMP_GRAPH_INTERVAL,
    DEFAULT_TEMP_GRAPH_INTERVAL,
    DEFAULT_EV_BATTERY_LEVEL_GRAPH_INTERVAL,
    SET_NEW_FEATURE_ANNOUNCEMENT_INFO,
    SET_USER_PERSONALISATION,
    SET_REATIME_CURRENT_VIEW,
    SET_REATIME_TOUR_SHOWN,
    SET_SHOW_NEW_ROUTE_FORM,
    SET_CURRENT_LANGUAGE,
    SET_EV_BATTERY_LEVEL_GRAPH_INTERVAL,
    SET_REALTIME_NOTIFICATION_INFO,
    SET_VEHICLE_VIEW_ON_JOB_MAP,
    SET_OVERRIDE_DEFAULT_VEHICLE_VIEW_ON_JOB_MAP,
    SET_CONSIGNMENT_TRACKING_LOGIN_DETAILS,
    SET_LOCAL_CUSTOMER_ID,
    SET_NEW_FEATURE_ANNOUNCEMENT_LAST_CLOSED_TIME,
    SET_ANNOUNCEMENT_POPUP_INFO,
    TEMP_GRAPH_TOUR,
    FUEL_GRAPH_TOUR,
    SET_TABLE_CONFIG,
    SET_NAVIGATION_STYLE,
    TOGGLE_BANNER,
    setLRUConfig,
    SET_LRU_CONFIG,
    SET_USER_GROUPS,
} from '../../actions/dashboard/local_action';
import { REALTIME_VIEWS } from '../../constant';
import { FETCH_LOGGED_IN_USER } from 'actions/dashboard/setting_action';
import { ISO_LANGUAGE_CODES } from 'utils/internationalization_utils';
import { get, set } from 'lodash';
import { overrideSidebarStyle } from 'utils/account_utils';

export const getDefaultState = () => {
    return {
        defaultGroupId: null,
        controlRoomLocationDashboardRadius: DEFAULT_LOCATION_SEARCH_RADIUS,
        controlRoomRunningDashboardMaxKm: DEFAULT_RUNNING_MAX_KM,
        controlRoomRunningDashboardOnlyJobs: false,
        controlRoomLocationDashboardSelectedAddresses: [],
        stoppageTimeMinutes: 0,
        referralShownTimeInfo: {
            lastTime: null,
            noOfTimesClosed: 0,
        },
        showNewUi: {
            showNewUiDefault: true,
            isNewUiOpted: false,
        },
        showNewJobForm: false,
        showNewRouteForm: false,
        showNewRouteFormDefault: true,
        userPersonalisation: {
            showHeaders: {
                realtime: true,
                overview: true,
                trips: true,
                vehicles: true,
                jobs: true,
                reminders: true,
                finance: true,
                vehicleRecords: true,
                issues: true,
                alarms: true,
                reportsModule: true,
                addresses: true,
                dtcs: true,
                assets: true,
            },
        },
        tempGraphInterval: DEFAULT_TEMP_GRAPH_INTERVAL,
        EVBatteryLevelGraphInterval: DEFAULT_EV_BATTERY_LEVEL_GRAPH_INTERVAL,
        realtimeCurrentView: REALTIME_VIEWS.DEFAULT,
        newFeatureAnnouncementInfo: {
            fNumber: 0,
            isCallToActionUrlClicked: false,
            lastShown: null,
        },
        announcementPopupInfo: {
            fNumber: 0,
            isCallToActionUrlClicked: false,
            lastShown: null,
        },
        realtimeNotificationInfo: {
            fNumber: 0,
            isCallToActionUrlClicked: false,
            lastShown: null,
        },
        i18nextLng: 'en',
        isVehicleViewOnMapEnabled: true,
        isDefaultVehicleViewOnMapEnabledOverWritten: false,
        consignmentTrackingLogin: {
            username: '',
            password: '',
            gstin: '',
        },
        localCustomerId: null,
        localCustomerIds: null,
        newFeatureAnnouncementLastClosedTime: null,
        userFeedbackModalClosed: false,
        tempGraphTourInfo: {
            lastShown: null,
        },
        fuelGraphTourInfo: {
            lastShown: null,
        },
        tableConfig: {},
        isNewStyle: overrideSidebarStyle(),
        isBannerOpen: false,
        lruConfig: [],
        userGroups: [],
    };
};

export default function (state, action) {
    if (!state) {
        state = getDefaultState();
    }
    const { type, payload } = action;
    switch (type) {
        case SET_LOCAL_DATE_PREFERENCE:
        case SET_LOCAL_VEHICLE_ID_PREFERENCE:
        case SET_LOCAL_TRIP_VIEW_TYPE_PREFERENCE:
        case SET_LOCAL_GEOCODED_LOCATIONS:
        case SET_LOCAL_ROUTE_ONLY_POINTS:
        case SET_LOCAL_DEFAULT_GROUP_ID:
        case SET_LOCAL_MINIMIZE_OVERSPEED_GRAPH:
        case SET_LOCAL_JS_VERSION:
        case SET_LOCAL_SEARCH_LOCATION_RADIUS:
        case SET_LOCAL_HISTORICAL_SEARCH_TIMESTAMP:
        case SET_LOCAL_SOS_CHECK_FROM_USING_LIVE_DATA_TIMESTAMP:
        case SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_RADIUS:
        case SET_LOCAL_CONTROL_ROOM_LOCATION_DASHBOARD_SELECTED_ADDRESSES:
        case SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_MAXKM:
        case SET_LOCAL_CONTROL_ROOM_RUNNING_DASHBOARD_ONLYJOBS:
        case SET_LOCAL_STOPPAGE_TIME_MINUTES:
        case SET_LOCAL_RESET_LOCAL_SETTINGS:
        case SET_LOCAL_TYRE_ID_PREFERENCE:
        case SET_LOCAL_SHOW_OPEN_APP_DRAWER:
            return { ...state, ...payload };
        case SET_LAST_REFERRAL_SHOWN_TIME:
            return { ...state, referralShownTimeInfo: payload.data };
        case SET_SHOW_NEW_UI_FLAG:
            return {
                ...state,
                showNewUi: payload,
            };
        case SET_DEFAULT_NEW_JOB_FORM_FLAG:
            return {
                ...state,
                showNewJobFormByDefault: payload,
            };
        case SET_TEMP_GRAPH_INTERVAL:
            return {
                ...state,
                tempGraphInterval: payload,
            };
        case SET_EV_BATTERY_LEVEL_GRAPH_INTERVAL:
            return {
                ...state,
                EVBatteryLevelGraphInterval: payload,
            };
        case SET_REATIME_CURRENT_VIEW:
            return {
                ...state,
                realtimeCurrentView: payload,
            };
        case SET_NEW_FEATURE_ANNOUNCEMENT_INFO:
            return {
                ...state,
                newFeatureAnnouncementInfo: payload,
            };
        case SET_ANNOUNCEMENT_POPUP_INFO:
            return {
                ...state,
                announcementPopupInfo: payload,
            };
        case SET_REALTIME_NOTIFICATION_INFO:
            return {
                ...state,
                realtimeNotificationInfo: payload,
            };
        case SET_USER_PERSONALISATION:
            return {
                ...state,
                userPersonalisation: payload,
            };
        case SET_REATIME_TOUR_SHOWN:
            return {
                ...state,
                realtimeTourShown: payload,
            };
        case SET_SHOW_NEW_ROUTE_FORM:
            return {
                ...state,
                showNewRouteForm: payload,
                showNewRouteFormDefault: payload,
            };
        case SET_CURRENT_LANGUAGE:
            return {
                ...state,
                i18nextLng: payload,
            };
        case FETCH_LOGGED_IN_USER:
            const newState = { ...state };
            let language = get(payload, 'data.language');
            language = ISO_LANGUAGE_CODES[language] ? ISO_LANGUAGE_CODES[language] : null;
            if (language) {
                set(newState, 'i18nextLng', language);
            }
            let customerIds = get(payload, 'data.customerIds');
            if (customerIds) {
                set(newState, 'localCustomerIds', customerIds);
            }
            return newState;
        case SET_VEHICLE_VIEW_ON_JOB_MAP:
            return {
                ...state,
                isVehicleViewOnMapEnabled: payload,
            };
        case SET_OVERRIDE_DEFAULT_VEHICLE_VIEW_ON_JOB_MAP:
            return {
                ...state,
                isDefaultVehicleViewOnMapEnabledOverWritten: payload,
            };
        case SET_LOCAL_TRANSPORTER_ACCOUNT:
            return {
                ...state,
                ...payload,
            };
        case SET_CONSIGNMENT_TRACKING_LOGIN_DETAILS:
            return {
                ...state,
                consignmentTrackingLogin: payload,
            };

        case SET_LOCAL_CUSTOMER_ID:
            return {
                ...state,
                ...payload,
            };
        case TEMP_GRAPH_TOUR:
            return {
                ...state,
                tempGraphTourInfo: payload,
            };
        case FUEL_GRAPH_TOUR:
            return {
                ...state,
                fuelGraphTourInfo: payload,
            };
        case SET_TABLE_CONFIG:
            return {
                ...state,
                tableConfig: payload,
            };
        case SET_NAVIGATION_STYLE:
            return {
                ...state,
                isNewStyle: payload,
            };
        case TOGGLE_BANNER:
            return {
                ...state,
                isBannerOpen: payload,
            };
        case SET_LRU_CONFIG:
            return {
                ...state,
                lruConfig: payload,
            };
        case SET_USER_GROUPS:
            return {
                ...state,
                userGroups: payload,
            };

        default:
            return state;
    }
}
