import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditTrainingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-training-add-edit" */ 'components/dashboard/training/addedit_training_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const TrainingComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-training" */ 'components/dashboard/training/training_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const trainingRoutes = [
    {
        index: true,
        lazy: TrainingComponent,
    },
    {
        path: 'add',
        lazy: AddEditTrainingComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditTrainingComponent,
    },
];
export default trainingRoutes;
