import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_API_URL_V2, ROOT_REPORTING_NODE_API_URL } from '../../constant';
import { getMomentTime } from 'utils/date_utils';
import { get, unset } from 'lodash';
const FETCH_CONTROL_ISSUE = 'fetch_control_issue';
const DOWNLOAD_CONTROL_REPORT = 'DOWNLOAD_CONTROL_REPORT';
export const FETCH_EVENTS_BOOK = 'FETCH_EVENTS_BOOK';
const FETCH_CONTROL_ISSUE_V2 = 'fetch_control_issue_v2';
const CONTROL_TOWER_CALL = 'CONTROL_TOWER_CALL';
const DOWNLOAD_CONTROL_REPORT_NEW = 'DOWNLOAD_CONTROL_REPORT_NEW';
const FETCH_CONTROL_ISSUE_SUMMARY = 'FETCH_CONTROL_ISSUE_SUMMARY';
const FETCH_CONTROL_ISSUE_V2_NEW = 'FETCH_CONTROL_ISSUE_V2_NEW';
const FETCH_CONTROL_ISSUE_V2_NEW_BY_ID = 'FETCH_CONTROL_ISSUE_V2_NEW_BY_ID';
const UPDATE_MULTI_CONTROL_TOWER_CALL = 'UPDATE_MULTI_CONTROL_TOWER_CALL';
const FETCH_CONTROL_ISSUE_SUMMARY_V2 = 'FETCH_CONTROL_ISSUE_SUMMARY_V2';
const UPDATE_ISSUE_ASSIGNEE_CONTROL_TOWER_CALL = 'UPDATE_ISSUE_ASSIGNEE_CONTROL_TOWER_CALL';
export function fetchControlRoomIssue(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ ...params }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}issues/control-room`, config);
    return {
        type: FETCH_CONTROL_ISSUE,
        promise: request,
    };
}
export function downloadControlTowerReport(accesstoken, payload) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/controlTowerReport`, payload, config);
    return {
        type: DOWNLOAD_CONTROL_REPORT,
        promise: request,
    };
}
export function fetchEventsBook(accesstoken, params) {
    const { size, currentPage = 1, startDate, endDate, vehicleNumber, eventType, jobName } = params;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size,
            page: currentPage - 1,
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            vehicleNumber,
            eventType,
            jobName,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}tp/events`, config);
    return {
        type: FETCH_EVENTS_BOOK,
        promise: request,
    };
}
export function fetchControlRoomIssueV2(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchControlRoomIssueV2';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams({
            ...params,
            sort: 'reportedAt,desc',
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}issues/control-tower`, config);
    return {
        type: FETCH_CONTROL_ISSUE_V2,
        promise: request,
    };
}
export function updateControlTowerCallStatus(issueId, accountId, payload) {
    const config = {
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL_V2}issues/call/${issueId}`, payload, config);
    return {
        type: CONTROL_TOWER_CALL,
        promise: request,
    };
}
export function fetchAccountOptions(accesstoken, params) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}accounts/config/property/accountIds`, config);
    return {
        type: CONTROL_TOWER_CALL,
        promise: request,
    };
}
export function triggerControlTowerReportDownload(accesstoken, payload) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(payload),
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/control-TowerReport`, config);
    return {
        type: DOWNLOAD_CONTROL_REPORT_NEW,
        promise: request,
    };
}
export function fetchControlTowerSummary(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchControlTowerSummary;';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams(params),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}issues/control-tower/summary`, config);
    return {
        type: FETCH_CONTROL_ISSUE_SUMMARY,
        promise: request,
    };
}
export function fetchControlRoomIssueNew(params) {
    const cancelCallbackKey = `CANCEL_CALLBACK_FUNCTION_FOR_fetchControlRoomIssueNew${params.issueDelayStatus}`;
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams({
            ...params,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/vehicle-issue`, config);
    return {
        type: FETCH_CONTROL_ISSUE_V2_NEW,
        promise: request,
    };
}
export function fetchControlRoomIssueNewById(params, id) {
    const cancelCallbackKey = `CANCEL_CALLBACK_FUNCTION_FOR_fetchControlRoomIssueNewById`;
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams({
            ...params,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}issues/vehicle-id/${id}`, config);
    return {
        type: FETCH_CONTROL_ISSUE_V2_NEW_BY_ID,
        promise: request,
    };
}
export function updateMultiControlTowerCallStatus(params, body) {
    const config = {
        params: parseQueryParams({
            ...params,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL_V2}issues/multi-call`, body, config);
    return {
        type: UPDATE_MULTI_CONTROL_TOWER_CALL,
        promise: request,
    };
}
export function fetchControlTowerSummaryV2(params) {
    const cancelCallbackKey = `CANCEL_CALLBACK_FUNCTION_FOR_fetchControlTowerSummaryV2${get(params, 'key', '')}`;
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    unset(params, 'key');
    const config = {
        params: parseQueryParams(params),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}issues/vehicle-issue/summary`, config);
    return {
        type: FETCH_CONTROL_ISSUE_SUMMARY_V2,
        promise: request,
    };
}
export function updateIssueAssignee(params, body) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.put(`${ROOT_API_URL_V2}issues/change-assignment`, body, config);
    return {
        type: UPDATE_ISSUE_ASSIGNEE_CONTROL_TOWER_CALL,
        promise: request,
    };
}
