import { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

class FuelFillIcon extends Component {
    static propTypes = {
        dontShow: PropTypes.object,
        animate: PropTypes.bool,
        cx: PropTypes.number,
        cy: PropTypes.number,
        payload: PropTypes.object,
    };

    static defaultProps = {
        animate: false,
    };

    render() {
        const { dontShow, animate = false } = this.props;
        const { cx, cy, payload } = this.props;

        const dontShowFill = get(dontShow, 'fill', false);
        const dontShowTheft = get(dontShow, 'theft', false);
        const dontShowInflow = get(dontShow, 'inflow', false);
        const dontShowOutflow = get(dontShow, 'outflow', false);

        if (dontShowFill && get(payload, 'subType') === 'fill') return <></>;
        if (dontShowTheft && get(payload, 'subType') === 'theft') return <></>;
        if (dontShowInflow && get(payload, 'subType') === 'inflow') return <></>;
        if (dontShowOutflow && get(payload, 'subType') === 'outflow') return <></>;
        if (get(payload, 'subType') === 'Pilferage') return <></>;

        return (
            <svg
                x={(animate ? cx - 12.5 : cx - 7.5) || 0}
                y={(animate ? cy - 12.5 : cy - 7.5) || 0}
                width={animate ? 25 : 15}
                height={animate ? 25 : 15}
                viewBox={animate ? '0 0 40 40' : '0 0 15 15'}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {animate && (
                    <circle cx="20" cy="20" r="6.375" fill={getColor(get(payload, 'subType'))}>
                        <animate attributeName="r" from="12" to="20" dur="1.2s" repeatCount="indefinite" />
                        <animate
                            attributeName="opacity"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.2s"
                            repeatCount="indefinite"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1; 0.42 0 0.58 1"
                        />
                    </circle>
                )}
                <circle
                    cx={animate ? 20 : 7.5}
                    cy={animate ? 20 : 7.5}
                    r={animate ? 9 : 6.375}
                    fill={getColor(get(payload, 'subType'))}
                    stroke="white"
                    strokeWidth="2.25"
                />
                <circle cx={animate ? 20 : 7.5} cy={animate ? 20 : 7.5} r={animate ? 2 : 1.25} fill="white" />
            </svg>
        );
    }
}

const getColor = (subType) => {
    switch (subType) {
        case 'fill':
            return '#009900';
        case 'theft':
            return '#D60000';
        case 'inflow':
            return '#009900';
        case 'outflow':
            return '#D60000';
        default:
            return '#D60000';
    }
};

export default FuelFillIcon;
