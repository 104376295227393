import { get, includes, round, toUpper, upperCase } from 'lodash';
import { isElectricApiDevice } from 'constant';
import { isV5Device } from '../constant';
import { FLEETX_ACCOUNTS } from 'utils/account_utils';

export function getBatteryPowerLevel(point, powerType = '') {
    if (isV5Device(point) || includes([11743], get(point, 'accountId'))) {
        return getBatteryPowerLevelForDisplay(get(point, 'otherAttributes.power'));
    }
    if (isHackPercentForEvBattery48V(point)) {
        return getHackPercentForEvBattery48V(point);
    }
    if (get(point, 'otherAttributes.evVoltage')) {
        return getBatteryPowerLevelForDisplay(
            get(point, 'otherAttributes.evVoltage', '0') || get(point, 'otherAttributes.evPower', '0')
        );
    }
    if (isElectricApiDevice(get(point, 'deviceId'))) {
        return getBatteryPercentForEvApiDevice(point);
    }

    return 0;
}
export function getBatteryPowerLevel2(point, powerType = '') {
    const param =
        powerType == 'BATTERY'
            ? get(point, 'otherAttributes.evBatterySOC', get(point, 'otherAttributes.battery', '0'))
            : 0;
    if (param) {
        return getBatteryPowerLevelForDisplay(param);
    }
    if (get(point, 'otherAttributes.evVoltage')) {
        return getBatteryPowerLevelForDisplay(
            get(point, 'otherAttributes.evVoltage', get(point, 'otherAttributes.evPower', '0'))
        );
    }

    return 0;
}

export function getBatteryPowerLevelForDisplay(voltage) {
    return round(parseFloat(voltage), 1);
}

export function getBatteryPercentForEvApiDevice(point) {
    return get(point, 'otherAttributes.battery', 0) + '%';
}

export function getBatteryRangeLevel(point) {
    return round(parseFloat(get(point, 'otherAttributes.range', '0')), 1);
}

export function getBatteryRangeLevelForBand(point) {
    return round(parseFloat(get(point, 'otherAttributes.evRangeForBand', '0')), 1);
}

export function isEvBatteryCharging(point) {
    console.log(toUpper(get(point, 'otherAttributes.evChargeStatus')));
    return (
        toUpper(get(point, 'otherAttributes.evChargeStatus')) == 'CHARGING' ||
        get(point, 'otherAttributes.evChargeStatus') == 'slow charging'
    );
}

export function isEvBatteryCharged(point) {
    return get(point, 'otherAttributes.evChargeStatus') == 'CHARGED';
}

function isHackPercentForEvBattery48V(point) {
    switch (get(point, 'vehicleId')) {
        case 172686:
            return true;
        default:
            return false;
    }
}

function getHackPercentForEvBattery48V(point) {
    const MIN = 35,
        MAX = 52;
    const voltage = parseFloat(get(point, 'otherAttributes.power', 0));
    return getHackPercentForEvBatteryGeneric(voltage, MIN, MAX);
}

function getHackPercentForEvBatteryGeneric(voltage, MIN, MAX) {
    if (voltage >= MIN) {
        if (voltage >= MAX) {
            return '100%';
        }
        return round(((MAX - voltage) * 100) / (MAX - MIN)) + '%';
    }
    return '0%';
}

export function getAt4BatteryPercent(batteryLevelString) {
    const voltage = parseFloat(batteryLevelString);
    let batteryPercent = 0;
    if (voltage >= 4.05) {
        batteryPercent = 100;
    } else if (voltage >= 4) {
        batteryPercent = 90;
    } else if (voltage >= 3.92) {
        batteryPercent = 80;
    } else if (voltage >= 3.85) {
        batteryPercent = 70;
    } else if (voltage >= 3.79) {
        batteryPercent = 60;
    } else if (voltage >= 3.74) {
        batteryPercent = 50;
    } else if (voltage >= 3.7) {
        batteryPercent = 40;
    } else if (voltage >= 3.67) {
        batteryPercent = 30;
    } else if (voltage >= 3.63) {
        batteryPercent = 20;
    } else if (voltage >= 3.59) {
        batteryPercent = 10;
    }
    return batteryPercent;
}

export const BATTERY_ALERT_TYPES = {
    chargingStart: 'start',
    chargingStop: 'stop',
    chargingFull: 'full',
};

export function getBatteryPowerLevelUnit(vehicle = {}) {
    const type = get(vehicle, 'otherAttributes.type');
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId');
    if (accountId === FLEETX_ACCOUNTS.OHM_E_LOGISTICS && type === 'GPS') {
        return '%';
    }
    return 'V';
}
