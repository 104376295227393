import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Select, InputNumber, Input } from 'sharedV2/FxFormItems';
import { FxButton, FxCol, FxRow } from 'sharedV2';
import { FxDeleteOutlined } from 'sharedV2/FxIcons';
const DeductionRow = memo((props) => {
    const { index, consignmentId, freightBillId, ledgerOptions, deduction, onRemove } = props;
    return (_jsxs(FxRow, { gutter: 16, className: "mb-3", children: [_jsx(Select, { colSpan: 6, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings[${index}].ledgerId`, options: ledgerOptions, placeholder: "Select Account", value: deduction.ledgerId }), _jsx(InputNumber, { colSpan: 6, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings[${index}].deductionAmount`, placeholder: "Amount", value: deduction.deductionAmount }), _jsx(Input, { colSpan: 6, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.deductionMappings[${index}].remark`, placeholder: "Remarks", value: deduction.remark }), _jsx(FxCol, { span: 2, children: _jsx(FxButton, { danger: true, onClick: () => onRemove(index), children: _jsx(FxDeleteOutlined, {}) }) })] }));
});
export default DeductionRow;
