import { Component } from 'react';
import { FxReactSelect as Select } from '../../../shared';
import { connect } from 'react-redux';
import { fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { filter, get, includes, isEmpty, map, toLower, toSafeInteger } from 'lodash';
import { setLocalVehicleId } from '../../../actions/dashboard/local_action';
import {
    disableVehicleIdSearchInVehicleDropdown,
    isRemoteSelectOpenForAccount,
    showRemoveVehiclesInSearchForAccount,
} from '../../../utils/account_utils';
import { isSwitchVehicles } from '../roles/permission_utils';
import { withTranslation } from 'react-i18next';
import { mappedVehicleListMiniOptions } from '../../utils/mapping_utils';
import { FxSelect } from 'sharedV2';

class SelectVehicle extends Component {
    filterCount = 0;
    disableVehicleId = disableVehicleIdSearchInVehicleDropdown();

    constructor(props) {
        super(props);
        this.state = {
            crazy: false,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    getFilterCondition(option, filter) {
        if (this.disableVehicleId) return includes(toLower(get(option, 'label', '')), toLower(filter));
        else
            return (
                includes(toLower(get(option, 'label', '')), toLower(filter)) ||
                includes(toLower(get(option, 'value', '')), toLower(filter))
            );
    }

    handleSelectChange(value, triggeredAction) {
        if (!this.props.onAntdPage && triggeredAction.action === 'clear') {
            this.resetFilterCount();
        }
        //const option = find(this.props.options, { value: value });
        const option = this.props.vehicleListMiniAsMap[value];
        this.props.setLocalVehicleId(value);
        this.props.onVehicleSelected(value, option ? option.vehicleName : null, option ? option.vehicleNumber : null);
    }

    componentDidMount() {
        if (!isRemoteSelectOpenForAccount()) {
            this.props.fetchVehicleListMini(
                this.props.accesstoken,
                showRemoveVehiclesInSearchForAccount(this.props.loggedInUser, this.props.isSwitchedUser),
                this.props.groupId,
                false,
                null,
                this.props.allVehicles,
                null,
                this.props.isVideoTalematics
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.groupId !== this.props.groupId && !isRemoteSelectOpenForAccount()) {
            this.props.fetchVehicleListMini(
                this.props.accesstoken,
                showRemoveVehiclesInSearchForAccount(this.props.loggedInUser, this.props.isSwitchedUser),
                this.props.groupId,
                false,
                null,
                this.props.allVehicles,
                null,
                this.props.isVideoTalematics
            );
        }
    }

    customFilterOption = (option, filter) => {
        if (this.filterCount < 50) {
            if (this.getFilterCondition(option, filter)) {
                this.filterCount++;
                return true;
            }
            return false;
        } else {
            return false;
        }
    };

    resetFilterCount = () => {
        this.filterCount = 0;
    };

    antdFilterOption = (input, option) => {
        return (
            `${option.label}`.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
            `${option.value}`?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        );
    };

    render() {
        const { isSwitchedUser, isSwitchUserAllowed, disabled, t, vehicleListMini, overrideOptions } = this.props;
        let vehicleList = vehicleListMini;
        if (this.props.onAntdPage) {
            return (
                <FxSelect
                    {...this.props}
                    placeholder={`${t('vehicles.selectVehicle')}`}
                    value={this.props.value ? toSafeInteger(this.props.value) : null}
                    options={
                        !isEmpty(overrideOptions)
                            ? overrideOptions
                            : mappedVehicleListMiniOptions(vehicleList, null, true, this.props.isVideoTalematics)
                    }
                    allowClear={get(this.props, 'clearable', true)}
                    onChange={this.handleSelectChange}
                    disabled={disabled}
                    loading={this.props.loading}
                    filterOption={isSwitchedUser ? this.antdFilterOption : undefined}
                />
            );
        }

        return (
            <Select
                simpleValue
                matchProp={isSwitchedUser || isSwitchUserAllowed ? 'any' : 'label'}
                key="select-vehicle-dropdown"
                placeholder={`${t('vehicles.selectVehicle')}`}
                value={this.props.value}
                options={mappedVehicleListMiniOptions(vehicleList, null, false, this.props.isVideoTalematics)}
                clearable={this.props.clearable}
                isLoading={this.props.loading}
                onChange={this.handleSelectChange}
                disabled={disabled}
                filterOption={this.customFilterOption}
                onKeyDown={this.resetFilterCount}
                onMenuOpen={this.resetFilterCount}
            />
        );
    }
}

function mapStateToProps(state, ownprops) {
    // const options = map(state.vehicles.vehicleListMini, (o) => {
    //     return {
    //         value: `${o.id}`,
    //         label: `${o.name} ${showVehicleNumber(state.settings.loggedInUser) ? `(${o['licensePlate']})` : ''}`,
    //         vehicleNumber: o['licensePlate'],
    //         vehicleName: o.name,
    //     };
    // });

    let vehicleListOptions = state.vehicles.vehicleListMini;
    if (ownprops.onlyFilterFuelLevelSensor) {
        vehicleListOptions = filter(state.vehicles.vehicleListMini, (v) => {
            return get(v, 'fuelSensor', 0) > 0 || ownprops.isFuelLevelAvailable(v.id);
        });
    }

    return {
        accesstoken: state.login.data.access_token,
        loading: state.vehicles.isVehicleListMiniLoading,
        hasList: !!state.vehicles.vehicleListMini,
        loggedInUser: state.settings.loggedInUser,
        isSwitchUserAllowed: isSwitchVehicles(get(state, 'login.data.scope')),
        isSwitchedUser: get(state, 'login.isSwitchedUser'),
        vehicleListMini: vehicleListOptions,
        vehicleListMiniAsMap: state.vehicles.vehicleListMiniAsMap,
    };
}

export default connect(mapStateToProps, { fetchVehicleListMini, setLocalVehicleId })(withTranslation()(SelectVehicle));
