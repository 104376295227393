import { concat, forEach, forEachRight, get, includes, map, orderBy, set, toUpper } from 'lodash';
import { DTCsFiveDigitCode, DTCsFourDigitCode } from 'components/dashboard/dtcV2/DTCsConstant';

export const DTC_SEVERITY_COLOR = {
    'Severity Critical': '#d60000',
    'Severity High': '#d65a00',
    'Severity Medium': '#605e5c',
    'Severity Low': '#005a9e',
};

export const DTC_SEVERITY_BG_COLOR = {
    'Severity Critical': '#fde7e9',
    'Severity High': '#fdefe7',
    'Severity Medium': '#fff4ce',
    'Severity Low': '#eff6fc',
};

export const DTC_SEVERITY_KEYS = {
    'Severity Critical': ['Critical'],
    'Severity High': ['High', 'HIGH'],
    'Severity Medium': ['MEDIUM', 'Medium', 'MID', 'Moderately Severe'],
    'Severity Low': ['LOW', 'Low'],
};

export const DTC_TABS = [
    {
        value: '1',
        label: 'Maker',
    },
    {
        value: '2',
        label: 'Vehicle Age',
    },
];

export const DTC_CHART_COLORS = ['#79a2f3', '#87dab7', '#7483a0', '#f1c949', '#e28169', '#91d0ed'];

export const prepareChartData = (dtcData) => {
    let dataMap = {};
    forEach(dtcData, (dtc) => {
        const vehicleMaker = get(dtc, 'vehicleMake');
        dataMap[vehicleMaker] = (dataMap[vehicleMaker] || 0) + 1;
    });

    let totalDataCount = 0;
    forEach(dataMap, (value) => {
        totalDataCount += value;
    });

    let arrayOfObjects = Object.keys(dataMap).map((key) => ({
        name: key,
        count: dataMap[key],
    }));
    arrayOfObjects.sort((a, b) => b.count - a.count);
    if (arrayOfObjects.length > 5) {
        const topFive = arrayOfObjects.slice(0, 5);

        const remainingCount = arrayOfObjects.slice(5).reduce((sum, obj) => sum + obj.count, 0);

        topFive.push({ name: 'Others', count: remainingCount });
        arrayOfObjects = topFive;
    }
    return arrayOfObjects;
};

export const getDtcListData = (dtcList) => {
    let newMap = {};
    let newMapUpdated = {
        'Severity Critical': 0,
        'Severity High': 0,
        'Severity Medium': 0,
        'Severity Low': 0,
    };
    forEachRight(dtcList, (item) => {
        if (!!get(item, 'fmiDesc.severity')) {
            const severityType = get(item, 'fmiDesc.severity');
            newMap[severityType] = (newMap[severityType] || 0) + 1;
        } else if (!!get(item, 'dtcCode.severity')) {
            const severityType = get(item, 'dtcCode.severity');
            newMap[severityType] = (newMap[severityType] || 0) + 1;
        } else {
            const severityType = get(item, 'severity');
            newMap[severityType] = (newMap[severityType] || 0) + 1;
        }
    });

    for (let key in newMap) {
        if (includes(DTC_SEVERITY_KEYS['Severity Critical'], key)) {
            newMapUpdated['Severity Critical'] += newMap[key];
        } else if (includes(DTC_SEVERITY_KEYS['Severity High'], key)) {
            newMapUpdated['Severity High'] += newMap[key];
        } else if (includes(DTC_SEVERITY_KEYS['Severity Medium'], key)) {
            newMapUpdated['Severity Medium'] += newMap[key];
        } else if (includes(DTC_SEVERITY_KEYS['Severity Low'], key)) {
            newMapUpdated['Severity Low'] += newMap[key];
        }
    }
    const sortOrder = ['Severity Critical', 'Severity High', 'Severity Medium', 'Severity Low'];

    return map(sortOrder, (key) => ({
        name: key,
        count: newMapUpdated[key],
        keys: DTC_SEVERITY_KEYS[key],
        color: DTC_SEVERITY_COLOR[key],
        bgColor: DTC_SEVERITY_BG_COLOR[key],
    }));
};

export const prepareChartDataAge = (dtcData) => {
    let currentYear = new Date().getFullYear();
    const AGE_CATEGORIES = {
        [`${currentYear} (<1 year)`]: 0,
        [`${currentYear - 1} (1 year)`]: 0,
        [`${currentYear - 2} (2 year)`]: 0,
        [`${currentYear - 3} (3 year)`]: 0,
        [`${currentYear - 4} (4 year)`]: 0,
        [`${currentYear - 5} >=5 (year)`]: 0,
    };
    forEach(dtcData, (vehicle) => {
        const vehicleYear = get(vehicle, 'vehicleYear');
        const age = currentYear - vehicleYear;

        if (age < 1) {
            AGE_CATEGORIES[`${currentYear} (<1 year)`] += 1;
        } else if (age === 1) {
            AGE_CATEGORIES[`${currentYear - 1} (1 year)`] += 1;
        } else if (age === 2) {
            AGE_CATEGORIES[`${currentYear - 2} (2 year)`] += 1;
        } else if (age === 3) {
            AGE_CATEGORIES[`${currentYear - 3} (3 year)`] += 1;
        } else if (age === 4) {
            AGE_CATEGORIES[`${currentYear - 4} (4 year)`] += 1;
        } else if (age >= 5) {
            AGE_CATEGORIES[`${currentYear - 5} >=5 (year)`] += 1;
        }
    });

    return Object.keys(AGE_CATEGORIES).map((key) => ({
        name: key,
        count: AGE_CATEGORIES[key],
    }));
};

export const getSeverityData = (record) => {
    let severityType;
    let severityColor;
    if (!!get(record, 'fmiDesc.severity')) {
        severityType = get(record, 'fmiDesc.severity');
    } else if (!!get(record, 'dtcCode.severity')) {
        severityType = get(record, 'dtcCode.severity');
    } else {
        severityType = get(record, 'severity');
    }

    if (includes(['Critical'], severityType)) {
        severityColor = '#d60000';
    } else if (includes(['High', 'HIGH'], severityType)) {
        severityColor = '#d65a00';
    } else if (includes(['Medium', 'MEDIUM', 'MID'], severityType)) {
        severityColor = '#faad14';
    } else {
        severityColor = '#005a9e';
    }

    return { text: severityType, color: severityColor };
};

export const ageDataOptions = () => {
    let currentYear = new Date().getFullYear();
    const AGE_CATEGORIES = {
        [`${currentYear} (<1 year)`]: 0,
        [`${currentYear - 1} (1 year)`]: 0,
        [`${currentYear - 2} (2 year)`]: 0,
        [`${currentYear - 3} (3 year)`]: 0,
        [`${currentYear - 4} (4 year)`]: 0,
        [`${currentYear - 5} >=5 (year)`]: 0,
    };
    return Object.keys(AGE_CATEGORIES).map((key) => ({
        label: key,
        value: key,
    }));
};

export const criticalityOptions = [
    { label: 'Critical', value: 'Critical' },
    { label: 'High', value: 'High' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Low', value: 'Low' },
];

export const extractYearsAndNames = (selectedItems) => {
    const vehicleMakerName = [];
    const vehicleYears = [];

    Object.keys(selectedItems).forEach((item) => {
        if (/\d/.test(item)) {
            // If the item contains a number, it's considered a year-related item
            let year = item.split(' ');
            vehicleYears.push(year[0]);
        } else {
            // If the item doesn't contain a number, it's considered as a vehicle Name
            vehicleMakerName.push(item);
        }
    });

    return { vehicleMakerName, vehicleYears };
};

export function isSpecificDtcs(dtc) {
    return includes(concat(DTCsFiveDigitCode, DTCsFourDigitCode), `${toUpper(get(dtc, 'dtcCode.code'))}`);
}

export function getFinalDtcsData(dtcs) {
    return orderBy(
        map(dtcs, (o) => {
            set(o, 'key', o.vehicleId + o.type + o.timeStamp + o.code + Math.random());
            set(o, 'finalSeverity', getSeverityData(o));
            return o;
        }),
        ['timeStamp'],
        ['desc']
    );
}
