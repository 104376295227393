/**
 * Created by udbhav on 18/07/17.
 */

import axios from 'axios';
import {
    ROOT_API_URL,
    ROOT_API_URL_V2,
    parseQueryParams,
    ROOT_API_RMS,
    ROOT_REPORTING_NODE_API_URL,
} from '../../constant';
import { DATE_FORMAT_TIMESTAMP, getMomentTime, getFormattedTimeStringForAPI } from '../../utils/date_utils';
import { get, map } from 'lodash';
import { isRealtimeVisible, isViewWorkOrderAllowedForUser } from '../../components/dashboard/roles/permission_utils';
import { getDummyActionResponse } from 'utils/permissionUtils/utils';
import { ControlTowerActionsTypes } from 'actions/dashboard/control_tower';

export const FETCH_ANALYTICS_LIVE = 'fetch_analytics_live';
export const FETCH_ANALYTICS_SHARE_VEHICLE = 'fetch_analytics_share_vehicle';
export const CREATE_SHARE_LOCATION = 'create_share_location';
export const SHARE_REALTIME_EMAIL_MOBILE = 'share_realtime_email_mobile';
export const FETCH_REALTIME_SHARES = 'fetch_realtime_shares';
export const SET_REALTIME_SHARE_INACTIVE = 'set_realtime_share_inactive';
export const FETCH_HISTORICAL_VEHICLE_LOCATION = 'fetch_historical_vehicle_location';
export const FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM = 'fetch_latest_trip_vehicle_number_om';
export const FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM_AUTH = 'fetch_latest_trip_vehicle_number_om_auth';
export const SHARE_LATEST_TRIP_VEHICLE_NUMBER_OM = 'share_latest_trip_vehicle_number_om';
export const SHARE_TIMELINE_VEHICLE_NUMBER_OM = 'share_timeline_vehicle_number_om';
export const SHARE_LATEST_TRIP_VEHICLE_NUMBER_OM_EMAIL_MOBILE = 'share_latest_trip_vehicle_number_om_email_mobile';
export const FETCH_NEARBY_DETAILS = 'fetch_nearby_details';
export const FETCH_VEHICLE_BY_NUMBER = 'fetch_vehicle_by_number';
export const REQUEST_SIM_LOCATION_BULK = 'request_sim_location_bulk';
export const FETCH_WORK_ORDER_LIVE = 'fetch_work_order_live';
export const FETCH_VEHICLES_ON_JOB_NEW = 'FETCH_VEHICLES_ON_JOB_NEW';

export const FETCH_REALTIME_REPORT = 'FETCH_REALTIME_REPORT';
export const FETCH_LOCATION_BY_TIME_REPORT = 'FETCH_LOCATION_BY_TIME_REPORT';

export const SATVA_TRACKING_SHARE = 'SATVA_TRACKING_SHARE';
export const JOB_ALARMS_ON_REALTIME = 'JOB_ALARMS_ON_REALTIME';
export const FETCH_ANALYTICS_LIVE_BY_FILTER = 'FETCH_ANALYTICS_LIVE_BY_FILTER';
export const FETCH_ANALYTICS_DISPATCH = 'FETCH_ANALYTICS_DISPATCH';

export function fetchAnalyticsLive(accesstoken, groupId, showVehicleType, tagName, transporterId, currentTransporter) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isRealtimeVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ANALYTICS_LIVE,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId: groupId,
            tagNames: tagName,
            showVehicleType: showVehicleType ? true : null,
            transporterId: transporterId,
            currentTransporter: currentTransporter ? true : null,
        }),
    };
    let url = `${ROOT_API_URL}analytics/live`;
    // if (groupId) {
    //     url += `?groupId=${groupId}`;
    // }
    // if (tagName) {
    //     url += `?tagNames=${tagName}`;
    // }
    // if (showVehicleType) {
    //     url += `?showVehicleType=true`;
    // }
    // if (transporterId) {
    //     url += `?transporterId=${transporterId}`;
    // }
    const request = axios.get(url, config);

    return {
        type: FETCH_ANALYTICS_LIVE,
        promise: request,
    };
}

export function fetchAnalyticsLiveFuel(accesstoken, groupId, showVehicleType, tagName) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isRealtimeVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ANALYTICS_LIVE,
            promise: Promise.resolve({}),
        };
    }
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let url = `${ROOT_API_URL}analytics/live`;
    if (groupId) {
        url += `?groupId=${groupId}`;
    }
    if (tagName) {
        url += `?tagNames=${tagName}`;
    }
    if (showVehicleType) {
        url += `?showVehicleType=true`;
    }
    const request = axios.get(url, config);

    return {
        type: FETCH_ANALYTICS_LIVE,
        promise: request,
    };
}

export function fetchVehicleByNumber(
    accesstoken,
    licensePlate,
    accountId,
    checkFitted = false,
    showOtherAttributes = false
) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            showOtherAttributes,
            checkFitted,
        }),
    };
    let url = `${ROOT_API_URL}analytics/live/byNumber/${licensePlate}`;

    const request = axios.get(url, config);

    return {
        type: FETCH_VEHICLE_BY_NUMBER,
        promise: request,
    };
}

export function fetchAnalyticsShareVehicleByToken(token, version = 'v1', vehicleNumber) {
    const config = {
        params: parseQueryParams({
            vehicleNumber,
        }),
    };
    let url = `${ROOT_API_URL}analytics/live${version === 'v2' ? '/new' : ''}/${token}`;
    const request = axios.get(url, config);

    return {
        type: FETCH_ANALYTICS_SHARE_VEHICLE,
        promise: request,
    };
}

export function createShareLocation(
    accesstoken,
    name,
    vehicleIds,
    from,
    to,
    notShareLocation = false,
    timezone,
    currency,
    fleetType,
    showMapOnly
) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        name,
        vehicleIds,
        startDate: getFormattedTimeStringForAPI(from),
        endDate: getFormattedTimeStringForAPI(to),
        notShareLocation,
        timezone,
        currency,
        fleetType,
        showMapOnly,
    };
    const request = axios.post(`${ROOT_API_URL}share/`, data, config);
    return {
        type: CREATE_SHARE_LOCATION,
        promise: request,
    };
}

export function requestSimLocationBulk(accesstoken, mobileNumbers) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        mobileNumbers,
    };
    const request = axios.post(`${ROOT_API_URL}devices/bulk/sim/location`, data, config);
    return {
        type: REQUEST_SIM_LOCATION_BULK,
        promise: request,
    };
}

export function shareRealtimeVehicleEmailMobile(accesstoken, shareLocationId, url, contacts, sendAt) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        users: contacts,
        shareLocationId,
        url,
    };
    if (sendAt) {
        data.sendAt = getMomentTime(sendAt).format(DATE_FORMAT_TIMESTAMP);
    }
    const request = axios.post(`${ROOT_API_URL}notify/live`, data, config);
    return {
        type: SHARE_REALTIME_EMAIL_MOBILE,
        promise: request,
    };
}

export function fetchRealtimeShares(accesstoken, groupId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let url = `${ROOT_API_URL}share/`;
    if (groupId) {
        url += `?groupId=${groupId}`;
    }
    const request = axios.get(url, config);

    return {
        type: FETCH_REALTIME_SHARES,
        promise: request,
    };
}

export function setRealtimeShareInactive(accesstoken, shareId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        status: 'INACTIVE',
    };
    let url = `${ROOT_API_URL}share/${shareId}`;
    const request = axios.put(url, data, config);

    return {
        type: SET_REALTIME_SHARE_INACTIVE,
        promise: request,
    };
}

export function fetchHistoricalVehicleLocation(accesstoken, vehicleId, timestamp) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    let url = `${ROOT_API_URL}vehicles/history/location?vehicleId=${vehicleId}&time=${timestamp}`;
    const request = axios.get(url, config);
    return {
        type: FETCH_HISTORICAL_VEHICLE_LOCATION,
        promise: request,
    };
}

export function fetchHistoricalVehicleLocationMulti(accesstoken, vehicleId, timestamps, callback) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const promises = map(timestamps, (t) =>
        axios.get(
            `${ROOT_API_URL}vehicles/history/location?vehicleId=${vehicleId}&time=${getMomentTime(t).valueOf()}`,
            config
        )
    );
    let results = [];
    axios.all(promises).then(
        axios.spread((...args) => {
            for (let i = 0; i < args.length; i++) {
                results.push(args[i].data);
            }
            callback(results);
        })
    );
}

export function fetchLatestTripByVehicleIdOm(token, vehicleId) {
    let url = `${ROOT_API_URL}trips/latest/om?vehicleId=${vehicleId}`.replace('/v1/', '/v2/');
    const request = axios.get(url);

    return {
        type: FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM,
        promise: request,
    };
}

export function fetchLatestTripByVehicleIdOmWithToken(token, vehicleId, startDate, endDate) {
    let url = `${ROOT_API_URL}trips/latest/om/auth?vehicleId=${vehicleId}`.replace('/v1/', '/v2/');
    url += `&token=${token}`;
    url += `&from=${startDate.valueOf()}`;
    url += `&to=${endDate.valueOf()}`;
    const request = axios.get(url);

    return {
        type: FETCH_LATEST_TRIP_VEHICLE_NUMBER_OM_AUTH,
        promise: request,
    };
}

export function shareLatestTripByVehicleIdOmWithToken(token, name, vehicleIds, from, to) {
    let url = `${ROOT_API_URL}share/om?token=${token}`;

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const data = {
        name,
        vehicleIds,
        startDate: getMomentTime(from).format(DATE_FORMAT_TIMESTAMP),
        endDate: getMomentTime(to).format(DATE_FORMAT_TIMESTAMP),
    };
    const request = axios.post(url, data, config);
    return {
        type: SHARE_LATEST_TRIP_VEHICLE_NUMBER_OM,
        promise: request,
    };
}

export function shareTimelineByVehicleIdOmWithToken(token, name, vehicleId, from, to) {
    let url = `${ROOT_API_URL_V2}trips/generate/timeline/om/url?token=${token}`;

    const config = {
        headers: { 'Content-Type': 'application/json' },
        params: parseQueryParams({
            name,
            vehicleId: vehicleId[0],
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(url, config);

    return {
        type: SHARE_TIMELINE_VEHICLE_NUMBER_OM,
        promise: request,
    };
}

export function shareLatestTripByVehicleIdOmEmailMobileWithToken(token, shareLocationId, url, contacts, sendAt) {
    let apiUrl = `${ROOT_API_URL}notify/live/om?token=${token}`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const data = {
        users: contacts,
        shareLocationId,
        url,
    };
    if (sendAt) {
        data.sendAt = getMomentTime(sendAt).format(DATE_FORMAT_TIMESTAMP);
    }
    const request = axios.post(apiUrl, data, config);
    return {
        type: SHARE_LATEST_TRIP_VEHICLE_NUMBER_OM_EMAIL_MOBILE,
        promise: request,
    };
}

export function fetchNearByDetails(accessToken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accessToken}` },
    };

    const request = axios.post(`${ROOT_API_URL}address_book/nearby/lat-long`, data, config);
    return {
        type: FETCH_NEARBY_DETAILS,
        promise: request,
    };
}

export function fetchWorkOrderForVehicles(accesstoken) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewWorkOrderAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return getDummyActionResponse({ type: FETCH_WORK_ORDER_LIVE });
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            workOrderStatuses: 'OPEN,IN_PROGRESS',
        }),
    };
    let url = `${ROOT_API_URL}work_orders/live`;
    const request = axios.get(url, config);

    return {
        type: FETCH_WORK_ORDER_LIVE,
        promise: request,
    };
}

export function fetchVehiclesOnJobsNew(accesstoken, groupId, tagIds) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            groupId: groupId && groupId != -1 ? groupId : '',
            tagIds: tagIds ? tagIds : '',
        }),
    };
    const request = axios.get(`${ROOT_API_RMS}realtime/vehicles`, config);
    return {
        type: FETCH_VEHICLES_ON_JOB_NEW,
        promise: request,
    };
}

export function downloadRealtimeReport(accesstoken, payload) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerRealtimeReport`, payload, config);

    return {
        type: FETCH_REALTIME_REPORT,
        promise: request,
    };
}

export function downloadLocationByTimeReport(accesstoken, payload) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/locationByTime`, payload, config);

    return {
        type: FETCH_LOCATION_BY_TIME_REPORT,
        promise: request,
    };
}

export function fetchSatvaTrackingShare(params) {
    const config = {
        params: parseQueryParams(params),
    };
    let url = `${ROOT_API_URL}share/custom/live`;
    const request = axios.get(url, config);

    return {
        type: SATVA_TRACKING_SHARE,
        promise: request,
    };
}

export function fetchJobAlarmsOnRealtime(accesstoken, params) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams(params),
    };
    let url = `${ROOT_API_URL}dispatch/job-alarms`;
    const request = axios.get(url, config);

    return {
        type: JOB_ALARMS_ON_REALTIME,
        promise: request,
    };
}

export function fetchAnalyticsLiveByFilter(accesstoken, params) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isRealtimeVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ANALYTICS_LIVE_BY_FILTER,
            promise: Promise.resolve({}),
        };
    }
    const { vehicleNumbers, accountId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleNumbers,
            accountId,
        }),
    };
    let url = `${ROOT_API_URL}analytics/live`;
    const request = axios.get(url, config);

    return {
        type: FETCH_ANALYTICS_LIVE,
        promise: request,
    };
}

export function fetchAnalyticsDispatchFromToken(token, params) {
    const config = {
        params: parseQueryParams(params),
    };
    const url = `${ROOT_API_URL}analytics/dispatch/${token}`;
    const request = axios.get(url, config);
    return {
        type: FETCH_ANALYTICS_DISPATCH,
        promise: request,
    };
}
