import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, map, slice } from 'lodash';
import { useState, useEffect } from 'react';
import FxButton from 'sharedV2/FxButton';
import FxSelect from 'sharedV2/FxSelect';
import { setUnLoadingAction } from 'actions/dashboard/route_dispatch/jobs_action';
import { connect } from 'react-redux';
import { handleError } from 'constant';
const getRoutePointOptions = (routes) => {
    return map(routes, (route, index) => {
        return {
            value: index,
            label: route.address,
        };
    });
};
const JobMergedTripUnloadingComponent = (props) => {
    const [selectedRoutePoint, setSelectedRoutePoint] = useState(null);
    const [loading, setLoading] = useState(false);
    const roleId = props.switchedUserOriginalRoleId;
    const isAllowedToSetUnloading = roleId == 7 || roleId == 23;
    useEffect(() => {
        if (props.routePoints.length > 0) {
            setSelectedRoutePoint(props.routePoints.length - 1);
        }
    }, []);
    const onMarkUnloadingPoint = async (point, sDate, eDate) => {
        setLoading(true);
        const { latitude, longitude } = point;
        const jobId = get(props, 'job.id');
        const data = {
            jobId,
            pointPosition: selectedRoutePoint,
            lat: latitude,
            lon: longitude,
            startTime: sDate.format('DD-MM-YYYY HH:mm:ss'),
            endTime: eDate.format('DD-MM-YYYY HH:mm:ss'),
        };
        if (!selectedRoutePoint) {
            window.alert('Select Analytic Point');
            return;
        }
        await props
            .setUnLoadingAction(data)
            .then((res) => {
            if (!res.error) {
                setLoading(true);
                window.alert('Unloading point marked successfully');
                window.location.reload();
            }
        })
            .catch((e) => {
            handleError(e);
            setLoading(true);
        });
    };
    const jobPointOptions = slice(getRoutePointOptions(get(props, 'routePoints')), 1);
    return (_jsxs("div", { className: "d-flex", children: [props.showUnloadingButton && isAllowedToSetUnloading && (_jsx("div", { className: "text-right my-2 ml-2", style: { width: 180 }, children: _jsx(FxSelect, { placeholder: "Select Job Point", onChange: (value) => {
                        setSelectedRoutePoint(value);
                    }, value: jobPointOptions[jobPointOptions.length - 1], options: jobPointOptions, disabled: jobPointOptions.length === 1, allowClear: false }) })), props.showUnloadingButton && isAllowedToSetUnloading && (_jsx("div", { className: "text-right my-2 ml-2", children: _jsx(FxButton, { className: 'mr-2', onClick: () => onMarkUnloadingPoint(props.point, props.sDate, props.eDate), loading: loading, children: "Mark as Unloading Point" }) }))] }));
};
function mapStateToProps(state, ownProps) {
    return {
        loggedInUser: get(state, 'settings.loggedInUser'),
        accesstoken: get(state, 'login.data.access_token'),
        switchedUserOriginalRoleId: get(state, 'login.switchedUserOriginalRoleId'),
    };
}
export default connect(mapStateToProps, {
    setUnLoadingAction,
})(JobMergedTripUnloadingComponent);
