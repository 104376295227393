import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { debounce, get } from 'lodash';
import { FxForm, FxInput, FxSelect, FxToolTip } from 'sharedV2';
import { getNameDropDownObject } from 'utils/custom_field_utils';
import { getMomentTime } from 'utils/date_utils';
import CalendarSingleDatePicker from 'components/utils/calendar_single_date_picker';
import { useEffect, useRef, useState } from 'react';
const FxCustomField = (props) => {
    const { touched = true, error, label, formItemStyle, customField, value, onBlur, onChange, isRequired, disabled, name, fieldType, ...restPros } = props;
    const { t } = useTranslation();
    const [tempValue, setTempValue] = useState(value);
    const updatePending = useRef(false);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (value !== tempValue && !updatePending.current) {
                setTempValue(value);
            }
        }, 500);
        return () => {
            clearTimeout(timeOut);
        };
    }, [value]);
    const onChangeValue = debounce((value) => {
        if (onChange) {
            onChange(value);
            updatePending.current = false;
        }
    }, 500);
    const updateTempValue = (value) => {
        setTempValue(value);
        updatePending.current = true;
        onChangeValue(value);
    };
    const onBlurHandler = (value) => {
        if (onBlur) {
            onBlur(value);
        }
    };
    const getComponent = () => {
        const tooltipValue = tempValue ? String(tempValue) : '';
        if (get(customField, 'field.fieldType') === 'MCQ_DROPDOWN') {
            return (_jsx(FxToolTip, { title: tooltipValue, children: _jsx(FxSelect, { options: getNameDropDownObject(get(customField, 'field.answers', [])), disabled: disabled, onChange: updateTempValue, onBlur: () => onBlurHandler(tempValue), value: tempValue, ...restPros }) }));
        }
        else if (get(customField, 'field.fieldType') === 'BOOLEAN') {
            return (_jsx(FxToolTip, { title: tooltipValue, children: _jsx(FxInput, { onChange: (event) => updateTempValue(event.target.value), name: name, type: "checkbox", disabled: disabled, checked: tempValue, onBlur: () => onBlurHandler(tempValue), ...restPros }) }));
        }
        else if (get(customField, 'field.fieldType') === 'DATE') {
            return (_jsx(FxToolTip, { title: tooltipValue, children: _jsx(CalendarSingleDatePicker, { name: name, timePicker: true, valueFormatter: (value) => (value ? getMomentTime(value).valueOf() : value), disabled: disabled, onDateSelected: (value) => {
                        updateTempValue(value);
                        onBlurHandler(value);
                    }, startDate: tempValue, ...restPros }) }));
        }
        else {
            return (_jsx(FxToolTip, { title: tooltipValue, children: _jsx(FxInput, { onChange: (event) => updateTempValue(event.target.value), name: name, disabled: disabled, value: tempValue, onBlur: () => onBlurHandler(tempValue), type: fieldType, ...restPros }) }));
        }
    };
    let helpText = '';
    if (touched && error) {
        helpText = error;
    }
    else if (props.help) {
        helpText = props.help;
    }
    const help = helpText ? _jsx("div", { className: 'font-sm', children: helpText }) : null;
    return (_jsx(FxForm.Item, { label: props.label, required: isRequired, validateStatus: touched && error ? 'error' : '', help: help, style: formItemStyle, children: getComponent() }));
};
export default FxCustomField;
