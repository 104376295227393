import { filter, get, includes, last } from 'lodash';
import { mappedInternalIssuesTypes } from 'components/utils/mapping_utils';
import { getMomentTime } from 'utils/date_utils';
import { FLEETX_ACCOUNTS } from 'utils/account_utils';

export const INTERNAL_ISSUE_CATEGORY = {
    FLEETX: 'FLEETX',
    CLIENT: 'CLIENT',
};

export const INTERNAL_ISSUE_SINGULAR_LABEL = 'Ticket';
export const INTERNAL_ISSUE_LABEL = 'Tickets';

export const CLIENT_ISSUES_TYPES = {
    REPAIR: 'REPAIR',
    CUSTOMIZATION: 'CUSTOMIZATION',
    REMOVAL: 'REMOVAL',
    REPLACEMENT: 'REPLACEMENT',
    KM_ISSUE: 'KM_ISSUE',
    DATA_ENTRY: 'DATA_ENTRY',
    LOCATION_ISSUE: 'LOCATION_ISSUE',
    MILEAGE_ISSUE: 'MILEAGE_ISSUE',
    TRAINING: 'TRAINING',
    INVOICE_REVISION: 'INVOICE_REVISION',
    INSTALLATION: 'INSTALLATION',
    REQUEST_NEW_FASTAG: 'REQUEST_NEW_FASTAG',
    CLONE_REQUEST: 'CLONE_REQUEST',
    CLONE_REMOVAL: 'CLONE_REMOVAL',
    EPOD: 'EPOD',
    FREIGHT_BILL: 'FREIGHT_BILL',
    FREIGHT_BILL_CANCELLATION_REQUEST: 'FREIGHT_BILL_CANCELLATION_REQUEST',
};
export const CLIENT_ISSUES_TYPES_LABEL = {
    REPAIR: 'Repair',
    CUSTOMIZATION: 'Customisation',
    REMOVAL: 'Removal',
    REPLACEMENT: 'Replacement',
    KM_ISSUE: 'KM Issue',
    DATA_ENTRY: 'Data Entry',
    LOCATION_ISSUE: 'Location Issue',
    MILEAGE_ISSUE: 'Mileage Issue',
    TRAINING: 'Training',
    INVOICE_REVISION: 'Invoice Revision',
    INSTALLATION: 'Installation',
    REQUEST_NEW_FASTAG: 'Request New FAStag',
    CLONE_REQUEST: 'Clone Request',
    CLONE_REMOVAL: 'Clone Removal',
    EPOD: 'EPOD',
    FREIGHT_BILL: 'Freight Bill',
    FREIGHT_BILL_CANCELLATION_REQUEST: 'Freight Bill Cancellation Request',
};

export const INTERNAL_ISSUES_DOCUMENT_TAGS = {
    AADHAR_CARD: 'AADHAR_CARD',
    GST_CERTIFICATE: 'GST_CERTIFICATE',
    CANCELLED_CHEQUE: 'CANCELLED_CHEQUE',
};

export const INTERNAL_ISSUES_DOCUMENT_TAGS_LABEL = {
    AADHAR_CARD: 'Aadhar Card',
    GST_CERTIFICATE: 'GST Certificate',
    CANCELLED_CHEQUE: 'Cancelled Cheque',
};

export const FLEETX_ISSUES_TYPES = {
    REPAIR: 'REPAIR',
    CUSTOMIZATION: 'CUSTOMIZATION',
    REMOVAL: 'REMOVAL',
    REPLACEMENT: 'REPLACEMENT',
    KM_ISSUE: 'KM_ISSUE',
    DATA_ENTRY: 'DATA_ENTRY',
    LOCATION_ISSUE: 'LOCATION_ISSUE',
    MILEAGE_ISSUE: 'MILEAGE_ISSUE',
    INVOICE_REVISION: 'INVOICE_REVISION',
    WELCOME_CALL: 'WELCOME_CALL',
    INSTALLATION: 'INSTALLATION',
    BUG: 'BUG',
    TRAINING_INTERNAL: 'TRAINING_INTERNAL',
    TRAINING_EXTERNAL: 'TRAINING_EXTERNAL',
    INVOICE_GENERATION: 'INVOICE_GENERATION',
    DEACTIVATION: 'DEACTIVATION',
    PRICING_ISSUE: 'PRICING_ISSUE',
    BILLING_CHANGE: 'BILLING_CHANGE',
    DEVICE_SHIFTING: 'DEVICE_SHIFTING',
    UPGRADE_DOWNGRADE: 'UPGRADE_DOWNGRADE',
    NO_DEMAND: 'NO_DEMAND',
    PARTIAL_DEMAND: 'PARTIAL_DEMAND',
    INSTALLATION_ON_HOLD: 'INSTALLATION_ON_HOLD',
    REQUEST_NEW_FASTAG: 'REQUEST_NEW_FASTAG',
    SIM_TRACKING_ISSUE: 'SIM_TRACKING_ISSUE',
    PORTABLE_GPS_ISSUE: 'PORTABLE_GPS_ISSUE',
    WIRED_GPS_ISSUE: 'WIRED_GPS_ISSUE',
    REEFER_ISSUE: 'REEFER_ISSUE',
    API_ISSUE: 'API_ISSUE',
    INDENT_ISSUE: 'INDENT_ISSUE',
    SWAPPING: 'SWAPPING',
    ERROR: 'ERROR',
    INVENTORY_REQUEST: 'INVENTORY_REQUEST',
    PORTAL_ISSUES: 'PORTAL_ISSUES',
    CLONE_REQUEST: 'CLONE_REQUEST',
    CLONE_REMOVAL: 'CLONE_REMOVAL',
    EPOD: 'EPOD',
    FREIGHT_BILL: 'FREIGHT_BILL',
    FREIGHT_BILL_CANCELLATION_REQUEST: 'FREIGHT_BILL_CANCELLATION_REQUEST',
};

export const FLEETX_ISSUES_TYPES_LABEL = {
    REPAIR: 'Repair',
    CUSTOMIZATION: 'Customisation',
    REMOVAL: 'Removal',
    REPLACEMENT: 'Replacement',
    KM_ISSUE: 'KM Issue',
    DATA_ENTRY: 'Data Entry',
    LOCATION_ISSUE: 'Location Issue',
    MILEAGE_ISSUE: 'Mileage Issue',
    INVOICE_REVISION: 'Invoice Revision',
    WELCOME_CALL: 'Welcome Call',
    INSTALLATION: 'Installation',
    BUG: 'Bug',
    TRAINING_INTERNAL: 'Training Internal',
    TRAINING_EXTERNAL: 'Training External',
    INVOICE_GENERATION: 'Invoice Generation',
    DEACTIVATION: 'Deactivation',
    PRICING_ISSUE: 'Pricing Issue',
    BILLING_CHANGE: 'Billing Change',
    DEVICE_SHIFTING: 'Device Shifting',
    UPGRADE_DOWNGRADE: 'Upgrade Downgrade',
    NO_DEMAND: 'No Demand',
    PARTIAL_DEMAND: 'Partial Demand',
    INSTALLATION_ON_HOLD: 'Installation On Hold',
    REQUEST_NEW_FASTAG: 'Request New FAStag',
    SIM_TRACKING_ISSUE: 'SIM Tracking Issue',
    PORTABLE_GPS_ISSUE: 'Portable GPS Issue',
    WIRED_GPS_ISSUE: 'Wired GPS Issue',
    REEFER_ISSUE: 'Reefer Issue',
    API_ISSUE: 'API Issue',
    INDENT_ISSUE: 'Indent Issue',
    SWAPPING: 'Swapping',
    ERROR: 'Error',
    INVENTORY_REQUEST: 'Inventory Request',
    PORTAL_ISSUES: 'Portal Issues',
    CLONE_REQUEST: 'Clone Request',
    CLONE_REMOVAL: 'Clone Removal',
    EPOD: 'EPOD',
    FREIGHT_BILL: 'Freight Bill',
    FREIGHT_BILL_CANCELLATION_REQUEST: 'Freight Bill Cancellation Request',
};

export function getDescriptionPlaceholder(issueType) {
    switch (issueType) {
        case CLIENT_ISSUES_TYPES.REPAIR:
        case CLIENT_ISSUES_TYPES.REMOVAL:
            return 'Tell us the driver phone number and vehicle location.';
        default:
            return 'Please describe your issue here...';
    }
}

export function isIssueCreatedByFleetxPerson(loggedInUser, reportedByAccountId) {
    return get(loggedInUser, 'accountId') !== reportedByAccountId;
}

export function isFleetxPersonCreatingIssue(isSwitchUserAllowed) {
    return isSwitchUserAllowed;
}

export function isVehicleRequiredInTicketForm(type, localTransporterAccount = null) {
    const accountId = localTransporterAccount || get(window, 'FLEETX_LOGGED_IN_USER.accountId');
    return (
        includes(
            [
                FLEETX_ISSUES_TYPES.REPAIR,
                FLEETX_ISSUES_TYPES.REMOVAL,
                FLEETX_ISSUES_TYPES.CLONE_REMOVAL,
                FLEETX_ISSUES_TYPES.REPLACEMENT,
                FLEETX_ISSUES_TYPES.CLONE_REQUEST,
                FLEETX_ISSUES_TYPES.KM_ISSUE,
                FLEETX_ISSUES_TYPES.LOCATION_ISSUE,
            ],
            type
        ) || includes([FLEETX_ACCOUNTS.ORIENT_CEMENT], accountId)
    );
}

export const InternalIssuesAccounts = [12925];

export function getTicketFormFieldConfig({ localTransporterAccount, values }) {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    const isTransporter = !!localTransporterAccount;
    const finalAccountId = localTransporterAccount || accountId;
    const issueType = get(values, 'type');

    let companyNameRequired = isTransporter
        ? !includes(InternalIssuesAccounts, finalAccountId)
        : includes([1570], accountId);
    let companyNameShow = isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true;

    let usernameRequired = isTransporter
        ? !includes(InternalIssuesAccounts, finalAccountId)
        : includes([1570, 14008], accountId);
    let usernameShow = isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true;

    let internalIssueVehiclesRequired =
        includes(
            [
                FLEETX_ISSUES_TYPES.REPAIR,
                FLEETX_ISSUES_TYPES.REMOVAL,
                FLEETX_ISSUES_TYPES.CLONE_REMOVAL,
                FLEETX_ISSUES_TYPES.REPLACEMENT,
                FLEETX_ISSUES_TYPES.CLONE_REQUEST,
                FLEETX_ISSUES_TYPES.KM_ISSUE,
                FLEETX_ISSUES_TYPES.LOCATION_ISSUE,
            ],
            issueType
        ) || includes([FLEETX_ACCOUNTS.ORIENT_CEMENT], finalAccountId);
    let internalIssueVehiclesShow = true;

    let descriptionRequired = isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true;
    let descriptionShow = isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true;

    if (finalAccountId === FLEETX_ACCOUNTS.ORIENT_CEMENT) {
        if (
            includes(
                [
                    FLEETX_ISSUES_TYPES.EPOD,
                    FLEETX_ISSUES_TYPES.FREIGHT_BILL,
                    FLEETX_ISSUES_TYPES.FREIGHT_BILL_CANCELLATION_REQUEST,
                ],
                issueType
            )
        ) {
            if (isTransporter) {
                descriptionRequired = true;
                descriptionShow = true;
            } else {
                companyNameRequired = false;
                companyNameShow = false;

                usernameRequired = false;
                usernameShow = false;
            }
            internalIssueVehiclesRequired = false;
            internalIssueVehiclesShow = false;
        }
    }

    return {
        summary: {
            show: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            required: isTransporter ? !includes(InternalIssuesAccounts, finalAccountId) : true,
            disabled: isTransporter ? includes(InternalIssuesAccounts, finalAccountId) : false,
        },
        description: {
            show: descriptionShow,
            required: descriptionRequired,
            disabled: false,
        },
        companyName: {
            show: companyNameShow,
            required: companyNameRequired,
            disabled: false,
        },
        username: {
            show: usernameShow,
            required: usernameRequired,
            disabled: false,
        },
        transporterId: {
            show: isTransporter ? false : includes([12925], accountId),
            required: false,
            disabled: false,
        },
        internalIssueVehicles: {
            show: internalIssueVehiclesShow,
            required: internalIssueVehiclesRequired,
            disabled: false,
        },
    };
}

export function getTicketFormFieldItemConfig(formFieldConfig, key) {
    return get(formFieldConfig, key, {});
}

export function getTicketTypeOptions(category, localTransporterAccount) {
    if (localTransporterAccount && includes(InternalIssuesAccounts, localTransporterAccount)) {
        return filter(mappedInternalIssuesTypes(category), (option) =>
            includes(
                [
                    FLEETX_ISSUES_TYPES.INSTALLATION,
                    FLEETX_ISSUES_TYPES.REPAIR,
                    FLEETX_ISSUES_TYPES.REMOVAL,
                    FLEETX_ISSUES_TYPES.REPLACEMENT,
                    FLEETX_ISSUES_TYPES.CLONE_REMOVAL,
                    FLEETX_ISSUES_TYPES.CLONE_REQUEST,
                    FLEETX_ISSUES_TYPES.EPOD,
                    FLEETX_ISSUES_TYPES.FREIGHT_BILL,
                    FLEETX_ISSUES_TYPES.FREIGHT_BILL_CANCELLATION_REQUEST,
                ],
                option.value
            )
        );
    }
    return mappedInternalIssuesTypes(category);
}

export function getDefaultVehicleAvailableDate(localTransporterAccount) {
    const isTransporter = !!localTransporterAccount;
    const accountId = localTransporterAccount
        ? localTransporterAccount
        : get(window, 'FLEETX_LOGGED_IN_USER.accountId');
    switch (accountId) {
        case 12925:
            return isTransporter ? getMomentTime() : undefined;
    }
}

export function getDefaultVehicleAvailableTime(localTransporterAccount) {
    const slotOptions = getAvailableSlotOptions({ localTransporterAccount });
    const hour = getMomentTime().hour();
    const defaultSlot = slotOptions.find((option) => {
        return hour >= option.generatorConfig.start && hour < option.generatorConfig.end;
    });
    if (defaultSlot) {
        return defaultSlot.value;
    } else {
        return get(last(slotOptions), 'value');
    }
}

export function getAvailableDateStartAndEnd({ vehicleAvailableDate, vehicleAvailableTime, localTransporterAccount }) {
    const selectedOption = getAvailableSlotOptions({ localTransporterAccount }).find(
        (option) => option.value === vehicleAvailableTime
    );
    let vehicleAvailableStartDate;
    let vehicleAvailableEndDate;
    const generatorConfig = get(selectedOption, 'generatorConfig', {});
    const { start, end, startDay, endDay } = generatorConfig;
    if (start && end && startDay && endDay && vehicleAvailableDate) {
        if (startDay === 'next') {
            vehicleAvailableStartDate = getMomentTime(vehicleAvailableDate)
                .add(1, 'day')
                .hour(start)
                .minute(0)
                .second(0);
        } else {
            vehicleAvailableStartDate = getMomentTime(vehicleAvailableDate).hour(start).minute(0).second(0);
        }
        if (endDay === 'next') {
            vehicleAvailableEndDate = getMomentTime(vehicleAvailableDate).add(1, 'day').hour(end).minute(0).second(0);
        } else {
            vehicleAvailableEndDate = getMomentTime(vehicleAvailableDate).hour(end).minute(0).second(0);
        }
    }
    return { vehicleAvailableStartDate, vehicleAvailableEndDate };
}

export function getAvailableSlotOptions({ localTransporterAccount }) {
    const accountId = localTransporterAccount
        ? localTransporterAccount
        : get(window, 'FLEETX_LOGGED_IN_USER.accountId');

    const defaultOptions = [
        {
            label: '9AM to 1PM',
            value: 9,
            generatorConfig: { start: 9, end: 13, startDay: 'same', endDay: 'same' },
        },
        {
            label: '1PM to 5PM',
            value: 13,
            generatorConfig: { start: 13, end: 17, startDay: 'same', endDay: 'same' },
        },
        {
            label: '5PM to 9PM',
            value: 17,
            generatorConfig: { start: 17, end: 21, startDay: 'same', endDay: 'same' },
        },
    ];
    switch (accountId) {
        case FLEETX_ACCOUNTS.ORIENT_CEMENT:
            return [
                {
                    value: 6,
                    label: '6AM - 2PM',
                    generatorConfig: { start: 6, end: 14, startDay: 'same', endDay: 'same' },
                },
                {
                    value: 14,
                    label: '2PM - 10PM',
                    generatorConfig: { start: 14, end: 22, startDay: 'same', endDay: 'same' },
                },
                {
                    value: 22,
                    label: '10PM - 6AM',
                    generatorConfig: { start: 22, end: 6, startDay: 'same', endDay: 'next' },
                },
            ];
        default:
            return defaultOptions;
    }
}
