import { jsx as _jsx } from "react/jsx-runtime";
import { components } from 'react-select';
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: 'auto',
        background: '#d4eefa',
        borderRadius: '4px',
        fontSize: '11px',
        padding: '3px',
        order: 99,
    };
    const title = items.join(', ');
    const length = items.length;
    const label = `+ ${length}`;
    return (_jsx("div", { style: style, title: title, children: label }));
};
export const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);
    return index < maxToShow ? (_jsx(components.MultiValue, { ...props })) : index === maxToShow ? (_jsx(MoreSelectedBadge, { items: overflow })) : null;
};
export const colourStyles = {
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            maxWidth: 70,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        };
    },
};
export const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#AA647B',
    '#90CAF9',
    '#D32F2F',
    '#795548',
    '#607D8B',
    '#ffcaed',
    '#fedb6f',
    '#ca95ff',
    '#dadada',
    '#ffbfa5',
    '#ffa9a5',
    '#95e5ff',
    '#5e7f5e',
    '#a272a3',
    '#63b8b5',
    '#779ecb',
    '#8fa3a4',
    '#cd94a8',
    '#77b5b5',
    '#e5c4be',
    '#748f8d',
];
export const YAxisTypesInLineChart = [
    {
        label: window.t('onTimePlacement'),
        value: 'onTimePlacementJobs',
    },
    {
        label: window.t('onTimeDelivery'),
        value: 'onTimeDeliveryJobs',
    },
    {
        label: window.t('delayedJobs'),
        value: 'delayedJobs',
    },
    {
        label: window.t('totalKM'),
        value: 'totalKm',
    },
    {
        label: window.t('avgJobsPerVehicle'),
        value: 'avgJobsPerVehicle',
    },
    {
        label: window.t('totalAccidents'),
        value: 'totalAccidents',
    },
];
export const YAxisTypesInLineChartObj = {
    'onTimePlacementJobs': window.t('onTimePlacement'),
    'onTimeDeliveryJobs': window.t('onTimeDelivery'),
    'delayedJobs': window.t('delayedJobs'),
    'totalKm': window.t('totalKM'),
    'avgJobsPerVehicle': window.t('avgJobsPerVehicle'),
    'totalAccidents': window.t('totalAccidents'),
};
export const dummyData = [
    {
        resolvedAlarms: 20,
        pendingAlarms: 25,
        resolvedIssues: 45,
        pendingIssues: 24,
    },
    {
        resolvedAlarms: 70,
        pendingAlarms: 85,
        resolvedIssues: 25,
        pendingIssues: 14,
    },
    {
        resolvedAlarms: 20,
        pendingAlarms: 54,
        resolvedIssues: 25,
        pendingIssues: 84,
    },
];
export const getVehicleReportCols = () => [
    {
        label: window.t('vehiclesLabel'),
        key: 'licensePlate',
        isKey: true,
    },
    {
        label: window.t('trips'),
        key: 'trips',
    },
    {
        label: window.t('utilization'),
        key: 'utilization',
    },
    {
        label: window.t('assetEffectiveness'),
        key: 'assetEffectiveness',
    },
    {
        label: window.t('totalKM'),
        key: 'totalOdometer',
    },
    {
        label: window.t('overSpeedTime'),
        key: 'overSpeedDuration',
    },
    {
        label: window.t('score'),
        key: 'score',
    },
    {
        label: window.t('hardAcc'),
        key: 'hardAcc',
    },
    {
        label: window.t('hardDeclaration'),
        key: 'hardDecc',
    },
    {
        label: window.t('km'),
        key: 'percentKm',
        width: 250,
    },
];
export const getTripAcrossZoneCols = () => [
    {
        label: window.t('region'),
        key: 'groupName',
        isKey: true,
        width: 200,
    },
    {
        label: window.t('move'),
        key: 'totalJobs',
        sort: true,
    },
    {
        label: '20`',
        key: 'twentyFeet',
        sort: true,
    },
    {
        label: '40`',
        key: 'fortyFeet',
        sort: true,
    },
    {
        label: window.t('total'),
        key: 'totalContainer',
        sort: true,
    },
    {
        label: 'TFE',
        key: 'twentyFeetEquivalent',
        sort: true,
    },
    {
        label: 'FFE',
        key: 'fortyFeetEquivalent',
        sort: true,
    },
    {
        label: 'TFE/FFE',
        key: 'diffOfTwentyAndForty',
    },
];
export const getRegionTrendCols = () => [
    {
        label: window.t('region'),
        key: 'groupId',
        width: 200,
        isKey: true,
    },
    {
        label: window.t('vehicleType'),
        key: 'vehicleSizeId',
        width: 200,
    },
    {
        label: window.t('NoOfVehicles'),
        key: 'noOfVehicles',
        sort: true,
    },
    {
        label: window.t('KMAllowed'),
        key: 'kmAllowed',
        sort: true,
    },
    {
        label: window.t('tripMovement'),
        key: 'tripMovement',
        sort: true,
    },
    {
        label: window.t('rateKMPerUnit'),
        key: 'rateKmPerUnit',
    },
    {
        label: window.t('rateTripPerUnit'),
        key: 'rateTripPerUnit',
    },
    {
        label: window.t('finalActualUtilization'),
        key: 'finalActualUtilisation',
    },
    {
        label: window.t('countActualUtilization'),
        key: 'countActualUtilisation',
    },
    {
        label: window.t('utilizationPercent'),
        key: 'utilization',
    },
    {
        label: window.t('cost'),
        key: 'cost',
        sort: true,
    },
    {
        label: window.t('actualLeaseCost'),
        key: 'netActualLeaseCost',
        sort: true,
    },
    {
        label: window.t('marketCost'),
        key: 'marketCost',
        sort: true,
    },
    {
        label: window.t('variance'),
        key: 'variance',
        sort: true,
    },
    {
        label: window.t('variancePercent'),
        key: 'variancePercent',
    },
];
export const LineChartTypes = {
    Customer: 'customer',
    Transporter: 'transporter',
    Lane: 'lane',
};
export const getTrendTypes = () => [
    { value: LineChartTypes.Customer, label: window.t('customers') },
    { value: LineChartTypes.Transporter, label: window.t('vendors') },
    { value: LineChartTypes.Lane, label: window.t('lanes') },
];
export const getAnalyticsOptions = (v) => {
    const obj = {
        [LineChartTypes.Customer]: {
            label: window.t('customer'),
            key: 'entityId',
            isKey: true,
        },
        [LineChartTypes.Transporter]: {
            label: window.t('vendor'),
            key: 'entityId',
            isKey: true,
        },
        [LineChartTypes.Lane]: {
            label: window.t('lane'),
            key: 'entityId',
            isKey: true,
        },
    };
    return obj[v];
};
export const getTable9Cols = () => [
    {
        label: window.t('trips'),
        key: 'totalJobs',
        sort: true,
    },
    {
        label: window.t('totalKM'),
        key: 'totalKm',
        sort: true,
    },
    {
        label: window.t('totalTripAvg'),
        key: 'kmPerTrip',
        sort: true,
    },
    {
        label: window.t('onTimePlacement'),
        key: 'onTimePlacement',
    },
    {
        label: window.t('onTimeDelivery'),
        key: 'onTimeDelivery',
    },
    {
        label: 'Indent With Vehicles',
        key: 'indentsWithVehicle',
    },
    {
        label: 'Indent Without Vehicles',
        key: 'indentsWithoutVehicle',
    },
    {
        label: 'Total Indents',
        key: 'totalIndents',
    },
];
