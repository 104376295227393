import {
    find,
    get,
    includes,
    map,
    omit,
    reduce,
    round,
    set,
    toLower,
    forEach,
    toSafeInteger,
    first,
    last,
    isEmpty,
    pick,
} from 'lodash';
import * as Yup from 'yup';
import {
    isExpressRoadwaysAccount,
    isSaintGobainAccount,
    isLoadedInTransitAccount,
    FLEETX_ACCOUNTS,
    useJobEndDateForMergedTripsForAccount,
    jobStartDateKeyForMergedTripsForAccount,
} from './account_utils';
import { isRemoved } from './vehicle_utils';
import { getMomentTime } from './date_utils';
import { combineNameAddress, handleError, parseAddress } from 'constant';
import { transformNumber } from 'utils/validation_utils';
import { createIdValueFromAddress } from 'utils/form_utils';
import moment from 'moment-timezone';
import { isJobTimelineExtendAllowed } from 'utils/job_utils';
import { jobEndDateKeyForMergedTripsForAccount } from 'utils/accountUtils/jobs';

export const ROUTE_ANALYTICS_TYPE = {
    DEFAULT: 'LAST_POINT',
    ALL_POINTS: 'ALL_POINTS',
    LAST_POINT: 'LAST_POINT',
};

export const JOB_STATUS_VALUES = {
    ASSIGNED: 'ASSIGNED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    UNASSIGNED: 'UNASSIGNED',
    CANCELLED: 'CANCELLED',
};
export const MOVEMENT_TYPE = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
};

export const JOB_STATUS = [
    {
        value: JOB_STATUS_VALUES.ASSIGNED,
        label: 'Scheduled',
    },
    {
        value: JOB_STATUS_VALUES.STARTED,
        label: 'Running',
    },
    {
        value: JOB_STATUS_VALUES.COMPLETED,
        label: 'Completed',
    },
    {
        value: JOB_STATUS_VALUES.CANCELLED,
        label: 'Cancelled',
    },
];
export const MOVEMENT_TYPE_OPTIONS = [
    {
        value: MOVEMENT_TYPE.INBOUND,
        label: 'Inbound',
    },
    {
        value: MOVEMENT_TYPE.OUTBOUND,
        label: 'Outbound',
    },
];
export const JOB_STATUS_EXPRESS = [
    {
        value: JOB_STATUS_VALUES.ASSIGNED,
        label: 'Assigned',
    },
    {
        value: JOB_STATUS_VALUES.STARTED,
        label: 'Running',
    },
    {
        value: JOB_STATUS_VALUES.COMPLETED,
        label: 'Completed',
    },
    {
        value: JOB_STATUS_VALUES.CANCELLED,
        label: 'Cancelled',
    },
];

export const POINT_STATUS_VALUES = {
    EARLY: 'EARLY',
    COMPLETED: 'COMPLETED',
    MISSED: 'MISSED',
    LATE: 'LATE',
    SCHEDULED: 'SCHEDULED',
    ONTIME: 'ONTIME',
};

export const POINT_STATUS = [
    {
        value: POINT_STATUS_VALUES.EARLY,
        label: 'Early',
    },
    {
        value: POINT_STATUS_VALUES.COMPLETED,
        label: 'On Time',
    },
    {
        value: POINT_STATUS_VALUES.MISSED,
        label: 'Missed',
    },
];

export const UNLOADING_DURATION = [
    {
        value: '0-6',
        label: '0-6 hour',
    },
    {
        value: '6-12',
        label: '6-12 hour',
    },
    {
        value: '12-24',
        label: '12-24 hour',
    },
    {
        value: '24-null',
        label: '>24 hour',
    },
];

export const LOADING_DURATION = [
    {
        value: '0-6',
        label: '0-6 hour',
    },
    {
        value: '6-12',
        label: '6-12 hour',
    },
    {
        value: '12-24',
        label: '12-24 hour',
    },
    {
        value: '24-null',
        label: '>24 hour',
    },
];

export const ETA_DELAY = [
    {
        value: '0-4',
        label: '0-4 hour',
    },
    {
        value: '4-8',
        label: '4-8 hour',
    },
    {
        value: '8-12',
        label: '8-12 hour',
    },
    {
        value: '12-null',
        label: '>12 hour',
    },
    {
        value: '24-null',
        label: '>24 hour',
    },
    {
        value: '48-null',
        label: '>48 hour',
    },
];

export const DEVIATION_KM = [
    {
        value: 'nullto-40',
        label: '<-40',
    },
    {
        value: '-40to-25',
        label: '-40 to -25',
    },
    {
        value: '-25to-15',
        label: '-25 to -15',
    },
    {
        value: '-15to0',
        label: '-15 to 0',
    },
    {
        value: '0to15',
        label: '0 - 15',
    },
    {
        value: '15to25',
        label: '15 - 25',
    },
    {
        value: '25to40',
        label: '25 - 40',
    },
    {
        value: '40tonull',
        label: '>40',
    },
];

export const DEVIATION_KM_OVERVIEW = [
    {
        value: 'nullto10',
        label: '<10',
    },
    {
        value: '10to100',
        label: '10 to 100',
    },
    {
        value: '100to250',
        label: '100 to 250',
    },
    {
        value: '250tonull',
        label: '>250',
    },
];

export const jobClosingReasonFixed = [
    {
        value: 'AUTO_TERMINATE',
        label: 'Auto Terminate',
        color: '#AA647B',
    },
    {
        value: 'MAX_TIME_SPENT_INNER',
        label: 'Max Time Inner',
        color: '#98D9FC',
    },
    {
        value: 'MAX_TIME_SPENT_OUTER',
        label: 'Max Time Outer',
        color: '#87B887',
    },
    {
        value: 'STOPPAGE_OUT_GEOFENCE',
        label: 'Stoppage Out',
        color: '#E7F1E7',
    },
    {
        value: 'RETURN_TO_PLANT',
        label: 'Return to Plant',
        color: '#EB8080',
    },
    {
        value: 'RETURN_TO_PLANT_FROM_INNER',
        label: 'Return from Inner',
        color: '#FEDA6E',
    },
    {
        value: 'RETURN_TO_PLANT_FROM_OUTER',
        label: 'Return from Outer ',
        color: '#FFA500',
    },
    {
        value: 'Closed by API',
        label: 'Closed by API ',
        color: '#AA647B',
    },
    {
        value: 'Closing job via geofence',
        label: 'Closing job via geofence ',
        color: '#98D9FC',
    },
    {
        value: 'DEVICE_NOT_ATTACHED_TO_VEHICLE',
        label: 'Device not attached to vehicle',
        color: '#87B887',
    },
    {
        value: 'Force closed by API',
        label: 'Force closed by API',
        color: '#E7F1E7',
    },
    {
        value: 'Job closed due to extra km travelled than scheduled',
        label: 'Job closed due to extra km travelled than scheduled',
        color: '#EB8080',
    },
    {
        value: 'Modifying and closing old job',
        label: 'Modifying and closing old job',
        color: '#FEDA6E',
    },
    {
        value: 'NO_GEOFENCE_ENTRY',
        label: 'No geofence entry',
        color: '#FEDA6E',
    },
    {
        value: 'OLD_JOB_FLOW_VEHICLE_DESTINATION_REACHED',
        label: 'Old job flow vehicle destination reached',
        color: '#FFA500',
    },
];

export function getRouteAnalytics(routeObject, allRouteAnalytics) {
    const analyticsId = `${routeObject.name}~${routeObject.id}`;
    const routeAnalytics = get(allRouteAnalytics, `routeToAnalytics.${analyticsId}`, {});
    return routeAnalytics;
}

export const EMIT_ACTION_JOBS_SUMMARY_ICON_FILTER = 'EMIT_ACTION_JOBS_SUMMARY_ICON_FILTER';

export const JOB_TIMING_STATE = {
    ON_TIME: 'ONTIME',
    EARLY: 'EARLY',
    LATE: 'LATE',
};

export const TAG_INFO_TEXT = {
    Critical_Battery: 'Battery is between 0-5%',
    Low_Battery: 'Battery is between 5-30%',
    Fully_Charged: 'Battery is 100% changed',
    Medium_Battery: 'Battery is between 30-100%',
};

export const JOB_TIMING_STATE_VALUES = [
    {
        id: JOB_TIMING_STATE.ON_TIME,
        name: 'On Time',
    },
    {
        id: JOB_TIMING_STATE.EARLY,
        name: 'Early',
    },
    {
        id: JOB_TIMING_STATE.LATE,
        name: 'Late',
    },
];

export function findJobTimingStateFromId(jobDelayStateId, loggedInUser) {
    const jobDelayState = find(JOB_TIMING_STATE_VALUES, { id: jobDelayStateId });
    return jobDelayState.name;
}

export function getJobTimingFilterOptions() {
    return {
        ON_TIME: findJobTimingStateFromId(JOB_TIMING_STATE.ON_TIME),
        EARLY: findJobTimingStateFromId(JOB_TIMING_STATE.EARLY),
        LATE: findJobTimingStateFromId(JOB_TIMING_STATE.LATE),
    };
}

export const VEHICLE_JOB_STATE = {
    LOADED: 'LOADED',
    UNLOADED: 'UNLOADED',
    LOADING: 'LOADING',
    UNLOADING: 'UNLOADING',
    NOT_ON_JOB: 'OTHERS',
    ON_JOB: 'ONJOB',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    SCHEDULED: 'SCHEDULED',
    OTHERS: 'OTHERS',
    CONTAINERISED_IMPORT: 'CONTAINERISED_IMPORT',
    CONTAINERISED_EXPORT: 'CONTAINERISED_EXPORT',
    WAITING_TO_LOAD: 'WAITING_TO_LOAD',
    WAITING_TO_UNLOAD: 'WAITING_TO_UNLOAD',
    WAITING_IN_GARAGE: 'WAITING_IN_GARAGE',
    IN_GARAGE: 'IN_GARAGE',
    MAINTENANCE: 'MAINTENANCE',
    FUELING: 'FUELING',
    ENROUTE: 'ENROUTE',
    UNLOADING_COMPLETE: 'UNLOADING_COMPLETE',
    MARKET_LOAD: 'MARKET_LOAD',
    ASSIGNED: 'ASSIGNED',
    IN_TRANSIT: 'IN_TRANSIT',
};

export const JOB_FORM_VEHICLE_STATE = [
    {
        id: VEHICLE_JOB_STATE.LOADED,
        name: 'Loaded',
    },
    {
        id: VEHICLE_JOB_STATE.UNLOADED,
        name: 'Empty',
    },
    {
        id: VEHICLE_JOB_STATE.OTHERS,
        name: 'Others',
    },
    {
        id: VEHICLE_JOB_STATE.CONTAINERISED_IMPORT,
        name: 'Containerised Import',
    },
    {
        id: VEHICLE_JOB_STATE.CONTAINERISED_EXPORT,
        name: 'Containerised Export',
    },
    {
        id: VEHICLE_JOB_STATE.MAINTENANCE,
        name: 'Maintenance',
    },
    {
        id: VEHICLE_JOB_STATE.FUELING,
        name: 'Fueling',
    },
    {
        id: VEHICLE_JOB_STATE.MARKET_LOAD,
        name: 'Market Load',
    },
    {
        id: VEHICLE_JOB_STATE.IN_TRANSIT,
        name: 'In Transit',
    },
];

export const VEHICLE_STATE = [
    {
        id: VEHICLE_JOB_STATE.WAITING_TO_LOAD,
        name: 'waitingToLoad',
    },
    {
        id: VEHICLE_JOB_STATE.LOADED,
        name: 'loaded',
    },
    {
        id: VEHICLE_JOB_STATE.UNLOADED,
        name: 'empty',
    },
    {
        id: VEHICLE_JOB_STATE.LOADING,
        name: 'loading',
    },
    {
        id: VEHICLE_JOB_STATE.WAITING_TO_UNLOAD,
        name: 'waitingToUnload',
    },
    {
        id: VEHICLE_JOB_STATE.WAITING_IN_GARAGE,
        name: 'waitingInGarage',
    },
    {
        id: VEHICLE_JOB_STATE.IN_GARAGE,
        name: 'inGarage',
    },
    {
        id: VEHICLE_JOB_STATE.UNLOADING,
        name: 'unLoading',
    },
    {
        id: VEHICLE_JOB_STATE.SCHEDULED,
        name: 'scheduled',
    },
    {
        id: VEHICLE_JOB_STATE.NOT_ON_JOB,
        name: 'notOnJob',
    },
    {
        id: VEHICLE_JOB_STATE.ON_JOB,
        name: 'onJob',
    },
    {
        id: VEHICLE_JOB_STATE.CONTAINERISED_EXPORT,
        name: 'Containerised Export',
    },
    {
        id: VEHICLE_JOB_STATE.CONTAINERISED_IMPORT,
        name: 'Containerised Import',
    },
    {
        id: VEHICLE_JOB_STATE.ENROUTE,
        name: 'Enroute',
    },
    {
        id: VEHICLE_JOB_STATE.UNLOADING_COMPLETE,
        name: 'unloadingComplete',
    },
    {
        id: VEHICLE_JOB_STATE.IN_TRANSIT,
        name: 'In Transit',
    },
];

export function getVehicleStatusUIText(vehicleState) {
    const vehicleStateObject = find(VEHICLE_STATE, { id: vehicleState });
    if (vehicleStateObject) {
        return vehicleStateObject.name;
    }

    return vehicleState;
}

export function getJobStatusFilterOptions(loggedInUser) {
    return {
        OTHERS: findvehicleStateFromId(VEHICLE_JOB_STATE.NOT_ON_JOB, loggedInUser),
        LOADED: findvehicleStateFromId(VEHICLE_JOB_STATE.LOADED, loggedInUser),
        LOADING: findvehicleStateFromId(VEHICLE_JOB_STATE.LOADING, loggedInUser),
        UNLOADING: findvehicleStateFromId(VEHICLE_JOB_STATE.UNLOADING, loggedInUser),
        UNLOADED: findvehicleStateFromId(VEHICLE_JOB_STATE.UNLOADED, loggedInUser),
        SCHEDULED: findvehicleStateFromId(VEHICLE_JOB_STATE.SCHEDULED, loggedInUser),
    };
}

export function getJobStatus(status, loggedInUser) {
    if (!isExpressRoadwaysAccount(loggedInUser)) {
        if (status === JOB_STATUS_VALUES.ASSIGNED) {
            return 'SCHEDULED';
        } else {
            return status;
        }
    }
    return status;
}

export function findvehicleStateFromId(vehicleStateId, loggedInUser) {
    const vehicleState = find(VEHICLE_STATE, { id: vehicleStateId });
    if (isSaintGobainAccount(loggedInUser)) {
        if (vehicleStateId === VEHICLE_JOB_STATE.NOT_ON_JOB) {
            return window.t('wayToPlant');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.LOADED) {
            return window.t('wayToCustomer');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.LOADING) {
            return window.t('atPlant');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADING) {
            return window.t('atCustomer');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADED) {
            return window.t(vehicleState.name);
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADING_COMPLETE) {
            return window.t('unloadingComplete');
        }
    }
    if (isExpressRoadwaysAccount(loggedInUser)) {
        if (vehicleStateId === VEHICLE_JOB_STATE.NOT_ON_JOB) {
            return window.t(vehicleState.name);
        } else if (vehicleStateId === VEHICLE_JOB_STATE.LOADED) {
            return window.t('inTransit');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.LOADING) {
            return window.t(vehicleState.name);
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADING) {
            return window.t(vehicleState.name);
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADED) {
            return window.t('emptyJob');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.UNLOADING_COMPLETE) {
            return window.t('unloadingComplete');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.SCHEDULED) {
            return window.t('scheduledJobs');
        } else if (vehicleStateId === VEHICLE_JOB_STATE.ASSIGNED) {
            return window.t('assigned');
        }
    }
    if (isLoadedInTransitAccount(loggedInUser)) {
        if (vehicleStateId === VEHICLE_JOB_STATE.LOADED) {
            return window.t('inTransit');
        }
    }
    return window.t(get(vehicleState, 'name', ''));
}

export const VEHICLE_STATUS_NAME_JOB = {
    RUNNING: 'Running',
    IDLE: 'Idle',
    PARKED: 'Parked',
    DISCONNECTED: 'Disconnect',
    UNREACHABLE: 'No Network',
    IMMOBILISED: 'Immobilised',
    REMOVED: 'Removed',
    INSHOP: 'Workshop',
    NO_POWER: 'Parked',
    BATTERY_DISCHARGED: 'Disconnect',
};

export const AddressBookOptionEnum = {
    LOADING: 'LOADING',
    UNLOADING: 'UNLOADING',
    LOADING_UNLOADING: 'LOADING_UNLOADING',
};

export const ADDRESS_BOOK_TYPE_OPTIONS = [
    { value: AddressBookOptionEnum.LOADING, label: 'Loading Point' },
    {
        value: AddressBookOptionEnum.UNLOADING,
        label: 'Unloading Point',
    },
    { value: AddressBookOptionEnum.LOADING_UNLOADING, label: 'Loading & Unloading Point' },
];

export const ADDRESS_BOOK_CATEGORY_OPTIONS = [
    { value: 'BRANCH', label: 'Branch', includeAccount: [] },
    { value: 'CHECK_POST', label: 'Check Post', includeAccount: [] },
    { value: 'CRANE_SERVICE', label: 'Crane Service', includeAccount: [] },
    { value: 'DISTRIBUTERS', label: 'Distributers', includeAccount: [6367, 113] },
    { value: 'DISTRIBUTOR', label: 'Distributor', includeAccount: [] },
    { value: 'DRIVER_HOME', label: 'Driver Home', includeAccount: [] },
    { value: 'FACTORY', label: 'Factory', includeAccount: [] },
    { value: 'FIRE_STATION', label: 'Fire Station', includeAccount: [] },
    { value: 'GENERAL_TRADE', label: 'General Trade', includeAccount: [6367] },
    { value: 'GODOWN', label: 'Godown', includeAccount: [] },
    { value: 'HOME', label: 'Home', includeAccount: [6367] },
    { value: 'HOSPITAL', label: 'Hospital', includeAccount: [] },
    { value: 'HOTEL_DHABA', label: 'Hotel Dhaba', includeAccount: [] },
    { value: 'HUB', label: 'HUB', includeAccount: [] },
    { value: 'ICD', label: 'ICD', includeAccount: [] },
    { value: 'INSTITUTIONAL', label: 'Institutional', includeAccount: [6367] },
    { value: 'LOAN_LIAISONING', label: 'Loan Liaisoning', includeAccount: [] },
    { value: 'MODERN_TRADE', label: 'Modern Trade', includeAccount: [6367] },
    { value: 'NBD', label: 'NBD', includeAccount: [6367] },
    { value: 'OWN_MANUFACTURING', label: 'Own Manufacturing', includeAccount: [] },
    { value: 'PARKING', label: 'Parking', includeAccount: [] },
    { value: 'PETROL_PUMP', label: 'Petrol Pump', includeAccount: [] },
    { value: 'PLANT', label: 'Plant', includeAccount: [] },
    { value: 'POLICE_STATION', label: 'Police Station', includeAccount: [] },
    { value: 'SCHOOL', label: 'School', includeAccount: [] },
    { value: 'SERVICE_CENTRE', label: 'Service Center', includeAccount: [] },
    { value: 'THIRD_PARTY_MANUFACTURING', label: 'Third Party Manufacturing', includeAccount: [] },
    { value: 'TOLL_NAKA', label: 'Toll Naka', includeAccount: [] },
    { value: 'TOLL_PLAZA', label: 'Toll Plaza', includeAccount: [] },
    { value: 'WAREHOUSE', label: 'Warehouse', includeAccount: [] },
    { value: 'WEIGH_BRIDGE', label: 'Weigh Bridge', includeAccount: [] },
    { value: 'WAY_POINT', label: 'Way Point', includeAccount: [8526, 113] },
    { value: 'WASHING_AREA', label: 'Washing Area', includeAccount: [] },
    { value: 'YARD', label: 'Yard', includeAccount: [] },
    { value: 'MODERN_TRADE', label: 'Modern Trade', includeAccount: [] },
    { value: 'CHARGING_STATION', label: 'Charging Station', includeAccount: [] },
    { value: 'DEPOT', label: 'Depot', includeAccount: [] },
    { value: 'GOVERNMENT', label: 'Government', includeAccount: [] },
    { value: 'COMPETITOR_PLANT', label: 'Competitor Plant', includeAccount: [] },
    { value: 'CFS', label: 'CFS', includeAccount: [] },
    { value: 'CUSTOMER', label: 'Customer', includeAccount: [] },
    { value: 'DEALER', label: 'Dealer', includeAccount: [] },
    { value: 'PORT', label: 'PORT', includeAccount: [] },
    { value: 'CNF', label: 'CNF', includeAccount: [13921] },
    { value: 'SUPER_STOCK', label: 'Super Stock', includeAccount: [13921] },
    { value: 'RETAIL_OUTLET', label: 'Retail Outlet', includeAccount: [13921] },
    { value: 'MINES', label: 'Mines', includeAccount: [] },
    { value: 'ENGINE_MECHANIC', label: 'Engine Mechanic', includeAccount: [] },
    { value: 'ELECTRICIAN_SHOP', label: 'Electrician Shop', includeAccount: [] },
    { value: 'KAMAN_PHATA', label: 'KAMAN phata', includeAccount: [] },
    { value: 'TYRE_DEALER', label: 'Tyre dealer', includeAccount: [] },
    { value: 'DEPOT_PWH', label: 'Depot - PWH', includeAccount: [12925] },
    { value: 'DEPOT_VWH', label: 'Depot - VWH', includeAccount: [12925, 13179] },
    { value: 'DEPOT_RH', label: 'Depot - RH', includeAccount: [12925] },
    { value: 'DEPOT_CRWC', label: 'Depot - CRWC', includeAccount: [12925] },
    { value: 'DEPOT_SOW', label: 'Depot - SOW', includeAccount: [12925, 13179] },
    { value: 'CLEARING_FORWADING_AGENT', label: 'Clearing Forwading - Agent', includeAccount: [13179] },
    { value: 'SALES_PROMOTER_HA', label: 'Sales Promoter - HA', includeAccount: [13179] },
];
export const ALARM_VEHICLE_STATE_OPTIONS = [
    { value: 'ALL', label: 'All Vehicles' },
    { value: 'ON_JOB', label: 'On Job Vehicles' },
    { value: 'NOT_ON_JOB', label: 'Not On Job Vehicles' },
];

export function appendJobDetailsInList(jobList) {
    return map(jobList, (job) => {
        return appendJobDetails(job);
    });
}

export const isValidJson = (stringValue) => {
    try {
        JSON.parse(stringValue);
        return true;
    } catch (err) {
        return false;
    }
};

export function appendJobDetails(job) {
    const appendedJob = { ...job };
    const distance = get(job, 'route.distance');
    appendedJob.jobStatusStartTime = getJobStatusStartTime(job);
    //appendedJob.delay = job.predictedDelay ? job.predictedDelay : getJobDelay(job.jobAnalytics);
    const isReachedAtDestination = !!get(last(job.jobAnalytics), 'actualArrival');
    appendedJob.delay = job.predictedDelay
        ? job.predictedDelay
        : isReachedAtDestination
        ? get(last(job.jobAnalytics), 'delay', 0)
        : 0;
    appendedJob.delayState =
        appendedJob.delay === 0
            ? JOB_TIMING_STATE.ON_TIME
            : appendedJob.delay > 0
            ? JOB_TIMING_STATE.LATE
            : JOB_TIMING_STATE.EARLY;
    appendedJob.completionPercent =
        job.status === JOB_STATUS_VALUES.STARTED && distance && job.currentKm
            ? round((100 * job.currentKm) / distance)
            : job.status === JOB_STATUS_VALUES.COMPLETED
            ? 100
            : 0;
    if (appendedJob.completionPercent >= 100 && job.jobStatus === VEHICLE_JOB_STATE.UNLOADING) {
        //Forcing completion percent to 99% as job is unloading
        appendedJob.completionPercent = 99;
    }
    if (appendedJob.completionPercent == 0 && job.jobStatus === VEHICLE_JOB_STATE.LOADING) {
        //Forcing completion percent to 1% as job us loading
        appendedJob.completionPercent = 1;
    }
    //Force compute 2nd point scheduledArrival
    if (
        get(appendedJob, 'jobAnalytics', []).length === 2 &&
        !!get(appendedJob, 'jobAnalytics[0].actualDeparture') &&
        !!get(appendedJob, 'jobAnalytics[0].routePoint.transitTime') &&
        !get(appendedJob, 'jobAnalytics[1].scheduledArrival')
    ) {
        set(
            appendedJob,
            'jobAnalytics[1].scheduledArrival',
            get(appendedJob, 'jobAnalytics[0].actualDeparture') +
                get(appendedJob, 'jobAnalytics[0].routePoint.transitTime')
        );
    }

    return appendedJob;
}

export function getJobStatusStartTime(job) {
    if (job.jobStatus === VEHICLE_JOB_STATE.LOADED) {
        if (get(job, 'jobAnalytics[0].actualDeparture')) {
            return get(job, 'jobAnalytics[0].actualDeparture');
        }
    } else if (job.jobStatus === VEHICLE_JOB_STATE.LOADING) {
        if (get(job, 'jobAnalytics[0].actualArrival')) {
            return get(job, 'jobAnalytics[0].actualArrival');
        }
    } else if (job.jobStatus === VEHICLE_JOB_STATE.UNLOADING) {
        const lastIndex = get(job, 'jobAnalytics', []).length - 1;
        if (lastIndex > 0) {
            if (get(job, `jobAnalytics[${lastIndex}].actualArrival`)) {
                return get(job, `jobAnalytics[${lastIndex}].actualArrival`);
            }
        }
    } else {
        return null;
    }
}

export function getJobDelay(jobAnalytics) {
    let delay = 0;
    map(jobAnalytics, (analytics) => {
        if (analytics.delay && analytics.actualArrival) {
            delay = analytics.delay;
        }
    });
    return delay;
}

export function getMergedJobsWithVehicles(realtimeVehicles = [], jobList) {
    const jobObject = reduce(
        jobList,
        (acc, job) => {
            if (job.vehicle) {
                return { ...acc, [job.vehicle.id]: job };
            } else {
                return { ...acc };
            }
        },
        {}
    );

    const realtimeVehicleList = [];
    map(realtimeVehicles, (v) => {
        if (!isRemoved(get(v, 'currentStatus'))) {
            const vehicleWithJob = { ...v };
            const latestJob = jobObject[vehicleWithJob.vehicleId];
            if (latestJob) {
                vehicleWithJob.latestJob = latestJob;
                vehicleWithJob.latestJobStatus = latestJob['jobStatus'];
            } else {
                vehicleWithJob.latestJobStatus = VEHICLE_JOB_STATE.NOT_ON_JOB;
            }
            realtimeVehicleList.push(vehicleWithJob);
        }
    });
    return realtimeVehicleList;
}

export const JOB_ANALYTICS_TAB = {
    ROUTE: 'ROUTE',
    GROUP: 'GROUP',
    VEHICLE: 'VEHICLE',
    CONSIGNOR: 'CONSIGNOR',
};

export const JOB_ANALYTICS_TAB_TEXT = {
    ROUTE: 'Route',
    GROUP: 'Group',
    VEHICLE: 'Vehicle',
    CONSIGNOR: 'Consignor',
};

export const routeFilterOptions = (options, filter, currentValues, filteringOptions, existingValueId) => {
    const MAX_ROUTE_DISPLAY_LIMIT = 50;
    const savedOptions = [];
    const length = options.length;
    let count = 0;

    for (let i = 0; i < length; i++) {
        const option = get(options, `[${i}]`, {});
        if (existingValueId && get(option, 'value', '') == existingValueId) {
            savedOptions.push(option);
        } else if (count < MAX_ROUTE_DISPLAY_LIMIT && includes(toLower(get(option, 'label', '')), toLower(filter))) {
            savedOptions.push(option);
            count++;
        } else if (!existingValueId && count >= MAX_ROUTE_DISPLAY_LIMIT) {
            break;
        }
    }
    return savedOptions;
};

export const refactorRoutePointResponseFromServer = (origRoute) => {
    if (!origRoute) {
        return null;
    }
    const newRoute = { ...omit(origRoute, ['routePoints']) };
    let tatLast = null,
        tatCurrent = null;
    newRoute.routePoints = [];
    map(get(origRoute, 'routePoints', []), (rp, index) => {
        let newRp = { ...rp };
        if (rp.transitTime) {
            tatCurrent = rp.transitTime;
        }
        newRp = omit(newRp, ['tat', 'transitTime']);
        if (tatLast) {
            newRp.tat = tatLast;
        }
        newRoute.routePoints.push(newRp);

        if (tatCurrent) {
            tatLast = tatCurrent;
        } else {
            tatLast = null;
        }
        tatCurrent = null;
    });
    return newRoute;
};

export const RISK_LEVEL = {
    N: 'N',
    C: 'C',
    E: 'E',
};
export const RISK_LEVEL_DEF = {
    N: 'No Risk',
    C: 'Medium Risk',
    E: 'High Risk',
};

export const JOB_DOCUMENTS_TYPES = [
    {
        value: 'POD',
        label: 'Proof of Delivery(POD)',
    },
    {
        value: 'lr',
        label: 'LR/Docket',
    },
    {
        value: 'dateLogger',
        label: 'Date Logger',
    },
    {
        value: 'tripSheet',
        label: 'Trip Sheet',
    },
    {
        value: 'Vehicle RC front',
        label: 'Vehicle RC front',
    },
    {
        value: 'Vehicle RC back',
        label: 'Vehicle RC back',
    },
    {
        value: 'Owner Pan Card',
        label: 'Owner Pan Card',
    },
    {
        value: 'Driver Picture',
        label: 'Driver Picture',
    },
    {
        value: 'Driver License',
        label: 'Driver License',
    },
    {
        value: 'Declaration',
        label: 'Declaration',
    },
    {
        value: 'LR 1',
        label: 'LR 1',
    },
    {
        value: 'LR 2',
        label: 'LR 2',
    },
    {
        value: 'E-way bill 1',
        label: 'E-way bill 1',
    },
    {
        value: 'E-way bill 2',
        label: 'E-way bill 2',
    },

    {
        value: 'Invoice Number 1',
        label: 'Invoice Number 1',
    },
    {
        value: 'Invoice Number 2',
        label: 'Invoice Number 2',
    },
    {
        value: 'weightFlowReading',
        label: 'Weight/Mass flow reading',
    },
    {
        value: 'unloadedWeightFlowReading',
        label: 'Unloaded Weight/Mass Flow Reading',
    },
    {
        value: 'initialVolume',
        label: 'Initial Volume/Pressure',
    },
    {
        value: 'finalVolume',
        label: 'Final Volume/Pressure',
    },
    {
        value: 'ContainerNumber',
        label: 'Container Number',
    },
    {
        value: 'SealNumber',
        label: 'Seal Number',
    },
    {
        value: 'T1',
        label: 'T1',
    },
    {
        value: 'IM8',
        label: 'IM8',
    },
    {
        value: 'SubT1',
        label: 'SubT1',
    },
    {
        value: 'Invoice (INV)',
        label: 'Invoice (INV)',
    },
    {
        value: 'Packing list (PL)',
        label: 'Packing list (PL)',
    },
    {
        value: 'Movement sheet',
        label: 'Movement sheet',
    },
    {
        value: 'Bill of Lading (BL)',
        label: 'Bill of Lading (BL)',
    },
    {
        value: 'Cargo Delivery Note (CDN)',
        label: 'Cargo Delivery Note (CDN)',
    },
];

export function isVehicleOnJob(vehicleJob) {
    const isLoaded = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.LOADED;
    const isUnLoaded = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.UNLOADED;
    const isLoading = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.LOADING;
    const isUnloading = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.UNLOADING;
    const isScheduled = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.SCHEDULED;
    const isWaitingToLoad = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.WAITING_TO_LOAD;
    const isWaitingInGarage = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.WAITING_IN_GARAGE;
    const isInGarage = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.IN_GARAGE;
    const isWaitingToUnload = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.WAITING_TO_UNLOAD;
    const isUnloadingComplete = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.UNLOADING_COMPLETE;
    const inTransit = get(vehicleJob, 'status') === VEHICLE_JOB_STATE.IN_TRANSIT;

    return (
        isLoaded ||
        isUnLoaded ||
        isLoading ||
        isUnloading ||
        isScheduled ||
        isWaitingToLoad ||
        isWaitingToUnload ||
        isWaitingInGarage ||
        isUnloadingComplete ||
        isInGarage ||
        inTransit
    );
}

export function getTotalTAT(route) {
    const { routePoints } = route;
    if (!routePoints) {
        return 0;
    }
    let totalDurationInMS = 0;
    const totalRoutePoint = routePoints.length;
    forEach(routePoints, (point, index) => {
        if (index < totalRoutePoint - 1) {
            totalDurationInMS += toSafeInteger(point.transitTime);
            if (index !== 0) {
                totalDurationInMS += toSafeInteger(point.haltTime);
            }
        }
    });

    return totalDurationInMS;
}

export function isJobRoutePointUnloadingPoint(point, index, totalPoint) {
    return index > 0 && index < totalPoint - 1;
}

export const EWAY_EXPIRING_IN_DAYS = [
    {
        value: 1,
        label: '1 Day',
    },
    {
        value: 2,
        label: '2 Days',
    },
];

export function getEwayBillDayFilterToFilterParams(days) {
    let params = {};
    const daysLocal = toSafeInteger(days);
    if (daysLocal) {
        let ewayExpiryDurationInMillis = getMomentTime().add(daysLocal, 'd').diff(getMomentTime());
        params = {
            ewayExpiryDurationInMillis: ewayExpiryDurationInMillis,
        };
    }

    return params;
}

export function getBooleanTrueOnlyFlag(value, key) {
    let params = {};
    if (value) {
        params = {
            [key]: value,
        };
    }
    return params;
}

export function getLabelForActualDistance(index) {
    const startChar = 'A';
    const startCharCode = startChar.charCodeAt(0);
    return `Distance ${String.fromCharCode(startCharCode + index - 1)}-${String.fromCharCode(startCharCode + index)}`; // like A-B, B-C
}

export const getRoutePointSelectorSchema = (commodityName) => {
    return Yup.array()
        .of(
            Yup.object().shape({
                routePoint: Yup.object().shape({
                    selectId: Yup.string().required('Field Required.').nullable(),
                }),
                [commodityName]: Yup.array().of(
                    Yup.object().shape({
                        commodity: Yup.object().shape({
                            id: Yup.number().required('Field Required.').nullable(),
                        }),
                        materialCount: Yup.number()
                            .transform(transformNumber)
                            .test('materialCount-required', 'Scheduled Count Required', function (materialCount) {
                                const materialQuantity = get(this.options, 'parent.materialQuantity');
                                return materialCount || materialQuantity;
                            }),
                    })
                ),
            })
        )
        .test('route-point-required', 'Please add route points', function (vals) {
            const isExistingRouteSelected = get(this.options, 'parent.isExistingRouteSelected');
            return isExistingRouteSelected ? true : !isEmpty(vals);
        })
        .nullable();
};

export function getAutofillValuesOnRouteSelected(selectedRoute) {
    if (selectedRoute) {
        return map(selectedRoute.routePoints, (point) => {
            const parsedAddress = parseAddress(point.address);
            const obj = {
                address: point.address,
                latitude: point.latitude,
                longitude: point.longitude,
                addressBookId: point.addressBookId,
                id: point.id,
                name: parsedAddress,
            };
            return {
                routePoint: {
                    ...obj,
                    selectId: createIdValueFromAddress(obj).value,
                },
            };
        });
    } else {
        return [];
    }
}

export function getRouteCreateData(routePointList) {
    const data = {
        name: `${get(first(routePointList), 'routePoint.name')} - ${get(last(routePointList), 'routePoint.name')}`,
        existingRoute: true,
        routePoints: map(routePointList, (item) => {
            const rp = item.routePoint;
            return {
                address: rp.address,
                latitude: rp.latitude,
                longitude: rp.longitude,
                addressBookId: rp.addressBookId,
            };
        }),
    };
    return data;
}

export function getRoutePointAnalyticsValuesOnEdit(routePointAnalytics) {
    return map(routePointAnalytics, (rpa) => {
        const parsedAddress = parseAddress(rpa.routePoint.address);
        return {
            ...rpa,
            routePoint: {
                ...rpa.routePoint,
                name: parsedAddress,
                selectId: createIdValueFromAddress(rpa.routePoint).value,
            },
        };
    });
}

export async function routePointSelectionOnSubmitPlugin(data, values, config) {
    const { routePointsName } = config;
    if (!values.isExistingRouteSelected) {
        let createdRouteId = '';
        let laneIdFound = '';
        let routePoints = [];

        try {
            const res = await this.props.createRoute(
                this.props.accesstoken,
                getRouteCreateData(values[routePointsName])
            );
            if (res.error) {
                throw new Error(handleError(res.payload.response));
            }
            createdRouteId = get(res, 'payload.data.id');
            laneIdFound = get(res, 'payload.data.parentId');
            routePoints = get(res, 'payload.data.routePoints');
        } catch (err) {
            console.log(err);
            throw new Error(handleError(err));
        }

        set(data, 'route.id', createdRouteId);

        set(
            data,
            routePointsName,
            map(values[routePointsName], (point, index) => {
                return {
                    ...point,
                    routePoint: {
                        ...pick(routePoints[index], ['id']),
                        ...point.routePoint,
                    },
                };
            })
        );

        if (laneIdFound) {
            set(data, 'laneId', laneIdFound);
        } else {
            if (createdRouteId && values.laneId) {
                try {
                    const res = await this.props.updateLanes(this.props.accesstoken, values.laneId, createdRouteId, {
                        existingLane: true,
                    });
                    if (res.error) {
                        throw new Error(handleError(res.payload.response));
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }
}

export function filteredJobAlarms(jobAlarms) {
    const removeAlarms = [];
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (accountId) {
        case FLEETX_ACCOUNTS.AMRIT_BOTTLERS:
        case FLEETX_ACCOUNTS.DIAMOND:
        case 11046:
            removeAlarms.push('parked');
            break;
        case FLEETX_ACCOUNTS.HUL:
            removeAlarms.push(...['Sharp Turns', 'parked']);
            break;
        default:
    }
    forEach(removeAlarms, (alarm) => {
        delete jobAlarms[alarm];
    });
    return jobAlarms;
}

export function computeDistance(route) {
    let computedDistance = { total: 0, pointsDistance: [] };
    if (route) {
        computedDistance.pointsDistance.push(0);
        let cumulativeDistance = 0;
        for (let i = 0; i < route.legs.length; i++) {
            cumulativeDistance += route.legs[i].distance.value / 1000;
            computedDistance.pointsDistance.push(cumulativeDistance);
            cumulativeDistance = 0;

            computedDistance.total += route.legs[i].distance.value;
        }
        computedDistance.total = computedDistance.total / 1000;
    }
    return computedDistance;
}

export const JOB_TRIP_START_DATE_FIELDS = {
    ACTUAL_ARRIVAL: 'actualArrival',
    ACTUAL_DEPARTURE: 'actualDeparture',
    JOB_START_DATE: 'startDate',
    JOB_CREATED_DATE: 'createdDate',
};

export const JOB_TRIP_START_DATE_FIELDS_LABEL = {
    actualArrival: 'First point actual arrival',
    actualDeparture: 'First point actual departure',
    startDate: 'Job start date',
    createdDate: 'Job creation date',
};

export const JOB_TRIP_END_DATE_FIELDS = {
    ACTUAL_ARRIVAL: 'actualArrival',
    ACTUAL_DEPARTURE: 'actualDeparture',
    JOB_END_DATE: 'endDate',
};

export const JOB_TRIP_END_DATE_FIELDS_LABEL = {
    actualArrival: 'Point actual arrival',
    actualDeparture: 'Point actual departure',
    endDate: 'Job End date',
};

export function getJobTripStartDate(job) {
    const startDate = get(job, 'startDate');
    const createdDate = get(job, 'createdDate');
    const jobAnalytics = get(job, 'jobAnalytics', []);
    const firstAnalytics = first(jobAnalytics);
    let startDateObj = getMomentTime(startDate);
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);

    if (firstAnalytics) {
        if (firstAnalytics.actualArrival) {
            startDateObj = getMomentTime(firstAnalytics.actualArrival);
        } else if (firstAnalytics.actualDeparture) {
            startDateObj = getMomentTime(firstAnalytics.actualDeparture);
        }
    }

    const jobStartDateKey = jobStartDateKeyForMergedTripsForAccount();

    switch (jobStartDateKey) {
        case JOB_TRIP_START_DATE_FIELDS.ACTUAL_ARRIVAL:
            if (firstAnalytics.actualArrival) {
                startDateObj = getMomentTime(firstAnalytics.actualArrival);
            }
            break;
        case JOB_TRIP_START_DATE_FIELDS.ACTUAL_DEPARTURE:
            if (firstAnalytics.actualDeparture) {
                startDateObj = getMomentTime(firstAnalytics.actualDeparture);
            } else {
                if (includes([11582], accountId) && firstAnalytics.scheduledDeparture) {
                    startDateObj = getMomentTime(firstAnalytics.scheduledDeparture);
                }
            }
            break;
        case JOB_TRIP_START_DATE_FIELDS.JOB_START_DATE:
            if (startDate) {
                startDateObj = getMomentTime(startDate);
            }
            break;
        case JOB_TRIP_START_DATE_FIELDS.JOB_CREATED_DATE:
            if (createdDate) {
                startDateObj = getMomentTime(createdDate);
            }
            break;
        default:
    }
    return startDateObj;
}

export function getJobTripEndDate(job, extendTimelineByMs = 0) {
    const endDate = get(job, 'endDate');
    let endDateObj = endDate ? getMomentTime(endDate) : getMomentTime();
    const jobAnalytics = get(job, 'jobAnalytics', []);
    const lastAnalytics = last(jobAnalytics);
    const jaLength = jobAnalytics.length;

    const jobEndDateKey = jobEndDateKeyForMergedTripsForAccount();

    if (jaLength > 1 && lastAnalytics && (!endDate || !useJobEndDateForMergedTripsForAccount())) {
        const secondLastAnalytics = jobAnalytics[jaLength - 2];
        if (secondLastAnalytics.vehicleState === 'UNLOADED') {
            if (lastAnalytics.actualArrival) {
                endDateObj = getMomentTime(lastAnalytics.actualArrival);
            }
        } else {
            if (lastAnalytics.actualDeparture) {
                endDateObj = getMomentTime(lastAnalytics.actualDeparture);
            } else if (job.status === 'COMPLETED' && lastAnalytics.actualArrival) {
                endDateObj = getMomentTime(lastAnalytics.actualArrival);
            }
        }
    }
    switch (jobEndDateKey) {
        case JOB_TRIP_END_DATE_FIELDS.ACTUAL_ARRIVAL:
            if (lastAnalytics.actualArrival) {
                endDateObj = getMomentTime(lastAnalytics.actualArrival);
            }
            break;
        case JOB_TRIP_END_DATE_FIELDS.ACTUAL_DEPARTURE:
            if (lastAnalytics.actualDeparture) {
                endDateObj = getMomentTime(lastAnalytics.actualDeparture);
            }
            break;
        case JOB_TRIP_END_DATE_FIELDS.JOB_END_DATE:
            if (endDate) {
                endDateObj = getMomentTime(endDate);
            }
            break;
        default:
    }

    if (isJobTimelineExtendAllowed() && extendTimelineByMs) {
        endDateObj = getMomentTime(endDateObj.valueOf() + extendTimelineByMs);
    }

    return endDateObj;
}

export function isAssetFallbackRequiredForMergeTripAPI({ anomalyReason, isSimDevice }) {
    if (anomalyReason === 'Distance updated from asset data' || isSimDevice) {
        return true;
    }

    return false;
}

export function jobSummaryFieldsConfig() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId');
    return {
        [VEHICLE_JOB_STATE.WAITING_IN_GARAGE]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        [VEHICLE_JOB_STATE.IN_GARAGE]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        [VEHICLE_JOB_STATE.UNLOADED]: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        rfidMismatch: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
        zigZagStop: {
            show: !includes(
                [
                    FLEETX_ACCOUNTS.SHREE_CEMENT_8000,
                    11532,
                    11494,
                    11468,
                    11524,
                    11578,
                    11604,
                    11680,
                    11638,
                    11639,
                    11601,
                    11423,
                ],
                accountId
            ),
        },
    };
}

export function isJobSummaryItemShouldShown(key, config) {
    return get(config, `${key}.show`, true);
}
