import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { getMomentTime } from '../../../../utils/date_utils';
import { get, map, round, toSafeInteger } from 'lodash';
import ConsignmentsListingComponent from './ConsignmentsListingComponent';
const FreightBillListingComponent = (props) => {
    const { entityList, formikProps, setEntityAdvances, isEdit, totalRowAmount, epodDetails, jobDetails, onOpenEpodModal, setCurrentCNViewEpod, renderOnAccountSettledAmountField, copyBalanceAmountToPaymentAmount, } = props;
    const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
        if (!isExpandableRow) {
            return null;
        }
        return isExpanded ? _jsx("i", { className: "fa fa-chevron-down" }) : _jsx("i", { className: "fa fa-chevron-right" });
    };
    const renderBillNumber = (cell, row) => {
        const billNumber = get(row, 'basicDetails.billNumber');
        const id = get(row, 'basicDetails.freightBillId', get(row, 'id'));
        return (_jsx("a", { target: "_blank", href: get(window.FLEETX_LOGGED_IN_DATA, 'isSwitchedUser') ||
                get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0) > 11000
                ? `/dashboard/bills/freightbillv2/edit/${id}`
                : `/dashboard/bills/freightbill/edit/${id}`, children: billNumber }));
    };
    const isRoundOffModeEnabled = get(entityList, '[0].isRoundOffModeEnabled', false);
    const renderFreightAmount = (cell, row) => {
        const freightAmount = isEdit ? get(row, 'basicDetails.freightAmount') : get(row, 'totalFreight');
        return _jsxs(_Fragment, { children: ["\u20B9 ", round(freightAmount, 2)] });
    };
    const renderBillingDate = (cell, row) => {
        const date = get(row, 'basicDetails.billingDate');
        return getMomentTime(date).format('DD/MM/YYYY');
    };
    const renderBillSettledAmount = (cell, row) => {
        return get(row, 'voucher.settledAmount');
    };
    const renderTotalFreightBillPayment = (cell, row) => {
        return get(totalRowAmount, get(row, 'id'));
    };
    const expandComponent = (row) => {
        let data = get(row, 'consignmentBillDetailsList', []);
        const freightBillId = isEdit ? get(row, 'basicDetails.freightBillId') : get(row, 'id');
        const preparedData = map(data, (el, index) => {
            const deductionAmount = get(el, 'consignmentEntityDetailsDto.receivedDeductionAmount.amount', 0);
            const entityObj = {};
            entityObj.freightBillId = freightBillId;
            entityObj.id = `${toSafeInteger(get(el, 'consignmentId'))}-${toSafeInteger(get(el, 'id'))}`;
            entityObj.consignmentId = get(el, 'consignmentId');
            entityObj.status = get(el, 'consignmentEntityDetailsDto.consignmentStatus');
            entityObj.basicDetails = {
                consignmentNumber: get(el, 'consignmentEntityDetailsDto.consignmentNumber'),
                consignmentDate: get(el, 'consignmentEntityDetailsDto.consignmentDate'),
                chargedRouteId: get(el, 'consignmentEntityDetailsDto.chargedRoute'),
                vehicle: {
                    licensePlate: get(el, 'consignmentEntityDetailsDto.licensePlate'),
                },
            };
            entityObj.advanceDetails = {
                advanceAmount: get(el, 'total'),
                receivedAdvanceAmount: get(el, 'receivedPaymentAmount', 0) + deductionAmount,
                consignmentAdvanceAmount: get(el, 'receivedAdvanceAmount.amount'),
                balanceAdvanceAmount: get(el, 'total', 0) - get(el, 'receivedPaymentAmount', 0) - deductionAmount,
                advanceAmountEntry: get(el, 'paymentAmount'),
                onAccountPaymentAmount: get(el, 'onAccountPaymentAmount'),
                totalMappingAmount: get(el, 'totalMappingAmount'),
            };
            entityObj.remarks = get(el, 'remark');
            return entityObj;
        });
        return (_jsx(ConsignmentsListingComponent, { tableOptions: props.tableOptions, selectRowProp: props.selectRowProp, entityList: preparedData, renderRemarksField: props.renderRemarksField, renderAdvanceQuantityField: props.renderAdvanceQuantityField, formikProps: props.formikProps, epodDetails: epodDetails, onOpenEpodModal: onOpenEpodModal, setCurrentCNViewEpod: setCurrentCNViewEpod, renderOnAccountSettledAmountField: renderOnAccountSettledAmountField, copyBalanceAmountToPaymentAmount: copyBalanceAmountToPaymentAmount }));
    };
    return (_jsx("div", { className: "fleetx-table-parent-container", children: _jsxs(BootstrapTable, { tableContainerClass: "card fleetx-table-container white-table-background", tableHeaderClass: "fleetx-table grey-table-background font-size-mid text-center", tableBodyClass: "fleetx-table white-table-background", trClassName: "tr-user-height table-row-style", data: entityList, version: "4", hover: true, expandableRow: () => true, expandComponent: expandComponent, expandColumnOptions: {
                expandColumnVisible: true,
                expandColumnComponent,
                columnWidth: 40,
            }, children: [_jsx(TableHeaderColumn, { width: "0", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, hidden: true, dataField: "id", isKey: true }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillNumber, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Number" }), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderFreightAmount, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Amount" }), isRoundOffModeEnabled && !isEdit && (_jsx(TableHeaderColumn, { width: "180", dataField: "totalFreight", thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Round Off Amount" })), _jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillingDate, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Billing Date" }), !isEdit && (_jsx(TableHeaderColumn, { width: "180", dataFormat: renderBillSettledAmount, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Bill Settled Amount" })), !isEdit && (_jsx(TableHeaderColumn, { width: "180", dataFormat: renderTotalFreightBillPayment, thStyle: { whiteSpace: 'normal' }, tdStyle: {
                        whiteSpace: 'normal',
                    }, children: "Payment" }))] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(FreightBillListingComponent);
