import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual, map } from 'lodash';
import PropTypes from 'prop-types';
import { FxReactSelect as Select } from '../../shared';

class FuelTypeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: map(props.fuelTypes, (value) => ({ value, label: value })),
            value: props.initialFuelType,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    render() {
        if (!this.props.fuelTypes || this.props.fuelTypes.length === 0) {
            return null;
        }

        if (this.props.fuelTypes.length === 1) {
            return (
                <span>
                    <h6 className="small ">({this.props.fuelTypes[0]})</h6>
                </span>
            );
        }
        return (
            <div className="d-inline-block">
                <Select
                    simpleValue
                    style={{ width: 120 }}
                    className={`select-fuel ${this.props.small ? 'small' : ''}`}
                    placeholder="Select Fuel Type"
                    value={this.state.value}
                    options={this.state.options}
                    clearable={false}
                    onChange={this.handleSelectChange}
                />
            </div>
        );
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    handleSelectChange(value) {
        this.setState({ value });
        this.props.onFuelTypeChange(value);
    }
}

FuelTypeSelector.propTypes = {
    fuelTypes: PropTypes.array,
    initialFuelType: PropTypes.string,
    onFuelTypeChange: PropTypes.func,
    small: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelTypeSelector);
