import axios from 'axios';
import { assign } from 'lodash';
import { parseQueryParams, ROLE_MAP, ROOT_API_ALFRED, ROOT_API_URL } from '../../../constant';
import { isViewTransportersAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { getDummyActionResponse } from 'utils/permissionUtils/utils';
export const FETCH_TRANSPORTER_BY_ID = 'fetch_transporter_by_id';
export const CREATE_TRANSPORTER = 'create_transporter';
export const FETCH_ALL_TRANSPORTERS = 'fetch_all_transporters';
export const UPDATE_TRANSPORTER = 'update_transporter';
export const DELETE_TRANSPORTER = 'delete_transporter';
export const FETCH_ODA_MATRIX = 'FETCH_ODA_MATRIX';
export const UPDATE_ODA_MATRIX = 'UPDATE_ODA_MATRIX';
export const SET_AWB_NUMBER = 'SET_AWB_NUMBER';
const CancelToken = axios.CancelToken;
export function fetchTransporterById(accesstoken, transporterId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}/transporters/${transporterId}`, config);
    return {
        type: FETCH_TRANSPORTER_BY_ID,
        promise: request,
    };
}
export function fetchAllTransporters(accesstoken) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchTransporters';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const isTransporter = window?.FLEETX_LOGGED_IN_DATA?.role === ROLE_MAP.ROLE_TRANSPORTER.name;
    if (!isViewTransportersAllowedForUser(scope) || isTransporter) {
        return getDummyActionResponse({ type: FETCH_ALL_TRANSPORTERS });
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL}transporters`, config);
    return {
        type: FETCH_ALL_TRANSPORTERS,
        promise: request,
    };
}
export function createTransporter(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const data = assign({}, values);
    const request = axios.post(`${ROOT_API_URL}transporters/`, data, config);
    return {
        type: CREATE_TRANSPORTER,
        promise: request,
    };
}
export function updateTransporter(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}transporters`, data, config);
    return {
        type: UPDATE_TRANSPORTER,
        promise: request,
    };
}
export function deleteTransporter(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_URL}transporters/${id}`, config);
    return {
        type: DELETE_TRANSPORTER,
        promise: request,
    };
}
export function updateODAMatrix(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}transporters/oda-matrix`, data, config);
    return {
        type: UPDATE_ODA_MATRIX,
        promise: request,
    };
}
export function fetchODAMatrix(accesstoken, transporterId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}/transporters/oda-matrix/${transporterId}`, config);
    return {
        type: FETCH_ODA_MATRIX,
        promise: request,
    };
}
export function transporterServiceabilitySearch(accesstoken, transporterIds, pinCodes, serviceabilityList, currentPage) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            transporterIds,
            pinCodes,
            serviceabilityList,
            size: 10,
            page: currentPage - 1,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}/transporters/serviceability/search`, config);
    return {
        type: FETCH_ODA_MATRIX,
        promise: request,
    };
}
export function setAWBSeries(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` }, params: parseQueryParams(data) };
    const request = axios.post(`${ROOT_API_ALFRED}/users/set-awb-range/`, {}, config);
    return {
        type: SET_AWB_NUMBER,
        promise: request,
    };
}
