import { jsx as _jsx } from "react/jsx-runtime";
import { Input as $Input } from './input';
import { Checkbox as $Checkbox, CheckboxGroup as $CheckboxGroup } from './checkbox';
import FormItem from './form-item';
import { DatePicker as $DatePicker } from './date-picker';
import { InputNumber as $InputNumber } from './input-number';
import { Radio as $Radio } from './radio';
import { Select as $Select } from './select';
import { Switch as $Switch } from './switch';
export function Input({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Input, { id: rest.name, ...rest }) }));
}
export function InputNumber({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($InputNumber, { id: rest.name, ...rest }) }));
}
Input.Number = InputNumber;
export function TextArea({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Input.TextArea, { id: rest.name, ...rest }) }));
}
Input.TextArea = TextArea;
export function Password({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Input.Password, { id: rest.name, ...rest }) }));
}
Input.Password = Password;
export function Checkbox({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Checkbox, { id: rest.name, ...rest }) }));
}
export function CheckboxGroup({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($CheckboxGroup, { ...rest }) }));
}
export function DatePicker({ label, required, colSpan, formikFormat, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($DatePicker, { id: rest.name, formikFormat: formikFormat, ...rest }) }));
}
export function RangePicker({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($DatePicker.RangePicker, { id: rest.name, ...rest }) }));
}
export function Radio({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: {
            span: 24,
            ...labelCol,
        }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Radio, { id: rest.name, ...rest }) }));
}
export function RadioGroup({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: {
            span: 24,
            ...labelCol,
        }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Radio.Group, { id: rest.name, ...rest }) }));
}
export function Select({ formItem, label, required, colSpan, allowClear, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Select, { id: rest.name, allowClear: allowClear || true, ...rest }) }));
}
export function Switch({ label, required, colSpan, formItem, ...rest }) {
    const { labelCol, ...restFormItem } = formItem || {};
    return (_jsx(FormItem, { label: label, required: required, labelCol: { span: 24, ...labelCol }, colSpan: colSpan, ...restFormItem, name: rest.name, htmlFor: rest.name, id: rest.name, children: _jsx($Switch, { ...rest }) }));
}
export const FORMIK_FORMATS = {
    ISO: 'ISO',
    SPACE: 'SPACE',
};
export { default as SubmitButton } from './submit-button';
