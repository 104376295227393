import {
    forEach,
    join,
    find,
    pick,
    first,
    last,
    floor,
    get,
    isEmpty,
    includes,
    round,
    filter,
    toSafeInteger,
    split,
    isArray,
    map,
    set,
    concat,
    reduce,
    findIndex,
} from 'lodash';
import { getEllipsisedText, safeArrayStringSplit } from './string_utils';
import { getMomentTime, getFormattedTimeStringForAPI, HOURS_24, HOURS_1 } from './date_utils';
import moment from 'moment-timezone';
import { JOB_STATUS_VALUES } from './job_route_utils';
import {
    FLEETX_ACCOUNTS,
    getIndentOrderQuantityLabelName,
    getIndentPONumberLabelName,
    getJobBookingCommodityLabelName,
    getJobBookingContainerQuantityLabelName,
    getInvoiceDatelabelName,
    getLoadingDateLabelName,
    isCompletedJob100PercentInGraph,
    getJobBookingNumberLabelName,
    getAutoChallanType,
    isDtdcAccount,
    getFlagValueFromAccountConfig,
    showMovementTypeAndTripNatureInJob,
    isShreeCementsAccounts,
    getChecklistOptions,
    getAccountId,
} from './account_utils';
import { UncontrolledTooltip } from 'reactstrap';
import {
    COLOR_GRAPH_GREEN,
    COLOR_GRAPH_RED,
    COLOR_GRAPH_YELLOW,
    COLOR_GREEN,
    COLOR_PRIMARY,
    COLOR_RED,
    COLOR_WHITE,
    COLOR_YELLOW,
} from '../theme/colors';
import { FxBadge, FxTag, FxToolTip } from 'sharedV2/index';
import { showInstantFuelInJobFlag, showJobDowntime } from 'utils/accountUtils/jobs';
import { isWithinScope } from 'utils/permissionUtils/utils';
import { getScopesArr } from 'components/dashboard/roles/permission_utils';
import { sortListAlphabetically } from './list_utils';

export function getColorSchemaClassForRiskLevel(riskLevel) {
    if (!riskLevel) {
        return '';
    }
    switch (riskLevel) {
        case 'N':
            return 'badge badge-pill badge-success';
        case 'C':
            return 'badge badge-pill badge-warning';
        case 'E':
            return 'badge badge-pill badge-danger';
    }
}

export const JOBS_TAB = {
    JOBS: 'Jobs',
    EPOD: 'EPOD',
    JOB_ANALYTICS: 'JOB_ANALYTICS',
    JOB_REPORT_LIST: 'JOB_REPORT_LIST',
    JOB_CUSTOM_FIELD: 'JOB_CUSTOM_FIELD',
};

export const JOB_BOOKING_REJECTION_REASONS = [
    {
        value: 'Pricing Issue',
        label: 'Pricing Issue',
    },
    {
        value: 'Loads',
        label: 'Loads',
    },
    {
        value: 'Driver not available',
        label: 'Driver not available',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

export const BILL_NUMBER_TYPES = {
    LR: 'LR',
    INVOICE: 'INVOICE',
    EWAY: 'EWAY',
    DOCKET: 'DOCKET',
};
export const BILL_NUMBER_TYPES_TO_KEY = {
    LR: 'lrnumber',
    INVOICE: 'invoiceNumber',
    EWAY: 'ewayBillNumber',
    DOCKET: 'docketNumber',
};

export function convertBillNumbersIntoTypedArray(
    {
        docketNumber: DOCKET,
        lrnumber: LR,
        invoiceNumber: INVOICE,
        ewayBillNumber: EWAY,
        billNumbers: oldBillNumbers,
        ewayBillExpiryDate: ewayBillExpiryDate,
        ewayBillExpiry: ewayBillExpiry,
    },
    editable
) {
    const billNumbers = [];
    forEach({ DOCKET, LR, INVOICE, EWAY }, (value, key) => {
        const values = safeArrayStringSplit(value);
        forEach(values, (bl) => {
            const oldBl = find(oldBillNumbers, { type: key, value: bl });
            if (key === 'EWAY') {
                let expiryDate = editable ? ewayBillExpiryDate : ewayBillExpiry;
                if (expiryDate) {
                    expiryDate = getFormattedTimeStringForAPI(expiryDate);
                }
                billNumbers.push({
                    ...pick(oldBl, ['id']),
                    type: key,
                    value: bl,
                    expiryDate,
                });
            } else {
                billNumbers.push({
                    ...pick(oldBl, ['id']),
                    type: key,
                    value: bl,
                });
            }
        });
    });
    return billNumbers;
}

export function convertTypedArrayIntoBillNumbers(billNumbers, point) {
    let ewayBillExpiryDate = null;
    let ewayBillExpiry = null;
    const billNumbersAsObject = {};
    forEach(billNumbers, ({ type, value }) => {
        if (!billNumbersAsObject[type]) {
            billNumbersAsObject[type] = [];
        }
        if (type === 'EWAY') {
            ewayBillExpiryDate = point?.ewayBillExpiryDate;
            ewayBillExpiry = point?.ewayBillExpiry;
        }
        billNumbersAsObject[type].push(value);
    });
    const finalObj = {};
    forEach(billNumbersAsObject, (v, type) => {
        finalObj[BILL_NUMBER_TYPES_TO_KEY[type]] = join(v, ',');
    });

    if (ewayBillExpiryDate) {
        finalObj.ewayBillExpiryDate = ewayBillExpiryDate;
    }
    if (ewayBillExpiry) {
        finalObj.ewayBillExpiry = ewayBillExpiry;
    }
    if (!finalObj[BILL_NUMBER_TYPES_TO_KEY.INVOICE] && get(point, BILL_NUMBER_TYPES_TO_KEY.INVOICE)) {
        finalObj[BILL_NUMBER_TYPES_TO_KEY.INVOICE] = get(point, BILL_NUMBER_TYPES_TO_KEY.INVOICE);
    }
    return finalObj;
}

export const JOB_VEHICLE_ATTACHED_TAG_TYPES = [
    {
        value: 'RF',
        label: 'RF',
    },
    {
        value: 'BARCODE',
        label: 'BARCODE',
    },
];

export function getLoadingDuration(jobAnalytics) {
    const { actualArrival, actualDeparture } = first(jobAnalytics) || {};
    if (actualArrival && actualDeparture) {
        return getMomentTime(actualDeparture).diff(actualArrival, 'ms');
    }

    return 0;
}

export function getUnloadingDuration(jobAnalytics) {
    const { actualArrival, actualDeparture } = last(jobAnalytics) || {};
    if (actualArrival && actualDeparture) {
        return getMomentTime(actualDeparture).diff(actualArrival, 'ms');
    }

    return 0;
}

export function getActualTATDuration(jobAnalytics, status) {
    const { actualDeparture } = first(jobAnalytics) || {};
    const { actualArrival } = last(jobAnalytics) || {};
    if (actualArrival && actualDeparture) {
        return getMomentTime(actualArrival).diff(actualDeparture, 'ms');
    }
    if (status === JOB_STATUS_VALUES.STARTED && actualDeparture) {
        return getMomentTime().diff(actualDeparture, 'ms');
    }

    return 0;
}

export function getLoadingUnloadingColorClass(duration) {
    if (duration && floor(moment.duration(duration).asHours()) > 3) {
        return 'text-danger';
    } else {
        return '';
    }
}

export function getTATColorClass(actualTATDuration, userEnteredTransitTime) {
    return actualTATDuration && userEnteredTransitTime && actualTATDuration > userEnteredTransitTime
        ? 'text-danger'
        : '';
}

export const isRouteAvailableForJob = (job) => {
    return !isEmpty(get(job, 'route'));
};

export function getColorClassForJobAnalyticsPoint(analytics = {}, ind, config = {}) {
    const { colors } = config || {};
    const { colorGreen = COLOR_GREEN, colorRed = COLOR_RED, colorWhite = COLOR_WHITE, colorPrimary = COLOR_PRIMARY } =
        colors || {};
    const { status, id, actualArrival, actualDeparture, scheduledDeparture } = analytics || {};
    let textColor = '';
    let fillColor = '';
    let delay = 0;
    switch (status) {
        case 'EARLY':
        case 'COMPLETED':
        case 'ONTIME':
            textColor = colorWhite;
            fillColor = colorGreen;
            break;
        case 'LATE':
            textColor = colorWhite;
            fillColor = colorRed;
            break;
        default:
            textColor = '#536c79';
            fillColor = '#cecece';
            break;
    }

    //source point /start point
    if (ind === 0) {
        fillColor = colorPrimary;
    }

    if (analytics?.delay && includes(['EARLY', 'COMPLETED', 'LATE'], status)) {
        if (ind > 0 || (ind == 0 && scheduledDeparture)) {
            delay = analytics?.delay;
        }
    }
    if (delay !== 0) {
        if (delay > 0) {
            textColor = colorWhite;
            fillColor = colorRed;
        } else {
            textColor = colorWhite;
            fillColor = colorGreen;
        }
    }

    return {
        textColor,
        fillColor,
    };
}

export function getColorClassForJobEPODStatusPoint(analytics, ind) {
    const green = COLOR_GREEN;
    const yellow = COLOR_YELLOW;
    const { epodStatus } = analytics;
    let textColor = '';
    let fillColor = '';
    let delay = 0;
    switch (epodStatus) {
        case 'ACCEPTED':
            textColor = COLOR_WHITE;
            fillColor = green;
            break;
        case 'OPEN':
            textColor = COLOR_WHITE;
            fillColor = yellow;
            break;
        case 'REJECTED':
            textColor = COLOR_WHITE;
            fillColor = COLOR_RED;
            break;
        default:
            textColor = '#536c79';
            fillColor = '#cecece';
            break;
    }

    //source point /start point
    if (ind === 0) {
        fillColor = COLOR_PRIMARY;
    }

    return {
        textColor,
        fillColor,
    };
}

export function isTransporterMandatoryInJobForm() {
    return includes([2857], window.FLEETX_LOGGED_IN_USER?.accountId);
}

export const JOB_WEIGHT_VOLUME_LABELS = {
    weight: 'Initial Mass Flow Meter/Weight',
    unloadedWeight: 'Final Mass Flow Meter/Unloaded Weight',
    volume: 'Initial Pressure/Volume',
    unloadedVolume: 'Final Pressure/Volume',
};

export function getJobFormFieldConfigForAccount() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        jobBooking: {
            field: 'jobBooking.id',
            show: !includes([FLEETX_ACCOUNTS.IRC], accountId),
        },
        user: {
            field: 'user',
            show: includes([FLEETX_ACCOUNTS.BVC], accountId),
        },
        customer: {
            field: 'customer.id',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JSW,
                    FLEETX_ACCOUNTS.JSW_1,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        transporter: {
            field: 'transporter.id',
            show: !includes(
                [FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE, FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH],
                accountId
            ),
            mandatory: isTransporterMandatoryInJobForm(),
        },
        showBillNumberComponent: {
            field: 'showBillNumberComponent',
            show: !includes([FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE], accountId),
        },
        mappedType: {
            field: 'mappedType',
            // Only show mappedType as a field when Truck Load type is FTL and PTL both
            show: getAutoChallanType() === 'FTL_PTL',
        },
        tripNature: {
            field: 'tripNature',
            // Only show tripNature as a field when Truck Load type is FTL and PTL both
            show:
                getAutoChallanType() === 'FTL' ||
                getAutoChallanType() === 'PTL' ||
                getAutoChallanType() === 'FTL_PTL' ||
                showMovementTypeAndTripNatureInJob(),
        },
        movementType: {
            field: 'movementType',
            // Only show movementType as a field when Truck Load type is FTL and PTL both
            show:
                getAutoChallanType() === 'FTL' ||
                getAutoChallanType() === 'PTL' ||
                getAutoChallanType() === 'FTL_PTL' ||
                showMovementTypeAndTripNatureInJob(),
        },
        customFields: {
            field: 'customFields',
            show: !includes([FLEETX_ACCOUNTS.IRC], accountId),
        },
        jobUsers: {
            field: 'jobUsers',
            show: !includes([FLEETX_ACCOUNTS.IRC, FLEETX_ACCOUNTS.GROFERS], accountId),
        },
        notes: {
            field: 'notes',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JSW,
                    FLEETX_ACCOUNTS.JSW_1,
                    FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                    FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        openingOdo: {
            field: 'openingOdo',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.START_CEMENT,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        closingOdo: {
            field: 'closingOdo',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.START_CEMENT,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JSW,
                    FLEETX_ACCOUNTS.JSW_1,
                    FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        commodities: {
            field: 'commodities',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                    FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        isSelectExistingRoute: {
            field: 'isSelectExistingRoute',
            show: !includes([FLEETX_ACCOUNTS.IRC, FLEETX_ACCOUNTS.MAHALAXMI, FLEETX_ACCOUNTS.JSW_1], accountId),
        },
        routes: {
            field: 'routes',
            show: true,
            customer: {
                field: 'customer.id',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            project: {
                field: 'projectId',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            ewayBillExpiryDate: {
                field: 'ewayBillExpiryDate',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            ewayBillExpiry: {
                field: 'ewayBillExpiry',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            docketNumber: {
                field: 'docketNumber',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.SOTC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            lrnumber: {
                field: 'lrnumber',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            invoiceNumber: {
                field: 'invoiceNumber',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            ewayBillNumber: {
                field: 'ewayBillNumber',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            freightRate: {
                field: 'freightRate',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            freightFare: {
                field: 'freightFare',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.CANOPUS_NATURAL_GAS,
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            vehicleState: {
                field: 'vehicleState',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            volume: {
                field: 'volume',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.ALGOR,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.SOTC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.KAPOOR_DIESELS,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            packageCount: {
                field: 'packageCount',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.SOTC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.KAPOOR_DIESELS,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            weight: {
                field: 'weight',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.ALGOR,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.SOTC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.KAPOOR_DIESELS,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            unloadedWeight: {
                field: 'unloadedWeight',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.START_CEMENT,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.ALGOR,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.SOTC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.KAPOOR_DIESELS,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            consignmentDetails: {
                field: 'consignmentDetails',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            customJobDetails: {
                field: 'customJobDetails',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            uploadDocuments: {
                field: 'uploadDocuments',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.SHREE_MANOHAR_TRANSPORT,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            notes: {
                field: 'notes',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BLR_LOGISTICS,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            sendDeliveryConfirmAlert: {
                field: 'sendDeliveryConfirmAlert',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.MAHALAXMI,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.JSW,
                        FLEETX_ACCOUNTS.JSW_1,
                    ],
                    accountId
                ),
            },
            addressBookAlert: {
                field: 'addressBookAlert',
                show: !includes([FLEETX_ACCOUNTS.IRC, FLEETX_ACCOUNTS.BVC, FLEETX_ACCOUNTS.GROFERS], accountId),
            },
            scheduleArrival: {
                field: 'scheduleArrival',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            scheduleDeparture: {
                field: 'scheduleDeparture',
                show: !includes([FLEETX_ACCOUNTS.IRC, FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH], accountId),
            },
            transitTime: {
                field: 'transitTime',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.IRC,
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            plannedTransitTime: {
                field: 'plannedTransitTime',
                show: !includes(
                    [
                        FLEETX_ACCOUNTS.BVC,
                        FLEETX_ACCOUNTS.GROFERS,
                        FLEETX_ACCOUNTS.NORTHERN_CARGO_SERVICE,
                        FLEETX_ACCOUNTS.VSE,
                        FLEETX_ACCOUNTS.VSH,
                    ],
                    accountId
                ),
            },
            consignments: {
                field: 'consignments',
                show: !includes([FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH], accountId),
            },
        },
        uploadDocuments: {
            field: 'uploadDocuments',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JSW,
                    FLEETX_ACCOUNTS.JSW_1,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        expenseDetails: {
            field: 'expenseDetails',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.MAHALAXMI,
                    FLEETX_ACCOUNTS.BLR_LOGISTICS,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JSW,
                    FLEETX_ACCOUNTS.JSW_1,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        jobFrequency: {
            field: 'jobFrequency',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.JYOTI_INDUSTRIES,
                    FLEETX_ACCOUNTS.VSE,
                ],
                accountId
            ),
        },
        addressBookAlert: {
            field: 'addressBookAlert',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        saveRouteForFutureUse: {
            field: 'save',
            show: !includes(
                [
                    FLEETX_ACCOUNTS.IRC,
                    FLEETX_ACCOUNTS.BVC,
                    FLEETX_ACCOUNTS.GROFERS,
                    FLEETX_ACCOUNTS.VSE,
                    FLEETX_ACCOUNTS.VSH,
                ],
                accountId
            ),
        },
        container: {
            show: !includes([FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH], accountId),
        },
        originStartText: {
            show: !includes([FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH], accountId),
        },
        orderNumber: {
            field: 'orderNumber',
            show: !includes([FLEETX_ACCOUNTS.VSE, FLEETX_ACCOUNTS.VSH], accountId),
        },
        scheduleDeparture: {
            field: 'scheduleDeparture',
            show: !includes([FLEETX_ACCOUNTS.IRC], accountId),
        },
    };
}

export function getJobBookingFieldConfigForAccount() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        vendorId: {
            field: 'vendorId',
            show: !includes(
                [FLEETX_ACCOUNTS.MAERSK, FLEETX_ACCOUNTS.MAERSK_SRI_LANKA, FLEETX_ACCOUNTS.MAERSK_BANGLADESH],
                accountId
            ),
        },
        commodity: {
            field: 'commodity',
            label: getJobBookingCommodityLabelName(),
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        containerQuantity: {
            field: 'containerQuantity',
            label: getJobBookingContainerQuantityLabelName(),
            show: true,
        },
        vehicleQuantity: {
            field: 'vehicleQuantity',
            label: 'No. of Vehicles',
            show: !includes(
                [FLEETX_ACCOUNTS.MAERSK, FLEETX_ACCOUNTS.MAERSK_SRI_LANKA, FLEETX_ACCOUNTS.MAERSK_BANGLADESH],
                accountId
            ),
        },
        bookingNumber: {
            field: 'bookingNumber',
            label: getJobBookingNumberLabelName(),
            show: true,
        },
        salesPerson: {
            field: 'salesPerson',
            show: !includes(
                [FLEETX_ACCOUNTS.MAERSK, FLEETX_ACCOUNTS.MAERSK_SRI_LANKA, FLEETX_ACCOUNTS.MAERSK_BANGLADESH],
                accountId
            ),
        },
        region: {
            field: 'region',
            show: includes(
                [
                    FLEETX_ACCOUNTS.MAERSK,
                    FLEETX_ACCOUNTS.MAERSK_SRI_LANKA,
                    FLEETX_ACCOUNTS.MAERSK_BANGLADESH,
                    FLEETX_ACCOUNTS.FLEETX,
                ],
                accountId
            ),
        },
        category: {
            field: 'category',
            show: includes(
                [
                    FLEETX_ACCOUNTS.MAERSK,
                    FLEETX_ACCOUNTS.MAERSK_SRI_LANKA,
                    FLEETX_ACCOUNTS.MAERSK_BANGLADESH,
                    FLEETX_ACCOUNTS.FLEETX,
                ],
                accountId
            ),
        },
        movementType: {
            field: 'movementType',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        jobBookingVehicleSizes: {
            field: 'jobBookingVehicleSizes',
            show: true,
            item: {
                containerQuantity: {
                    show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
                },
            },
        },
        jobBookingImages: {
            field: 'jobBookingImages',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        uploadInternalDocuments: {
            field: 'uploadInternalDocuments',
            show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
        routePoint: {
            customer: {
                field: 'customer.id',
                show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
            },
            commodities: {
                field: 'commodities',
                show: !includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
            },
        },
        bookingCreatedBy: {
            field: 'bookingCreatedBy',
            show: true,
            mandatory: includes([FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE], accountId),
        },
    };
}

export function getIndentFieldConfigForAccount(accountId, isPtlIndent) {
    return {
        orderQuantity: {
            field: 'orderQuantity',
            label: getIndentOrderQuantityLabelName(accountId),
            show: true,
        },
        soNumber: {
            field: 'soNumber',
            label: getIndentPONumberLabelName(accountId, isPtlIndent),
            show: true,
        },
        invoiceDate: {
            field: 'invoiceDate',
            label: getInvoiceDatelabelName(accountId),
            show: true,
        },
        loadingDate: {
            field: 'loadingDate',
            label: getLoadingDateLabelName(accountId),
            show: true,
        },
    };
}

export function getJobFormFieldShowValue(field, config) {
    return get(config, `${field}.show`, true);
}

export function getConfigFieldShowValue(field, config) {
    return get(config, `${field}.show`, true);
}

export function getJobBookingFieldShowValue(field, config) {
    return get(config, `${field}.show`, true);
}

export function getJobBookingFieldConfig(field, config) {
    return get(config, `${field}`, {});
}

export function getFormFieldConfig(field, config) {
    return get(config, `${field}`, {});
}

export const CHECKLIST_TABS = {
    PORT: {
        name: 'Port',
        order: 1,
        key: 'PORT',
    },
    PLANT: {
        name: 'Plant',
        order: 2,
        key: 'PLANT',
    },
    PLANT_SECURITY: {
        name: 'Security',
        order: 3,
        key: 'PLANT_SECURITY',
    },
};

export function getJobDestinationPointWeightagePercentage(job, analyticPoint) {
    if (!job || !analyticPoint) {
        return 0;
    }
    const scheduledPointDistance = get(analyticPoint, 'routePoint.distance', 0);
    const scheduledTotalDistance = get(job, 'route.distance', 0);
    if (scheduledPointDistance && scheduledTotalDistance) {
        return (scheduledPointDistance / scheduledTotalDistance) * 100;
    }
    return 0;
}

export function getJobCompletionPercentage(job) {
    if (isEmpty(job)) {
        return 0;
    }
    const { percentageOfRouteCompletedFromPreviousPoint = 0, jobAnalytics, status: jobStatus } = job;

    const jAlength = jobAnalytics.length;
    const noOfDestinations = jAlength - 1;

    if (noOfDestinations === 0) {
        return 0;
    }

    // const percentageWeightForEachJA = 100 / noOfDestinations;
    let percentageCompleted = 0;
    let found = false;

    forEach(jobAnalytics, (analytic, ind) => {
        const { actualArrival } = analytic;
        // source point /start point
        if (ind !== 0) {
            const percentageWeightForEachJA = getJobDestinationPointWeightagePercentage(job, analytic);
            if (actualArrival) {
                percentageCompleted += percentageWeightForEachJA;
            } else if (percentageOfRouteCompletedFromPreviousPoint && !found) {
                found = true;
                percentageCompleted += percentageWeightForEachJA * percentageOfRouteCompletedFromPreviousPoint * 0.01;
            }
        }
    });

    // special case
    if (jobStatus === 'COMPLETED' && isCompletedJob100PercentInGraph()) {
        percentageCompleted = 100;
    }
    percentageCompleted = percentageCompleted > 100 ? 100 : round(percentageCompleted);

    return percentageCompleted;
}

export function getContainerNumbers(job) {
    let cNumbers = [];
    forEach(job.jobAnalytics, (ja) => {
        const customContainerNumbersFound = filter(ja.customFields, (cf) =>
            `${get(cf, 'field.keyName', '')}`.match('Container Number')
        );
        cNumbers = [...cNumbers, ...customContainerNumbersFound];
    });

    return cNumbers;
}

export function getJobDistanceRoundedValue(distance) {
    if (!distance) {
        return distance;
    }
    const showDecimal = includes(
        [11190, FLEETX_ACCOUNTS.CHANDERIA_MINES, FLEETX_ACCOUNTS.RMC],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
    return showDecimal ? round(distance, 2) : round(distance);
}

export function showRouteDeviationCountInJobReportByDefault() {
    return includes(
        [3037, 2048, 2049, 2050, 2051, 3047, 7637, 4264, 2217, 1957, 1971, 6573, 11082, 5380, 2026, 2027],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function showRFVerifiedTimeByDefaultInJobReport() {
    return includes([FLEETX_ACCOUNTS.GRASIM_INBOUND], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export const CHECKLIST_OPTIONS = [
    {
        label: 'Epod',
        value: 'EPOD',
    },
    {
        label: 'Port',
        value: 'PORT',
    },
    {
        label: 'Plant',
        value: 'PLANT',
    },
    {
        label: 'Plant Security',
        value: 'PLANT_SECURITY',
    },
    {
        label: 'Gate In',
        value: 'GATE_IN',
    },
    {
        label: 'Gate Out',
        value: 'GATE_OUT',
    },
    {
        label: 'Loading',
        value: 'LOADING',
    },
    {
        label: 'Unloading',
        value: 'UNLOADING',
    },
    {
        label: 'Driver Inspection',
        value: 'DRIVER_INSPECTION',
    },
    {
        label: 'Vehicle Inspection',
        value: 'VEHICLE_INSPECTION',
    },
    {
        label: 'Inspection',
        value: 'INSPECTION',
    },
    {
        label: 'TYRE INSPECTION',
        value: 'TYRE_INSPECTION',
    },
    {
        label: 'Hazardous',
        value: 'HAZARDOUS',
    },
    {
        label: 'Non Hazardous',
        value: 'NON_HAZARDOUS',
    },
    {
        label: 'Checklist Stages',
        value: 'CHECKLIST_STAGES',
    },
];

export const showDistance = (currentKm, job, t, loggedInUser) => {
    const OdometerDiff = toSafeInteger(job.closingOdo || 0 - job.openingOdo || 0);
    const scheduledDistance = get(job, 'scheduledDistance');
    const indicativeKmPresent = get(job, 'indicativeKmPresent', false);

    const lastJobAnalyticPoint = job.jobAnalytics[job.jobAnalytics.length - 1];

    let percentDownTime;
    if (job.status === 'COMPLETED' && job.jobAnalytics.length > 1) {
        const firstPointDepartTime = job.jobAnalytics[0].actualDeparture;
        const lastPointArrivalTime = lastJobAnalyticPoint.actualArrival;
        const diff = lastPointArrivalTime - firstPointDepartTime;
        if (job.percentDownTime > 0 && job.percentDownTime < diff) {
            percentDownTime = (job.percentDownTime / diff) * 100;
        }
    }
    let distance = scheduledDistance ? scheduledDistance : get(job, 'route.distance', 0);
    if (!distance && get(lastJobAnalyticPoint, 'routePoint.distance')) {
        distance = get(lastJobAnalyticPoint, 'routePoint.distance', 0);
    }

    const currentKM = get(job, 'currentKm', 0);
    let distanceText = '';
    if (distance && currentKM) {
        if (distance - currentKM > 5) {
            distanceText = `-${round(distance - currentKM, 2)} km`;
        } else if (currentKM - distance > 5) {
            distanceText = `+${round(currentKM - distance, 2)} km`;
        }
    }
    let fuelToShow = job.currentFuel;
    if (showInstantFuelInJobFlag()) {
        const findInstantFuelEntry = find(job.jobMetrics, {
            type: 'INSTANT_FUEL_CONSUMED',
        });
        const textVal = get(findInstantFuelEntry, 'textVal');
        if (textVal) {
            fuelToShow = parseFloat(textVal);
        } else {
            fuelToShow = 0;
        }
    }
    return (
        <div>
            <div className="medium">
                {round(currentKm, 2)} km{' '}
                {indicativeKmPresent ? (
                    get(loggedInUser, 'accountId') === FLEETX_ACCOUNTS.RMC ? (
                        ' (Indicative KM)'
                    ) : (
                        <>
                            <span className="text-bold" id={`indicative-${job.id}`} key={`indicative-${job.id}`}>
                                {'('}
                                <i className="fa fa-info"></i>
                                {')'}
                            </span>
                            <UncontrolledTooltip placement="top" target={`indicative-${job.id}`} delay={100}>
                                {`Indicative KM`}
                                <br />
                            </UncontrolledTooltip>
                        </>
                    )
                ) : (
                    ''
                )}{' '}
                {distance ? `/ ${round(distance, 2)} km` : ''}{' '}
            </div>
            {isDtdcAccount(loggedInUser) && <div className="medium">{OdometerDiff} km(DTDC)</div>}
            {fuelToShow > 0 && <div className="text-muted medium">{round(fuelToShow, 2)} L</div>}

            {job.missingKm > 0 && (
                <div className="medium">
                    <span className="text-muted">
                        {t('missing')} {t('common.km').toUpperCase()}:{' '}
                    </span>
                    <span>{round(job.missingKm, 2)}</span>
                </div>
            )}
            {distanceText && job.status === 'COMPLETED' && (
                <div className="medium">
                    <span className="text-info">({distanceText})</span>
                </div>
            )}
            {showJobDowntime() && percentDownTime > 0 && (
                <div className="medium">
                    <span className="text-muted">% {t('downtime')}: </span>
                    <span>{round(percentDownTime, 2)}</span>
                </div>
            )}
        </div>
    );
};
export const showFrom = (jobAnalytics) => {
    return <div className="medium">{getEllipsisedText(get(jobAnalytics, '[0].routePoint.address'), 30)}</div>;
};
export const renderCol = (cell, row, formatExtraData) => {
    const dataField = formatExtraData.dataField;
    const value = get(row, formatExtraData.dataField, '');
    switch (dataField) {
        case 'vehicle.licensePlate':
            return (
                <div className="medium">
                    <div>{get(row, 'vehicle.name')}</div>
                    <div>{get(row, 'vehicle.licensePlate')}</div>
                </div>
            );

        default:
            return value;
    }
};

export const showTo = (jobAnalytics) => {
    return (
        <FxToolTip title={get(last(jobAnalytics), 'routePoint.address')}>
            <div className="medium">{getEllipsisedText(get(last(jobAnalytics), 'routePoint.address'), 30)}</div>
        </FxToolTip>
    );
};

const renderBillNumber = (billNo) => <div>{`${billNo.type} : ${billNo.value}\n`}</div>;
export const billNumberClubbed = (row = {}) => {
    let obj = {};
    let _billNumbers = [];
    let jobAnalyticsVehicleWiseBillNumbers = [];
    forEach(get(row, 'jobAnalytics', []), (jobAnalytic) => {
        jobAnalyticsVehicleWiseBillNumbers = concat(jobAnalyticsVehicleWiseBillNumbers, jobAnalytic.billNumbers);
    });
    forEach(jobAnalyticsVehicleWiseBillNumbers, (item) => {
        if (!obj[item.type + item.value]) {
            obj[item.type + item.value] = true;
            _billNumbers.push(item);
        }
    });
    _billNumbers = sortListAlphabetically(_billNumbers, 'label', 'type');
    const restBillNumbers = reduce(
        _billNumbers.slice(2),
        (str, billNo) => `${str}${billNo.type} : ${billNo.value}\n`,
        ''
    );
    const allBillNumberTooltip = reduce(_billNumbers, (str, billNo) => `${str}${billNo.type} : ${billNo.value}\n`, '');
    return (
        <div className="flex-text-wrapper" title={allBillNumberTooltip}>
            {_billNumbers[0] && renderBillNumber(_billNumbers[0])}
            {_billNumbers[1] && renderBillNumber(_billNumbers[1])}
            <div className="text-truncate" style={{ width: 300 }}>
                {' '}
                {restBillNumbers}
            </div>
        </div>
    );
};

export function getRangeInMSFromJobDelayFilterValue(value) {
    if (!value) {
        return [];
    }
    const [minDelay, maxDelay] = split(value, '-');
    const minDelayVal = moment.duration(toSafeInteger(minDelay), 'hours').asMilliseconds();
    const maxDelayVal = moment.duration(toSafeInteger(maxDelay), 'hours').asMilliseconds();
    return [minDelayVal, maxDelayVal];
}

export function isJobTimelineExtendAllowed() {
    return getFlagValueFromAccountConfig('isJobTimelineExtendEnabled');
}

export function getExportTimelineOptions() {
    return [
        {
            label: `6 ${window.t('hours')}`,
            value: HOURS_1 * 6, // ms
        },
        {
            label: `1 ${window.t('days')}`,
            value: HOURS_24 * 1, // ms
        },
        {
            label: `3 ${window.t('days')}`,
            value: HOURS_24 * 3, // ms
        },
        {
            label: `7 ${window.t('days')}`,
            value: HOURS_24 * 7, // ms
        },
    ];
}

export function getJobPageRefreshTimeInSeconds() {
    return 30;
}

export function isJobAutoRefreshEnabled() {
    return includes([4895, 5248, 5380], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0));
}

export function getJobLink(id) {
    return `https://app.fleetx.io/dashboard/routes/jobs/${id}`;
}

export function getPlantIdsOptionFiltered(tags) {
    const options = [];
    forEach(tags, (t) => {
        if (get(t, 'type') == 'PLANT') {
            options.push({ value: t.id, label: t.name });
        }
    });
    return options;
}

export function getSortOptionsStopsAndIdles() {
    return [
        { value: 'timestamp', label: `${window.t('timestamp')}` },
        { value: 'duration', label: `${window.t('duration')}` },
    ];
}

export function getJobAnalyticJobRequestValue(jobRequestValue, jobAnalytic) {
    if (!jobRequestValue) {
        return null;
    }
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    let validJsonList = [];
    try {
        validJsonList = JSON.parse(jobRequestValue);
        if (!isArray(validJsonList)) {
            validJsonList = [validJsonList];
        }
    } catch (e) {
        console.log(e);
    }
    if (!includes([FLEETX_ACCOUNTS.BHARTI_CEMENT_BULKER, 7573], accountId)) {
        return validJsonList[0];
    }
    let findByField = 'invoiceNumber';
    switch (accountId) {
        case 7573:
            return find(validJsonList, { ['vbeln']: get(jobAnalytic, 'docketNumber') });
        default:
            return find(validJsonList, { [findByField]: get(jobAnalytic, findByField) });
    }
}

export const TEMP_DATA_TIME_RANGE_OPTIONS_JOBS = {
    JOB_START_END_DATE: 'JOB_START_END_DATE',
};

export function getTempDataAtJobTimeRange({ jobAnalytics, endDate, startDate }) {
    const selectedOption = getFlagValueFromAccountConfig('showTempDataAtJobLevel');

    let tempStartDate = startDate;
    let tempEndDate = endDate;

    const firstPoint = first(jobAnalytics);
    const lastPoint = last(jobAnalytics);

    switch (selectedOption) {
        case TEMP_DATA_TIME_RANGE_OPTIONS_JOBS.JOB_START_END_DATE:
            tempStartDate = startDate;
            tempEndDate = endDate;
            break;
        default:
            if (firstPoint && firstPoint.actualDeparture) {
                tempStartDate = firstPoint.actualDeparture;
            }
            if (lastPoint && lastPoint.actualArrival) {
                tempEndDate = lastPoint.actualArrival;
            }
    }
    return {
        tempStartDate,
        tempEndDate,
    };
}

export const showIndentTypeInJobBooking = () => {
    return getFlagValueFromAccountConfig('showIndentType');
};

export function renderJobRiskLevel(riskLevel) {
    if (!riskLevel) {
        return null;
    }
    let title = '';
    let color;
    switch (riskLevel) {
        case 'N':
            title = 'No risk';
            color = COLOR_GRAPH_GREEN;
            break;
        case 'C':
            title = 'Medium risk';
            color = COLOR_GRAPH_YELLOW;
            break;
        case 'E':
            title = 'High risk';
            color = COLOR_GRAPH_RED;
    }
    return <FxBadge count={title} color={color} />;
}

export function getActualArrivalAndDepartureTimeForUnloadingPoint(jobAnalyticPoint) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    let actualArrival = get(jobAnalyticPoint, 'actualArrival');
    let actualDeparture = get(jobAnalyticPoint, 'actualDeparture');
    if (includes([12925], accountId)) {
        actualArrival = get(jobAnalyticPoint, 'stoppageStartTime');
        actualDeparture = get(jobAnalyticPoint, 'stoppageEndTime');
    }

    return {
        actualArrival,
        actualDeparture,
    };
}

export const mapRouteBudgets = (routeBudgets) => {
    return map(routeBudgets, (routeBudget) => {
        return {
            label: routeBudget.name,
            value: routeBudget.id,
        };
    });
};

export function useRoutePointLatLonInsteadOfAddressBook() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return (
        includes([...isShreeCementsAccounts, 12298], accountId) ||
        getFlagValueFromAccountConfig('useRouteLatLonInsteadAddressBookInJobMapForMarker')
    );
}

export function getCustomFieldValue(fieldId, customFields) {
    return get(
        find(customFields, (cf) => {
            return cf.field?.id === fieldId;
        }),
        'value',
        0
    );
}

export function getCustomFieldkey(fieldId, fieldNames) {
    return fieldNames[fieldId];
}

export const PH_ROLE_ID = 110310; // PLANT HEAD
export const RHO_ROLE_ID = 110309; // Regional Head Operation.
export const allowedToEditCustomFieldsForRHO = [2676, 2778, 2692, 2885, 2887];

export const getCfKey = (cf) => {
    return `field_${cf.id}`;
};

export function jobFormCustomFieldConfig(values, isOnCustomFieldsJobDashboard = false) {
    const roleId = get(FLEETX_LOGGED_IN_USER, 'role.id', 0);
    const accountId = get(FLEETX_LOGGED_IN_USER, 'accountId', 0);

    const baseKeyForJobData = isOnCustomFieldsJobDashboard ? 'job.' : '';
    const getJobData = (key) => {
        return get(values, `${baseKeyForJobData}${key}`);
    };

    const plantInCode = getJobData('jobAnalytics.[1].routePoint.address');
    const plantInDateTime = getJobData('jobAnalytics.[1].actualArrival');

    const plantOutCode = getJobData('jobAnalytics.[0].routePoint.address');
    const plantOutDateTime = getJobData('jobAnalytics.[0].actualDeparture');

    switch (accountId) {
        case 14008:
            const isEdit = !!values.id || isOnCustomFieldsJobDashboard;
            if (!isEdit) {
                return {
                    2722: {
                        name: 'TM Reg number',
                        disabled: true,
                        required: false,
                        id: 2722,
                    },
                };
            }
            const isUserPH = PH_ROLE_ID === roleId;
            const iseUserRHO = RHO_ROLE_ID === roleId;
            return {
                2671: {
                    name: 'Plant Name',
                    disabled: false,
                    required: false,
                    id: 2671,
                    inputByRoleId: PH_ROLE_ID,
                },
                2672: {
                    name: 'Project Code',
                    disabled: false,
                    required: false,
                    id: 2672,
                    inputByRoleId: PH_ROLE_ID,
                },
                2673: {
                    name: 'Project Name',
                    disabled: false,
                    required: false,
                    id: 2673,
                    inputByRoleId: PH_ROLE_ID,
                },
                2693: {
                    name: 'VTS KMs',
                    disabled: true,
                    required: false,
                    id: 2693,
                },
                2694: {
                    name: 'Google KMs',
                    disabled: true,
                    required: false,
                    id: 2694,
                },
                2674: {
                    name: 'Base KM',
                    disabled: !isUserPH,
                    required: isUserPH,
                    id: 2674,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2675: {
                    name: 'PH Recommended KM',
                    disabled: !isUserPH,
                    required: isUserPH,
                    id: 2675,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2676: {
                    name: 'RHO Recommended KM',
                    disabled: !iseUserRHO,
                    required: iseUserRHO,
                    id: 2676,
                    inputByRoleId: RHO_ROLE_ID,
                    fieldType: 'number',
                },
                2691: {
                    name: 'Approved By PH',
                    disabled: !isUserPH,
                    required: false,
                    id: 2691,
                    inputByRoleId: PH_ROLE_ID,
                },
                2692: {
                    name: 'Approved By RHO',
                    disabled: !iseUserRHO,
                    required: false,
                    id: 2692,
                    inputByRoleId: RHO_ROLE_ID,
                },
                2681: {
                    name: 'Final Odometer Reading',
                    disabled: true,
                    required: false,
                    id: 2681,
                },

                2679: {
                    name: 'Odometer Reading From KMs',
                    disabled: !isUserPH,
                    required: false,
                    id: 2679,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },

                2680: {
                    name: 'Odometer Reading To KMs',
                    disabled: !isUserPH,
                    required: false,
                    id: 2680,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },

                2677: {
                    name: 'Invoice Number',
                    disabled: !isUserPH,
                    required: false,
                    id: 2677,
                    inputByRoleId: PH_ROLE_ID,
                },
                2678: {
                    name: 'Delivery Number',
                    disabled: !isUserPH,
                    required: false,
                    id: 2678,
                    inputByRoleId: PH_ROLE_ID,
                },
                2696: {
                    name: 'Remarks by PH',
                    disabled: !isUserPH,
                    required: false,
                    id: 2696,
                    inputByRoleId: PH_ROLE_ID,
                },
                2685: {
                    name: 'Sales Order Number',
                    disabled: iseUserRHO,
                    required: false,
                    id: 2685,
                },
                2684: {
                    name: 'Remarks',
                    disabled: iseUserRHO,
                    required: false,
                    id: 2684,
                },
                2682: {
                    name: 'Vendor Code',
                    disabled: iseUserRHO,
                    required: false,
                    id: 2682,
                },
                2683: {
                    name: 'Vendor Name',
                    disabled: iseUserRHO,
                    required: false,
                    id: 2683,
                },
                2722: {
                    name: 'TM Reg number',
                    disabled: true,
                    required: false,
                    id: 2722,
                },
                2886: {
                    name: 'PH Approval Date - Time',
                    disabled: true,
                    required: false,
                    id: 2886,
                },
                2884: {
                    name: 'PH ID',
                    disabled: true,
                    required: false,
                    id: 2884,
                },
                2887: {
                    name: 'RHO Approval Date - Time',
                    disabled: true,
                    required: false,
                    id: 2887,
                },
                2885: {
                    name: 'RHO ID',
                    disabled: true,
                    required: false,
                    id: 2885,
                },
                2984: {
                    name: 'Plant In',
                    disabled: !!plantInCode || !isUserPH,
                    required: false,
                    id: 2984,
                },
                2720: {
                    name: 'Plant In Date Time',
                    disabled: !!plantInDateTime || !isUserPH,
                    required: false,
                    id: 2720,
                },
                2670: {
                    name: 'Plant Out',
                    disabled: !!plantOutCode || !isUserPH,
                    required: isUserPH,
                    id: 2670,
                    inputByRoleId: PH_ROLE_ID,
                    // fieldType: 'number',
                },
                2715: {
                    name: 'Plant Out Date Time',
                    disabled: !!plantOutDateTime || !isUserPH,
                    required: isUserPH,
                    id: 2715,
                },
                2716: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2716,
                },
                2719: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2719,
                },
                2723: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2723,
                },
                2713: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2713,
                },
                2714: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2714,
                },
                2761: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2761,
                },
                2711: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2711,
                },
                2712: {
                    name: '',
                    disabled: true,
                    required: false,
                    id: 2712,
                },
                2883: {
                    name: 'Invoice Type',
                    disabled: !isUserPH,
                    required: false,
                    id: 2883,
                    inputByRoleId: PH_ROLE_ID,
                },
                2686: {
                    name: 'Ticket Qty M3',
                    disabled: false,
                    required: false,
                    id: 2686,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                    dataValidationRequired: isUserPH,
                },
                2880: {
                    name: 'Pump 1 Qty. (M3)',
                    disabled: false,
                    required: false,
                    id: 2880,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2881: {
                    name: 'Pump 2 Qty. (M3)',
                    disabled: false,
                    required: false,
                    id: 2881,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2882: {
                    name: 'Pump 3 Qty. (M3)',
                    disabled: false,
                    required: false,
                    id: 2882,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2806: {
                    name: 'Dump Qty',
                    disabled: false,
                    required: false,
                    id: 2806,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2805: {
                    name: 'Customer Pump Qty',
                    disabled: false,
                    required: false,
                    id: 2805,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2695: {
                    name: 'Wastage Qty. (M3)',
                    disabled: !isUserPH,
                    required: false,
                    id: 2695,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2754: {
                    name: 'Sales Order Qty',
                    disabled: false,
                    required: false,
                    id: 2754,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
                2888: {
                    name: '',
                    disabled: false,
                    required: false,
                    id: 2888,
                    inputByRoleId: PH_ROLE_ID,
                    fieldType: 'number',
                },
            };
        case 13172:
            return {
                3005: {
                    name: 'Grade',
                    disabled: false,
                    required: true,
                    id: 3005,
                },
                3006: {
                    name: 'Quantity in tones',
                    disabled: false,
                    required: true,
                    id: 3006,
                },
            };
        case 13708:
            return {
                3405: {
                    name: 'THS Number',
                    disabled: false,
                    required: true,
                    id: 3405,
                },
            };

        default:
            return {};
    }
}

export function isCustomFieldRequired(fieldId, values, value) {
    const customFieldConfig = jobFormCustomFieldConfig(values);
    return get(customFieldConfig, `${fieldId}.required`, false);
}

export function jobFormCustomFieldChangeOperation({
    fieldNames,
    fieldsConfig,
    values,
    setFieldValue,
    name,
    field,
    value,
}) {
    const roleId = get(FLEETX_LOGGED_IN_USER, 'role.id', 0);
    const accountId = get(FLEETX_LOGGED_IN_USER, 'accountId', 0);
    const customFields = get(values, 'customFields', []);
    const isEdit = !!values.id;
    switch (accountId) {
        case 14008:
            if (isEdit) {
                const PH_ROLE_ID = 110310; // PLANT HEAD
                const RHO_ROLE_ID = 110309; // Regional Head Operation.
                const PH_kms = getCustomFieldValue(2675, customFields); //2675
                let RHO_kms = getCustomFieldValue(2676, customFields); //2676
                const isApprovedByPH = getCustomFieldValue(2691, customFields); //2691
                const isApprovedByRHO = getCustomFieldValue(2692, customFields); //2692
                const currentFieldId = get(field, `field.id`, 0);
                // auto fill values
                let odometerReadingFrom = parseFloat(
                    currentFieldId === 2679 ? value : getCustomFieldValue(2679, customFields)
                );
                let odometerReadingTo = parseFloat(
                    currentFieldId === 2680 ? value : getCustomFieldValue(2680, customFields)
                );
                let finalOdometerReading = odometerReadingTo - odometerReadingFrom; // 2681
                if (finalOdometerReading > 0) {
                    setFieldValue(getCustomFieldkey(2681, fieldNames), finalOdometerReading);
                }
                const currentVTSKm = getCustomFieldValue(2693, customFields);
                if (!currentVTSKm) {
                    const VTSKm = get(values, 'currentKm', 0); //2693
                    if (VTSKm) {
                        setFieldValue(getCustomFieldkey(2693, fieldNames), round(VTSKm, 2));
                    }
                }
                if (!RHO_kms && PH_kms) {
                    setFieldValue(getCustomFieldkey(2676, fieldNames), PH_kms); // RHO kms same as PH km
                }
            }
            break;
        default:
    }
}

export const JobFieldNames = {
    EWAYBillNumber: 'EWAYBillNumber',
    EWAYBillExpiryDate: 'EWAYBillExpiryDate',
    IgnoreOffLineVehicles: 'ignoreOffLineVehicles',
    JobConsignor: 'jobConsignor',
    Driver: 'driver',
    IsExistingRoute: 'isExistingRoute',
};

export const isJobFieldsRequired = (fieldName) => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (fieldName) {
        case JobFieldNames.EWAYBillNumber:
            return accountId === 13395;
        case JobFieldNames.EWAYBillExpiryDate:
            return accountId === 13395;
        case JobFieldNames.JobConsignor:
            return accountId === 13395;
        case JobFieldNames.Driver:
            return accountId === 13395;
        default:
            return false;
    }
};

export const collectRepairedPolyPoints = (point, repairedAllPolyPointsArr, repairedPolyPointsCurrArr) => {
    // const isRepaired = get(point, 'otherAttributes.repaired') === 'true';
    const isRepaired = get(point, 'repaired');
    if (isRepaired) {
        repairedPolyPointsCurrArr.push({ lat: point.latitude, lng: point.longitude });
    } else {
        if (repairedPolyPointsCurrArr.length) {
            repairedAllPolyPointsArr.push(repairedPolyPointsCurrArr);
            repairedPolyPointsCurrArr = [];
        }
    }
    return repairedPolyPointsCurrArr;
};

export const isJobFieldOnByDefault = (fieldName) => {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    switch (fieldName) {
        case JobFieldNames.IgnoreOffLineVehicles:
            return accountId === 13395;
        case JobFieldNames.IsExistingRoute:
            return accountId === 13395;
        default:
            return false;
    }
};

export const getConsignmentIdsFromJobAnalyticsCommodities = (jobAnalytics) => {
    const cnIds = [];
    forEach(jobAnalytics, (ja) => {
        forEach(ja.commodities || [], (c) => {
            if (c.consignmentMaterial?.consignmentId) {
                cnIds.push(c.consignmentMaterial?.consignmentId);
            }
        });
    });
    return [...new Set(cnIds)];
};

export function isSImPingPointSnapEnabled() {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
    return !includes([FLEETX_ACCOUNTS.MAERSK], accountId);
}

export const JOB_SUMMARY_TABS_OTHERS = [
    {
        value: 'jobPoints',
        label: 'Job Points',
    },
    {
        value: 'loadStatus',
        label: 'Load Status',
    },
    {
        value: 'others',
        label: 'Others',
    },
];

export const getFilteredKeyData = (data) => {
    const keysToFilter = {
        'LOADING': 'Loading',
        'UNLOADING': 'Unloading',
        'WAITING_TO_UNLOAD': 'Waiting To Unload',
        'LOADED': 'Loaded',
        'WAITING_TO_LOAD': 'Waiting To Load',
        'IN_GARAGE': 'In Garage',
        'WAITING_IN_GARAGE': 'Waiting In Garage',
        'EMPTY': 'Empty',
    };

    const filteredData = Object.keys(keysToFilter).map((key) => {
        return {
            key: keysToFilter[key],
            value: data[key] !== undefined ? data[key] : 0,
        };
    });

    return filteredData;
};
export const getFilteredKeyDataOthers = (data) => {
    const keysToFilter = {
        'ZIG_ZAG_JOBS': 'Zig-zag',
        'rfid_mismatch': 'Tag Mismatch',
        // 'SOURCE_FLAG': 'Source Flag',
        // 'DESTINATION_FLAG': 'Destination Flag',
        'totalDeviationCount': 'Deviation',
    };

    const filteredData = Object.keys(keysToFilter).map((key) => {
        return {
            key: keysToFilter[key],
            value: data[key] !== undefined ? data[key] : 0,
        };
    });

    return filteredData;
};
export const getFilteredJobPointsData = (data) => {
    const keysToFilter = {
        'EARLY_POINTS': 'Early Points',
        'LATE_POINTS': 'Late Points',
        'ON_TIME': 'On Time',
    };

    let totalCount = 0;

    Object.keys(keysToFilter).forEach((key) => {
        totalCount += data[key] !== undefined ? data[key] : 0;
    });

    return totalCount;
};

export const JOB_SUMMARY_LOADING_STATUS = {
    LOADING: 'Loading',
    UNLOADING: 'Unloading',
    WAITING_TO_UNLOAD: 'Wating To Unload',
    LOADED: 'Loaded',
    WAITING_TO_LOAD: 'Wating To Load',
    IN_GARAGE: 'In Garage',
    WAITING_IN_GARAGE: 'Wating In Garage',
    EMPTY: 'Empty',
};

export const isValueGreaterThanThousand = (object) => {
    const totalData = get(object, 'TOTAL_JOBS');
    return totalData > 1000;
};

export const getMileageTypeFromMovementType = (movementType) => {
    const isMovementTypeEmpty = includes(movementType, 'EMPTY');
    const isMovementTypeLoad = includes(movementType, 'LOAD');
    return isMovementTypeEmpty && isMovementTypeLoad ? 'BOTH' : isMovementTypeEmpty ? 'EMPTY' : 'LOAD';
};

export const getJobCheckListTabOptions = () => {
    const checkListConfig = getChecklistOptions();
    const CHECKLIST_TABS = {};
    forEach(checkListConfig, (el, i) => {
        const currentElement = find(CHECKLIST_OPTIONS, { value: el });
        set(CHECKLIST_TABS, get(currentElement, 'value'), {
            name: get(currentElement, 'label'),
            key: get(currentElement, 'value'),
            order: i + 1,
        });
    });
    return CHECKLIST_TABS;
};

export const APP_JOB_DETAILS_TABS = [
    {
        label: 'Job Details',
        value: 'JOB_DETAILS',
    },
    {
        label: 'Timeline',
        value: 'TIMELINE',
    },
    {
        label: 'Itinerary',
        value: 'ITINERARY',
    },
    {
        label: 'Stops',
        value: 'STOPS',
    },
    {
        label: 'Tolls',
        value: 'TOLLS',
    },
    {
        label: 'Consignment Details',
        value: 'CONSIGNMENT_DETAILS',
    },
    {
        label: 'Comments',
        value: 'COMMENTS',
    },
    {
        label: 'Job Images',
        value: 'JOB_IMAGES',
    },
    {
        label: 'Checklist',
        value: 'CHECKLIST',
    },
    {
        label: 'RFID',
        value: 'RFID',
    },
    {
        label: 'Generate LR',
        value: 'GENERATE_LR',
    },
];

export const getLatestJaPointHit = (jobAnalytics) => {
    for (let i = jobAnalytics.length - 1; i >= 0; i--) {
        if (jobAnalytics[i].status !== 'MISSED' && jobAnalytics[i].status !== 'SCHEDULED') {
            return { point: jobAnalytics[i], latestPointIndex: i };
        }
    }
    return { point: null, latestPointIndex: -1 };
};

export function useDispatchDateAsRouteStartDate() {
    return includes(
        [FLEETX_ACCOUNTS.GOLDEN_GLOBES, FLEETX_ACCOUNTS.STRIKE],
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)
    );
}

export function getScoreTextColorV2(score, jobStatus = {}) {
    const status = get(jobStatus, 'status');
    if (status === 'Scheduled') {
        return '#1E90FF';
    }
    if (score > 90) {
        return '#00a81f';
    } else if (score >= 80 && score <= 90) {
        return '#37c652';
    } else if (score >= 70 && score <= 80) {
        return '#f7db51';
    } else if (score >= 60 && score <= 70) {
        return '#f1ca0b';
    } else if (score >= 50 && score <= 60) {
        return '#fcb15f';
    } else if (score >= 40 && score <= 50) {
        return '#fa8d14';
    } else if (score >= 30 && score <= 20) {
        return '#f94546';
    } else {
        return '#f82c2d';
    }
}

export function getStatusLabel(jobStatus) {
    const status = get(jobStatus, 'status');
    if (status === 'early predicted') {
        return 'Early';
    } else if (status === 'late predicted') {
        return 'Late';
    } else if (status === 'On Time') {
        return 'On Time';
    } else if (status === 'Completed') {
        return 'Completed';
    } else if (status === 'Scheduled') {
        return 'Scheduled';
    } else if (status === 'Cancelled') {
        return 'Cancelled';
    }
}

export const groupedByLocation = (data) => {
    return reduce(
        data,
        (acc, obj) => {
            const key = get(obj, 'currentLocation');
            if (!acc[key]) {
                acc[key] = [];
            }

            acc[key].push(obj);
            return acc;
        },
        {}
    );
};

export const setCustomFieldValue = (values, cfId, setFieldValue, value) => {
    if (getAccountId() === FLEETX_ACCOUNTS.SHREE_CEMNET_RMC) {
        // custom code
        const vehicleCFIndex = findIndex(get(values, 'customFields', []), (cf) => cf.field.id === cfId);
        if (vehicleCFIndex !== -1) {
            setFieldValue(`customFields[${vehicleCFIndex}].value`, value);
        }
    }
};
