import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { isEmpty, map } from 'lodash';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { renderPieChartTooltip } from 'utils/chartUtils';
import { DotComponent, getLoadingUI } from 'utils/gearDistributionUtils';
import { FxCard } from 'sharedV2';
const GearPickupFromStandstillGraph = (props) => {
    const { data, isLoading } = props;
    const { t } = useTranslation();
    if (isLoading) {
        return getLoadingUI();
    }
    return (_jsxs(FxCard, { className: "mb-2", style: { height: '100%' }, bodyStyle: { paddingBottom: 6 }, children: [_jsx("h6", { children: t('gearPickupFromStandstill') }), isEmpty(data) ? (_jsx("div", { className: "text-center d-flex", style: { height: 250 }, children: _jsx("div", { style: { margin: 'auto' }, children: `No Data For ${t('gearPickupFromStandstill')}` }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "d-flex justify-content-center", children: _jsxs(PieChart, { width: 250, height: 250, children: [_jsx(Pie, { data: data, dataKey: "value", cx: "50%", cy: "50%", labelLine: false, animationDuration: 300, 
                                    // label={renderPieChartCustomizedLabel}
                                    fill: "#20c997", innerRadius: 55, outerRadius: 80, children: map(data, (entry, index) => {
                                        return _jsx(Cell, { fill: entry.color }, index);
                                    }) }), _jsx(Tooltip, { content: renderPieChartTooltip, cursor: { fill: 'grey', fillOpacity: 0.07 } })] }) }), _jsx("div", { className: "row medium  justify-content-center", children: map(data, (d) => {
                            return (_jsxs("div", { className: "col-md-4 text-center", children: [_jsxs("div", { className: "d-flex align-items-center justify-content-center", children: [_jsx(DotComponent, { color: d.color }), _jsxs("span", { className: 'pl-1 font-weight-bold', children: [d.value, "%"] })] }), _jsx("div", { children: d.name })] }, d.name));
                        }) })] }))] }));
};
export default GearPickupFromStandstillGraph;
