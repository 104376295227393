import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { COLORS, DotComponent, GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY, getGearDistributionPeakRangeCategoryName, getLoadingUI, renderDistributionTooltip, } from 'utils/gearDistributionUtils';
import { isEmpty, map, round } from 'lodash';
import { getTimeDiffHours } from 'utils/date_utils';
import { UncontrolledTooltip } from 'reactstrap';
import { FxCard } from 'sharedV2';
const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    if (!value) {
        return '';
    }
    return (_jsxs("text", { fontSize: "10px", x: x + width / 2, y: y - radius, textAnchor: "middle", dominantBaseline: "middle", children: [round(value, 1), "%"] }));
};
const renderCustomizedLabelTimeSpent = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 40;
    return (_jsx("text", { fontSize: "11px", x: x + width / 2, y: 30, fill: '#76c09d', textAnchor: "middle", dominantBaseline: "middle", children: getTimeDiffHours(value) }));
};
const legendItems = [
    { value: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.withinPeakRange, color: COLORS.color1 },
    { value: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.belowPeakRange, color: COLORS.color2 },
    { value: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.abovePeakRange, color: COLORS.color3 },
];
const GearSpecificEngineSpeedDistributionGraph = (props) => {
    const { data, isLoading } = props;
    const { t } = useTranslation();
    if (isLoading) {
        return getLoadingUI();
    }
    return (_jsxs(FxCard, { className: "mb-2", bodyStyle: { paddingBottom: 6 }, children: [_jsxs("h6", { children: [t('gearSpecificEngineSpeedDistribution'), _jsx("i", { className: "fa fa-info-circle cursor-pointer mx-1", id: "gearSpecificEngineSpeedDistribution-info-tooltip" }), _jsx(UncontrolledTooltip, { placement: "right", target: "gearSpecificEngineSpeedDistribution-info-tooltip", children: t('gearSpecificEngineSpeedDistributionInfo') })] }), isEmpty(data) ? (_jsx("div", { className: "text-center d-flex", style: { height: 250 }, children: _jsx("div", { style: { margin: 'auto' }, children: `No Data For ${t('gearSpecificEngineSpeedDistribution')}` }) })) : (_jsxs(_Fragment, { children: [_jsx(ResponsiveContainer, { width: "100%", minHeight: 300, children: _jsxs(BarChart, { width: 500, height: 300, data: data, margin: {
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, { domain: ['dataMin', 'dataMax + 20'] }), _jsx(Tooltip, { content: renderDistributionTooltip }), _jsx(Bar, { dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.withinPeakRange, fill: COLORS.color1, children: _jsx(LabelList, { content: renderCustomizedLabel, dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.withinPeakRange }) }), _jsxs(Bar, { dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.belowPeakRange, fill: COLORS.color2, children: [_jsx(LabelList, { content: renderCustomizedLabel, dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.belowPeakRange }), _jsx(LabelList, { content: renderCustomizedLabelTimeSpent, dataKey: 'timeSpent' })] }), _jsx(Bar, { dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.abovePeakRange, fill: COLORS.color3, children: _jsx(LabelList, { content: renderCustomizedLabel, dataKey: GEAR_DISTRIBUTION_PEAK_RANGE_CATEGORY.abovePeakRange }) })] }) }), _jsx("div", { className: "d-flex justify-content-center", children: map(legendItems, (item) => {
                            return (_jsx("div", { className: "text-center", children: _jsxs("div", { className: "d-flex align-items-center justify-content-center mr-3", children: [_jsx(DotComponent, { color: item.color }), _jsx("div", { className: "ml-2", children: getGearDistributionPeakRangeCategoryName(item.value) })] }) }, item.value));
                        }) })] }))] }));
};
export default GearSpecificEngineSpeedDistributionGraph;
