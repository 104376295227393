import { includes, startsWith } from 'lodash';

export function getDownloadableURLFromArrayBuffer(data, dataType) {
    if (!data || !dataType) {
        return null;
    }
    let blob = new Blob([data], { type: dataType });
    return window.URL.createObjectURL(blob);
}

export function readBlobAsJson(blob) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function (e) {
            try {
                resolve(JSON.parse(e.target.result));
            } catch (e) {
                reject(e);
            }
        };
        fr.readAsText(blob);
    });
}

const redirectAllowedToHosts = [];

export function isSafeRedirectUrl(redirectUrl) {
    if (!redirectUrl) {
        return false;
    }
    try {
        const allAllowedHosts = [window.location.hostname, ...redirectAllowedToHosts];
        const urlObj = new URL(decodeURIComponent(redirectUrl));
        const { hostname } = urlObj || {};
        return includes(allAllowedHosts, hostname);
    } catch (e) {}
    return false;
}

export function isCancelledRequest(res) {
    return res?.error && (!res?.payload?.message || res?.payload?.message === 'AbortError');
}

export function isBlobUrl(url) {
    return startsWith(url, 'blob:');
}
