import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, filter, isEqual, find, includes } from 'lodash';
import { Field } from 'formik';
import { FxSkeleton } from '../../../shared';
import { getMappedBookTypes, mappedBranches, mappedStationaries } from '../../utils/mapping_utils';
import { fetchStationaries, getNextStationary, validateStationary, fetchStationary, fetchDeletedStationaries, } from 'actions/dashboard/stationary/actions';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { isViewBranchAllowedForUser, isViewStationaryAllowedForUser } from '../roles/permission_utils';
import { FxCol, FxRow, FxSelectField, FxTextFieldStackedLabelV2 } from '../../../sharedV2';
import DeletedStationariesModal from './deleted_stationaries_modal';
class BillNumberComponent extends Component {
    initalStationaryId = null;
    initalStationaryNumber = null;
    branchSetRef = false;
    state = {
        allStationaries: [],
        stationaryNature: null,
        isBranchesLoading: true,
        allBranches: [],
        isNextStationaryNumberFetching: false,
        deletedStationaries: [],
        isDeletedStationariesModalOpen: false,
        selectedDeletedStationary: null,
        isBranchChangedManully: false,
    };
    componentDidMount = async () => {
        if (isViewBranchAllowedForUser(this.props.login.scope)) {
            const respBranches = await this.props.fetchBranches(this.props.accesstoken, true);
            const allBranches = get(respBranches, 'payload.data');
            this.setState({
                allBranches,
                isBranchesLoading: false,
            });
        }
        const { branch, bookType } = this.props.formikProps.values;
        if (branch && bookType) {
            this.fetchStationaries(branch, bookType, () => {
                this.onStationarySelect(this.props.formikProps.values.stationaryId, branch, bookType, this.props.formikProps);
            });
        }
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isReadyToFetchBillNumber && this.props.isEdit) {
            if (!this.props.formikProps.values.branch &&
                get(this.props.formikProps.values, 'stationaryId') &&
                !this.branchSetRef) {
                this.branchSetRef = true;
                this.initalStationaryId = get(this.props.formikProps.values, 'stationaryId');
                this.initalStationaryNumber = get(this.props.formikProps.values, 'stationaryNumber');
                if (isViewStationaryAllowedForUser(this.props.login.scope)) {
                    this.props
                        .fetchStationary(this.props.accesstoken, get(this.props.formikProps.values, 'stationaryId'))
                        .then((res) => {
                        const stationary = get(res, 'payload.data');
                        if (stationary && stationary.branch.id) {
                            this.props.formikProps.setFieldValue('branch', stationary.branch.id);
                            this.fetchStationaries(stationary.branch.id, stationary.bookType, () => {
                                this.onStationarySelect(this.props.formikProps.values.stationaryId, stationary.branch.id, stationary.bookType, this.props.formikProps);
                            });
                        }
                    });
                }
            }
        }
        if (!this.props.isEdit && !isEqual(prevProps.formikProps.values, this.props.formikProps.values)) {
            const { branch, bookType, stationaryId } = this.props.formikProps.values;
            if (!isEqual(prevProps.formikProps.values.branch, this.props.formikProps.values.branch) &&
                prevState.isBranchChangedManully == this.state.isBranchChangedManully) {
                this.fetchStationaries(branch, bookType, () => {
                    this.onStationarySelect(stationaryId, branch, bookType, this.props.formikProps);
                }, true);
            }
            else {
                if (branch && bookType && stationaryId) {
                    this.onStationarySelect(stationaryId, branch, bookType, this.props.formikProps);
                }
            }
        }
    };
    getDeletedStationary = ({ branchId, bookName, bookType }) => {
        const stationary = filter(this.state.allStationaries, (stationary) => stationary.id == bookName);
        this.props
            .fetchDeletedStationaries(this.props.accesstoken, {
            branchId,
            bookName: get(stationary, '[0].bookName'),
            bookType,
        })
            .then((res) => {
            this.setState({
                deletedStationaries: get(res, 'payload.data[0].detachedStationaryList'),
                isDeletedStationariesModalOpen: true,
            });
        });
    };
    onSelectDeletedStationary = (val) => {
        this.setState({
            selectedDeletedStationary: val,
        });
    };
    fetchStationaries = (branch, bookType, onStationarySelect, forceEnable) => {
        if (isViewStationaryAllowedForUser(this.props.login.scope)) {
            this.props
                .fetchStationaries(this.props.accesstoken, branch, bookType, this.props.isEdit && !forceEnable ? null : true)
                .then((res) => {
                this.setState({
                    allStationaries: get(res, 'payload.data'),
                }, () => {
                    if (!onStationarySelect) {
                        this.props.formikProps.setFieldValue('stationaryId', '');
                        this.props.formikProps.setFieldValue('stationaryNumber', '');
                    }
                    else {
                        onStationarySelect();
                    }
                });
            });
        }
    };
    onStationarySelect = (val, branch, bookType, formikProps, forceFetchNextStationary) => {
        if (val) {
            formikProps.setFieldValue('stationaryId', val);
            const stationary = filter(this.state.allStationaries, (stationary) => stationary.id == val);
            const stationaryNature = get(stationary, '[0].nature');
            this.setState({
                stationaryNature,
            }, () => {
                if (this.state.stationaryNature == 'AUTO' &&
                    branch &&
                    bookType &&
                    (!formikProps.values.stationaryNumber || forceFetchNextStationary)) {
                    if (this.initalStationaryId != val) {
                        this.setState({ isNextStationaryNumberFetching: true });
                        this.props
                            .getNextStationary(this.props.accesstoken, branch, bookType, get(stationary, '[0].bookName'))
                            .then((res) => {
                            this.setState({ isNextStationaryNumberFetching: false });
                            const nextStationaryNumber = get(res, 'payload.data.[0].nextStationaryNumber', '');
                            if (!nextStationaryNumber) {
                                alert('Book name not valid or disabled !!');
                                formikProps.setFieldValue('stationaryId', '');
                            }
                            formikProps.setFieldValue('stationaryNumber', nextStationaryNumber);
                            this.props.onStationaryNumberPopulate &&
                                this.props.onStationaryNumberPopulate(nextStationaryNumber);
                        });
                    }
                    else {
                        formikProps.setFieldValue('stationaryNumber', this.initalStationaryNumber);
                        this.props.onStationaryNumberPopulate &&
                            this.props.onStationaryNumberPopulate(this.initalStationaryNumber);
                    }
                }
                else if (includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature) &&
                    (!formikProps.values.stationaryNumber || forceFetchNextStationary)) {
                    if (this.initalStationaryId != val) {
                        formikProps.setFieldValue('stationaryNumber', `${get(stationary, '[0].code')}-`);
                    }
                    else {
                        formikProps.setFieldValue('stationaryNumber', this.initalStationaryNumber);
                        this.props.onStationaryNumberPopulate &&
                            this.props.onStationaryNumberPopulate(this.initalStationaryNumber);
                    }
                }
            });
        }
    };
    validateBillNumber = (stationaryNumber, stationaryId, setFieldError) => {
        if (stationaryNumber && stationaryId) {
            this.props
                .validateStationary(this.props.accesstoken, stationaryId, stationaryNumber)
                .then((res) => {
                if (!get(res, 'payload.data')) {
                    setFieldError('stationaryNumber', 'Invalid Stationary Number');
                }
                else {
                    this.props.onStationaryNumberPopulate &&
                        this.props.onStationaryNumberPopulate(stationaryNumber);
                }
            })
                .catch((e) => { });
        }
    };
    wrapper = (children) => {
        const { removeDivWrapper = false } = this.props;
        if (!removeDivWrapper) {
            return _jsx(FxRow, { gutter: 16, children: children });
        }
        else {
            return _jsx(_Fragment, { children: children });
        }
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps, this.props)) {
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
    render() {
        const { branch, bookType, stationaryId } = this.props.formikProps.values;
        const { isNextStationaryNumberFetching } = this.state;
        const { showNarration, colClass = 8 } = this.props;
        const gridColConfig = this.props.gridColConfig || {
            xs: 24,
            sm: 12,
            md: colClass,
        };
        return this.wrapper(_jsxs(_Fragment, { children: [!!showNarration && (_jsx(FxCol, { ...gridColConfig, children: _jsx(Field, { size: this.props.size, name: `narration`, component: FxTextFieldStackedLabelV2, label: "Narration", isRequired: true, disabled: false, maxlength: "256", "data-testid": "narration-field" }) })), isViewBranchAllowedForUser(this.props.login.scope) && (_jsx(FxCol, { ...gridColConfig, children: this.state.isBranchesLoading || !this.props.isReadyToFetchBillNumber ? (_jsx(FxSkeleton, { width: "100%", height: "40px", className: "mt-4", children: _jsx("div", {}) })) : (_jsx(Field, { size: this.props.size, name: "branch", options: mappedBranches(this.state.allBranches), component: FxSelectField, onChange: (val) => {
                            this.props.onBranchSelect &&
                                this.props.onBranchSelect(val, find(this.state.allBranches, { id: val }));
                            this.fetchStationaries(val, bookType, null, true);
                        }, isRequired: this.props.branchRequired, disabled: get(this.props, 'fieldDisabled.branch', false), allowClear: true, label: get(this.props, 'fieldLabel.branch', 'Select Branch'), "data-testid": "branch-field" })) })), _jsx(FxCol, { ...gridColConfig, children: _jsx(Field, { size: this.props.size, name: "bookType", options: getMappedBookTypes(), component: FxSelectField, 
                        // onSelected={(val) => this.fetchStationaries(branch, val, null, true)}
                        disabled: true, label: 'Book Type', "data-testid": "book-type-field" }) }), branch && bookType && (_jsx(FxCol, { ...gridColConfig, children: _jsx(Field, { size: this.props.size, name: "stationaryId", isRequired: this.props.branchRequired, options: mappedStationaries(this.state.allStationaries), component: FxSelectField, onChange: (val) => {
                            if (this.props.onStationarySelectCb) {
                                this.props.onStationarySelectCb(val);
                            }
                            this.onStationarySelect(val, branch, bookType, this.props.formikProps, true);
                        }, disabled: get(this.props, 'fieldDisabled.bookName', false), label: 'Select Book Name', "data-testid": "book-name-field" }) })), branch && bookType && stationaryId && !this.props.stationaryNumberNotRequired && (_jsxs(FxCol, { ...gridColConfig, children: [_jsx(Field, { size: this.props.size, name: "stationaryNumber", type: "text", component: FxTextFieldStackedLabelV2, disabled: this.state.stationaryNature == 'AUTO' ||
                                get(this.props, 'fieldDisabled.stationaryNumber', false), onBlur: includes(['MANUAL', 'BOOK_SERIES'], this.state.stationaryNature)
                                ? (stationaryNumber) => this.validateBillNumber(stationaryNumber, stationaryId, this.props.formikProps.setFieldError)
                                : () => { }, isRequired: !!stationaryId, label: get(this.props, 'fieldLabel.stationaryNumber', 'Stationary Number'), "data-testid": "stationary-number-field" }), this.state.stationaryNature === 'AUTO' && !this.props.hideStationaryActionButton && (_jsxs("div", { className: "d-flex justify-content-between", style: { marginTop: '-20px' }, children: [_jsx("button", { type: "button", className: "btn btn-link", onClick: () => this.getDeletedStationary({
                                        branchId: branch,
                                        bookName: stationaryId,
                                        bookType,
                                    }), style: { paddingLeft: 0 }, "data-testid": "get-deleted-stationary-button", children: "Get Deleted Stationary" }), _jsx("button", { disabled: isNextStationaryNumberFetching, type: "button", onClick: () => {
                                        this.onStationarySelect(stationaryId, branch, bookType, this.props.formikProps, true);
                                    }, className: "btn btn-link", "data-testid": "refresh-stationary-number-button", children: "Refresh Stationary Number" })] }))] })), _jsx(DeletedStationariesModal, { isOpen: this.state.isDeletedStationariesModalOpen, deletedStationaries: this.state.deletedStationaries, onCancel: () => this.setState({
                        deletedStationaries: [],
                        isDeletedStationariesModalOpen: false,
                    }), onOk: () => {
                        if (this.state.selectedDeletedStationary) {
                            this.props.formikProps.setFieldValue('stationaryNumber', this.state.selectedDeletedStationary);
                        }
                        this.setState({
                            deletedStationaries: [],
                            isDeletedStationariesModalOpen: false,
                        });
                    }, onSelectedDeletedStationary: this.onSelectDeletedStationary, selectedDeletedStationary: this.state.selectedDeletedStationary })] }));
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStationaries,
        getNextStationary,
        validateStationary,
        fetchBranches,
        fetchStationary,
        fetchDeletedStationaries,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillNumberComponent);
