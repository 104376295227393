import { get, map } from 'lodash';
export const CONSIGNMENT_TYPES = {
    PAID: 'PAID',
    TO_PAY: 'TO_PAY',
};
export const CONSIGNMENT_NATURES = {
    PTL: 'PTL',
    FTL: 'FTL', // full truck load
};
export const CONSIGNMENT_STATUS = {
    OPEN: 'OPEN',
    ASSIGNED: 'ASSIGNED',
    INDENTED: 'INDENTED',
    IN_TRANSIT: 'IN_TRANSIT',
    EPOD: 'EPOD',
    BILLED: 'BILLED',
    CLOSED: 'CLOSED',
    DELIVERED: 'DELIVERED',
    EPOD_ACCEPTED: 'EPOD_ACCEPTED',
    EPOD_UPLOADED: 'EPOD_UPLOADED',
    CANCELLED: 'CANCELLED',
    LOADING: 'LOADING',
    SCHEDULED: 'SCHEDULED',
};
export const CN_FORM_TABS = {
    BASIC_DETAILS: {
        name: '1. Basic Details',
        order: 1,
        key: 'BASIC_DETAILS',
        isRequired: true,
    },
    ADVANCE_DETAILS: {
        name: '2. Advance Details',
        order: 2,
        key: 'ADVANCE_DETAILS',
        isRequired: true,
    },
};
export function mappedTmsConsignments(consignments, addressBookAsMap) {
    return map(consignments, (cn) => {
        const sName = get(addressBookAsMap, `${cn.sourceAddressBookId}.name`);
        const dName = get(addressBookAsMap, `${cn.destinationAddressBookId}.name`);
        const consigneeName = get(cn, 'consigneeDetails.name', '');
        return {
            value: cn.id,
            label: `${sName} - ${dName} - ${consigneeName}`,
        };
    });
}
