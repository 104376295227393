// Google Identity Services
export function addGoogleGSIScript(callback) {
    if (typeof window.google?.accounts !== 'undefined') {
        callback(true);
    }
    else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://accounts.google.com/gsi/client');
        s.onload = () => {
            callback(false);
        };
        document.body.appendChild(s);
    }
}
