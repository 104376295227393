import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from '../../../constant';
export const CREATE_ARRIVAL = 'CREATE_ARRIVAL';
export const FETCH_ARRIVALS = 'FETCH_ARRIVALS';
export const FETCH_ARRIVAL = 'FETCH_ARRIVAL';
export const UPDATE_ARRIVAL = 'UPDATE_ARRIVAL';
export const DELETE_ARRIVAL = 'DELETE_ARRIVAL';
export const FETCH_ARRIVAL_PAGINATED = 'FETCH_ARRIVAL_PAGINATED';
export function createArrival(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}arrival-form`, data, config);
    return {
        type: CREATE_ARRIVAL,
        promise: request,
    };
}
export function updateArrival(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ERP}arrival-form`, data, config);
    return {
        type: UPDATE_ARRIVAL,
        promise: request,
    };
}
export function deleteArrival(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.delete(`${ROOT_API_ERP}arrival-form/${id}`, config);
    return {
        type: DELETE_ARRIVAL,
        promise: request,
    };
}
export function fetchArrivals(accesstoken, params = {}) {
    const { branchId, type, routeId, startDate, endDate, vehicleId, isLorryArrival, loadingFrom, loadingTo, reachFrom, reachTo, } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            loadingFrom: loadingFrom,
            loadingTo: loadingTo,
            reachFrom: reachFrom,
            reachTo: reachTo,
            branchId,
            arrivalType: type,
            routeId,
            vehicleId,
            isLorryArrival,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}arrival-form`, config);
    return {
        type: FETCH_ARRIVAL,
        promise: request,
    };
}
export function fetchArrivalsPaginated(params = {}) {
    const { branchId, type, routeId, startDate, endDate, vehicleId, isLorryArrival, loadingFrom, loadingTo, reachFrom, reachTo, page, size = 20, consignmentNumber, refNo, } = params;
    const config = {
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            loadingFrom: loadingFrom,
            loadingTo: loadingTo,
            reachFrom: reachFrom,
            reachTo: reachTo,
            branchId,
            arrivalType: type,
            routeId,
            vehicleId,
            isLorryArrival,
            page: page - 1,
            size,
            consignmentNumber,
            refNo,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}arrival-form/search`, config);
    return {
        type: FETCH_ARRIVAL_PAGINATED,
        promise: request,
    };
}
export function fetchArrival(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_ERP}arrival-form/${id}`, config);
    return {
        type: FETCH_ARRIVAL,
        promise: request,
    };
}
