import { createGlobalStyle } from './utils';
import { COLOR_ERROR, COLOR_PRIMARY, COLOR_PRIMARY_HOVER, COLOR_SUCCESS, COLOR_WARNING } from './colors';
const DefaultCssBaseline = createGlobalStyle `
  html {
    body {

    }
  }
`;
const DEFAULT_THEME = {
    token: {
        zIndexPopupBase: 1050,
        colorPrimary: COLOR_PRIMARY,
        colorLink: COLOR_PRIMARY,
        colorLinkHover: COLOR_PRIMARY_HOVER,
        colorWarning: COLOR_WARNING,
        colorError: COLOR_ERROR,
        colorSuccess: COLOR_SUCCESS,
        borderRadius: 4,
        borderRadiusLG: 4,
        fontFamily: "Inter,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    },
    components: {
        Layout: {
            bodyBg: '#fff',
            siderBg: '#fff',
        },
        Image: {
            colorBgMask: 'rgba(0, 0, 0, 0.8)',
        },
        Dropdown: {
            zIndexPopupBase: 1021,
        },
        Modal: {
            zIndexPopupBase: 1020,
        },
        Alert: {
            colorSuccessBg: '#f6ffed',
            colorSuccessBorder: '#b7eb8f',
        },
    },
};
export default {
    CssBaseline: DefaultCssBaseline,
    theme: DEFAULT_THEME,
};
