import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, filter } from 'lodash';
import { FxSkeleton } from '../../../../shared';
import { FxRow, FxCol } from '../../../../sharedV2';
import { Select, TextArea } from 'sharedV2/FxFormItems';
import { fetchStationaries, getNextStationary, fetchStationary } from 'actions/dashboard/stationary/actions';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { getMappedBookTypes, mappedBranches, mappedStationaries } from '../../../utils/mapping_utils';
import { isViewBranchAllowedForUser, isViewStationaryAllowedForUser } from '../../roles/permission_utils';
const AdjustmentBillComponent = (props) => {
    const { accesstoken, login, formikProps, colClass, isReadyToFetchBillNumber } = props;
    const { branch, creditBookType, debitBookType } = formikProps.values;
    const [allBranches, setAllBranches] = useState([]);
    const [allCreditStationaries, setAllCreditStationaries] = useState([]);
    const [allDebitStationaries, setAllDebitStationaries] = useState([]);
    const [isBranchesLoading, setIsBranchesLoading] = useState(true);
    const [allStationaries, setAllStationaries] = useState([]);
    const fetchAllBranches = async () => {
        if (isViewBranchAllowedForUser(login.scope)) {
            const respBranches = await props.fetchBranches(accesstoken, true);
            const allBranches = get(respBranches, 'payload.data');
            setAllBranches(allBranches);
            setIsBranchesLoading(false);
        }
    };
    useEffect(() => {
        fetchAllBranches();
    }, []);
    const fetchStationariesForBranch = (branch, creditBookType, debitBookType) => {
        if (isViewStationaryAllowedForUser(login.scope)) {
            // Combine credit and debit book types as a single comma-separated value
            const combinedBookTypes = [creditBookType, debitBookType].filter(Boolean).join(',');
            props.fetchStationaries(accesstoken, branch, combinedBookTypes).then((res) => {
                // Split the response payload based on types or assume the response provides sufficient metadata
                const allStationaries = get(res, 'payload.data', []);
                setAllStationaries(allStationaries);
                const allCreditStationaries = allStationaries.filter((stationary) => stationary.bookType === creditBookType);
                const allDebitStationaries = allStationaries.filter((stationary) => stationary.bookType === debitBookType);
                setAllCreditStationaries(allCreditStationaries);
                setAllDebitStationaries(allDebitStationaries);
            });
        }
    };
    const fetchStationaryNumbersForStationary = async (type, val, branch, bookType) => {
        const stationary = filter(allStationaries, (stationary) => stationary.id == val);
        const result = await props.getNextStationary(props.accesstoken, branch, bookType, get(stationary, '[0].bookName'));
        const nextStationaryNumber = get(result, 'payload.data[0].nextStationaryNumber', '');
        if (!nextStationaryNumber) {
            alert('Book name not valid or disabled !!');
        }
        else {
            if (type == 'credit' && bookType && val) {
                formikProps.setFieldValue('creditStationaryNumber', nextStationaryNumber);
            }
            if (type == 'debit' && bookType && val) {
                formikProps.setFieldValue('debitStationaryNumber', nextStationaryNumber);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FxRow, { gutter: 12, children: [isViewBranchAllowedForUser(login.scope) && (_jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: isBranchesLoading || !isReadyToFetchBillNumber ? (_jsx(FxSkeleton, { width: "100%", height: "40px", className: "mt-4", children: _jsx("div", {}) })) : (_jsx(Select, { name: 'branch', options: mappedBranches(allBranches), onChange: (val) => {
                                formikProps.setFieldValue('branch', val);
                                fetchStationariesForBranch(val, creditBookType, debitBookType);
                            }, allowClear: true, placeholder: 'Select Branch', label: 'Select Branch', "data-testid": "branch-field", required: true })) })), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Select, { name: 'creditBookType', options: getMappedBookTypes(), disabled: true, placeholder: 'Credit Book Type', label: 'Credit Book Type', "data-testid": "book-type-field" }) }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Select, { name: 'creditStationaryId', options: mappedStationaries(allCreditStationaries), onChange: (val) => {
                                formikProps.setFieldValue('creditStationaryId', val);
                                fetchStationaryNumbersForStationary('credit', val, branch, creditBookType);
                                formikProps.setFieldValue('creditStationaryNumber', '');
                            }, placeholder: 'Select Credit Book Name', label: 'Select Credit Book Name', "data-testid": "book-name-field", allowClear: true, disabled: branch ? false : true }) }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(TextArea, { name: 'creditStationaryNumber', disabled: true, placeholder: 'Credit Stationary Number', label: 'Credit Stationary Number', "data-testid": "stationary-number-field", rows: 1, style: { resize: 'none' } }) })] }), _jsxs(FxRow, { gutter: 12, children: [_jsx(FxCol, { xs: 24, sm: 12, md: colClass, style: { marginBottom: '8px' } }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Select, { name: 'debitBookType', options: getMappedBookTypes(), disabled: true, placeholder: 'Debit Book Type', label: 'Debit Book Type', "data-testid": "book-type-field" }) }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(Select, { name: 'debitStationaryId', options: mappedStationaries(allDebitStationaries), onChange: (val) => {
                                formikProps.setFieldValue('debitStationaryId', val);
                                fetchStationaryNumbersForStationary('debit', val, branch, debitBookType);
                                formikProps.setFieldValue('debitStationaryNumber', '');
                            }, placeholder: 'Select Debit Book Name', label: 'Select Debit Book Name', "data-testid": "book-name-field", allowClear: true, disabled: branch ? false : true }) }), _jsx(FxCol, { xs: 24, sm: 12, md: colClass, children: _jsx(TextArea, { name: 'debitStationaryNumber', placeholder: 'Debit Stationary Number', label: 'Debit Stationary Number', "data-testid": "stationary-number-field", disabled: true, rows: 1, style: { resize: 'none' } }) })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStationaries,
        getNextStationary,
        fetchBranches,
        fetchStationary,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentBillComponent);
