import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get, groupBy, map, reduce, round, find, isEmpty } from 'lodash';
import { FxDrawer, FxRow, FxCol, FxDescriptions, FxButton, FxSpin, FxEmpty, FxTypography, FxFlex } from 'sharedV2';
import { DATE_FORMAT_TIMESTAMP, getMomentTime } from 'utils/date_utils';
import { isInternationalAccount } from 'utils/account_utils';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { useMemo, useEffect, useState } from 'react';
import { isUnapproveVoucherAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { bulkApproveVouchers, fetchVoucher } from 'actions/dashboard/vouchers/actions';
import { bindActionCreators } from 'redux';
import { handleError } from 'constant';
import { Link } from 'react-router-dom';
import { isCreateVoucherAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { fetchDivisions } from 'actions/dashboard/consignments/divisionActions';
import { fetchLedgers } from 'actions/dashboard/consignments/ledgersActions';
import { fetchUserListMini } from 'actions/dashboard/users_action';
// Presentation component
const VoucherDetails = (props) => {
    const { onClose, voucherId, voucherData, accountData, ledgers, branches, divisions, scope, accesstoken, bulkApproveVouchers, onSuccess, userListMiniAsMap, } = props;
    const isCurrencyConversionApplicable = isInternationalAccount() && get(accountData, 'multiCurrency', false);
    const branchName = useMemo(() => {
        const branchId = get(voucherData, 'branchId');
        if (!branchId)
            return '-';
        const branch = find(branches, { id: branchId });
        return get(branch, 'officeName', '-');
    }, [voucherData.branchId, branches]);
    const divisionName = useMemo(() => {
        const divisionId = get(voucherData, 'divisionId');
        if (!divisionId)
            return '-';
        const division = find(divisions, { id: divisionId });
        return get(division, 'name', '-');
    }, [voucherData.divisionId, divisions]);
    const getLedgerName = useMemo(() => {
        return (ledgerId) => {
            if (!ledgerId)
                return '-';
            const ledger = find(ledgers, { id: ledgerId });
            return get(ledger, 'ledgerName', '-');
        };
    }, [ledgers]);
    const getTotalDebitAndCreditAmount = (entries) => {
        const groupedAmount = groupBy(entries, 'entryType');
        const totalDebitAmount = reduce(get(groupedAmount, 'DR'), (sum, v) => +get(v, 'amount', 0) + sum, 0);
        const totalCreditAmount = reduce(get(groupedAmount, 'CR'), (sum, v) => +get(v, 'amount', 0) + sum, 0);
        return {
            totalDebitAmount: round(totalDebitAmount, 2),
            totalCreditAmount: round(totalCreditAmount, 2),
        };
    };
    const columns = useMemo(() => {
        const baseColumns = [
            {
                title: 'Type',
                dataIndex: 'entryType',
                key: 'entryType',
                render: (text) => (text === 'DR' ? 'Debit' : 'Credit'),
            },
            {
                title: 'Ledger',
                dataIndex: 'ledgerName',
                key: 'ledgerName',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                align: 'right',
            },
        ];
        if (isCurrencyConversionApplicable) {
            baseColumns.push({
                title: 'Currency',
                dataIndex: 'currencyCode',
                key: 'currencyCode',
            });
        }
        return baseColumns;
    }, [isCurrencyConversionApplicable]);
    const renderDebitCreditEntries = () => {
        const debitEntries = get(voucherData, 'debitEntries', []);
        const creditEntries = get(voucherData, 'creditEntries', []);
        const allEntries = [
            ...map(debitEntries, (entry) => ({
                ...entry,
                entryType: 'DR',
                ledgerName: getLedgerName(entry.ledgerId),
            })),
            ...map(creditEntries, (entry) => ({
                ...entry,
                entryType: 'CR',
                ledgerName: getLedgerName(entry.ledgerId),
            })),
        ];
        const { totalDebitAmount, totalCreditAmount } = getTotalDebitAndCreditAmount(allEntries);
        const summary = () => (_jsx(Table.Summary, { fixed: true, children: _jsxs(Table.Summary.Row, { className: "font-weight-bold", children: [_jsx(Table.Summary.Cell, { index: 0, colSpan: 2, children: "Total" }), _jsxs(Table.Summary.Cell, { index: 1, align: "right", children: [_jsxs("div", { children: ["DR: ", totalDebitAmount] }), _jsxs("div", { children: ["CR: ", totalCreditAmount] })] }), isCurrencyConversionApplicable && _jsx(Table.Summary.Cell, { index: 2 })] }) }));
        return (_jsxs(_Fragment, { children: [_jsx("h6", { className: "mb-3", children: "Debit & Credit Entries" }), _jsx(Table, { columns: columns, dataSource: allEntries, pagination: false, summary: summary, rowKey: (record, index) => `${record.entryType}-${index}`, size: "small", bordered: true })] }));
    };
    const getUserName = (userId, date) => {
        if (!userId)
            return '-';
        const user = get(userListMiniAsMap, userId, []);
        const name = `${get(user, '[1]', '')} ${get(user, '[2]', '')}`.trim() || '-';
        if (!date)
            return name;
        return (_jsxs("div", { children: [_jsx("div", { children: name }), _jsx("div", { className: "text-muted font-sm", children: getMomentTime(date).format(DATE_FORMAT_TIMESTAMP) })] }));
    };
    const items = [
        {
            key: 'voucherNumber',
            label: 'Voucher Number',
            children: get(voucherData, 'voucherNumber', '').split('#')[0],
            span: 2,
            className: 'font-weight-bold',
        },
        {
            key: 'voucherType',
            label: 'Voucher Type',
            children: get(voucherData, 'voucherType', ''),
        },
        {
            key: 'date',
            label: 'Date',
            children: getMomentTime(get(voucherData, 'date')).format(DATE_FORMAT_TIMESTAMP),
        },
        {
            key: 'office',
            label: 'Office',
            children: branchName,
        },
        {
            key: 'division',
            label: 'Division',
            children: divisionName,
        },
        ...(isCurrencyConversionApplicable
            ? [
                {
                    key: 'currency',
                    label: 'Currency',
                    children: get(voucherData, 'currencyCode', ''),
                },
            ]
            : []),
        {
            key: 'createdBy',
            label: 'Created By',
            children: getUserName(get(voucherData, 'createdBy'), get(voucherData, 'createdDate')),
        },
        {
            key: 'lastModifiedBy',
            label: 'Last Modified By',
            children: getUserName(get(voucherData, 'lastModifiedBy'), get(voucherData, 'lastModifiedDate')),
        },
        {
            key: 'narration',
            label: 'Narration',
            children: get(voucherData, 'narration', ''),
            span: 2,
        },
    ];
    const handleApprove = () => {
        if (!voucherId)
            return;
        bulkApproveVouchers(accesstoken, voucherId)
            .then((res) => {
            if (!res.error) {
                alert('Voucher Approved!');
                onClose();
                if (onSuccess) {
                    onSuccess();
                }
            }
            else {
                throw new Error(handleError(res.payload.response));
            }
        })
            .catch((error) => {
            alert(handleError(error));
        });
    };
    const isApproved = get(voucherData, 'isApproved', false);
    const canApproveVoucher = isUnapproveVoucherAllowedForUser(scope);
    return (_jsxs(FxFlex, { vertical: true, gap: 32, children: [_jsx(FxRow, { children: _jsx(FxCol, { span: 24, children: _jsx(FxDescriptions, { items: items, column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }) }) }), _jsx(FxRow, { children: _jsx(FxCol, { span: 24, children: renderDebitCreditEntries() }) }), _jsxs(FxRow, { justify: "space-between", align: "middle", children: [_jsxs(FxCol, { children: [_jsx(FxTypography.Text, { type: "secondary", children: "Status:" }), ' ', get(voucherData, 'isApproved') ? (_jsx("span", { className: "text-success", children: "Approved" })) : (_jsx("span", { className: "text-warning", children: "Pending Approval" }))] }), _jsx(FxCol, { children: !isApproved && canApproveVoucher && (_jsx(FxButton, { type: "primary", onClick: handleApprove, children: "Approve Voucher" })) })] })] }));
};
// Container component
const ViewVoucherDrawerComponent = (props) => {
    const { accesstoken, visible, onClose, voucherId, branches, divisions, ledgers, userListMiniAsMap } = props;
    const canEditVoucher = isCreateVoucherAllowedForUser(props.scope);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [voucherData, setVoucherData] = useState({});
    const fetchVoucherData = () => {
        if (!voucherId)
            return;
        setLoading(true);
        setError(null);
        props
            .fetchVoucher(accesstoken, voucherId)
            .then((res) => {
            if (res.error) {
                throw new Error(handleError(res.payload.response));
            }
            setVoucherData(get(res, 'payload.data', {}));
        })
            .catch((err) => {
            setError(handleError(err));
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (visible && voucherId) {
            fetchVoucherData();
        }
        else {
            setVoucherData({});
            setError(null);
        }
    }, [visible, voucherId, props.accesstoken]);
    useEffect(() => {
        if (isEmpty(branches)) {
            props.fetchBranches(accesstoken);
        }
    }, [branches]);
    useEffect(() => {
        if (isEmpty(divisions)) {
            props.fetchDivisions(accesstoken);
        }
    }, [divisions]);
    useEffect(() => {
        if (isEmpty(ledgers)) {
            props.fetchLedgers(accesstoken);
        }
    }, [ledgers]);
    useEffect(() => {
        if (isEmpty(userListMiniAsMap)) {
            props.fetchUserListMini(accesstoken);
        }
    }, [userListMiniAsMap]);
    const handleRetry = () => {
        fetchVoucherData();
    };
    const renderContent = () => {
        if (loading) {
            return (_jsx("div", { className: "text-center p-5", children: _jsx(FxSpin, {}) }));
        }
        if (error) {
            return (_jsx("div", { className: "text-center p-5", children: _jsx(FxEmpty, { description: _jsxs("div", { children: [_jsx("div", { className: "text-danger mb-3", children: error }), _jsx(FxButton, { type: "primary", onClick: handleRetry, children: "Try Again" })] }) }) }));
        }
        return _jsx(VoucherDetails, { ...props, voucherData: voucherData });
    };
    const extra = useMemo(() => {
        if (!canEditVoucher || !voucherId)
            return null;
        return (_jsx(Link, { to: `/dashboard/voucher/edit/${voucherId}`, target: "_blank", children: _jsxs(FxButton, { children: [_jsx("i", { className: "fa fa-edit mr-1" }), "Edit"] }) }));
    }, [voucherId, canEditVoucher]);
    return (_jsx(FxDrawer, { open: visible, onClose: onClose, title: "View Voucher Details", width: 800, destroyOnClose: true, extra: extra, children: renderContent() }));
};
function mapStateToProps(state) {
    return {
        accountData: get(state, 'settings.account'),
        ledgers: get(state, 'consignments.ledgers', []),
        branches: get(state, 'branch.branches', []),
        divisions: get(state, 'consignments.divisions', []),
        scope: get(state, 'login.data.scope', []),
        accesstoken: get(state, 'login.data.access_token'),
        userListMiniAsMap: get(state, 'users.userListMiniAsMap'),
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        bulkApproveVouchers,
        fetchVoucher,
        fetchBranches,
        fetchDivisions,
        fetchLedgers,
        fetchUserListMini,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewVoucherDrawerComponent);
