import { jsx as _jsx } from "react/jsx-runtime";
import { getAllWordsCapitalized } from '../../../utils/string_utils';
import { showTagNameInsteadOfJointNameForAcnt } from '../../../utils/account_utils';
import FxTag from 'sharedV2/FxTag';
export default (props) => {
    const { tag, loggedInUser, ...prop } = props;
    const showTagName = showTagNameInsteadOfJointNameForAcnt(loggedInUser);
    if (!tag) {
        return null;
    }
    return (_jsx(FxTag, { className: "tag-style", title: tag.jointName, ...prop, children: getAllWordsCapitalized(showTagName ? tag.name : tag.jointName) }));
};
export const ShowTyreTag = ({ tag }) => {
    return (_jsx(FxTag, { className: "tag-style", title: tag.jointName, children: getAllWordsCapitalized(tag.name) }));
};
