import axios from 'axios';
import { parseQueryParams, ROOT_API_ERP } from 'constant';
export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_ALL_BILL_NUMBERS = 'FETCH_ALL_BILL_NUMBERS';
export const CREATE_ADJUSTMENT_NOTES = 'CREATE_ADJUSTMENT_NOTES';
export const CREATE_BULK_PAYMENTS = 'CREATE_BULK_PAYMENTS';
export const FETCH_ADJUSTMENT_NOTES = 'FETCH_ADJUSTMENT_NOTES';
export const FETCH_CUSTOMER_OVERVIEW = 'FETCH_CUSTOMER_OVERVIEW';
export const DELETE_CREDIT_NOTE_PAYMENT = 'DELETE_CREDIT_NOTE_PAYMENT';
export const EDIT_PAYMENT = 'EDIT_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const FETCH_BILLS_BY_NATURE = 'FETCH_BILLS_BY_NATURE';
export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const FETCH_CREDIT_NOTE = 'FETCH_CREDIT_NOTE';
export const CREATE_BULK_BILLS_SETTLEMENT = 'CREATE_BULK_BILLS_SETTLEMENT';
// New payment form actions
export function fetchPayments(accesstoken, params = {}) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, allAmountApplied, page, size, } = params;
    const config = {
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            customerIds: creditAccountId,
            referenceNumber: voucherNumber,
            unsettledOnAccount,
            allAmountApplied,
            page,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}settlement/payment`, config);
    return {
        type: FETCH_PAYMENTS,
        promise: request,
    };
}
export function fetchBillNumbers(accesstoken, customerName, startDate, endDate) {
    const config = {
        params: parseQueryParams({
            customerName,
            startDate,
            endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}billnumbers`, config);
    return {
        type: FETCH_ALL_BILL_NUMBERS,
        promise: request,
    };
}
export function createAdjustmentNotes(accesstoken, data) {
    const request = axios.post(`${ROOT_API_ERP}adjustment-note`, data);
    return {
        type: CREATE_ADJUSTMENT_NOTES,
        promise: request,
    };
}
export function createBulkPayments(accesstoken, data) {
    const request = axios.post(`${ROOT_API_ERP}payment/bulk`, data);
    return {
        type: CREATE_BULK_PAYMENTS,
        promise: request,
    };
}
export function fetchAdjustmentNotes(accesstoken, params = {}) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, creditNote, page, size, unsettledVoucher, withoutReference, } = params;
    const config = {
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            customerIds: creditAccountId,
            referenceNumber: voucherNumber,
            unsettledOnAccount,
            creditNote,
            page,
            size,
            unsettledVoucher,
            withoutReference,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}adjustment-note`, config);
    return {
        type: FETCH_ADJUSTMENT_NOTES,
        promise: request,
    };
}
export function fetchCustomerOverview(accesstoken, params) {
    const { startDate, endDate, branchId, paymentNature, adviceNumber, creditAccountId, voucherNumber, unsettledOnAccount, isCreditNote, page, size, } = params;
    const config = {
        params: parseQueryParams({
            fromVoucherDate: startDate,
            toVoucherDate: endDate,
            branchId,
            paymentNature,
            adviceNumber,
            ids: creditAccountId,
            voucherNumber,
            unsettledOnAccount,
            isCreditNote,
            page,
            size,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}settlement/customer-overview`, config);
    return {
        type: FETCH_CUSTOMER_OVERVIEW,
        promise: request,
    };
}
export function deleteCreditNoteAdjustment(accesstoken, id) {
    const request = axios.delete(`${ROOT_API_ERP}settlement/credit-note/${id}`);
    return {
        type: DELETE_CREDIT_NOTE_PAYMENT,
        promise: request,
    };
}
export function editPayment(accesstoken, values) {
    const request = axios.patch(`${ROOT_API_ERP}payment/receipt/${values.id}`, values);
    return {
        type: EDIT_PAYMENT,
        promise: request,
    };
}
export function deletePayment(accesstoken, id) {
    const request = axios.delete(`${ROOT_API_ERP}settlement/payment/${id}`);
    return {
        type: DELETE_PAYMENT,
        promise: request,
    };
}
export function fetchPaymentById(accesstoken, id) {
    const request = axios.get(`${ROOT_API_ERP}settlement/payment/${id}`);
    return {
        type: FETCH_PAYMENT,
        promise: request,
    };
}
export function fetchBillsByNature(params) {
    const { startDate, endDate, branchId, type, routeId, vehicleId, hasArrived, deliveryType, size, page = 1, billingPartyId, consignmentId, billNumber, billingNature, serviceType, stateOfSupply, paymentStatus, divisionId, advanceAmountPaid, unsettledVoucher, billMappingType, billTypeId, sortOrder, sortName, targetCurrency, ids, } = params;
    let sort = '';
    if (sortName) {
        sort = `${sortName}`;
    }
    if (sortName && sortOrder) {
        sort += `,${sortOrder}`;
    }
    const config = {
        params: parseQueryParams({
            from: startDate,
            to: endDate,
            billingOfficeId: branchId,
            billingPartyId,
            consignmentId,
            type,
            routeId,
            vehicleId,
            hasArrived,
            deliveryType,
            size,
            page: page - 1,
            billNumber,
            billingNature,
            serviceType,
            stateOfSupply,
            paymentStatus,
            divisionId,
            advanceAmountPaid,
            unsettledVoucher,
            billMappingType,
            billTypeId,
            sort,
            targetCurrency,
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_ERP}consignment/freight-bill`, config);
    return {
        type: FETCH_BILLS_BY_NATURE,
        promise: request,
    };
}
export function fetchCreditNoteById(accesstoken, id) {
    const request = axios.get(`${ROOT_API_ERP}settlement/credit-note/${id}`);
    return {
        type: FETCH_CREDIT_NOTE,
        promise: request,
    };
}
export function createBulkBillsSettlement(accesstoken, data) {
    const request = axios.post(`${ROOT_API_ERP}settlement`, data);
    return {
        type: CREATE_BULK_BILLS_SETTLEMENT,
        promise: request,
    };
}
