import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const VehicleStearing = (props) => {
    return (_jsxs("svg", { width: "19", height: "18", viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "mask0_735_6766", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "19", height: "18", children: _jsx("rect", { x: "0.25", width: "18", height: "18", fill: "#D9D9D9" }) }), _jsx("g", { mask: "url(#mask0_735_6766)", children: _jsx("path", { d: "M9.25113 16.125C8.26563 16.125 7.33932 15.938 6.47219 15.564C5.60507 15.19 4.85082 14.6824 4.20944 14.0413C3.56807 13.4001 3.06025 12.6462 2.686 11.7795C2.31188 10.9127 2.12482 9.98664 2.12482 9.00127C2.12482 8.01577 2.31182 7.08945 2.68582 6.22233C3.05982 5.3552 3.56738 4.60095 4.2085 3.95958C4.84963 3.3182 5.60357 2.81039 6.47032 2.43614C7.33707 2.06202 8.26313 1.87495 9.2485 1.87495C10.234 1.87495 11.1603 2.06195 12.0274 2.43595C12.8946 2.80995 13.6488 3.31752 14.2902 3.95864C14.9316 4.59977 15.4394 5.3537 15.8136 6.22045C16.1878 7.0872 16.3748 8.01327 16.3748 8.99864C16.3748 9.98414 16.1878 10.9105 15.8138 11.7776C15.4398 12.6447 14.9323 13.399 14.2911 14.0403C13.65 14.6817 12.8961 15.1895 12.0293 15.5638C11.1626 15.9379 10.2365 16.125 9.25113 16.125ZM8.68732 14.9394V12.3288C7.98057 12.1836 7.37913 11.8629 6.883 11.3668C6.38688 10.8706 6.06619 10.2692 5.92094 9.56245H3.31038C3.4315 10.9971 3.99932 12.2135 5.01382 13.2116C6.02819 14.2096 7.25269 14.7855 8.68732 14.9394ZM9.81232 14.9394C11.2469 14.8086 12.4714 14.2385 13.4858 13.2288C14.5003 12.2192 15.0681 10.9971 15.1893 9.56245H12.5787C12.4334 10.2692 12.1128 10.8706 11.6166 11.3668C11.1205 11.8629 10.5191 12.1836 9.81232 12.3288V14.9394ZM3.31038 8.43745H5.87482L7.90844 6.37495H10.5912L12.6248 8.43745H15.1893C15.045 6.88645 14.4056 5.59245 13.2709 4.55545C12.1363 3.51845 10.7959 2.99995 9.24982 2.99995C7.70369 2.99995 6.36332 3.51845 5.22869 4.55545C4.09407 5.59245 3.45463 6.88645 3.31038 8.43745Z", fill: "#7E2393" }) })] }));
};
const VehicleStearingIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: VehicleStearing, ...props });
};
export default VehicleStearing;
