import { Component } from 'react';
import { change, Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
    DOCUMENT_TYPE,
    getAddress,
    handleError,
    SERVICE_ENTRY_TYPES,
    SERVICE_ENTRY_TYPES_LABEL,
    TAX_TYPES_V2,
} from '../../../../constant';
import SelectInput from '../../../utils/SelectInput';

import {
    fetchVehicleById,
    fetchVehicleListMini,
    fetchDataFromModulesForVehicle,
} from '../../../../actions/dashboard/vehicles_action';
import CalendarSingleDatePicker from '../../../utils/calendar_single_date_picker';
import { getEndDate, getMomentTime } from '../../../../utils/date_utils';
import { fetchVendors } from '../../../../actions/dashboard/vendors_action';
import { fetchServiceTasks } from '../../../../actions/dashboard/easy/service_tasks_action';
import {
    createServiceEntry,
    fetchServiceEntry,
    updateServiceEntry,
} from '../../../../actions/dashboard/easy/service_entry_action';

import ServiceLineItem from './service_line_item';
import { filter, find, forEach, get, gt, map, omit, set, toNumber, includes, round, upperFirst, noop } from 'lodash';
import AddNewVendorComponent from '../../vendors/add_new_vendor_component';
import moment from 'moment-timezone';
import {
    isMangalamCement,
    isOdometerNonMandatoryAndHiddenInServiceEntries,
    showRemoveVehiclesInSearchForAccount,
} from '../../../../utils/account_utils';
import {
    mappedVehiclesMini,
    mappedVendors,
    renderTextField,
    renderTextFieldStackedLabel,
} from '../../../../utils/form_utils';
import { searchIssues } from '../../../../actions/dashboard/issues_action';
import {
    isCreateServiceEntryAllowedForUser,
    isUpdateServiceEntryAllowedForUser,
    isViewIssuesAllowedForUser,
    isViewLedgersAllowedForUser,
    isViewStationaryAllowedForUser,
    isViewBranchAllowedForUser,
    isViewLedgersGroupsAllowedForUser,
    isViewPartsAllowedForUser,
} from '../../roles/permission_utils';
import { ISSUES_STATUS } from '../../../../utils/issues_utils';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
import { fetchParts } from '../../../../actions/dashboard/parts_inventory/parts_action';
import { mappedBranches, mappedStationaries, mappedParts, mappedOptionsFromArray } from '../../../utils/mapping_utils';
import { fetchBranches } from '../../../../actions/dashboard/branch/actions';
import {
    fetchStationaries,
    fetchStationary,
    getNextStationary,
} from '../../../../actions/dashboard/stationary/actions';
import { fetchLedgers } from '../../../../actions/dashboard/consignments/ledgersActions';
import { fetchLedgersGroups } from '../../../../actions/dashboard/consignments/ledgerGroupActions';
import { UNIT_TYPE_TO_UNITS_MAP } from '../../../../utils/rateChartsUtils';
import { CUSTOM_ACCOUNT_FIELD_TYPE } from '../../../../utils/custom_field_utils';
import { fetchCustomFieldsForAccount } from '../../../../actions/dashboard/custom_field_actions';
import { FxSelectFieldRedux } from '../../../../shared';
import { withRoute } from '../../customHocs/withRoute';
import CalendarComponent from '../../../utils/calendar_component';
import { fetchJobListNewV2 } from 'actions/dashboard/route_dispatch/jobs_action';
import FxMultiFileDropzoneComponent from 'sharedV2/fileUpload/FxMultiFileDropzoneComponent';

const selector = formValueSelector('AddEditServiceEntryForm');

const getFieldName = (fieldPrefix, index, name) => {
    return `${fieldPrefix}.${index}.${name}`;
};

const getNameDropDownObject = (v) => {
    const finalArray = [];

    for (let i = 0; i < v.length; i++) {
        finalArray.push({
            value: v[i].answer,
            label: v[i].answer,
        });
    }
    return finalArray;
};

class AddEditServiceEntryComponent extends Component {
    mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            id: 0,
            date: null,
            startDate: null,
            subtotal: 0,
            submitError: null,
            uploadedDocuments: [],
            uploadedImages: [],
            newUploadedDocuments: [],
            newUploadedImages: [],
            vehicleError: null,
            serviceTaskErrors: [],
            partItemsError: [],
            lineItemError: null,
            fileUploadError: null,
            partOptions: [],
            branches: [],
            stationaryList: [],
            allMandatoryCustomFields: [],
            workOrderOptions: [],
            dateRange: {
                startDate: getMomentTime().subtract(6, 'days').startOf('day'),
                endDate: getEndDate(),
            },
            jobListOptions: [],
        };
        this.onDateSelected = this.onDateSelected.bind(this);
        this.onStartDateSelected = this.onStartDateSelected.bind(this);
        this.fileImageSuccessCallback = this.fileImageSuccessCallback.bind(this);
        this.fileImageDeleteCallback = this.fileImageDeleteCallback.bind(this);
        this.fileDocumentSuccessCallback = this.fileDocumentSuccessCallback.bind(this);
        this.fileDocumentDeleteCallback = this.fileDocumentDeleteCallback.bind(this);
    }

    onDateSelect = (startDate, endDate) => {
        this.setState({ dateRange: { startDate, endDate } });
        this.fetchJobList(this.state.vehicleId, { startDate, endDate });
    };

    fetchJobList = async (vehicleId, dateRange, jobListOptions = null) => {
        if (jobListOptions) {
            this.setState({ jobListOptions: [jobListOptions] });
        }

        try {
            const result = await this.props.fetchJobListNewV2(this.props.accesstoken, {
                vehicleId: vehicleId,
                from: dateRange.startDate,
                to: dateRange.endDate,
                page: 1,
                size: 10000,
            });
            let mapped = map(get(result, 'payload.data.content', []), (job) => {
                return {
                    value: job.id,
                    label: job.name,
                };
            });
            if (jobListOptions) {
                mapped.push(jobListOptions);
            }
            this.setState({ jobListOptions: mapped });
        } catch (e) {
            this.setState({ jobListOptions: [] });
        }
    };

    fileImageSuccessCallback(fileList) {
        this.setState((prevState) => ({
            newUploadedImages: fileList,
        }));
    }

    fileImageDeleteCallback(fileList, deletedFile) {
        this.setState({ newUploadedImages: fileList });
    }

    fileDocumentSuccessCallback(fileList) {
        this.setState((prevState) => ({
            newUploadedDocuments: fileList,
        }));
    }

    fileDocumentDeleteCallback(fileList, deletedFile) {
        var uploadedDocuments = this.state.uploadedDocuments;
        uploadedDocuments = filter(uploadedDocuments, (item) => item.uid !== deletedFile.uid);
        this.setState({ uploadedDocuments: uploadedDocuments });
    }

    goBack = () => {
        this.props.navigate(-1);
    };

    shouldShowTax2Type(tax1Type) {
        return includes(['SGST', 'CGST'], tax1Type);
    }

    componentDidMount() {
        this.mounted = true;
        if (isViewPartsAllowedForUser(this.props.login.scope)) {
            this.props.fetchParts(this.props.accesstoken).then((result) => {
                this.setState({
                    partOptions: mappedParts(get(result, 'payload.data', [])),
                });
            });
        }
        if (isViewBranchAllowedForUser(this.props.login.scope)) {
            this.props.fetchBranches(this.props.accesstoken).then((res) => {
                this.setState({
                    branches: get(res, 'payload.data'),
                });
            });
        }
        const { id, vehicleId } = this.props.params;
        if (!this.props.vehicle && vehicleId) {
            this.props.fetchVehicleById(vehicleId, this.props.accesstoken);
            this.fetchWorkOrders(vehicleId);
        } else if (!vehicleId) {
            this.props.fetchVehicleListMini(
                this.props.accesstoken,
                showRemoveVehiclesInSearchForAccount(this.props.loggedInUser)
            );
        }
        this.props.fetchVendors(this.props.accesstoken);
        if (isViewLedgersGroupsAllowedForUser(this.props.login.scope)) {
            this.props.fetchLedgersGroups(this.props.accesstoken);
        }
        if (isViewLedgersAllowedForUser(this.props.login.scope)) {
            this.props.fetchLedgers(this.props.accesstoken);
        }
        this.props.fetchServiceTasks(this.props.accesstoken);
        if (id) {
            this.setState({
                editable: true,
                id: id,
            });
            this.fetchAllCustomFields(true);

            return this.props.fetchServiceEntry(id, this.props.accesstoken).then((result) => {
                if (!this.mounted) {
                    return;
                }
                if (result.error) {
                    this.props.navigate('/dashboard');
                } else {
                    const serviceEntry = result.payload.data;
                    const uploadedDocuments = [];
                    const uploadedImages = [];

                    map(serviceEntry.serviceEntryImages, (o) => {
                        if (o.documentType === DOCUMENT_TYPE.IMAGE) {
                            uploadedImages.push({ id: o.id, uploadedUrl: o.url });
                        } else {
                            uploadedDocuments.push({ id: o.id, uploadedUrl: o.url });
                        }
                        return o;
                    });
                    if (get(serviceEntry, 'workOrderId')) {
                        this.setState({
                            workOrderOptions: [
                                {
                                    label: get(serviceEntry, 'workOrderNumber'),
                                    value: get(serviceEntry, 'workOrderId'),
                                    stationaryNumber: get(serviceEntry, 'workOrderNumber'),
                                },
                            ],
                        });
                    } else if (get(serviceEntry, 'vehicleId') && !(!this.props.vehicle && vehicleId)) {
                        let jobListOptions = null;
                        if (get(serviceEntry, 'jobId')) {
                            jobListOptions = {
                                label: get(serviceEntry, 'jobName'),
                                value: get(serviceEntry, 'jobId'),
                            };
                        }
                        this.fetchWorkOrders(serviceEntry.vehicleId, jobListOptions);
                    }

                    this.setState(
                        {
                            date: moment(serviceEntry.date),
                            startDate: serviceEntry.startDate ? moment(serviceEntry.startDate) : null,
                            uploadedDocuments: uploadedDocuments,
                            uploadedImages: uploadedImages,
                            customFieldForEdit: get(serviceEntry, 'customFields', []),
                        },
                        () => this.fetchAllCustomFields(true)
                    );
                    if (serviceEntry.startDate) {
                        this.props.change('setStartDate', true);
                    }
                }
            });
        }
        if (!id) {
            this.fetchAllCustomFields(false);
        }

        this.fetchIssuesList(vehicleId, id);
    }

    fetchIssuesList(vehicleId, editId = this.state.id) {
        const scope = get(this.props.login, 'scope');
        if (!vehicleId || !isViewIssuesAllowedForUser(scope)) {
            return;
        }
        this.props.searchIssues(this.props.accesstoken, {
            status: editId ? null : ISSUES_STATUS.OPEN,
            vehicleId,
            groupId: this.props.defaultGroupId,
        });
    }

    renderTextArea(field) {
        const { input, rows = 3 } = field;

        return (
            <div>
                <textarea
                    placeholder="Notes Here"
                    rows={rows ? rows : 3}
                    style={{ width: '100%' }}
                    name="notes"
                    {...input}
                />
            </div>
        );
    }

    componentDidUpdate(prevProps, props) {
        if (get(prevProps.vehicle, 'id') !== get(this.props.vehicle, 'id')) {
            this.fetchIssuesList(get(this.props.vehicle, 'id'));
        }
        if (
            this.props.hasServiceLineItems !== prevProps.hasServiceLineItems ||
            this.props.discount !== prevProps.discount ||
            this.props.discountPercentage !== prevProps.discountPercentage ||
            this.props.laborTax1 !== prevProps.laborTax1 ||
            this.props.laborTax1Percentage !== prevProps.laborTax1Percentage ||
            this.props.laborTax2 !== prevProps.laborTax2 ||
            this.props.laborTax2Percentage !== prevProps.laborTax2Percentage
        ) {
            let total = 0;
            let totalLaborCost = 0;
            let subTotal = 0;
            let totalPartsCost = 0;

            forEach(this.props.hasServiceLineItems, function (value) {
                if (value.subTotal) {
                    total += value.subTotal;
                }
                if (value.laborCost) {
                    totalLaborCost += toNumber(value.laborCost);
                }
                if (value.subTotal) {
                    subTotal += value.subTotal;
                }
                if (value.serviceParts?.length) {
                    totalPartsCost += value.serviceParts.reduce((sum, item) => {
                        return sum + Number(item.cost || 0);
                    }, 0);
                }
            });
            subTotal = round(subTotal, 2);
            totalPartsCost = round(totalPartsCost, 2);
            this.setState({
                subtotal: subTotal,
            });
            this.props.dispatch(change('AddEditServiceEntryForm', `partsSubtotal`, totalPartsCost));
            this.props.dispatch(change('AddEditServiceEntryForm', `laborSubtotal`, totalLaborCost));
            if (total > 0) {
                if (this.props.discountSelect && this.props.discountSelect !== '%') {
                    if (this.props.discount) {
                        total -= toNumber(this.props.discount);
                    }
                } else if (!this.props.discountSelect || this.props.discountSelect === '%') {
                    if (this.props.discountPercentage) {
                        total -= toNumber(this.props.discountPercentage) * 0.01 * total;
                    }
                }

                let laborTax1 = 0,
                    laborTax2 = 0;

                if (this.props.taxSelect && this.props.taxSelect !== '%') {
                    if (this.props.laborTax1) {
                        laborTax1 = toNumber(this.props.laborTax1);
                    }
                } else if (!this.props.taxSelect || this.props.taxSelect === '%') {
                    if (this.props.laborTax1Percentage) {
                        laborTax1 = toNumber(this.props.laborTax1Percentage) * 0.01 * totalLaborCost;
                    }
                }
                if (this.props.tax2Select && this.props.tax2Select !== '%') {
                    if (this.props.laborTax2) {
                        laborTax2 = toNumber(this.props.laborTax2);
                    }
                } else if (!this.props.tax2Select || this.props.tax2Select === '%') {
                    if (this.props.laborTax2Percentage) {
                        laborTax2 = toNumber(this.props.laborTax2Percentage) * 0.01 * totalLaborCost;
                    }
                }

                total += laborTax1;
                total += laborTax2;
            }
            this.props.dispatch(change('AddEditServiceEntryForm', `totalAmount`, round(total, 2)));
            if (this.props.stationaryId !== prevProps.stationaryId || this.props.branchId !== prevProps.branchId) {
                const stationaryId = this.props.stationaryId;
                if (stationaryId && isViewBranchAllowedForUser(this.props.login.scope)) {
                    this.props.fetchStationary(this.props.accesstoken, stationaryId).then((result) => {
                        const branchId = get(result, 'payload.data.branch.id');
                        if (branchId) {
                            this.props.change('branchId', branchId);
                            this.fetchStationaryData(branchId);
                        }
                    });
                }
            }
        }
    }

    onSuccessfulVendorCreation = () => {
        this.props.fetchVendors(this.props.accesstoken);
    };

    renderField(field) {
        const {
            input,
            name,
            type,
            label,
            isRequired,
            help,
            disableOnEdit,
            min,
            step,
            meta: { touched, error },
        } = field;
        const className = `form-group row ${isRequired && touched && error ? 'has-danger' : ''}`;
        const labelClass = `${isRequired && touched && error ? 'text-danger' : ''}`;

        return (
            <div className={className}>
                <div className="col-md-3">
                    <label htmlFor={name} className={labelClass}>
                        {label}
                        {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
                <div className="col-md-9">
                    {min && (
                        <input
                            type={type}
                            className="form-control "
                            {...(step ? { step: step } : {})}
                            min={min}
                            {...input}
                            disabled={disableOnEdit}
                            onWheel={(e) => e.currentTarget.blur()}
                        />
                    )}
                    {!min && (
                        <input
                            type={type}
                            className="form-control "
                            {...(step ? { step: step } : {})}
                            {...input}
                            disabled={disableOnEdit}
                            onWheel={(e) => e.currentTarget.blur()}
                        />
                    )}
                    {error && <div className="text-danger">{touched ? error : '  '}</div>}
                    {!error && help && <div className="text-muted">{help}</div>}
                </div>
            </div>
        );
    }

    renderCheckBoxField(field) {
        const { input, name, label, colclass, infoTooltipText } = field;
        const className = `form-group ${colclass ? colclass : ''}`;

        return (
            <div className={className}>
                <div className="checkbox">
                    <label htmlFor={name}>
                        <input type="checkbox" {...input} />
                        {label}
                        {infoTooltipText && <InfoToolTip text={infoTooltipText} />}
                    </label>
                </div>
            </div>
        );
    }

    renderVendorOption(option) {
        return (
            <div>
                <div>{option.label}</div>
                {option.address && <div>{getAddress(option.address)}</div>}
            </div>
        );
    }

    onSubmit(values) {
        const {
            newUploadedDocuments,
            newUploadedImages,
            uploadedDocuments,
            uploadedImages,
            date,
            startDate,
        } = this.state;
        let _serviceTaskErrors = [];
        let _partItemsError = [];

        let formDataError = false;
        // if (!isMangalamCement() && !get(values, 'meterEntry.value') && !get(values, 'runningHours')) {
        //     alert('Please select running hours or odometer!');
        //     return;
        // }
        if (get(values, 'vehicle.id', 0) == 0) {
            this.setState({ vehicleError: 'Please select a vehicle.' });
            return;
        } else {
            this.setState({ vehicleError: null });
        }

        let data = Object.assign({}, values);

        const discountSelectType = get(data, 'discountSelect');
        if (discountSelectType === '₹') {
            data = omit(data, 'discountPercentage');
        } else if (discountSelectType === '%') {
            data = omit(data, 'discount');
        }
        const laborTaxSelect = get(data, 'taxSelect');
        if (laborTaxSelect === '₹') {
            data = omit(data, 'laborTax1Percentage');
        } else if (laborTaxSelect === '%') {
            data = omit(data, 'laborTax1');
        }
        if (data.laborTax1Type !== 'IGST' && data.laborTax1Type !== 'VAT') {
            const laborTaxSelect = get(data, 'tax2Select');
            if (laborTaxSelect === '₹') {
                data = omit(data, 'laborTax2Percentage');
            } else if (laborTaxSelect === '%') {
                data = omit(data, 'laborTax2');
            }
        }

        set(data, 'vehicleId', values.vehicle.id);
        set(data, 'date', date ? date.valueOf() : getMomentTime().valueOf());
        if (startDate) {
            set(data, 'startDate', startDate.valueOf());
        }

        const length = data.serviceLineItems ? data.serviceLineItems.length : 0;
        if (length === 0) {
            if (!isMangalamCement()) {
                this.setState({ lineItemError: 'Please add at least one line item.' });
                return;
            }
        } else {
            this.setState({ lineItemError: null });

            let totalPartsTax = 0;
            for (let i = 0; i < length; i++) {
                const serviceLineItem = data.serviceLineItems[i];
                if (!serviceLineItem.itemId) {
                    formDataError = true;
                    _serviceTaskErrors[i] = `${SERVICE_ENTRY_TYPES_LABEL[serviceLineItem.itemType]} for Line Item ${
                        i + 1
                    } is required.`;
                }
                if (serviceLineItem.itemType === SERVICE_ENTRY_TYPES.SERVICE_TASK) {
                    const serviceTask = find(this.props.serviceTasksList, ['id', serviceLineItem.itemId]);
                    serviceLineItem.name = get(serviceTask, 'name', 'Service');
                } else if (serviceLineItem.itemType === SERVICE_ENTRY_TYPES.ISSUE) {
                    const issue = find(this.props.issuesList, ['id', serviceLineItem.itemId]);
                    serviceLineItem.name = get(issue, 'summary', 'Issue');
                }
                let serviceItemEmpty = false; // if more than 1 service item is empty then we need to throw error if 1 is empty then old flow will work
                // remove unused fields
                if (serviceLineItem.serviceParts.length) {
                    let _serviceLineItem = [...serviceLineItem.serviceParts];
                    _serviceLineItem.forEach((item, index) => {
                        const costPercentage =
                            (item.cost *
                                (toNumber((item.tax1Type ? item.tax1Percent : 0) || 0) +
                                    toNumber((item.tax2Type ? item.tax2Percent : 0) || 0))) /
                            100;
                        totalPartsTax = totalPartsTax + round(costPercentage, 2);
                        if (serviceItemEmpty && !item.part?.id) {
                            _partItemsError[
                                i
                            ] = `Part Type can not be empty for more than one part entry in Line Item ${i + 1}`;
                            formDataError = true;
                        } else if (!serviceItemEmpty && !item.part?.id) {
                            serviceItemEmpty = true;
                        }
                        if (!item.part?.id) {
                            // delete part if part id is empty or null
                            delete serviceLineItem.serviceParts[index].part;
                        }
                        if (item.tax1Type === 'IGST' || item.tax1Type === 'VAT') {
                            serviceLineItem.serviceParts[index].tax2Type = '';
                            serviceLineItem.serviceParts[index].tax2Percent = '';
                        }
                    });
                }
            }
            const totalLaborTax =
                (data.laborSubtotal *
                    (toNumber((data.laborTax1Type ? data.laborTax1Percentage : 0) || 0) +
                        toNumber((data.laborTax2Type ? data.laborTax2Percentage : 0) || 0))) /
                100;
            const totalAmount = get(data, 'totalAmount');
            let totalTaxLedgerAmount = 0;
            let totalDebitLedgerAmount = totalAmount;
            if (this.isViewLedgerAndModifyServiceEntryAllowed()) {
                if (get(data, 'ledgerDetails.igstLedgerEntry.ledgerId')) {
                    totalTaxLedgerAmount = totalPartsTax + totalLaborTax;
                    totalDebitLedgerAmount = totalAmount - (totalPartsTax + totalLaborTax || 0);
                    set(data, 'ledgerDetails.igstLedgerEntry.amount', round(totalTaxLedgerAmount, 2) || 0);
                }
                set(data, 'ledgerDetails.directIncomeLedgerEntry.amount', round(totalDebitLedgerAmount, 2));
            }
        }
        if (values.customFields) {
            let customFields = [];
            const allExistingCustomFields = values.customFields;
            const customFieldTemp = [];
            map(this.state.allMandatoryCustomFields, (cf, i) => {
                customFieldTemp.push({
                    field: cf.field,
                    value: get(allExistingCustomFields[i], 'value', ''),
                });
            });
            set(data, 'customFields', customFieldTemp);
        }

        this.setState({
            serviceTaskErrors: _serviceTaskErrors,
            partItemsError: _partItemsError,
        });

        if (formDataError) {
            return;
        }

        data = omit(data, 'serviceEntryImages');

        if (
            newUploadedImages.length > 0 ||
            uploadedImages.length > 0 ||
            newUploadedDocuments.length > 0 ||
            uploadedDocuments.length > 0
        ) {
            const uploadImagesArr = [];

            forEach(newUploadedImages, (image) => {
                uploadImagesArr.push({
                    url: image.uploadedUrl,
                    documentType: DOCUMENT_TYPE.IMAGE,
                });
            });

            forEach(uploadedImages, (image) => {
                uploadImagesArr.push({
                    url: image.uploadedUrl,
                    id: image.id,
                    documentType: DOCUMENT_TYPE.IMAGE,
                });
            });

            forEach(newUploadedDocuments, (document) => {
                uploadImagesArr.push({
                    url: document.uploadedUrl,
                    documentType: DOCUMENT_TYPE.DOCUMENT,
                });
            });

            forEach(uploadedDocuments, (document) => {
                uploadImagesArr.push({
                    url: document.uploadedUrl,
                    id: document.id,
                    documentType: DOCUMENT_TYPE.DOCUMENT,
                });
            });
            set(data, 'serviceEntryImages', uploadImagesArr);
        }

        data = omit(data, 'branchId');
        data = omit(data, 'isStationaryNumberEditAllowed');

        if (this.state.editable) {
            data = omit(data, 'vehicle');
            return this.props.updateServiceEntry(this.props.accesstoken, data, this.state.id).then((result) => {
                if (!this.mounted) {
                    return;
                }
                if (!result.error) {
                    this.props.navigate('/dashboard/maintenance/serviceentries');
                } else {
                    const error = handleError(result.payload.response);
                    this.setState({ submitError: error });
                }
            });
        } else {
            return this.props.createServiceEntry(this.props.accesstoken, data).then((result) => {
                if (!this.mounted) {
                    return;
                }
                if (!result.error) {
                    this.props.navigate('/dashboard/maintenance/serviceentries');
                } else {
                    const error = handleError(result.payload.response);
                    this.setState({ submitError: error });
                }
            });
        }
    }

    renderNewTextField(field) {
        const { type, min, step, input, disabled } = field;
        return (
            <div>
                <input
                    type={type}
                    className="form-control"
                    disabled={disabled}
                    {...(step ? { step: step } : {})}
                    {...input}
                    {...(min ? { min: min } : {})}
                    onWheel={(e) => e.currentTarget.blur()}
                />
            </div>
        );
    }

    renderNewTextFieldWithoutDiv(field) {
        const { type, min, step, input, disabled, max } = field;
        return (
            <input
                type={type}
                className="form-control"
                disabled={disabled}
                {...(step ? { step: step } : {})}
                {...input}
                {...(min ? { min: min } : {})}
                {...(max ? { max: max } : {})}
                onWheel={(e) => e.currentTarget.blur()}
            />
        );
    }

    renderLineItems = ({ partOptions, fields, meta: { error, submitFailed } }) => (
        <div className="my-2 list-unstyled">
            {fields.map((lineItem, index) => (
                <ServiceLineItem
                    serviceTasksList={this.props.serviceTasksList}
                    lineItem={lineItem}
                    fields={fields}
                    index={index}
                    key={index}
                    selector={selector}
                    loggedInUser={this.props.loggedInUser}
                    partOptions={partOptions || []}
                    change={get(this.props, 'change')}
                />
            ))}
            <div className="mt-2">
                <div className="pull-left">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() =>
                            fields.push({
                                itemType: SERVICE_ENTRY_TYPES.SERVICE_TASK,
                                serviceParts: [
                                    {
                                        part: { id: '' },
                                    },
                                ],
                            })
                        }
                    >
                        Add Line Item
                    </button>
                    {submitFailed && error && <span>{error}</span>}
                </div>
                <div className="pull-right">
                    <div className="d-none">
                        <button type="button" onClick={this.toggleServic} className="btn btn-link pl-0">
                            View Service Reminders
                        </button>
                    </div>
                </div>
                <div className="clearfix" />
            </div>
        </div>
    );

    onDateSelected(date) {
        this.setState({ date: date });
    }

    onCustomDateSelected = (date, index) => {
        let customFields = this.props.customFields ? this.props.customFields : [];
        customFields[index] = { ...customFields[index], value: date.valueOf() };
        this.props.dispatch(change('AddEditServiceEntryForm', `customFields`, customFields));
    };

    onStartDateSelected(date) {
        this.setState({ startDate: date });
    }

    renderServiceTaskErrors(serviceTaskErrors, _key = '') {
        return serviceTaskErrors.map((error, index) => {
            return (
                <li key={`serviceTask-${index}-${_key}`}>
                    <strong>{error}</strong>
                </li>
            );
        });
    }

    isViewLedgerAndModifyServiceEntryAllowed = () => {
        const scope = get(this.props.login, 'scope');
        if (
            isViewLedgersAllowedForUser(scope) &&
            (isCreateServiceEntryAllowedForUser(scope) || isUpdateServiceEntryAllowedForUser(scope))
        ) {
            return true;
        }
        return false;
    };

    onStationaryChange = (id) => {
        const key = 'stationaryNumber';
        if (id) {
            const selectedStationary = find(this.state.stationaryList, { id });
            const branchId = get(selectedStationary, 'branch.id');
            const bookType = get(selectedStationary, 'bookType');
            const bookName = get(selectedStationary, 'bookName');
            const nature = get(selectedStationary, 'nature');
            this.props
                .getNextStationary(this.props.accesstoken, branchId, bookType, bookName)
                .then((result) => {
                    if (result.error) {
                        throw new Error(handleError(result.payload.response));
                    } else {
                        const nextStationaryNumber = get(result, 'payload.data.[0].nextStationaryNumber');
                        this.props.change(key, nextStationaryNumber);
                        this.props.change('isStationaryNumberEditAllowed', includes(['MANUAL', 'BOOK_SERIES'], nature));
                    }
                })
                .catch((err) => {
                    alert(handleError(err));
                });
        } else {
            this.props.change(key, '');
        }
    };

    fetchStationaryData = (branchId) => {
        const { scope } = this.props.login;
        if (isViewStationaryAllowedForUser(scope)) {
            this.props.fetchStationaries(this.props.accesstoken, branchId, 'SERVICE_ENTRY', true).then((res) => {
                const d = filter(
                    get(res, 'payload.data', []),
                    (item) =>
                        !item.expiry ||
                        this.state.editable ||
                        (item.expiry && getMomentTime(item.expiry).isAfter(getMomentTime()))
                );
                this.setState({
                    stationaryList: d,
                });
            });
        }
    };

    arrangeCustomFields = (fields) => {
        return map(fields, (field) => {
            return { field: { ...field }, value: '' };
        });
    };

    getMandatoryFieldsType = (fields, type) => {
        return filter(fields, (customField) => {
            return customField.field.mandatory && customField.field.type === type;
        });
    };

    getMandatoryFields = (fields) => {
        return filter(fields, (customField) => {
            return (
                (customField.field.mandatory && customField.field.type === CUSTOM_ACCOUNT_FIELD_TYPE.SERVICE_ENTRY) ||
                (customField.field.mandatory && customField.field.type === CUSTOM_ACCOUNT_FIELD_TYPE.SERVICE_ENTRY)
            );
        });
    };

    fetchAllCustomFields = async (isEdit) => {
        let newData = { ...this.props.initialValues };
        this.props.fetchCustomFieldsForAccount(this.props.accesstoken).then((resp) => {
            const allCustomFields = this.arrangeCustomFields(get(resp, 'payload.data.customFields'));
            let indentMandatoryFields = isEdit
                ? this.getMandatoryFieldsType(this.state.customFieldForEdit, CUSTOM_ACCOUNT_FIELD_TYPE.SERVICE_ENTRY)
                : this.getMandatoryFieldsType(allCustomFields, CUSTOM_ACCOUNT_FIELD_TYPE.SERVICE_ENTRY);

            this.setState({
                allMandatoryCustomFields: indentMandatoryFields,
            });
        });
    };

    fetchWorkOrders = (vehicleId, jobListOptions = null) => {
        if (vehicleId) {
            this.fetchJobList(vehicleId, this.state.dateRange, jobListOptions);
            this.props.fetchDataFromModulesForVehicle(this.props.accesstoken, vehicleId).then((res) => {
                if (!res.error) {
                    const data = get(res, 'payload.data', {});
                    this.setState({
                        workOrderOptions: [
                            {
                                label: data.workOrderNumber,
                                value: data.workOrderId,
                                stationaryNumber: data.workOrderNumber,
                            },
                        ],
                    });
                } else {
                    this.setState({
                        workOrderOptions: [],
                    });
                }
            });
        } else {
            this.setState({
                workOrderOptions: [],
            });
        }
        this.setState({
            vehicleId,
        });
    };

    onWorkOrderSelect = (workOrderId) => {
        if (workOrderId) {
            const stationaryNumber = get(
                find(this.state.workOrderOptions, { value: workOrderId }),
                'stationaryNumber',
                ''
            );
            this.props.change('workOrderNumber', stationaryNumber);
        }
    };

    renderCustomFields = (props) => {
        const fieldPrefix = 'customFields';
        const className = 'col-md-3 my-2 pl-0';
        return (
            <div className="row mx-0">
                {map(get(this.state, 'allMandatoryCustomFields', []), (field, index) => {
                    const fieldId = `customFields.${index}.${get(field, 'id')}`;
                    return (
                        <div
                            key={`field-outer-${index}-${get(field, 'field.id')}`}
                            className={className ? className : 'col-md-2'}
                        >
                            <div className="text-left">
                                {upperFirst(get(field, 'field.keyName'))}
                                <sup className="text-info" style={{ fontSize: 10 }}>
                                    Custom
                                </sup>{' '}
                            </div>
                            {/* <Field
                                name={getFieldName(fieldPrefix, index, 'field.id')}
                                component={renderTextFieldStackedLabel}
                                style={{
                                    visibility: 'hidden',
                                    maxHeight: '1px',
                                    height: '1px',
                                    padding: '2px',
                                }}
                                labelClass={'d-block'}
                                type="text"
                                value={get(field, 'field.id')}
                                key={`${get(field, 'field.keyName')}-${index}-${get(field, 'field.id')}`}
                            /> */}
                            {get(field, 'field.fieldType') === 'MCQ_DROPDOWN' ? (
                                <Field
                                    name={getFieldName(fieldPrefix, index, 'value')}
                                    component={FxSelectFieldRedux}
                                    options={getNameDropDownObject(get(field, 'field.answers', []))}
                                    style={{ minHeight: '38px', height: '38px' }}
                                    labelClass={'d-block'}
                                    type="text"
                                    key={`${get(field, 'field.keyName')}-${index}-${get(field, 'field.id')}-value`}
                                />
                            ) : get(field, 'field.fieldType') === 'BOOLEAN' ? (
                                <Field
                                    name={getFieldName(fieldPrefix, index, 'value')}
                                    component="input"
                                    type="checkbox"
                                    style={{ minHeight: '38px', height: '38px' }}
                                    labelClass={'d-block'}
                                    key={`${get(field, 'field.keyName')}-${index}-${get(field, 'field.id')}-value`}
                                />
                            ) : get(field, 'field.fieldType') === 'DATE' ? (
                                <Field
                                    name={getFieldName(fieldPrefix, index, 'value')}
                                    component={CalendarSingleDatePicker}
                                    startDate={get(field, `value`)}
                                    timePicker={true}
                                    onDateSelected={(date) => {
                                        this.onCustomDateSelected(date, index);
                                    }}
                                    style={{ minHeight: '38px', height: '38px' }}
                                    buttonWidth="240px"
                                    labelClass={'d-block'}
                                    key={`${get(field, 'field.keyName')}-${index}-${get(field, 'field.id')}-value`}
                                />
                            ) : (
                                <Field
                                    name={getFieldName(fieldPrefix, index, 'value')}
                                    component={renderTextFieldStackedLabel}
                                    style={{ minHeight: '38px', height: '38px' }}
                                    labelClass={'d-block'}
                                    type="text"
                                    key={`${get(field, 'field.keyName')}-${index}-${get(field, 'field.id')}-value`}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        const {
            handleSubmit,
            vehicleListMini,
            hasStartDate,
            vendors,
            serviceTasksList,
            submitting,
            discountSelect,
            taxSelect,
            tax2Select,
            vehicle,
        } = this.props;

        const isViewLedgerAndModifyServiceEntry = this.isViewLedgerAndModifyServiceEntryAllowed();

        const vendorTypesToFilter = ['Parts', 'Tyre', 'Service'];

        const filteredVendors = filter(vendors, (vdr) => {
            return includes(vendorTypesToFilter, vdr.vendorType) && get(vdr, 'ledgerId');
        });

        const {
            editable,
            date,
            startDate,
            subtotal,
            uploadedDocuments,
            uploadedImages,
            serviceTaskErrors,
            partItemsError,
            submitError,
            vehicleError,
            lineItemError,
        } = this.state;
        const makePositive = (value) => value && Math.abs(value);
        const isMangalamAccount = isMangalamCement();

        return (
            <div className="card-group my-4">
                <div className="card p-1">
                    <div className="card-block">
                        <div className="text-left">
                            <h4>{this.state.editable ? 'Edit Service Entry' : 'Add Service Entry'}</h4>
                            <hr />
                            <div className="service-entry-form">
                                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                    <div
                                        hidden={
                                            !(
                                                serviceTaskErrors?.length > 0 ||
                                                partItemsError?.length > 0 ||
                                                submitError ||
                                                vehicleError ||
                                                lineItemError
                                            )
                                        }
                                        className="card"
                                    >
                                        <div className="card-block text-center" style={{ margin: '0 auto' }}>
                                            <ul className="text-danger">
                                                {submitError && (
                                                    <li>
                                                        <strong>{submitError}</strong>
                                                    </li>
                                                )}
                                                {vehicleError && (
                                                    <li>
                                                        <strong>{vehicleError}</strong>
                                                    </li>
                                                )}
                                                {lineItemError && (
                                                    <li>
                                                        <strong>{lineItemError}</strong>
                                                    </li>
                                                )}
                                                {serviceTaskErrors &&
                                                    serviceTaskErrors.length > 0 &&
                                                    this.renderServiceTaskErrors(serviceTaskErrors)}
                                                {Boolean(partItemsError.length) &&
                                                    this.renderServiceTaskErrors(partItemsError, 'partItem')}
                                            </ul>
                                        </div>
                                    </div>
                                    {isViewStationaryAllowedForUser(this.props.login.scope) && (
                                        <div>
                                            <div className="form-group row">
                                                <div className="col-md-3">
                                                    <label>Branch</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <Field
                                                        name="branchId"
                                                        placeholder="Select Branch"
                                                        clearable={false}
                                                        options={mappedBranches(this.state.branches)}
                                                        component={SelectInput}
                                                        onSelected={(val) => {
                                                            this.props.change('stationaryId', '');
                                                            this.props.change('stationaryNumber', '');
                                                            this.fetchStationaryData(val);
                                                        }}
                                                        disabled={this.state.editable}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-3">
                                                    <label>Stationary</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <Field
                                                        name="stationaryId"
                                                        placeholder="Select Stationary"
                                                        clearable={false}
                                                        options={mappedStationaries(this.state.stationaryList)}
                                                        component={SelectInput}
                                                        onSelected={this.onStationaryChange}
                                                        disabled={this.props.editable}
                                                    />
                                                </div>
                                            </div>

                                            <Field
                                                disabled={
                                                    !this.props.isStationaryNumberEditAllowed || this.props.editable
                                                }
                                                name="stationaryNumber"
                                                type={'text'}
                                                component={renderTextField}
                                                label={`Stationary Number`}
                                            />
                                        </div>
                                    )}
                                    {this.props.params.vehicleId && (
                                        <Field
                                            name="vehicle.licensePlate"
                                            type="text"
                                            component={this.renderField}
                                            label="Vehicle Number"
                                            disableOnEdit={true}
                                        />
                                    )}
                                    {!this.props.params.vehicleId && (
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <label>
                                                    Vehicle<abbr className="text-danger">*</abbr>
                                                </label>
                                            </div>
                                            <div className="col-md-9">
                                                <Field
                                                    name="vehicle.id"
                                                    placeholder="Select Vehicle"
                                                    options={mappedVehiclesMini(vehicleListMini, false)}
                                                    component={SelectInput}
                                                    disabled={editable}
                                                    onSelected={this.fetchWorkOrders}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group row">
                                        <div className="col-md-3">
                                            <label>Job</label>
                                        </div>
                                        <div className="col-md-3">
                                            <Field
                                                name="jobId"
                                                placeholder="Select Job"
                                                options={this.state.jobListOptions}
                                                component={SelectInput}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CalendarComponent
                                                onDateSelected={this.onDateSelect}
                                                startDate={this.state.dateRange.startDate}
                                                endDate={this.state.dateRange.endDate}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <label>Work Order</label>
                                            </div>
                                            <div className="col-md-9">
                                                <Field
                                                    name="workOrderId"
                                                    placeholder="Select Work Order"
                                                    options={this.state.workOrderOptions}
                                                    component={SelectInput}
                                                    //disabled={editable}
                                                    onSelected={this.onWorkOrderSelect}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {!isOdometerNonMandatoryAndHiddenInServiceEntries() && (
                                        <Field
                                            name="meterEntry.value"
                                            min="0"
                                            type="number"
                                            step="any"
                                            isRequired={true}
                                            normalize={makePositive}
                                            component={this.renderField}
                                            label="Odometer"
                                            help="Meter Reading at time of service"
                                        />
                                    )}

                                    {!isOdometerNonMandatoryAndHiddenInServiceEntries() && (
                                        <div className="form-group row">
                                            <div className="col-md-3" />
                                            <div className="col-md-9">
                                                <div className="row row-checkbox">
                                                    <Field
                                                        colclass="col-md-3"
                                                        name="meterEntry.voided"
                                                        id="meterEntry.isVoid"
                                                        component={this.renderCheckBoxField}
                                                        type="checkbox"
                                                        label="Mark as void"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <Field
                                        name="runningHours"
                                        min="0"
                                        type="number"
                                        step="any"
                                        normalize={makePositive}
                                        component={this.renderField}
                                        label="Running Hours"
                                    />
                                    <div className="form-group row">
                                        <div className="col-md-3">
                                            <label>
                                                Completion Date<abbr className="text-danger">*</abbr>
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            <CalendarSingleDatePicker
                                                onDateSelected={this.onDateSelected}
                                                timePicker={true}
                                                buttonWidth="240px"
                                                startDate={date ? date : getMomentTime()}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-3">
                                            <label>Set Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Field
                                                name="setStartDate"
                                                id="setStartDate"
                                                component="input"
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                    {hasStartDate && (
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <label>Start Date</label>
                                            </div>
                                            <div className="col-md-9">
                                                <CalendarSingleDatePicker
                                                    onDateSelected={this.onStartDateSelected}
                                                    timePicker={true}
                                                    buttonWidth="240px"
                                                    startDate={startDate}
                                                    maxDate={date ? date : getMomentTime()}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!isMangalamAccount && (
                                        <div className="form-group row">
                                            <div className="col-md-3">
                                                <label>Vendor</label>
                                            </div>
                                            <div className="col-md-9">
                                                <Field
                                                    name="vendor.id"
                                                    placeholder="Please select"
                                                    options={mappedVendors(
                                                        isViewLedgerAndModifyServiceEntry ? filteredVendors : vendors
                                                    )}
                                                    component={SelectInput}
                                                    optionRenderer={this.renderVendorOption.bind(this)}
                                                    disabled={!!editable}
                                                />
                                                <AddNewVendorComponent onSuccess={this.onSuccessfulVendorCreation} />
                                            </div>
                                        </div>
                                    )}

                                    {!isMangalamAccount && (
                                        <Field
                                            name="reference"
                                            type="text"
                                            component={this.renderField}
                                            label="Reference"
                                            // disableOnEdit={isViewLedgerAndModifyServiceEntry}
                                            help="(e.g. invoice number, receipt number etc. )"
                                        />
                                    )}
                                    <div className="form-group row">
                                        <div className="col-md-3">
                                            <label>{t('modeOfPayment')}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="radio d-inline-block" style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="Cash"
                                                    />{' '}
                                                    {t('cash')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block" style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="Net Banking"
                                                    />{' '}
                                                    {t('netBanking')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="Happay"
                                                    />{' '}
                                                    {t('happay')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="IOCL"
                                                    />{' '}
                                                    {t('iocl')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="UPI"
                                                    />{' '}
                                                    {t('upi')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="HPCL"
                                                    />{' '}
                                                    {t('hpcl')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="BPCL"
                                                    />{' '}
                                                    {t('bpcl')}
                                                </label>
                                            </div>

                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="Reliance"
                                                    />{' '}
                                                    {t('reliance')}
                                                </label>
                                            </div>
                                            <div className="radio d-inline-block " style={{ marginLeft: 1.25 + 'rem' }}>
                                                <label className="mr-2">
                                                    <Field
                                                        name="modeOfPayment"
                                                        component="input"
                                                        type="radio"
                                                        value="FASTag Wallet"
                                                    />{' '}
                                                    {t('fastTagWallet')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-muted h6">Line Items</div>
                                    <hr />
                                    <FieldArray
                                        name="serviceLineItems"
                                        props={{ partOptions: this.state.partOptions }}
                                        component={this.renderLineItems}
                                    />
                                    <div className="text-muted h6 mt-2">Custom Field</div>
                                    <hr />
                                    <FieldArray name={`customFields`} component={this.renderCustomFields} />
                                    <div className="form-group row">
                                        <div className={`${isMangalamAccount ? 'col-md-12' : 'col-md-6'}`}>
                                            <div>General Notes</div>
                                            <div>
                                                <Field name="notes" rows={3} component={this.renderTextArea} />
                                            </div>
                                        </div>
                                        {!isMangalamAccount && (
                                            <div className="col-md-6">
                                                <div>
                                                    <div>
                                                        <div className="pull-left">Subtotal:</div>
                                                        {gt(subtotal, 0) && (
                                                            <div className="pull-right">
                                                                {subtotal}{' '}
                                                                {getCurrencyFromCountry(this.props.loggedInUser, true)}
                                                            </div>
                                                        )}
                                                        <div className="clearfix" />
                                                    </div>
                                                    <hr />
                                                    <div className="form-group row">
                                                        <div className="col-md-3">Discount:</div>
                                                        <div className="col-md-9">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <Field
                                                                        style={{ height: '35px' }}
                                                                        name="discountSelect"
                                                                        component="select"
                                                                    >
                                                                        <option value="%">%</option>
                                                                        <option
                                                                            value={`${getCurrencyFromCountry(
                                                                                this.props.loggedInUser
                                                                            )}`}
                                                                        >
                                                                            {getCurrencyFromCountry(
                                                                                this.props.loggedInUser
                                                                            )}
                                                                        </option>
                                                                    </Field>
                                                                </div>
                                                                {discountSelect && discountSelect !== '%' && (
                                                                    <Field
                                                                        name="discount"
                                                                        component={this.renderNewTextFieldWithoutDiv}
                                                                        min="0"
                                                                        type="number"
                                                                        step="any"
                                                                        parse={(val) => round(val, 2)}
                                                                    />
                                                                )}
                                                                {(!discountSelect || discountSelect === '%') && (
                                                                    <Field
                                                                        name="discountPercentage"
                                                                        component={this.renderNewTextFieldWithoutDiv}
                                                                        min="0"
                                                                        max="100"
                                                                        type="number"
                                                                        step="any"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row mt-2">
                                                        <div className="col-md-3">
                                                            Labor({getCurrencyFromCountry(this.props.loggedInUser)}):
                                                        </div>
                                                        <div className="col-12 col-md-9">
                                                            <Field
                                                                name="laborSubtotal"
                                                                component={this.renderNewTextField}
                                                                min="0"
                                                                disabled={true}
                                                                type="number"
                                                                step="any"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-3">Labor Tax Type:</div>
                                                        <div className="col-md-9">
                                                            <Field
                                                                name="laborTax1Type"
                                                                placeholder="Select Tax Type"
                                                                options={TAX_TYPES_V2}
                                                                component={SelectInput}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-md-3">Labor Tax:</div>
                                                        <div className="col-md-9">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <Field
                                                                        style={{ height: '35px' }}
                                                                        name="taxSelect"
                                                                        component="select"
                                                                    >
                                                                        <option value="%">%</option>
                                                                        <option
                                                                            value={getCurrencyFromCountry(
                                                                                this.props.loggedInUser
                                                                            )}
                                                                        >
                                                                            {getCurrencyFromCountry(
                                                                                this.props.loggedInUser
                                                                            )}
                                                                        </option>
                                                                    </Field>
                                                                </div>
                                                                {taxSelect && taxSelect !== '%' && (
                                                                    <Field
                                                                        name="laborTax1"
                                                                        component={this.renderNewTextFieldWithoutDiv}
                                                                        min="0"
                                                                        type="number"
                                                                        step="any"
                                                                        parse={(val) => round(val, 2)}
                                                                    />
                                                                )}
                                                                {(!taxSelect || taxSelect === '%') && (
                                                                    <Field
                                                                        name="laborTax1Percentage"
                                                                        component={this.renderNewTextFieldWithoutDiv}
                                                                        min="0"
                                                                        max="100"
                                                                        type="number"
                                                                        step="any"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.shouldShowTax2Type(this.props.laborTax1Type) ? (
                                                        <>
                                                            <div className="form-group row">
                                                                <div className="col-md-3">Labor Tax 2 Type:</div>
                                                                <div className="col-md-9">
                                                                    <Field
                                                                        name="laborTax2Type"
                                                                        placeholder="Select Tax 2 Type"
                                                                        options={TAX_TYPES_V2}
                                                                        component={SelectInput}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-md-3">Labor Tax2:</div>
                                                                <div className="col-md-9">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <Field
                                                                                style={{ height: '35px' }}
                                                                                name="tax2Select"
                                                                                component="select"
                                                                            >
                                                                                <option value="%">%</option>
                                                                                <option
                                                                                    value={getCurrencyFromCountry(
                                                                                        this.props.loggedInUser
                                                                                    )}
                                                                                >
                                                                                    {getCurrencyFromCountry(
                                                                                        this.props.loggedInUser
                                                                                    )}
                                                                                </option>
                                                                            </Field>
                                                                        </div>
                                                                        {tax2Select && tax2Select !== '%' && (
                                                                            <Field
                                                                                name="laborTax2"
                                                                                component={
                                                                                    this.renderNewTextFieldWithoutDiv
                                                                                }
                                                                                min="0"
                                                                                type="number"
                                                                                step="any"
                                                                            />
                                                                        )}
                                                                        {(!tax2Select || tax2Select === '%') && (
                                                                            <Field
                                                                                name="laborTax2Percentage"
                                                                                component={
                                                                                    this.renderNewTextFieldWithoutDiv
                                                                                }
                                                                                min="0"
                                                                                max="100"
                                                                                type="number"
                                                                                step="any"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    {isViewLedgerAndModifyServiceEntry && (
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <label>Debit Ledger</label>
                                                                <abbr className="text-danger">*</abbr>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <Field
                                                                    name="ledgerDetails.directIncomeLedgerEntry.ledgerId"
                                                                    placeholder="Please select"
                                                                    options={mappedOptionsFromArray(
                                                                        filter(this.props.ledgers, (ledger) =>
                                                                            includes(
                                                                                [6, 12],
                                                                                get(ledger, 'group.baseGroupId')
                                                                            )
                                                                        ),
                                                                        'ledgerName'
                                                                    )}
                                                                    component={SelectInput}
                                                                    clearable={true}
                                                                    isRequired
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {isViewLedgerAndModifyServiceEntry && (
                                                        <div className="form-group row">
                                                            <div className="col-md-3">
                                                                <label>Tax Ledger</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <Field
                                                                    name="ledgerDetails.igstLedgerEntry.ledgerId"
                                                                    placeholder="Please select"
                                                                    options={mappedOptionsFromArray(
                                                                        this.props.ledgers,
                                                                        'ledgerName'
                                                                    )}
                                                                    component={SelectInput}
                                                                    clearable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                    <hr />
                                                    <div className="form-group row">
                                                        <div className="col-md-3">
                                                            Total({getCurrencyFromCountry(this.props.loggedInUser)}):
                                                        </div>
                                                        <div className="col-12 col-md-9">
                                                            <Field
                                                                name="totalAmount"
                                                                component={this.renderNewTextField}
                                                                min="0"
                                                                disabled={true}
                                                                type="number"
                                                                step="any"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {vehicle && (
                                        <div className="form-group row mt-4">
                                            <div className="col-md-3">
                                                <label>Photo</label>
                                            </div>
                                            <div className="col-md-9">
                                                <FxMultiFileDropzoneComponent
                                                    accept="image/jpeg, image/png, image/jpg, image/gif"
                                                    // id={vehicle.id}
                                                    message="Try dropping some photos here, or click to select photos to upload."
                                                    name="photos"
                                                    buttonText="Uploaded Photos"
                                                    fileList={uploadedImages}
                                                    folderPath={`/vehicle/${vehicle.id}/serviceentry/`}
                                                    fileSuccessCallback={this.fileImageSuccessCallback}
                                                    fileDeleteCallback={this.fileImageDeleteCallback}
                                                    fileErrorCallback={noop}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {vehicle && (
                                        <div className="form-group row mt-4">
                                            <div className="col-md-3">
                                                <label>Document</label>
                                            </div>
                                            <div className="col-md-9">
                                                <FxMultiFileDropzoneComponent
                                                    // id={vehicle.id}
                                                    message="Try dropping some files here, or click to select files to upload."
                                                    name="documents"
                                                    uploadedText="Uploaded Documents"
                                                    fileList={uploadedDocuments}
                                                    folderPath={`/vehicle/${vehicle.id}/serviceentry/`}
                                                    fileSuccessCallback={this.fileDocumentSuccessCallback}
                                                    fileDeleteCallback={this.fileDocumentDeleteCallback}
                                                    fileErrorCallback={noop}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="mb-2 float-right">
                                        <button type="submit" disabled={submitting} className="btn btn-primary mr-1">
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={this.goBack.bind(this)}
                                            data-csbox="true"
                                            data-gacategory="Vehicle Records"
                                            data-galabel="Service History - Cancel"
                                            data-gaaction="Click"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}

function mapStateToProps(state, ownProps) {
    const { data } = state.serviceEntries;
    const discountSelect = selector(state, 'discountSelect');
    const stationaryId = selector(state, 'stationaryId');
    const branchId = selector(state, 'branchId');
    const taxSelect = selector(state, 'taxSelect');
    const tax2Select = selector(state, 'tax2Select');
    const hasServiceLineItems = selector(state, 'serviceLineItems');
    const discount = selector(state, 'discount');
    const discountPercentage = selector(state, 'discountPercentage');
    const laborTax1 = selector(state, 'laborTax1');
    const laborTax1Type = selector(state, 'laborTax1Type');
    const laborTax1Percentage = selector(state, 'laborTax1Percentage');
    const laborTax2 = selector(state, 'laborTax2');
    const laborTax2Percentage = selector(state, 'laborTax2Percentage');
    const hasStartDate = selector(state, 'setStartDate');
    const isStationaryNumberEditAllowed = selector(state, 'isStationaryNumberEditAllowed');
    const customFields = selector(state, 'customFields');

    let vehicle = state.vehicles.data[ownProps.params.vehicleId];
    if (!ownProps.params.vehicleId) {
        const vehicleId = selector(state, 'vehicle.id');
        if (vehicleId) {
            vehicle = {};
            if (vehicleId.value) {
                vehicle.id = vehicleId.value;
            } else {
                vehicle.id = vehicleId;
            }
        }
    }
    const defaultInitialValues = {};
    if (isOdometerNonMandatoryAndHiddenInServiceEntries()) {
        set(defaultInitialValues, 'meterEntry.value', -1);
        set(defaultInitialValues, 'meterEntry.voided', true);
    }
    const configuration = {
        login: get(state, 'login.data', {}),
        accesstoken: state.login.data.access_token,
        ledgers: state.consignments.ledgers,
        ledgersGroups: get(state.consignments.accountGroups, 'content'),
        isStationaryNumberEditAllowed,
        vehicleListMini: state.vehicles.vehicleListMini,
        hasStartDate,
        discountSelect,
        taxSelect,
        hasServiceLineItems,
        tax2Select,
        discount,
        discountPercentage,
        laborTax1,
        laborTax1Type,
        laborTax1Percentage,
        laborTax2,
        laborTax2Percentage,
        vehicle: vehicle,
        serviceTasksList: state.servicetasks.serviceTasksList,
        vendors: state.vendors.vendorList,
        loggedInUser: state.settings.loggedInUser,
        defaultGroupId: state.local.defaultGroupId,
        issuesList: state.issues.issuesList,
        stationaryId,
        branchId,
        customFields,
        initialValues: {
            ...defaultInitialValues,
        },
    };
    const currency = getCurrencyFromCountry(state.settings.loggedInUser);
    if (ownProps && data && data[ownProps.params.id]) {
        const serviceEntry = data[ownProps.params.id];
        set(serviceEntry, 'vehicle', vehicle);
        return {
            ...configuration,
            ...{
                initialValues: {
                    ...serviceEntry,
                    discountSelect: get(serviceEntry, 'discount') ? currency : '%',
                    taxSelect: get(serviceEntry, 'laborTax1') ? currency : '%',
                    tax2Select: get(serviceEntry, 'laborTax2') ? currency : '%',
                    serviceLineItems: map(get(serviceEntry, 'serviceLineItems'), (lineItem) => {
                        return {
                            ...lineItem,
                            discountSelect: get(lineItem, 'discount') ? currency : '%',
                            taxSelect: get(lineItem, 'tax1') ? currency : '%',
                            tax2Select: get(lineItem, 'tax2') ? currency : '%',
                            serviceParts: map(get(lineItem, 'serviceParts', []), (servicePart) => {
                                const unit = get(servicePart, 'unit', '');
                                let unitType = '';
                                forEach(UNIT_TYPE_TO_UNITS_MAP, (value, key) => {
                                    if (get(value, unit, false)) {
                                        unitType = key;
                                    }
                                });
                                return {
                                    ...servicePart,
                                    unitType,
                                };
                            }),
                        };
                    }),
                },
            },
        };
    } else {
        return configuration;
    }
}

function validate(values, props) {
    const errors = { meterEntry: {} };
    const scope = get(props.login, 'scope');
    if (!isOdometerNonMandatoryAndHiddenInServiceEntries()) {
        if (!values.meterEntry || !values.meterEntry.value) {
            errors.meterEntry.value = 'Odometer value is required.';
        }
    }

    if (values.meterEntry && values.meterEntry.value <= 0) {
        errors.meterEntry.value = 'Odometer value should be greater than zero.';
    }

    if (
        isViewLedgersAllowedForUser(scope) &&
        (isCreateServiceEntryAllowedForUser(scope) || isUpdateServiceEntryAllowedForUser(scope)) &&
        !get(values, 'ledgerDetails.directIncomeLedgerEntry.ledgerId')
    ) {
        set(errors, 'ledgerDetails.directIncomeLedgerEntry.ledgerId', 'Please Select Debit Ledger');
    }

    return errors;
}

AddEditServiceEntryComponent = reduxForm({
    validate,
    enableReinitialize: true,
    form: 'AddEditServiceEntryForm',
})(AddEditServiceEntryComponent);

AddEditServiceEntryComponent = connect(mapStateToProps, {
    fetchVehicleListMini,
    fetchVendors,
    fetchServiceTasks,
    createServiceEntry,
    updateServiceEntry,
    fetchServiceEntry,
    fetchVehicleById,
    searchIssues,
    fetchParts,
    fetchBranches,
    fetchStationaries,
    getNextStationary,
    fetchStationary,
    fetchLedgers,
    fetchLedgersGroups,
    fetchCustomFieldsForAccount,
    fetchDataFromModulesForVehicle,
    fetchJobListNewV2,
})(AddEditServiceEntryComponent);
export default withRoute(AddEditServiceEntryComponent);
